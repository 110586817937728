import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { MOVING_STATUS } from '../../fields'
import { getMovings } from '../../utils/DataService'
import { dateFormat } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'

export default class MovingListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 100,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Tipo',
          field: 'moving_type.name',
          width: 100,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Data',
          field: 'date',
          width: 100,
          filter: 'text',
          suppressMenu: true,
          valueGetter: dateFormat
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 40,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => MOVING_STATUS[params.data.status]
        }
      ]
    }
  }

  onQuickFilterText = (event) => {
    this.setState({ quickFilterText: event })
  }

  onGridReady = (params) => {
    this.setState({ loading: true })

    getMovings().then((data) => {
      params.api.setRowData(data)
      this.setState({ rowData: data, loading: false })
      params.api.sizeColumnsToFit()
    }).catch((err) => {
      this.setState({ loading: false })
    })
  }

  render() {
    return (
      <PageBase title="Mudanças" create={false} onFilterChange={this.onQuickFilterText}>
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady}
                       pagination={true}
                       paginationPageSize={50}
                       domLayout={'autoHeight'}
          />

        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
