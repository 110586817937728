import {toast as toastr} from 'react-toastify'
import {call, put} from 'redux-saga/effects'
import {fetchApi, postApi, putApi} from '../../services/api'
import ToasterService from '../../services/ToasterService'
import {requestError, sendingRequest} from '../actions/loginActions'
import {ActionTypesBase} from './ducks_base'

const GetParams = {
  url_params: ''
}

const PostParams = {
  refresh: false,
  url_params: '',
}

const PutParams = {
  refresh: true,
  close_modal: true,
  url_params: ''
}

type GenericParamsType = {
  action_success?: any,
  msg?: string
}

const GenericGetParams: GenericParamsType = {}

const GenericPostParams = GenericGetParams

export function* getSagasBase(actionMap: ActionTypesBase, route: string, action: any, params: typeof GetParams = GetParams) {
  yield put(sendingRequest(true))
  const data = action.payload

  try {
    const response = yield call(async () => {
      const full_route = params.url_params ? `admin/${route}/${data.id}${params.url_params}` : `admin/${route}/${data.id}`
      return await fetchApi(full_route)
        .then((res: any) => res)
        .catch((err: any) => Promise.reject(err))
    })

    yield put(actionMap.get_success(response))
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put(requestError(error.message))
    return false
  } finally {
    yield put(sendingRequest(false))
  }
}

export function* postSagasBase(actionMap: ActionTypesBase, route: string, action: any, params: typeof PostParams = PostParams) {
  yield put(sendingRequest(true))
  const data = action.payload

  try {
    const response = yield call(async () => {
      const full_route = params.url_params ? `admin/${route}${params.url_params}` : `admin/${route}`
      return await postApi(full_route, data).then((res: any) => res).catch((err: any) => Promise.reject(err))
    })

    yield put(actionMap.post_success(response))

    if (params.refresh) {
      yield put(actionMap.all())
    }

    yield put(actionMap.modal(false))
    toastr.success('Adicionado com sucesso')
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put(requestError(error.message))
    return false
  } finally {
    yield put(sendingRequest(false))
  }
}

export function* putSagasBase(actionMap: ActionTypesBase, route: string, action: any, params: typeof PutParams = PutParams) {
  yield put(sendingRequest(true))
  const { id, data } = action.payload

  try {
    const response = yield call(async () => {
      return await putApi(`admin/${route}`, data, id, params.url_params).then((res: any) => res).catch((err: any) => Promise.reject(err))
    })

    yield put(actionMap.put_success(response))
    yield put(actionMap.current(response))

    if (params.refresh) {
      yield put(actionMap.all())
    }

    if (params.close_modal) {
      yield put(actionMap.modal(false))
    }

    toastr.success('Atualizado com sucesso')
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put(requestError(error.message))
    return false
  } finally {
    yield put(sendingRequest(false))
  }
}

export function* genericPostSagas(route: string, data: any, params: typeof GenericPostParams = GenericPostParams) {
  yield put(sendingRequest(true))

  try {
    const response = yield call(async () => {
      return await postApi(`admin/${route}`, data)
        .then((res: any) => res)
        .catch((err: any) => Promise.reject(err))
    })

    if (params.action_success) {
      // @ts-ignore
      yield put(params.action_success(response))
    }

    if (params.msg) {
      toastr.success(params.msg)
    }

    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put(requestError(error.message))
    return false
  } finally {
    yield put(sendingRequest(false))
  }
}
