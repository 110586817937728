import React from 'react'

// ao acionar estar remotando e atualizando os equipamentos tudo de novo

const VideosPage = () => {
  return (
    <div
      // style="position:relative; width:1px; min-width:100%;padding-bottom:178.571%;"

      style={{
        position: 'relative',
        width: '1px',
        minWidth: '100%',
        paddingBottom: '178.571%'
      }}
    >
      <iframe
        allow="autoplay"
        className="spotlightr"
        allowTransparency={true}
        // style="width:1px; min-width:100%; height: 100%; position:absolute"

        style={{
          width: '1px',
          minWidth: '100%',
          height: '100%',
          position: 'absolute',
        }}

        allowFullScreen={true} src="https://videos.cdn.spotlightr.com/watch/gallery/MzczMw=="
        frameBorder="0"
        scrolling="no"
        name="videoPlayer">

      </iframe>
    </div>
  )
}

export default VideosPage

