import {call, put} from 'redux-saga/effects'
import {REQUEST_ERROR, SENDING_REQUEST} from '../constants'
import {fetchApi, postApi, putApi} from '../utils/api'
import {createAction} from 'redux-actions'
import {getValue} from '../utils/Formatters'

let ToasterService = ''

export function* genericAllSagasBase(action_success, route) {
  yield put({type: SENDING_REQUEST, sending: true})

  try {
    const response = yield call(async () => {
      return await fetchApi(`admin/${route}`)
        .then(res => res.data)
        .catch(err => Promise.reject(err))
    })

    yield put(action_success(response))
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

export function* allSagasBase(actionMap, route, action = null) {
  yield put({type: SENDING_REQUEST, sending: true})

  try {
    const response = yield call(async () => {
      return await fetchApi(`admin/${route}`)
        .then(res => res.data)
        .catch(err => Promise.reject(err))
    })
    yield put(actionMap.all_success(response))

    if (getValue(action, 'payload')) {
      let result = response.find(i => i.id === action.payload)
      if (result) {
        yield put(actionMap.current(result))
        yield put(actionMap.modal(true))
      }
    }

    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

export function* getSagasBase(actionMap, route, action, params = null) {
  yield put({type: SENDING_REQUEST, sending: true})
  const data = action.payload

  try {
    const response = yield call(async () => {
      const full_route = params ? `admin/${route}/${data.id}${params}` : `admin/${route}/${data.id}`
      return await fetchApi(full_route)
        .then(res => res)
        .catch(err => Promise.reject(err))
    })

    yield put(actionMap.get_success(response))
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

export function* postSagasBase(actionMap, route, action, refresh = false) {
  yield put({type: SENDING_REQUEST, sending: true})
  const data = action.payload

  try {
    const response = yield call(async () => {
      return await postApi(`admin/${route}`, data).then(res => res).catch(err => Promise.reject(err))
    })

    yield put(actionMap.post_success(response))

    if (refresh) {
      yield put(actionMap.all())
    }

    yield put(actionMap.modal(false))
    // toastr.success('Adicionado com sucesso')
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

export function* putSagasBase(actionMap, route, action, close_modal = false) {
  yield put({type: SENDING_REQUEST, sending: true})
  const {id, data} = action.payload

  try {
    const response = yield call(async () => {
      return await putApi(`admin/${route}`, data, id).then(res => res).catch(err => Promise.reject(err))
    })

    yield put(actionMap.put_success(response))
    yield put(actionMap.current(response))
    yield put(actionMap.all())
    if(close_modal){
      yield put(actionMap.modal(false))
    }

    // toastr.success('Atualizado com sucesso')
    return response
  } catch (error) {
    ToasterService.sendErrors(error)
    yield put({type: REQUEST_ERROR, error: error.message})
    return false
  } finally {
    yield put({type: SENDING_REQUEST, sending: false})
  }
}

export function reducersMap(actionMap) {
  return {
    [actionMap.all_success]: (state, action) => ({...state, data: action.payload}),
    [actionMap.get_success]: (state, action) => ({...state, current: action.payload}),
    [actionMap.post_success]: (state, action) => ({...state, data: [action.payload, ...state.data]}),
    [actionMap.put_success]: (state, action) => {
      const object = action.payload
      return {
        ...state,
        data: state.data.map(item => item.id === object.id ? object : item),
      }
    },
    [actionMap.modal]: (state, action) => ({...state, showModal: action.payload}),
    [actionMap.current]: (state, action) => ({...state, current: action.payload}),
  }
}

export function actionMapBase(domain) {
  return {
    all: createAction(`${domain}/ALL`),
    all_success: createAction(`${domain}/ALL_SUCCESS`),
    get: createAction(`${domain}/GET`),
    get_success: createAction(`${domain}/GET_SUCCESS`),
    post: createAction(`${domain}/POST`),
    post_success: createAction(`${domain}/POST_SUCCESS`),
    put: createAction(`${domain}/PUT`, (id, data) => ({id, data})),
    put_success: createAction(`${domain}/PUT_SUCCESS`),
    delete: createAction(`${domain}/DELETE`),
    delete_success: createAction(`${domain}/DELETE_SUCCESS`),
    modal: createAction(`${domain}/MODAL`),
    current: createAction(`${domain}/CURRENT`),
    clear_all: createAction(`${domain}/CLEAR_ALL`)
  }
}
