import React, { Component } from 'react'
import { FormikSelect } from './FormInputs'
import { Field } from 'formik'
import { fetchApi } from '../../utils/api'

class UnitGroupsSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: []
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    fetchApi('admin/unit-groups')
      .then((res) => {
        this.setState({data: res.data})
      })
      .catch((err) => {
      })
  }

  render() {
    return (
      <Field
        label={this.props.label ? this.props.label : 'Grupo de unidades'} name={this.props.name ? this.props.name : 'unit_group'}
        component={FormikSelect}
        options={this.state.data}
        search={true}
        id_option='id' label_option='name'
        {...this.props}
      />
    )
  }
}

export default UnitGroupsSelect