import enviroment from '../enviroment'
import {apiAxios} from '../services/api'
import ToasterService from '../services/ToasterService'

export const access_logs = {
  createEntrance: async (values: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + 'admin/access-logs/entrance', values)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  createExit: async (access_log_id: string | number) => {
    try {
      const { data } = await apiAxios.get(enviroment().apiUrl + `admin/access-logs/${access_log_id}/exit`)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  patch: async (access_log_id: string | number, values: any) => {
    try {
      const { data } = await apiAxios.patch(enviroment().apiUrl + `admin/access-logs/${access_log_id}/edit`, values)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}