import {datadogRum} from '@datadog/browser-rum'
import moment from 'moment'
import 'moment/locale/pt-br'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import enviroment from './enviroment'
import {actionMap as devices_actions} from './redux/modules/devices'
import {actionMap as system_actions} from './redux/modules/system'
import {store} from './redux/store/configureStore'
import {push_toast} from './utils/common'
import {yupRules} from './utils/form'
import {getValue, show_modal} from './utils/Formatters'

datadogRum.init({
  applicationId: '2bd61352-2ab4-4ac1-9f82-6c4adf2efc2e',
  clientToken: 'pub2c3058ecd2e59678e0a4ba6ce057f2a3',
  site: 'datadoghq.com',
  sampleRate: 100,
  trackInteractions: true,
  version: enviroment().APP_VERSION_READABLE,
  env: 'production',
  defaultPrivacyLevel: 'allow',
  beforeSend: (event, context) => {
    // collect a RUM resource's response headers

    if (event.type === 'resource' && event.resource.type === 'xhr') {
      // @ts-ignore
      let response_type = context?.xhr?.responseType

      if (response_type == '' || response_type == 'text') {
        // console.log('ddEvent', event)
        // console.log('ddContext', context)

        // @ts-ignore
        event.context = { ...event.context, responseApi: context?.xhr?.responseText }
      }
    }

    if (event.type === 'resource' && event.resource.type === 'fetch') {
      // @ts-ignore
      event.context = { ...event.context, responseHeaders: context?.response?.headers }
    }
  }
})
datadogRum.startSessionReplayRecording()

moment.locale('pt-BR')
yupRules()

export function srv_push_toast(msg: string, type: 'success' | 'error' | 'info' | 'warn' | 'warning') {
  push_toast(msg, type)
}

export function srv_push_alert(msg: string, type: string) {
  show_modal(msg, type)
}

export function srv_push_clients(raw: string) {
  console.log(JSON.parse(raw))

  const json = JSON.parse(raw)
  let type = getValue(json, 'type')
  let data = getValue(json, 'data')

  console.log(type)

  switch (type) {
    case 'bio64_1':
      store.dispatch(devices_actions.bio64_1(data))
      break
    case 'bio64_2':
      store.dispatch(devices_actions.bio64_2(data))
      break
    case 'bio64_1_bin':
      store.dispatch(devices_actions.bio64_1_bin(data))
      break
    case 'bio64_2_bin':
      store.dispatch(devices_actions.bio64_2_bin(data))
      break
    case 'bio_template':
      store.dispatch(devices_actions.bio_template(data))
      break
    case 'bio_template_zk9':
      store.dispatch(devices_actions.bio_template_zk9(data))
      break
    case 'bio_enroll_client':
      store.dispatch(devices_actions.bio_enroll_client(data))
      break
    case 'download_status':
      store.dispatch(system_actions.download_status(data))
      break
  }
}

export function srv_on_load_check() {
  // Force login again when close the window

  console.log('srv_on_load_check')

  let _token = localStorage.getItem('token')

  if (_token && window.location.pathname.indexOf('admin') === -1) {
    localStorage.removeItem('token')
    localStorage.removeItem('current_user')
    localStorage.setItem('SESSION_STARTED_AT', moment().toISOString())
    window.location.reload()
  }
}

// endregion


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

// root.render(<Sentry.ErrorBoundary><App/></Sentry.ErrorBoundary>, document.getElementById('root'))

// serviceWorker.unregister()
// serviceWorker.register()
//
// // Show the prompt
// let {deferredPrompt} = window
// if (deferredPrompt) {
//   deferredPrompt.prompt()
//   // Wait for the user to respond to the prompt
//   deferredPrompt.userChoice.then(choiceResult => {
//     if (choiceResult.outcome === 'accepted') {
//       console.log('User accepted the A2HS prompt')
//       this.setState({
//         acceptedInstall: true,
//       })
//     } else {
//       console.log('User dismissed the A2HS prompt')
//       this.setState({
//         declinedInstall: true,
//       })
//     }
//     deferredPrompt = null
//   })
// }
