import { Formik } from 'formik'

import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as Yup from 'yup'
import { FF, FormikInput } from '../../../components/form/FormInputs'
import ActivityLoading from '../../../components/Loading'
import { WebcamCaptureUploader } from '../../../components/WebcamCapture'
import { qs } from '../../../queries'
import { push_toast } from '../../../utils/common'

type Props = {
  guest_id: string | number
  setClose: () => void
  pushToSelectedGuest?: (data) => void
}

export const GuestEditModal = (props: Props) => {
  const webcam_capture = useRef<any>()

  const [activity_loading, setActivityLoading] = useState<boolean>(false)

  const { isLoading, data, refetch } = useQuery(['getGuest', props.guest_id], () => qs.guests.get(props.guest_id))

  return (
    <React.Fragment>
      <Modal open={true} size="fullscreen">
        {data?.id &&
        <Formik
          initialValues={{
            id: data?.id ?? '',
            name: data?.name ?? '',
            cpf: data?.cpf ?? '',
            rg: data?.rg ?? '',
            description: data?.description ?? '',
            photo_url: data?.photo_url ?? '',
            file: data?.file_id ?? ''
          }}
          validationSchema={() => {
            return Yup.lazy((values: any) => {
              return Yup.object().shape({
                name: Yup.string().required(),
                // @ts-ignore
                cpf: values.cpf ? Yup.string().validCPF() : Yup.string()
              })
            })
          }}
          onSubmit={async (values) => {
            setActivityLoading(true)

            if (webcam_capture.current?.state.base64) {
              try {
                let file_res = await webcam_capture.current.savePhoto()
                console.log(file_res)
                values['file'] = file_res.file_id
              } catch (e) {
                setActivityLoading(false)
                push_toast('Erro ao salvar a foto', 'error')
                return
              }
            }

            qs.guests.patch(values).then((res: any) => {
              setActivityLoading(false)
              push_toast('Visitante salvo!', 'success')

              if (props.pushToSelectedGuest) {
                props.pushToSelectedGuest(res)
              } else {
                props.setClose()
              }

            }).catch((err) => {
              setActivityLoading(false)
            })

          }}
          render={({ values, submitForm, setFieldValue }) => (
            <React.Fragment>
              <Modal.Header>Atualizar visitante</Modal.Header>

              <Modal.Content scrolling>
                <Form error>
                  <FF label="Nome" name="name" component={FormikInput}/>

                  <Form.Group widths="equal">
                    <FF label="CPF" name="cpf" component={FormikInput}/>
                    <FF label="RG" name="rg" component={FormikInput}/>
                  </Form.Group>

                  <FF label="Observações" name="description" component={FormikInput}/>

                  <br/>
                  <br/>

                  <WebcamCaptureUploader
                    ref={webcam_capture}
                    file_type="guests"
                    values={values}
                    setFieldValue={setFieldValue}
                    size="medium"
                    show_success_modal={false}
                    hide_save_btn
                    isProfile
                  />
                </Form>
              </Modal.Content>

              <Modal.Actions>
                <Button color="red" type="button" content="Cancelar" onClick={props.setClose}/>
                <Button primary type="button" content="Salvar" onClick={submitForm}/>
              </Modal.Actions>
            </React.Fragment>
          )}
        />}
      </Modal>

      <ActivityLoading visible={activity_loading}/>
    </React.Fragment>
  )
}