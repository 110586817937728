import { gv } from './utils/common'
import { LocalDB } from './utils/LocalDB'

const PKG = require('../package.json')

export const APP_VERSION_READABLE = PKG.version

const prod = true

const dev_rootApi = 'http://localhost:8005/'
const dev_apiUrl = 'http://localhost:8005/api/v2/'

const prod_root_api_v1 = 'https://api-v1.condoid.com.br/'
const prod_api_url_v1 = 'https://api-v1.condoid.com.br/api/v1/'

const prod_root_api_v2 = 'https://api-v2.condoid.com.br/'
const prod_api_url_v2 = 'https://api-v2.condoid.com.br/api/v2/'

let getLocalIp = () => {
  const me = LocalDB.getItem('current_user')
  const ac_local_server_ip = gv(me, 'team.configs.ac_local_server_ip')

  if (ac_local_server_ip && ac_local_server_ip.length > 2) {
    return ac_local_server_ip
  }

  return null
}

const getTeamVersionLocal = () => {
  try {
    let version = localStorage.getItem('condoidv2')

    // @ts-ignore
    if (['true', 'false'].includes(version)) {
      // @ts-ignore
      return JSON.parse(version)
    }

    return null
  } catch (e) {
    console.error(e)
    throw e
  }
}

const obj = () => {
  try {
    const team_version = getTeamVersionLocal()

    console.log('vers_env', team_version)

    const prod_rootApi = team_version ? prod_root_api_v2 : prod_root_api_v1
    const prod_apiUrl = team_version ? prod_api_url_v2 : prod_api_url_v1

    return {
      rootApi: prod ? prod_rootApi : dev_rootApi,
      apiUrl: prod ? prod_apiUrl : dev_apiUrl,

      localWS: () => `ws://${getLocalIp()}:8000/ws/clients/`,
      localWSFastApi: () => `ws://${getLocalIp()}:8089/ws`,
      localUrl: () => `http://${getLocalIp()}:8000/api/`,
      localIp: () => getLocalIp(),

      APP_VERSION_READABLE: APP_VERSION_READABLE
    }
  } catch (e) {
    console.error(e)
    throw e
  }
}

export default obj
