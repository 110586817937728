import enviroment from '../enviroment'
import {apiAxios} from '../services/api'

export const access_groups = {
  guest: async () => {
    interface HTTPResponse {
      data: Datum[];
    }

    interface Datum {
      id: number;
      access_relays: AccessRelay[];
      access_devices_ids: number[];
      deleted: null;
      created_at: string;
      updated_at: string;
      name: string;
      description: null;
      type: number;
      status: number;
      uid: null;
      sun_allowed: boolean;
      sun_start: string;
      sun_end: string;
      mon_allowed: boolean;
      mon_start: string;
      mon_end: string;
      tue_allowed: boolean;
      tue_start: string;
      tue_end: string;
      wed_allowed: boolean;
      wed_start: string;
      wed_end: string;
      thu_allowed: boolean;
      thu_start: string;
      thu_end: string;
      fri_allowed: boolean;
      fri_start: string;
      fri_end: string;
      sat_allowed: boolean;
      sat_start: string;
      sat_end: string;
      created_by: number;
      updated_by: number;
      deleted_by: null;
      created_by_id: number;
      updated_by_id: number;
      deleted_by_id: null;
    }

    interface AccessRelay {
      id: number;
      with_access_device: string;
      deleted: null;
      created_at: string;
      updated_at: string;
      name: string;
      status: number;
      type: number;
      relay: number;
      receptor_type: number;
      can: number | null;
      way: number;
      access_type: number;
      take_relay: boolean;
      take_access: boolean;
      dispenser: boolean;
      control_parking: boolean;
      created_by: number;
      updated_by: number | null;
      deleted_by: null;
      access_device_id: number;
      created_by_id: number;
      updated_by_id: number | null;
      deleted_by_id: null;
    }

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + 'admin/access-groups?filter{type}=2&include[]=access_relays.id')
    return data.data
  }
}