import localForage from 'localforage'
import moment from 'moment'
import {qs} from './index'

export const profile_photos = {
  cache_key: '@PROFILE_PHOTOS@',
  last_update: '@PROFILE_PHOTOS_LAST_UPDATE@',

  _cacheUpdates: async () => {
    let data_cached = await localForage.getItem<object>(profile_photos.cache_key) ?? {}

    const guests = await qs.guests.allCached()
    const people = await qs.people.allCached(true)

    if (guests.length) {
      for (let o of guests) {
        data_cached[o.id] = o.photo_url
      }
    }

    if (people.length) {
      for (let o of people) {
        data_cached[o.id] = o?.photo?.file
      }
    }

    if (data_cached && Object.values(data_cached).length) {
      await localForage.setItem(profile_photos.cache_key, data_cached)
    }

    await localForage.setItem(profile_photos.last_update, moment().toISOString())

    return data_cached
  },
  allCached: async (force_update = false) => {
    if (force_update) {
      await profile_photos._cacheUpdates()
    }

    let data = await localForage.getItem<object>(profile_photos.cache_key)

    if (!data) {
      return await profile_photos._cacheUpdates()
    }

    return data
  }
}