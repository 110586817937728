import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { GUESTLIST_STATUS } from '../../fields'
import { getGuestBooks } from '../../utils/DataService'
import { dateTimeFormat, hasValue, removeAccents } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'
import { store } from '../../redux/store/configureStore'
import { actionMap as system_actions } from '../../redux/modules/system'

class GuestBookListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },

      BookQuickFilterText: null,
      loading: false,
      logData: '',

      columnBookDefs: [
        {
          headerName: 'Unidade',
          field: 'book.unit.with_block',
          width: 50,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Visitante',
          field: 'name_temp',
          width: 80,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => hasValue(params, 'data.person.name') !== null ? params.data.person.name : params.data.name_temp
        },
        {
          headerName: 'Espaço',
          field: 'book.space.name',
          width: 70,
          filter: 'text',
          suppressMenu: true,
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Data do Evento',
          width: 50,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => dateTimeFormat(params, 'book.book_date')
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 35,
          filter: 'text',
          valueGetter: (params) => GUESTLIST_STATUS[params.data.status],
          suppressMenu: true
        },
        {
          headerName: 'Ações',
          width: 150,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRendererFramework: (params) => {
            if (params.data && params.data.status === 1) {
              return (
                <button className="ui button basic compact green"
                        onClick={() => params.context.componentParent.handleBookAction(params.data)}>Entrar</button>)
            } else {
              return null
            }
          }
        }
      ],
      activeTab: 0,

      currentAuth: {},
      currentBook: {}
    }
  }

  BookOnQuickFilterText = (event) => {
    this.setState({ BookQuickFilterText: event })
  }

  onGridBookReady = (params) => {
    this.GridBookApi = params.api

    this.setState({ loading: true })

    getGuestBooks()
      .then(res => {
        params.api.setRowData(res)
        params.api.sizeColumnsToFit()
        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ loading: false })
      })

  }

  handleBookAction = (params) => {
    this.props.history.push('/admin/access-logs?source=guestlist', params)
    store.dispatch(system_actions.showAccessLogForm(true))
  }

  render() {
    return (
      <PageBase title="Lista de Convidados do Dia" create={false} onFilterChange={this.BookOnQuickFilterText}>
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnBookDefs}
                       quickFilterText={this.state.BookQuickFilterText}
                       onGridReady={this.onGridBookReady}
                       pagination={true}
                       domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}

export default withRouter(GuestBookListPage)