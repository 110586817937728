import create from 'zustand'

const useLicenseStore = create<{ ci_license: null | { license_expires_at: string, machine_uuid: string, status: number } }>(set => ({
  ci_license: null,
  updateLicense: (data: any) => {
    return set({
      ci_license: data
    })
  }
}))

export default useLicenseStore