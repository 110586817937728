import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { Formik } from 'formik'
import { qs } from '../../queries'
import { hasValue, show_modal } from '../../utils/Formatters'
import { FF, FormikInput } from '../form/FormInputs'
import ActivityLoading from '../Loading'

export default class PwdEnroll extends React.Component {
  static defaultProps = {
    person: '',
    resident: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal open={true} size='small'>
          <Modal.Header>Cadastrar Senha</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                type: 7,
                status: 1,
                person: this.props.person.id,

                user_id: this.props.person.id,
                user_name: this.props.person.name,
                code_type: 1,
                is_guest: false,

                code: '',
                confirm_code: '',
              }}
              validate={values => {
                let errors = {}
                if (values.code !== values.confirm_code) errors.confirm_code = 'Senhas não conferem'
                return errors
              }}
              onSubmit={async (values) => {
                if (this.props.resident === true) {
                  if (hasValue(this.props, 'person.id') && hasValue(this.props, 'person.name')) {
                    let check = await qs.access_drivers.filterByCode(values.code)
                    console.log(check)
                    if (check && check.length > 0) {
                      show_modal(`Senha já em uso! Use outra!`, 'error')
                      return
                    }

                    this.setState({loading: true})

                    qs.access_drivers.post(values)
                      .then((resp) => {
                        this.setState({loading: false})
                        show_modal('Senha cadastrada!', 'success')
                      })
                      .catch((err) => {
                        this.setState({loading: false})
                      })
                  } else {
                    show_modal('Dados inválidos', 'error')
                  }
                }
              }}
              render={({values, setFieldValue}) => (
                <div className='ui form large error'>
                  <FF label={'Senha'} name="code" component={FormikInput} type='password'/>
                  <FF label={'Confirme a Senha'} name="confirm_code" component={FormikInput} type='password'/>
                </div>
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' size='large' onClick={() => {
              this.props.parent.getPersonAccessDrivers()
              this.props.parent.setState({pwd_modal: false})
            }}>
              Fechar
            </Button>
            <Button primary size='large' onClick={(e) => this.form.handleSubmit(e)}>Cadastrar Senha</Button>
          </Modal.Actions>
        </Modal>
        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

