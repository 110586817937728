import React, { Component } from 'react'
import moment from 'moment'
import { Formik } from 'formik'
import { Form } from 'semantic-ui-react'
import { fetchApi, postApi } from '../../utils/api'
import { MODELTYPE_TYPE_ID } from '../../constants/model_types'
import { show_modal } from '../../utils/Formatters'
import { FF, FormikTextArea } from './FormInputs'
import ToasterService from '../../services/ToasterService'
import { gv } from '../../utils/common'

class CommentsBox extends Component {
  static defaultProps = {
    notify: false
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.visible,
      comments: []
    }
  }

  componentDidMount() {
    this.handleGetData()
  }

  handleGetData = () => {
    fetchApi(`admin/comments?filter{object_id}=${this.props.commentable_id}&filter{object_type_id}=${MODELTYPE_TYPE_ID[this.props.commentable_type]}&include[]=created_by`)
      .then(res => {
        this.setState({comments: res.data})
      })
      .catch(err => {
        show_modal('Erro ao recuperar os comentários!', 'error')
      })
  }

  render() {
    return (
      <div className="ui grid">
        <div className="eight wide column">
          <div className="scrolling content" style={{maxHeight: '340px'}}>
            <div className="ui comments">
              {
                Array.isArray(this.state.comments) && this.state.comments.map(item => {
                  return (
                    <div key={item.id} className="comment">
                      <div className="content">
                        <a className="author">{gv(item, 'created_by.name')}</a>
                        <div className="metadata">
                          <div className="date">
                            {moment(item.created_at).format('DD/MM/YYYY HH:mm')}
                          </div>
                        </div>
                        <div className="text">
                          <p>{item.body}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="eight wide column">
          <Formik
            innerRef={node => (this.form = node)}
            initialValues={{
              body: '',
              who_notify: [],
              commentable_id: this.props.commentable_id,
              commentable_type: this.props.commentable_type,
              type: 1,
            }}
            validate={values => {
              let errors = {}

              if (!values.body) errors.body = 'Digite o comentário'
              if (!values.commentable_id) errors.commentable_id = 'Erro'
              if (!values.commentable_type) errors.commentable_type = 'Erro'

              return errors
            }}
            onSubmit={(values, {resetForm}) => {
              values['commentable'] = {
                id: this.props.commentable_id,
                type: this.props.commentable_type
              }

              postApi('admin/comments', values)
                .then((res) => {
                  this.handleGetData()
                  ToasterService.success('Comentário adicionado')
                  resetForm()
                })
                .catch((err) => {
                  this.setState({loading: false})
                  ToasterService.sendErrors(err)
                })
            }}
            render={({handleSubmit,}) => (
              <Form error size='small'>
                <FF label={'Comentário'} name="body" component={FormikTextArea} rows={3}/>

                <button type="button" className="ui button primary small" onClick={handleSubmit}>Enviar comentário</button>
              </Form>
            )}
          />
        </div>
      </div>
    )
  }
}

export default CommentsBox
