import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import { putApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { dateTimeFormat, removeAccents } from '../../utils/Formatters'
import swal from 'sweetalert2'
import { OBJECT_CONTROL_STATUS } from '../../fields'
import { getObjects } from '../../utils/DataService'
import { gridDefaults } from '../../utils/gridDefaults'
import moment from 'moment'
import { Label } from 'semantic-ui-react'

export default class ObjectListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: 'Objeto',
          field: 'name',
          width: 60,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Solicitante',
          field: 'requester',
          width: 90,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Unidade/Status',
          field: 'unit.with_block',
          width: 55,
          filter: 'text',
          cellRendererFramework: (params) => {
            let value = params.getValue()
            let status_value = OBJECT_CONTROL_STATUS[params.data.status]

            return (
              <div>
                {value} &nbsp;
                {status_value === 'Devolvido' ?
                  <Label color="green" className="grid-label">{status_value}</Label> :
                  <Label color="yellow" className="grid-label">{status_value}</Label>}
              </div>
            )
          }
        },
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 50,
          filter: 'text',
          hide: true
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 50,
          filter: 'text',
          valueGetter: (params) => OBJECT_CONTROL_STATUS[params.data.status],
          hide: true
        },
        {
          headerName: 'Data',
          width: 75,
          cellRendererFramework: (params) => {
            return (
              <div>
                Criado: {dateTimeFormat(params, 'date')} <br/>
                Devolvido: {dateTimeFormat(params, 'returned_at')}
              </div>
            )
          }
        },
        {
          headerName: 'Data',
          field: 'date',
          width: 50,
          filter: 'text',
          hide: true,
          valueGetter: dateTimeFormat
        },
        {
          headerName: 'Devolvido em',
          field: 'returned_at',
          width: 50,
          filter: 'text',
          hide: true,
          valueGetter: (params) => dateTimeFormat(params, 'returned_at'),
        },
        {
          headerName: 'Ações',
          width: 110,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRendererFramework: this.actionsFormat
        },

      ],
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        rowHeight: 70
      },
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  actionsFormat = (params) => {
    if (params.data && params.data.status === 1) {
      return (
        <button className="ui button basic compact green"
                onClick={() => params.context.componentParent.handleAction(params.data)}>Devolver
        </button>)
    } else {
      return null
    }
  }

  handleAction = (params) => {
    this.setState({loading: true})

    let data = {
      status: 2,
      returned_at: moment().toISOString()
    }

    putApi('admin/object-controls', data, params.id)
      .then((res) => {

        getObjects()
          .then((data) => {
            this.GridApi.setRowData(data)
            this.setState({loading: false})
            this.GridLogApi.sizeColumnsToFit()
          })
          .catch((err) => {
            this.setState({loading: false})
          })

        swal({type: 'success', title: 'Salvo com sucesso!'})
      })
      .catch(() => {
        this.setState({loading: false})
        swal({type: 'error', title: 'Verifique os dados!'})
      })
  }

  onGridReady = (params) => {
    this.GridApi = params.api

    this.setState({loading: true})

    getObjects()
      .then((data) => {
        params.api.setRowData(data)
        this.setState({loading: false})
        params.api.sizeColumnsToFit()
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  render() {
    return (
      <PageBase title="Controle de Objetos" onFilterChange={this.onQuickFilterText} create={
        <Link to="/admin/object-form">
          <button className="ui button primary large">Registrar Saída</button>
        </Link>
      }>

        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={25}
                       domLayout={'autoHeight'}
          />
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
