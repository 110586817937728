import { GridApi, GridReadyEvent } from 'ag-grid'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { AclRender } from '../../../components/acl/components'
import { FF, FormikDate, FormikInput, FormikSelect } from '../../../components/form/FormInputs'
import UnitsSelect from '../../../components/form/UnitsSelect'
import { BOOK_STATUS } from '../../../fields'
import { useLocalStorageTS } from '../../../hooks'
import { grid_refs_actions } from '../../../redux/modules/grid-refs'
import { dateTimeFormat, removeAccents } from '../../../utils/Formatters'
import { gridDefaults } from '../../../utils/gridDefaults'

const BookGrid = (props: { componentParent: any }) => {
  const pagination_size = 25
  const cache_key = 'filter_grid_book'
  const initial_values = {
    unit: '',
    status: '',
    space_name: '',
    date_from: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    date_to: ''
  }

  const dispatch = useDispatch()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [filter_values, setFilterValues] = useLocalStorageTS<any>(cache_key, initial_values)

  useEffect(() => {
    return () => setFilterValues(initial_values)
  }, [])

  const prepareFilterQs = () => {
    let filter_qs_state = JSON.parse(localStorage.getItem(cache_key) ?? '{}') ?? {}

    if (filter_qs_state.unit) filter_qs_state['filter{unit}'] = filter_qs_state.unit
    if (filter_qs_state.status) filter_qs_state['filter{status}'] = filter_qs_state.status
    if (filter_qs_state.space_name) filter_qs_state['filter{space.name.icontains}'] = filter_qs_state.space_name

    if (filter_qs_state.date_from) {
      filter_qs_state['filter{book_date.gte}'] = moment(filter_qs_state.date_from).startOf('day').toISOString()
    } else {
      filter_qs_state['filter{book_date.gte}'] = moment().subtract(3, 'days').startOf('day').toISOString()
    }

    if (filter_qs_state.date_to) {
      filter_qs_state['filter{book_date.lte}'] = moment(filter_qs_state.date_to).endOf('day').toISOString()
    }

    filter_qs_state['include[]'] = ['unit.with_block', 'space.name', 'created_by_name']
    filter_qs_state['sort[]'] = 'book_date'

    return filter_qs_state
  }

  const serverSideDatasource = gridDefaults.makeServerSideDataSource('admin/books', pagination_size, prepareFilterQs)

  const GRID = {
    gridOptions: {
      ...gridDefaults.adminGrid,
      context: {
        componentParent: props.componentParent
      },
      rowHeight: 60,
      cacheBlockSize: pagination_size,
      paginationPageSize: pagination_size,
      serverSideDatasource: serverSideDatasource,
      rowModelType: 'serverSide'
    },
    columnDefs: [
      {
        headerName: 'Espaço',
        field: 'space.name',
        width: 50,
        filter: 'text',
        getQuickFilterText: removeAccents
      },
      {
        headerName: 'Unidade',
        field: 'unit.with_block',
        width: 40,
        filter: 'text'
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 25,
        filter: 'text',
        valueGetter: (params) => params.data && BOOK_STATUS[params.data.status]
      },
      {
        headerName: 'Data',
        field: 'book_date',
        width: 30,
        filter: 'text',
        valueGetter: (params) => params.data && dateTimeFormat(params, 'book_date')
      },
      {
        headerName: 'Criado por',
        field: 'created_by_name',
        width: 30,
        filter: 'text',
        getQuickFilterText: removeAccents
      },
      {
        headerName: 'Ações',
        width: 70,
        suppressResize: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true,
        suppressFilter: true,
        cellRendererFramework: (params) => {
          if (params.data) {
            return (
              <React.Fragment>
                {!params.data.used &&
                <button className="ui button basic compact primary"
                        onClick={() => params.context.componentParent.markUsed(params.data.id)}>Usar
                </button>
                }

                <button className="ui button basic compact primary"
                        onClick={() => params.context.componentParent.print(params.data.id)}>Imprimir
                </button>
                <AclRender required={['book.change_book']}>
                  <button className="ui button basic compact primary"
                          onClick={() => params.context.componentParent.handleEdit(params.data)}>Editar
                  </button>
                </AclRender>
              </React.Fragment>
            )
          } else {
            return null
          }
        }
      }
    ],
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api)
      dispatch(grid_refs_actions.update_ref({ key: 'book', ref: params.api }))
    }
  }

  return (
    <React.Fragment>
      <div className="ui segment page-filter-container">
        <Formik
          initialValues={{
            unit: filter_values.unit,
            status: filter_values.status,
            space_name: filter_values.space_name,
            date_from: filter_values.date_from,
            date_to: filter_values.date_to
          }}
          validate={values => {
            let errors: any = {}
            if (!values.date_from) errors.date_from = 'Selecione uma data'
            return errors
          }}
          onSubmit={(values) => {
            setFilterValues(values)
            gridApi?.onFilterChanged()
          }}
          render={({ submitForm }) => (
            <Form>
              <Form.Group widths="equal" style={{ margin: 0 }}>
                <UnitsSelect name="unit" label="Unidade"/>
                <FF label={'Status'} name="status" component={FormikSelect} local_options={BOOK_STATUS}/>

                <FF label={'Espaço'} name="space_name" component={FormikInput}/>

                <FF label="Data (Início)" name="date_from" component={FormikDate}/>
                <FF label="Data (Fim)" name="date_to" component={FormikDate}/>

                <Button primary size="small" basic content="Filtrar" onClick={submitForm}/>
              </Form.Group>
            </Form>
          )}
        />
      </div>

      <div className="ui raised segment page-grid-container">
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={GRID.gridOptions}
            columnDefs={GRID.columnDefs}
            onGridReady={GRID.onGridReady}
          />
        </div>
        <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
      </div>
      <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
    </React.Fragment>
  )
}

export default BookGrid