import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import ActivityLoading from '../../components/Loading'
import { getLockers } from '../../utils/DataService'

export default class LockersListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: 'Nome',
          field: 'code',
          width: 100,
          filter: 'text'
        },
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 35,
          filter: 'text'
        },
      ],
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  onGridReady = (params) => {
    this.setState({loading: true})

    getLockers()
      .then(data => {
        params.api.setRowData(data)
        this.setState({rowData: data, loading: false})
        params.api.sizeColumnsToFit()
      })
      .catch(err => {
        this.setState({loading: false})
      })
  }

  render() {
    return (
      <PageBase title="Escaninhos" create={false} onFilterChange={this.onQuickFilterText}>
        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={50}
                       domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
