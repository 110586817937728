import React from 'react'
import { useQuery } from 'react-query'
import { Button, Icon, Table } from 'semantic-ui-react'
import { qs } from '../../queries'
import { qs_local_server } from '../../queries-local-server'

const AccessButtonTable = () => {
  const { data } = useQuery('getAccessButtons', () => qs.access_buttons.allCached(), {
    // six hours
    staleTime: 1000 * 60 * 60 * 6
  })

  return (
    <Table basic="very" padded>
      <Table.Body>
        {data?.map(item => (
          <Table.Row>
            <Table.Cell width={4}>
              <Button color={'blue'} icon basic size="mini" floated="right" type="button" onClick={() => {

                qs_local_server.devices_manager.takeRelay(
                  item.access_relay.access_device_id,
                  item.access_relay.id,
                  item.access_relay.relay,
                  item.access_relay.can,
                  item.access_relay.receptor_type
                )

              }}>
                <Icon name="lock open"/>
              </Button>
            </Table.Cell>
            <Table.Cell width={12}>{item.description}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default AccessButtonTable