import React from 'react'
import {Button, Modal} from 'semantic-ui-react'
import {useMeQuery} from '../../../hooks'
import {qs_local_server} from '../../../queries-local-server'
import {history} from '../../../redux/store/configureStore'
import {show_modal} from '../../../utils/Formatters'

export const QRCodePrinterModal = ({ qr_codes }: {
  qr_codes: {
    auth_pin: string
    auth_id: string
    person_name: string
    unit_with_block: string
  }[]
}) => {
  const { data: me } = useMeQuery()

  const handleFinish = () => {
    history.push('/admin/access-logs')
    show_modal('Operação realizada com sucesso!', 'success')
  }

  const handlePrint = () => {
    const qr_codes_formatted = qr_codes.map(i => {
      return {
        qr_code: `${i.auth_pin}|${i.auth_id}`,
        description: `Nome: ${i.person_name}\nUnidade: ${i.unit_with_block}`
      }
    })

    qs_local_server.devices_manager.printQRCodes(
      '192.168.1.114',
      '9100',
      me?.team?.name,
      qr_codes_formatted
    )
  }

  return (
    <React.Fragment>
      <Modal open={true} size="tiny">
        <Modal.Content>
          <h2 style={{ textAlign: 'center' }}>QRCodes gerados!</h2>
          <h2 style={{ textAlign: 'center' }}>Clique no botão abaixo para imprimir!</h2>

        </Modal.Content>
        <Modal.Actions>
          <Button size="large" onClick={handleFinish}>Fechar</Button>
          <Button primary size="large" icon="print" onClick={handlePrint} content="IMPRIMIR"/>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}