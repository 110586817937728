import React from 'react'
import { hasPerm } from './index'

import { getValue } from '../../utils/Formatters'

// https://github.com/brainhubeu/react-permissible
// https://github.com/IkoroVictor/react-permissions
class Wrapper extends React.Component {
  static defaultProps = {
    hasAll: false,
    required: [],
    fallback: null,
  }

  checkPermissions() {
    const {required, hasAll} = this.props
    return hasPerm(required, hasAll)
  }

  render() {
    const user = JSON.parse(localStorage.getItem('current_user'))
    let userPermissions = getValue(user, 'permissions')

    const {children, required, fallback} = this.props

    if (!children || !userPermissions || !required) {
      return null
    }

    if (this.checkPermissions()) {
      return children
    } else if (fallback) {
      return fallback
    }
    return null
  }
}

export const AclRender = Wrapper

export const AclHOC = (Wrapped, required, hasAll = false, fallback) => {
  class PermissibleHOC extends React.Component {
    checkPermissions() {
      return hasPerm(required, hasAll)
    }

    render() {
      const user = JSON.parse(localStorage.getItem('current_user'))
      let userPermissions = getValue(user, 'permissions')

      if (!userPermissions || !required) {
        return null
      }

      if (this.checkPermissions()) {
        return (<Wrapped {...this.props} />)
      } else if (fallback) {
        return fallback
      }
      return <h1>Sem autorização para acessar!</h1>
    }
  }

  return PermissibleHOC
}