import {AgGridReact} from 'ag-grid-react'
import {Formik} from 'formik'
import moment from 'moment'
import * as queryString from 'querystring'
import React from 'react'
import {Button, Dropdown, Form} from 'semantic-ui-react'
import swal from 'sweetalert2'
import {AclRender} from '../../components/acl/components'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import SemanticSelect from '../../components/SemanticSelect'
import {ACCESSDRIVER_TYPES} from '../../fields'
import {qs} from '../../queries'
import {push_toast} from '../../utils/common'
import {hasValue} from '../../utils/Formatters'
import {gridDefaults} from '../../utils/gridDefaults'
import {localFetchApi, localPostApi} from '../../utils/LocalApi'

const options = [
  { value: 7, text: '7 dias atrás' },
  { value: 15, text: '15 dias atrás' },
  { value: 30, text: '30 dias atrás' },
  { value: 60, text: '60 dias atrás' },
  { value: 90, text: '90 dias atrás' },
  { value: 120, text: '120 dias atrás' },
  { value: 160, text: '160 dias atrás' },
  { value: 240, text: '240 dias atrás' },
  { value: 360, text: '360 dias atrás' },
  { value: 480, text: '480 dias atrás' },
  { value: 720, text: '720 dias atrás' }
]

export default class DriversListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: '#',
          field: 'id',
          width: 20,
          filter: 'text'
        },
        {
          headerName: 'Morador',
          field: 'person.name',
          width: 60,
          filter: 'text'
        },
        {
          headerName: 'Unidade/Vaga',
          width: 60,
          cellRendererFramework: (params) => {
            return (
              <div>
                Unidade: {params.data?.unit?.with_block} <br/>
                Vaga: {params.data?.parking?.code}
              </div>
            )
          }
        },
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 80,
          filter: 'text',
          hide: true
        },
        {
          headerName: 'Vaga',
          field: 'parking.code',
          width: 60,
          filter: 'text',
          hide: true
        },
        {
          headerName: 'Código/Tipo',
          width: 70,
          cellRendererFramework: (params) => {
            return (
              <div>
                {params.data?.code} <br/>
                {ACCESSDRIVER_TYPES[params.data.type]}
              </div>
            )
          }
        },
        {
          headerName: 'Código',
          field: 'code',
          width: 60,
          filter: 'text',
          hide: true
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 40,
          filter: 'text',
          valueGetter: (params) => ACCESSDRIVER_TYPES[params.data.type],
          hide: true
        },
        {
          headerName: 'Acessos',
          field: 'access_count',
          width: 30,
          filter: 'number'
        },
        {
          headerName: 'Ações',
          width: 115,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellClass: 'ag-button-cell',
          cellRendererFramework: this.actionsFormat
        }
      ],
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        enableSorting: true,
        rowHeight: 70
      }
    }
  }

  onQuickFilterText = (event) => {
    this.setState(
      { quickFilterText: event }
    )
  }

  onGridReady = async (params) => {
    this.gridApi = params.api

    await this.getData()
  }

  buildKey = (item) => {
    const drivers_type_map = {
      1: 'Controle',
      3: 'Cartão',
      5: 'Biometria',
      6: 'Tag Passivo',
      7: 'Senha',
      8: 'Face'
    }

    if ([3, 5, 7, 8].includes(item.type)) {
      return `${drivers_type_map[item.type]}_|_${item.person_id}`
    }

    if ([1, 6].includes(item.type)) {
      return `${drivers_type_map[item.type]}_|_${item.code}`
    }
  }

  getData = async (last_days = 60) => {
    try {
      this.setState({
        loading: true
      })

      let access_drivers = await qs.access_drivers.allCached()
      let new_data = []

      try {
        let start_date = moment().subtract(last_days, 'days').format('YYYY-MM-DD')
        let end_date = moment().format('YYYY-MM-DD')
        let qs = queryString.stringify({ start_date, end_date })

        let event_hash_map = await localFetchApi('events/statistics?' + qs, 1000 * 60 * 10)

        new_data = access_drivers.map((i) => {
          let key = this.buildKey(i)

          // @ts-ignore
          i['access_count'] = event_hash_map[key] ?? 0
          return i
        })

        console.log(new_data)

        this.gridApi.setRowData(new_data)
        this.gridApi.sizeColumnsToFit()
        this.setState({ rowData: new_data, loading: false })

      } catch (err) {
        console.error(err)
      }

      if (new_data.length === 0) {
        this.gridApi.setRowData(access_drivers)
        this.gridApi.sizeColumnsToFit()
        this.setState({ rowData: access_drivers, loading: false })
      }
    } catch (e) {
      console.error(e)
      push_toast('Error.', 'error')
      this.setState({ loading: false })
    }
  }

  actionsFormat = (params) => {
    let driver_type = params?.data?.type

    return (
      <React.Fragment>
        <AclRender required={['access_control.delete_accessdriver']}>
          <Button.Group basic primary compact>
            <Dropdown
              text="Ações  "
              icon="pencil"
              floating
              button
              className="icon"
            >
              <Dropdown.Menu>
                <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
                <Dropdown.Divider/>

                {[1, 2, 3, 5, 6, 8].includes(driver_type) &&
                  <Dropdown.Item onClick={() => {
                    params.context.componentParent.handleDelete(params.data)
                  }}>
                    Excluir Acionador no equipamento
                  </Dropdown.Item>}

                {[3, 5, 8].includes(driver_type) &&
                  <Dropdown.Item onClick={() => {
                    params.context.componentParent.handleDeleteAllData(params.data)
                  }}>
                    Excluir Acionador e todos dados do usuário no equipamento
                  </Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>
        </AclRender>
      </React.Fragment>
    )
  }

  handleDelete = (driver) => {
    let driver_type = driver?.type

    if ([1, 2, 6].includes(driver_type)) {
      this.handleDeleteDriverGuarita(driver)
    }

    if (driver_type === 3) {
      this.handleDeleteDriverCard(driver)
    }

    if (driver_type === 5) {
      this.handleDeleteDriverBio(driver)
    }

    if (driver_type === 8) {
      this.handleDeleteDriverFace(driver)
    }
  }

  handleDeleteDriverCard = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        console.log(driver)

        if (hasValue(driver, 'id') && hasValue(driver, 'code') && hasValue(driver, 'person_id')) {
          localPostApi('devices-manager/delete_card', driver, 60 * 1000)
            .then(res => {
              // Delete in server
              this.setState({ loading: true })
              qs.access_drivers.delete(driver).finally((res) => { this.setState({ loading: false }) })

            })
            .catch(err => {
              swal({ type: 'error', title: 'Verifique os erros para que seja possível excluir o acionador do servidor!' })
            })

        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  handleDeleteDriverBio = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        if (driver?.person_id) {
          let data = {
            user_id: driver.person_id,
            bio_index: 1
          }

          localPostApi('devices-manager/delete_bio', data, 60 * 1000)
            .then(res => {
              // Delete in server
              this.setState({ loading: true })
              qs.access_drivers.delete(driver).finally((res) => { this.setState({ loading: false }) })

            })
            .catch(err => {
              swal({ type: 'error', title: 'Verifique os erros para que seja possível excluir o acionador do servidor!' })
            })

        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  handleDeleteDriverFace = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        if (driver?.person_id) {
          const data = {
            user_id: driver?.person_id
          }

          localPostApi('devices-manager/delete_face', data, 60 * 1000)
            .then(res => {
              // Delete in server
              this.setState({ loading: true })

              let driver = {
                person_id: driver?.person_id,
                type: 8
              }

              qs.access_drivers.delete(driver).finally((res) => { this.setState({ loading: false }) })
            })
        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  handleDeleteAllData = (driver) => {
    swal({
      title: 'Essa ação irá excluir TODOS OS DADOS (biometrias e cartões) desse morador nos equipamentos!',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        if (driver?.person_id) {
          let data = {
            user_id: driver.person_id
          }

          localPostApi('devices-manager/delete_all_data_user', data, 60 * 1000)
            .then(res => {
              // Delete in server
              this.setState({ loading: true })

              qs.access_drivers.delete({ person_id: driver?.person_id, type: 3 }).finally((res) => { this.setState({ loading: false }) })
              qs.access_drivers.delete({ person_id: driver?.person_id, type: 5 }).finally((res) => { this.setState({ loading: false }) })
              qs.access_drivers.delete({ person_id: driver?.person_id, type: 8 }).finally((res) => { this.setState({ loading: false }) })
            })
        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  handleDeleteDriverGuarita = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        if (driver && (driver.code !== '' && driver.code !== null && driver.code !== undefined)) {
          this.setState({ loading: true })

          qs.access_drivers.delete(driver)
            .then((res) => {
              localPostApi('linear/delete_driver', driver)

              this.setState({ loading: false })
            })
            .catch(() => {
              this.setState({ loading: false })
            })
        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  render() {
    return (
      <PageBase
        title="Acionadores"
        onFilterChange={this.onQuickFilterText}
        create={
          <React.Fragment>
            <Formik
              initialValues={{
                last_days: 60
              }}
              onSubmit={(values) => {}}
              render={({ values, submitForm, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <React.Fragment>
                  <Form style={{ flex: 1 }}>
                    <Form.Group style={{ margin: 0 }}>
                      <SemanticSelect
                        labelField={'Contar acessos'}
                        value={values.last_days}
                        options={options}
                        onChange={(name, value) => {
                          setFieldValue(name, value)
                          this.getData(value)
                        }}
                        onBlur={setFieldTouched}
                        fieldKey="last_days"
                        style={{ flex: 1 }}
                      />
                    </Form.Group>
                  </Form>
                </React.Fragment>
              )}
            />
          </React.Fragment>
        }
      >

        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={50}
                       domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
