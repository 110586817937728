import 'cropperjs/dist/cropper.css'
import React from 'react'
import Cropper from 'react-cropper'
import { Button, Icon, Message, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { qs } from '../../queries'
import { toDataURL, uploadS3, putApi } from '../../services/api'
import { giv, push_toast } from '../../utils/common'
import { b64toBlob } from '../../utils/device_utils'
import { show_modal } from '../../utils/Formatters'
import ActivityLoading from '../Loading'
import { WebcamCapture } from '../WebcamCapture'
import { PersonDataModel } from '../../data-model/PersonDataModel'

export default class FaceEnroll extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      base64: '',
      show_modal: false
    }
  }

  getAccessGroup = () => {
    let master_access_group = JSON.parse(JSON.stringify(this.props.access_group))
    let slave_access_devices_ids = giv(this.props.slave_access_group, 'access_devices_ids', [])

    if (master_access_group) {
      let all_access_devices_ids = [...master_access_group.access_devices_ids, ...slave_access_devices_ids]

      master_access_group.access_devices_ids = [...new Set(all_access_devices_ids)]
    }

    console.log('master_access_group', master_access_group)

    return master_access_group
  }

  savePhoto = async () => {
    if (this.state.base64 !== '' && !!this.state.base64) {
      const cropped_base64 = this.state.cropperInstance.getCroppedCanvas().toDataURL('image/jpeg')

      if (!this.props.enable_sync_face) {
        this.setState({ loading: true })
      }

      if (this.props.enable_sync_face) {
        new PersonDataModel(this.props.person).local_enrollFaceClient(
          cropped_base64,
          this.getAccessGroup()
        )
      }

      let _base64 = cropped_base64.split(',')[1]
      const blob = b64toBlob(_base64, 'image/jpeg')

      uploadS3(blob, 'people', 'gater_ui_face.jpg')
        .then((res) => {

          const person_data = {
            admin_face_validated: true,
            face_deleted: null,
            file: res.id
          }

          putApi('admin/people', person_data, this.props.person.id, '?is_generic=true').then((res) => {
            this.setState({ loading: false })

            if (!this.props.enable_sync_face) {
              swal({ type: 'success', title: 'Foto salva com sucesso!' })
            }

          }).catch(() => {
            this.setState({ loading: false })
            swal({ type: 'error', title: 'Erro ao salvar a foto!' })
          })
        })
        .catch(() => {
          this.setState({ loading: false })
          swal({ type: 'error', title: 'Erro ao salvar a foto!' })
        })

    } else {
      swal({ type: 'error', title: 'Capture uma Face primeiro' })
    }

  }

  clearPhoto = async () => {
    new PersonDataModel(this.props.person).local_deleteFace().then(res => {
      // Delete in server
      this.setState({ loading: true })

      let driver = {
        person_id: this.props.person.id,
        type: 8
      }

      qs.access_drivers.delete(driver).finally((res) => { this.setState({ loading: false }) })

    })
      .catch(err => {
        console.error(err)
        show_modal('Verifique os erros para que seja possível excluir o acionador do servidor!', 'error')
      })
  }

  takePicture = () => {
    const imageSrc = this.webcam.getScreenshot()

    this.setState({
      base64: imageSrc
    })
  }

  reSendFace = async () => {
    if (this.props.person?.photo?.file) {
      push_toast('Enviando Face, por favor aguarde!', 'info')

      const person_data = {
        admin_face_validated: true,
        face_deleted: null
      }

      putApi('admin/people', person_data, this.props.person.id, '?is_generic=true').then((res) => {
      }).catch(() => {
        swal({ type: 'error', title: 'Erro ao validar a foto!' })
        return false
      })

      let face_base64 = await toDataURL(this.props.person.photo.file)

      new PersonDataModel(this.props.person).local_enrollFaceClient(
        face_base64,
        this.getAccessGroup()
      )
    }
  }

  deleteAllData = () => {
    swal({
      title: 'Essa ação irá excluir TODOS OS DADOS dessa PESSOA nos equipamentos!',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        new PersonDataModel(this.props.person).local_deleteAllData()
          .then(res => {
            // Delete in server
            this.setState({ loading: true })

            qs.access_drivers.delete({ person_id: this.props.person.id, type: 3 }).finally((res) => { this.setState({ loading: false }) })
            qs.access_drivers.delete({ person_id: this.props.person.id, type: 5 }).finally((res) => { this.setState({ loading: false }) })
            qs.access_drivers.delete({ person_id: this.props.person.id, type: 8 }).finally((res) => { this.setState({ loading: false }) })
          })
      }
    })
  }

  render() {
    const videoConstraints = {
      height: 720,
      facingMode: 'user'
    }

    return (
      <React.Fragment>
        <Message warning icon="warning"
                 header="Como salvar a face do morador:"
                 content='Posicione a câmera e clique em "Capturar Face", verifique se está de boa qualidade e
                    então clique em "Salvar Face".'/>

        <Button primary size={'large'} onClick={() => this.setState({ show_modal: true })}>Capturar Face</Button>

        {this.props.enable_sync_face &&
          <Button color={'red'} size={'large'} onClick={this.clearPhoto}><Icon name="trash"/>Apagar Face</Button>}

        {this.props.enable_sync_face &&
          <Button color={'red'} size={'large'} onClick={this.deleteAllData}><Icon name="trash"/>Excluir todos dados</Button>}

        {this.props.person?.photo?.file && this.props.enable_sync_face &&
          <Button basic primary size={'large'} onClick={this.reSendFace}><Icon name="refresh"/>Reenviar Face atual</Button>}

        {this.state.show_modal &&
          <>
            <Modal open={true} size="fullscreen">
              <Modal.Content>
                {!this.state.base64 &&
                  <div style={styles.capture}>
                    <WebcamCapture
                      audio={false}
                      refCapture={(ref) => this.webcam = ref}
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      screenshotQuality={1}
                      minScreenshotHeight={720}
                      style={{ height: '60vh' }}
                    />
                  </div>}

                {this.state.base64 &&
                  <>
                    <div style={styles.capture}>
                      <div style={{ flex: 1 }}>
                        <Message info icon="info" header="Agora recorte a foto enquadrando somente o rosto"/>
                        <img src={require('../../assets/images/como-recortar-face.gif')} style={{ width: '100%', paddingRight: '14px' }}/>
                      </div>

                      <div style={{ flex: 3 }}>
                        <Cropper
                          style={{ height: '60vh', width: '100%' }}
                          zoomTo={0}
                          initialAspectRatio={3 / 4}
                          aspectRatio={3 / 4}
                          src={this.state.base64}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          background={false}
                          responsive={true}
                          autoCropArea={0.6}
                          zoomable={false}
                          toggleDragModeOnDblclick={false}
                          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                          onInitialized={(instance) => {
                            this.setState({
                              cropperInstance: instance
                            })
                          }}
                          guides={false}
                        />
                      </div>
                    </div>
                  </>}

              </Modal.Content>
              <Modal.Actions>
                <Button color="red" size="large" onClick={() => this.setState({ show_modal: false, base64: null })}>Fechar</Button>

                {this.state.base64 && <Button size="large" onClick={() => this.setState({ base64: null })}>Capturar Novamente</Button>}
                {!this.state.base64 && <Button primary size="large" onClick={this.takePicture}>Capturar Face</Button>}
                {this.state.base64 && <Button primary size="large" onClick={this.savePhoto}>Salvar</Button>}
              </Modal.Actions>
            </Modal>
          </>}

        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

const styles = {
  capture: {
    padding: 15,
    backgroundColor: '#ebebeb',
    display: 'flex',
    justifyContent: 'center'
  }
}
