import axios from 'axios'
import React from 'react'
import enviroment from '../enviroment'
import {apiQsMetadata} from '../services/api'

const LocalApiAxios = axios.create({
  timeout: 4000
})

LocalApiAxios.interceptors.request.use(
  function (config) {

    const metadata_qs = apiQsMetadata()

    config.url = config.url && config.url.indexOf('?') > -1
      ? `${config.url}&${metadata_qs}`
      : `${config.url}?${metadata_qs}`

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

LocalApiAxios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // Sentry.captureException(error)
    return Promise.reject(error)
  }
)

export const localFetchApi = async (endpoint, timeout = 4000, headers = {}) => {
  return await LocalApiAxios({
    method: 'get',
    url: enviroment().localUrl() + endpoint,
    timeout: timeout,
    headers: headers
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const localFetchApi_ValidateLocalServer = async (endpoint, timeout = 4000, headers = {}) => {
  if (!enviroment().localIp()) {
    return null
  }

  return await LocalApiAxios({
    method: 'get',
    url: enviroment().localUrl() + endpoint,
    timeout: timeout,
    headers: headers
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const localPostApi = async (endpoint, data, timeout = 4000, headers = {}) => {
  return await LocalApiAxios({
    method: 'post',
    url: enviroment().localUrl() + endpoint,
    data: data,
    timeout: timeout,
    headers: headers
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const localPostApi_ValidateLocalServer = async (endpoint, data, timeout = 4000, headers = {}) => {
  if (!enviroment().localIp()) {
    return null
  }

  return await LocalApiAxios({
    method: 'post',
    url: enviroment().localUrl() + endpoint,
    data: data,
    timeout: timeout,
    headers: headers
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const localPutApi = async (endpoint, data, params = {}) => {
  return await LocalApiAxios({
    method: 'patch',
    url: enviroment().localUrl() + endpoint,
    data: data,
    ...params
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const localPutApi_ValidateLocalServer = async (endpoint, data) => {
  if (!enviroment().localIp()) {
    return null
  }

  return await LocalApiAxios({
    method: 'patch',
    url: enviroment().localUrl() + endpoint,
    data: data
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return Promise.reject(error)
  })
}

export const getEvents = () => localFetchApi('events')

export const getVisits = () => localFetchApi('visits')

/**
 * @deprecated
 */
export const getCache = async (key) => {
  if (enviroment().localIp()) {
    return localFetchApi(`cache/${key}`)
  }
  console.log(`Local server ip null to get cache: ${key}`)
  return null

}

/**
 * @deprecated
 */
export const putCache = async (cache_path, data, params = {}) => {
  if (enviroment().localIp()) {
    return localPutApi(`cache/${cache_path}`, data, params)
  }
  console.log(`Local server ip null to get cache: ${cache_path}`)
  return null
}

export const requestToServerCacheUpdate = async (cache_key) => {
  if (enviroment().localIp()) {
    return localPostApi(`cache/cache_updates`, { key: cache_key }, 320 * 1000)
  }
  console.log(`Local server ip null to partial update cache: ${cache_key}`)
  return null
}

