import {
  CLEAR_ERROR,
  FETCHING_DATA,
  FETCHING_DATA_FAILURE,
  FETCHING_DATA_SUCCESS,
  REQUEST_ERROR,
  SENDING_REQUEST,
  TOGGLE_MENU
} from '../../constants'

const initialState = {
  data: [],
  dataFetched: false,
  isFetching: false,
  error: false,
  currentlySending: false,
  dashboard: null,
  menu_visibility: false,
  // loggedIn: authService.loggedIn()
}

export default function dataReducer(state = initialState, action) {
  switch (action.type) {
    case SENDING_REQUEST:
      return {...state, currentlySending: action.sending}

    case REQUEST_ERROR:
      return {...state, error: action.error}

    case CLEAR_ERROR:
      return {...state, error: ''}

    case FETCHING_DATA:
      return {
        ...state,
        data: [],
        isFetching: true
      }
    case FETCHING_DATA_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      }
    case FETCHING_DATA_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: true
      }

    case TOGGLE_MENU:
      return {...state, menu_visibility: !state.menu_visibility}

    default:
      return state
  }
}