import React from 'react'
import { Button, Dropdown, Grid, Message, Modal, Table } from 'semantic-ui-react'
import { qs } from '../queries'
import ActivityLoading from './Loading'
import { localPostApi } from '../utils/LocalApi'
import { connect } from 'react-redux'
import { hasValue, show_modal } from '../utils/Formatters'
import { ACCESSDRIVER_TYPES } from '../fields'
import moment from 'moment'
import { Formik } from 'formik'
import { FF, FormikInput, FormikSelect } from './form/FormInputs'
import swal from 'sweetalert2'
import { decimal2wiegand } from '../utils/device_utils'

const code_type = [
  {value: 1, text: 'Wiegand padrão'},
  {value: 3, text: 'Decimal'},
]

class NeokorosManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bio_modal: false,
      pwd_modal: false,
      card_modal: false,
      loading: false,
      data: {},
      controllers: []
    }

    this.enroll_bio = this.enroll_bio.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    this.getControllers()
  }

  getControllers = () => {
    this.setState({loading: true})

    qs.access_drivers.filterByPersonId(this.props.resident.id)
      .then((res) => {
        this.setState({loading: false})
        this.setState({controllers: res})
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  handleClose() {
    this.setState({bio_modal: false})
  };

  enroll_bio(bio = 1) {
    this.setState({bio_modal: true})

    let data = {
      user_id: this.props.resident.id,
    }

    localPostApi('neokoros/enroll', data)
  };

  delete_bio = () => {
    let data = {
      user_id: this.props.resident.id,
    }

    localPostApi('neokoros/delete_bio', data)
  }

  delete_driver = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.value === true) {
        if (driver && (driver.code !== '' && driver.code !== null && driver.code !== undefined)) {
          this.setState({loading: true})

          qs.access_drivers.delete(driver)
            .then((res) => {
              this.getControllers()
              this.setState({loading: false})
            })
            .catch(() => {
              this.setState({loading: false})
            })
        } else {
          swal({type: 'error', title: 'Erro no código do acionador de acesso',})
        }
      }
    })
  }

  convert_decimal = () => {
    let decimal = decimal2wiegand(this.form.state.values.decimal).wiegand
    this.form.setFieldValue('code', decimal)
  }

  render() {
    return (
      <React.Fragment>
        <Message warning icon='warning'
                 header='Como fazer o cadastro biométrico:'
                 content='Clique em "Cadastrar Biometria", aguarde a resposta do equipamento e solicite o usuário para colocar
                 o dedo sobre o leitor.'/>

        {!this.props.resident.access_group_id &&
        <Message error icon='ban'
                 header='É necessário configurar a ROTA DE ACESSO!'
                 content=''/>
        }

        {this.props.resident.access_group_id &&
        <React.Fragment>
          <Dropdown text='Cadastrar' icon='plus' floating labeled button upward className='icon big primary'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.enroll_bio(1)}>Biometria</Dropdown.Item>
              <Dropdown.Item onClick={() => this.setState({card_modal: true})}>Cadastrar Cartão/Chaveiro</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown text='Excluir' icon='delete' floating labeled button upward className='icon big red'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.delete_bio()}>
                Excluir Biometria
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </React.Fragment>}


        <br/>
        <h2>Acionadores Cadastrados</h2>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data de Cadastro</Table.HeaderCell>
              <Table.HeaderCell>Última atualização</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.controllers.length > 0 && this.state.controllers.filter(i => i.type === 3).map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.code}
                  </Table.Cell>
                  <Table.Cell>
                    {ACCESSDRIVER_TYPES[item.type]}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    <button className="ui mini basic icon button red" onClick={() => this.delete_driver(item)}>
                      <i className="trash icon"/>
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>

        <Modal open={this.state.bio_modal} size='small'>
          <Modal.Header>Cadastrar Biometria</Modal.Header>
          <Modal.Content>
            <h3>Solicite ao usuário colocar o dedo sobre o leitor biometrico.<br/>
              O equipamento solicitará novamente, para confirmar a biometria.</h3>
          </Modal.Content>
          <Modal.Actions>
            <button className="ui button primary large" onClick={this.handleClose}>Fechar</button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.card_modal} size='small'>
          <Modal.Header>Cadastrar Cartão/Chaveiro: {hasValue(this.props, 'resident.name')}</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                user_id: this.props.resident.id,
                user_name: this.props.resident.name,
                reader_usb: '',

                type: 3,
                status: 1,
                person: this.props.resident.id,
                code: '', // Wiegand
                alias: '', // Decimal
                facility_code: '',

                decimal: '',
                code_type: 1,
              }}
              validate={values => {
                let errors = {}
                if (values.code.length !== 8) errors.code = 'Deve ter 8 dígitos'

                return errors
              }}
              onSubmit={(values) => {
                if (hasValue(this.props, 'resident.id') && hasValue(this.props, 'resident.name')) {
                  this.setState({loading: true})
                  let data = values
                  // Check for existing driver
                  qs.access_drivers.filterByCode(data.code)
                    .then((res) => {

                      if (res.length < 1) {
                        qs.access_drivers.post(data)
                          .then((resp) => {
                            swal({type: 'success', title: 'Acionador cadastrado!'})
                            this.setState({loading: false})

                            this.getControllers()
                          })
                          .catch((err) => {
                            this.setState({loading: false})
                          })
                      } else {
                        qs.access_drivers.patch(data, res[0]['id'])
                          .then((resp) => {
                            swal({type: 'success', title: 'Acionador cadastrado!'})
                            this.setState({loading: false})

                            this.getControllers()
                          })
                          .catch((err) => {
                            this.setState({loading: false})
                          })
                      }
                    })
                    .catch((err) => {
                      this.setState({loading: false})
                      throw err
                    })

                } else {
                  show_modal('Dados inválidos', 'error')
                }

              }}
              render={({values, errors, touched, handleChange, handleBlur, setFieldValue}) => (
                <div className='ui form large error'>
                  <FF label={'Tipo Acionador'} name="code_type" component={FormikSelect} options={code_type}
                      onChangePos={() => {
                        setFieldValue('code', '')
                        setFieldValue('decimal', '')
                        setFieldValue('alias', '')
                        setFieldValue('facility_code', '')
                      }}/>

                  {values.code_type === 1 &&
                  <React.Fragment>
                    <Grid>
                      <Grid.Column width={7}>
                        <FF label={'Código Wiegand'} name="code" component={FormikInput}/>
                      </Grid.Column>
                    </Grid>
                  </React.Fragment>}

                  {values.code_type === 3 &&
                  <React.Fragment>
                    <Grid>
                      <Grid.Column width={7}>
                        <FF label={'Código Decimal'} name="decimal" component={FormikInput}/>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <div className='field'>
                          <label>&nbsp;</label>
                          <Button floated={'right'} circular icon='angle right' onClick={this.convert_decimal}/>
                        </div>
                      </Grid.Column>

                      <Grid.Column width={7}>
                        <FF label={'Wiegand'} name="code" component={FormikInput}/>
                      </Grid.Column>
                    </Grid>
                  </React.Fragment>}
                </div>
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' size='large' onClick={() => this.setState({card_modal: false})}>Fechar</Button>
            <Button primary size='large' onClick={(e) => this.form.handleSubmit(e)}>Cadastrar Cartão</Button>
          </Modal.Actions>
        </Modal>
        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(NeokorosManager)
