import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import ActivityLoading from '../../components/Loading'
import { dateFormat } from '../../utils/Formatters'
import { CONSTRUCTION_STATUS } from '../../fields'
import { getConstructions } from '../../utils/DataService'
import { Grid, Header, Modal } from 'semantic-ui-react'
import { gridDefaults } from '../../utils/gridDefaults'

export default class ConstructionListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      details_modal: false,
      details_data: null,
      columnDefs: [
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 50,
          filter: 'text',
          suppressMenu: true,
        },
        {
          headerName: 'Data de Início',
          field: 'start_date',
          width: 50,
          filter: 'test',
          suppressMenu: true,
          valueGetter: (params) => dateFormat(params, 'start_date'),
        },
        {
          headerName: 'Data de Termino',
          field: 'end_date',
          width: 50,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => dateFormat(params, 'end_date'),
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 45,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => CONSTRUCTION_STATUS[params.data.status],
        },
        {
          id: 'actions',
          headerName: 'Ações',
          width: 120,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRendererFramework: this.action
        },
      ],
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        onCellClicked: (event) => {
          if (event.colDef.headerName !== 'Ações') {
            this.setState({details_data: event.data, details_modal: true})
          }
        },
        defaultColDef: {
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
        }
      },
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  onGridReady = (params) => {
    this.setState({loading: true})

    getConstructions()
      .then((data) => {
        params.api.setRowData(data)
        this.setState({loading: false})
        params.api.sizeColumnsToFit()
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  action = (params) => {
    return (
      <div>
        <button className="ui icon button basic compact green"
                onClick={() => params.context.componentParent.handleEdit(params.data)}>
          <i className="pencil icon"/>
        </button>
      </div>
    )
  }

  handleEdit = (data) => {
    console.log(data)
    this.setState({
      details_modal: true,
      details_data: data
    })
  }

  render() {
    return (
      <PageBase title="Obras" create={false} onFilterChange={this.onQuickFilterText}>

        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact
            gridOptions={this.state.gridOptions}
            enableFilter={true}
            columnDefs={this.state.columnDefs}
            quickFilterText={this.state.quickFilterText}
            onGridReady={this.onGridReady.bind(this)}
            pagination={true}
            paginationPageSize={50}
            domLayout={'autoHeight'}
          />
        </div>

        {this.state.details_modal &&
        <Modal open={this.state.details_modal} size='large'>
          <Modal.Header>Detalhes</Modal.Header>
          <Modal.Content>
            <h3 className="ui dividing header">Detalhes</h3>
            <br/>
            <Grid columns={'equal'} celled='internally' padded>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Local da obra:</Header>
                  <span>{this.state.details_data && this.state.details_data.local}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Descrição da obra:</Header>
                  <span>{this.state.details_data && this.state.details_data.description}</span>
                </Grid.Column>
              </Grid.Row>
              <div className="ui divider hidden"/>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Nome do responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.manager_name}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Telefone do responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.manager_phone}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Documento do responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.manager_doc}</span>
                </Grid.Column>
              </Grid.Row>
              <div className="ui divider hidden"/>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Nome da empresa responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.company_name}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Telefone da empresa responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.company_phone}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Documento da empresa responsável:</Header>
                  <span>{this.state.details_data && this.state.details_data.company_doc}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>


            <br/>
            <br/>
            <br/>
            <h3 className="ui dividing header">Materiais e Resíduos</h3>
            <br/>
            <Grid columns={'equal'} celled='internally' padded>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Materiais:</Header>
                  <span>{this.state.details_data && this.state.details_data.materials}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Materiais tóxicos:</Header>
                  <span>{this.state.details_data && this.state.details_data.materials_toxic}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Materiais combustíveis:</Header>
                  <span>{this.state.details_data && this.state.details_data.materials_combustible}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Materiais inflamáveis:</Header>
                  <span>{this.state.details_data && this.state.details_data.materials_flammable}</span>
                </Grid.Column>
              </Grid.Row>
              <div className="ui divider hidden"/>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Local de armazenamento dos materiais:</Header>
                  <span>{this.state.details_data && this.state.details_data.materials_storage}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Local de armazenamento dos resíduos gerados:</Header>
                  <span>{this.state.details_data && this.state.details_data.leavings_storage}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Planejamento de descarte dos resíduos gerados:</Header>
                  <span>{this.state.details_data && this.state.details_data.leavings_destiny}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Modal.Content>
          <Modal.Actions>
            <button className="ui button large" onClick={() => this.setState({details_modal: false, details_data: null})}>Fechar</button>
          </Modal.Actions>
        </Modal>}

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
