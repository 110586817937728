export const wiegand2decimal = (code, company_code = null) => {
  code = String(code)

  if (code.length !== 8) {
    return 'Erro'
  }

  let facility_code = code.substr(0, 3)
  let card_number = code.substr(-5)

  let hex_code = `${parseInt(facility_code).toString(16)}${parseInt(card_number).toString(16)}`

  if (company_code) {
    hex_code = String(company_code) + hex_code
  }

  return parseInt(hex_code, 16)
}

export const decimal2wiegand = (code, has_company_code = false) => {
  console.log('decimal2wiegand')

  let company_code = null
  code = parseInt(code).toString(16)
  console.log(code)
  // code = code.padStart(6, '0')
  // console.log('Handle pad zero', code)

  if (has_company_code) {
    company_code = code.substr(0, 2)
    code = code.substr(2)
    console.log('Handle has_company_code', code)
  }

  let facility_code = parseInt(code.substr(0, 2), 16)
  let card_number = parseInt(code.substr(2), 16)

  // code = code.slice(-7)
  // console.log('Code slice -7', code)
  //
  // let facility_code = parseInt(code.slice(0, -3), 16)
  // let card_number = parseInt(code.slice(-3), 16)

  console.log('facility_code', facility_code)
  console.log('card_number', card_number)

  let w = `${String(facility_code).padStart(3, '0')}${String(card_number).padStart(5, '0')}`

  console.log('wiegand', w)
  console.log('company_code', company_code)

  return {
    wiegand: w,
    company_code: company_code
  }
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

function ControlIdGetDeviceIdBySerial(nserie) {
  let parts = nserie.split('/')
  if (parts.length == 2) {

    let cHW = parts[0].substring(0, parts[0].length - 1)
    console.log('cHW', cHW)

    let nHW = parseInt(cHW, 36)
    console.log('nHW', nHW)

    console.log('part 1', parts[1])
    let nSEQ = parseInt(parts[1], 16)
    console.log('nSEQ', nSEQ)

    let vHigh = Math.pow(2, 32)

    return (nHW * vHigh) + nSEQ
  } else
    return parseInt(nserie, 36)
}

function testControlId() {
  let result = 687194767632

  console.log(result)

  console.log('\n\nreverso')
  console.log(result / 4294967296)
  console.log(result % 4294967296)
  console.log((Math.floor(result / 4294967296)).toString(36))
  console.log((result % 4294967296).toString(16))
}