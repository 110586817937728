import { handleActions } from 'redux-actions'
import { actionMapBase, reducersMap } from '../ducks_base'

const domain = 'visits'

// ACTIONS CREATORS
export const actionMap = actionMapBase(domain)

// REDUCER
const initialState = {
  data: [],
  current: {},
  showModal: false
}

let reducers = reducersMap(actionMap)
export default handleActions(reducers, initialState)
