import { handleActions } from 'redux-actions'
import { actionMapBase, ActionTypesBase, reducersMap } from '../../redux/ducks/ducks_base'
import { genericPostSagas, getSagasBase, postSagasBase, putSagasBase } from '../../redux/ducks/sagas_base'
import { takeEvery } from 'redux-saga/effects'
import { createAction, getType } from 'typesafe-actions'
import { Action } from 'redux'
import { PeopleState } from '../../redux'
import { buildQs } from '../../utils/common'

const domain: string = 'people'

// ACTIONS CREATORS Should be snake_case
type PeopleCustomActions = {
  modal_list: (show: boolean) => Action,
  by_unit: (id: number) => Action,
  by_unit_success: (data: object) => Action
}

export const person_actions: ActionTypesBase<PeopleCustomActions> = {
  ...actionMapBase(domain),
  modal_list: createAction(`${domain}/MODAL_LIST`, action => (show: boolean) => action(show)),
  by_unit: createAction(`${domain}/BY_UNIT`, action => (id: number) => action(id)),
  by_unit_success: createAction(`${domain}/BY_UNIT_SUCCESS`, action => (data: object) => action(data)),
}
// REDUCER Should be snake_case
const initialState: PeopleState = {
  data: [],
  by_unit: [],
  current: {},
  showModal: false,
  showModalList: false
}

let reducers = {
  ...reducersMap(person_actions),
  [getType(person_actions.modal_list)]: (state: any, action: any) => ({ ...state, showModalList: action.payload }),
  [getType(person_actions.by_unit_success)]: (state: any, action: any) => ({ ...state, by_unit: action.payload }),
}
export const people_reducers = handleActions(reducers, initialState)

// SAGAS
let qs = buildQs(['dependents', 'photo', 'units.parkings.with_pavement', 'units.vehicles'])
const getSagas = (action: Action) => getSagasBase(person_actions, domain, action, { url_params: `${qs}&is_generic=true` })
const postSagas = (action: Action) => postSagasBase(person_actions, domain, action, { url_params: `?is_generic=true`, refresh: false })
const putSagas = (action: Action) => putSagasBase(person_actions, domain, action, { url_params: `?is_generic=true`, refresh: true, close_modal: true })
const byUnitSagas = (action: any) =>
  genericPostSagas(domain + '/find_by_unit', { unit_id: action.payload }, { action_success: person_actions.by_unit_success })

export const people_sagas = [
  takeEvery(getType(person_actions.get), getSagas),
  takeEvery(getType(person_actions.put), putSagas),
  takeEvery(getType(person_actions.post), postSagas),
  takeEvery(getType(person_actions.by_unit), byUnitSagas)
]
