import { combineReducers } from 'redux'
import { SET_AUTH } from '../../constants'
import { people_reducers } from '../../containers/People'
import devices_reducer from '../modules/devices'
import { grid_refs_reducer } from '../modules/grid-refs'
import system_reducer from '../modules/system'
import visits_reducer from '../modules/visits'
import dataReducer from './dataReducer'

const appReducer = combineReducers({
  app: dataReducer,
  system: system_reducer,
  visits: visits_reducer,
  devices: devices_reducer,
  people: people_reducers,
  grid_refs: grid_refs_reducer
})

const rootReducer = (state, action) => {
  if (action.type === SET_AUTH && action.newAuthState === false) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer