import { createAction, handleActions } from 'redux-actions'

const domain = 'system'

// ACTIONS CREATORS
export const actionMap = {
  status_server: createAction(`${domain}/STATUS_SERVER`),
  db_server_running: createAction(`${domain}/DB_SERVER_RUNNING`),
  server_installed: createAction(`${domain}/SERVER_INSTALLED`),
  download_status: createAction(`${domain}/DOWNLOAD_STATUS`),
  showAccessLogForm: createAction(`${domain}/SHOW_ACCESS_LOG_FORM`),
}

// REDUCER
const initialState = {
  show_access_log_form: false,
  status_server: false,
  server_installed: false,
  db_server_running: false,
  download_status: {
    percent_complete: 0,
  },
}

let reducers = {
  [actionMap.status_server]: (state, action) => ({ ...state, status_server: action.payload }),
  [actionMap.server_installed]: (state, action) => ({ ...state, server_installed: action.payload }),
  [actionMap.db_server_running]: (state, action) => ({ ...state, db_server_running: action.payload }),
  [actionMap.download_status]: (state, action) => ({ ...state, download_status: action.payload }),
  [actionMap.showAccessLogForm]: (state, action) => ({ ...state, show_access_log_form: action.payload }),
}

export default handleActions(reducers, initialState)

