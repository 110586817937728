/*eslint-disable no-undef*/
import React from 'react'
import { Button, Message, Table } from 'semantic-ui-react'
import { qs } from '../../queries'
import ActivityLoading from '../Loading'
import swal from 'sweetalert2'
import { connect } from 'react-redux'
import { ACCESSDRIVER_TYPES } from '../../fields'
import moment from 'moment'
import { giv } from '../../utils/common'
import PwdEnroll from './PwdEnroll'

class PwdManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pwd_modal: false,
      loading: false,
      data: {},
      access_drivers: []
    }
  }

  componentDidMount() {
    this.getPersonAccessDrivers()
  }

  getPersonAccessDrivers = () => {
    this.setState({loading: true})

    qs.access_drivers.filterByPersonId(this.props.resident.id)
      .then((res) => {
        this.setState({loading: false})
        this.setState({access_drivers: res})
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  delete_driver = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.value === true) {
        console.log(driver)

        // Delete in server
        this.setState({loading: true})

        qs.access_drivers.delete(driver)
          .then((res) => {
            this.getPersonAccessDrivers()
            this.setState({loading: false})
          })
          .catch(() => {
            this.setState({loading: false})
          })
      }
    })
  }

  render() {
    let master_access_group = JSON.parse(JSON.stringify(this.props.access_group))
    let slave_access_devices_ids = giv(this.props.slave_access_group, 'access_devices_ids', [])

    if (master_access_group) {
      let all_access_devices_ids = [...master_access_group.access_devices_ids, ...slave_access_devices_ids]

      master_access_group.access_devices_ids = [...new Set(all_access_devices_ids)]
    }

    console.log('master_access_group', master_access_group)

    return (
      <React.Fragment>
        <Message
          info icon='question'
          header='Dica!'
          list={[
            'Compatível somente com equipamentos Neokoros',
            'Somente as senhas são exibidos na tabela abaixo',
            'Para excluir uma senha, use o botão grande vermelho "Excluir"',
          ]}
        />

        {!this.props.resident.access_group_id &&
        <Message error icon='ban'
                 header='É necessário configurar a ROTA DE ACESSO!'
                 content=''/>
        }

        {this.props.resident.access_group_id &&
        <React.Fragment>
          {this.props.person_status &&
          <Button icon='plus' floating labeled button upward className='icon big primary'
                  onClick={() => this.setState({pwd_modal: true})}>Cadastrar Senha</Button>
          }
        </React.Fragment>
        }

        <br/>
        <h2>Acionadores Cadastrados</h2>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data de Cadastro</Table.HeaderCell>
              <Table.HeaderCell>Última atualização</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.access_drivers.length > 0 && this.state.access_drivers.filter(i => i.type === 7).map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {ACCESSDRIVER_TYPES[item.type]}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    <button className="ui mini basic icon button red" onClick={() => this.delete_driver(item)}>
                      <i className="trash icon"/>
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>


        {this.state.pwd_modal &&
        <PwdEnroll parent={this} person={this.props.resident} resident={true} access_group={this.props.access_group}/>}

        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PwdManager)
