import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { Checkbox, Dropdown, Form, Grid, Header, Label, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import UnitsSelect from '../../components/form/UnitsSelect'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { ProfileCache } from '../../components/Utils'
import { ACCESSLOG_STATUS, ACCESSLOG_TYPES } from '../../fields'
import { qs } from '../../queries'
import { getGuestLogs } from '../../utils/DataService'
import { dateTimeFormat, getValue, hasValue, removeAccents, show_modal } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'

export default class AccessLogListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        onCellClicked: (event) => {
          if (event.colDef.headerName !== 'Ações') {
            this.setState({ detail_data: event.data, detail_modal: true })
          }
        },
        rowHeight: 90
      },

      LogQuickFilterText: null,
      loading: false,
      logData: '',

      columnLogDefs: [
        {
          headerName: 'Foto',
          width: 40,
          suppressMenu: true,
          cellRendererFramework: (params) => {
            return (
              <ProfileCache src={params.data.photo_url} style={{ height: '85px' }}/>
            )
          }
        },
        {
          headerName: 'Destino',
          field: 'destiny',
          width: 60,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Nome',
          field: 'guest_name',
          width: 90,
          filter: 'text',
          getQuickFilterText: removeAccents,
          cellRendererFramework: (params) => {
            let _value = params.getValue()
            return (
              <>
                <p>{_value}</p>
                {(params.node.data.vehicle_plate || params.node.data.vehicle_model || params.node.data.vehicle_color) &&
                  <>
                    <b>Veículo: </b>
                    {params.node.data.vehicle_plate && <>{params.node.data.vehicle_plate}</>} &nbsp;
                    {params.node.data.vehicle_model && <>{params.node.data.vehicle_model}</>} &nbsp;
                    {params.node.data.vehicle_color && <>{params.node.data.vehicle_color}</>}
                  </>}

                {params.node.data.badge_number &&
                  <Label
                    color="violet" icon="id badge"
                    detail={params.node.data.badge_number} content="Crachá"
                  />}
              </>
            )
          }
        },
        {
          headerName: 'Veículo',
          field: 'vehicle_model',
          width: 90,
          filter: 'text',
          suppressMenu: true,
          hide: true,
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Veículo',
          field: 'vehicle_plate',
          width: 90,
          filter: 'text',
          suppressMenu: true,
          hide: true,
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Veículo',
          field: 'vehicle_color',
          width: 90,
          filter: 'text',
          suppressMenu: true,
          hide: true,
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Entrou como',
          field: 'access_reason_name',
          width: 45,
          filter: 'text'
        },
        {
          headerName: 'Direção',
          field: 'type',
          width: 25,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => ACCESSLOG_TYPES[params.data.type]
        },
        {
          headerName: 'Data',
          width: 45,
          valueGetter: dateTimeFormat
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 40,
          filter: 'text',
          hide: true,
          suppressMenu: true,
          valueGetter: (params) => ACCESSLOG_STATUS[params.data.status]
        },
        {
          headerName: 'Crachá',
          field: 'badge_number',
          width: 40,
          filter: 'text',
          hide: true,
          suppressMenu: true
        },
        {
          id: 'actions',
          headerName: 'Ações',
          width: 120,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRendererFramework: this.actionLog
        }
      ],

      activeTab: 0,

      openModal: false,
      detail_data: null,
      detail_modal: false,
      edit_data: null,
      edit_modal: false
    }
  }

  LogOnQuickFilterText = (event) => {
    this.setState(
      { LogQuickFilterText: event }
    )
  }

  actionLog = (params) => {
    if (params.data && params.data.status === 3) {
      return (
        <div>
          <button className="ui button basic compact green" onClick={
            () => params.context.componentParent.handleLogExit(params.data)}>Sair
          </button>

          <button className="ui icon button basic compact green"
                  onClick={() => params.context.componentParent.handleEdit(params.data)}>
            <i className="pencil icon"/>
          </button>
        </div>
      )
    } else {
      return null
    }
  }

  onGridLogReady = (params) => {
    this.GridLogApi = params.api

    this.getData()
  }

  getData = (days = null) => {
    this.setState({
      loading: true
    })

    getGuestLogs(days)
      .then(res => {
        this.GridLogApi.setRowData(res)
        this.setState({ logoData: res, loading: false })
        this.GridLogApi.sizeColumnsToFit()
      })
      .catch(err => {
        this.setState({
          loading: false
        })
      })
  }

  handleLogExit = (params) => {
    this.setState({
      loading: true
    })

    qs.access_logs.createExit(params.id)
      .then((res) => {
        getGuestLogs()
          .then(res => {
            this.setState({ loading: false })
            this.GridLogApi.setRowData(res)
            this.GridLogApi.sizeColumnsToFit()
          })
          .catch(err => {
            this.setState({ loading: false })
          })

        swal({ type: 'success', title: 'Salvo com sucesso!' })
      })
      .catch(() => {
        this.setState({ loading: false })
        swal({ type: 'error', title: 'Verifique os dados!' })
      })
  }

  handleEdit = (params) => {
    this.setState({
      edit_data: params,
      edit_modal: true
    })
  }

  render() {
    return (
      <PageBase title="Registro de Entrada / Saída"
                create={
                  <React.Fragment>
                    <Dropdown basic button floating text="Filtrar" icon="filter" className="icon" size="large">
                      <Dropdown.Menu>
                        <Dropdown.Item text="5 dias atrás" onClick={() => this.getData(5)}/>
                        <Dropdown.Item text="10 dias atrás" onClick={() => this.getData(10)}/>
                        <Dropdown.Item text="20 dias atrás" onClick={() => this.getData(20)}/>
                        <Dropdown.Item text="30 dias atrás" onClick={() => this.getData(30)}/>
                      </Dropdown.Menu>
                    </Dropdown>
                  </React.Fragment>
                }
                onFilterChange={this.LogOnQuickFilterText}>

        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnLogDefs}
                       quickFilterText={this.state.LogQuickFilterText}
                       onGridReady={this.onGridLogReady}
                       pagination={true}
                       domLayout={'autoHeight'}
          />
        </div>

        {this.state.detail_modal &&
          <Modal open={this.state.detail_modal}>
            <Modal.Header>Detalhes</Modal.Header>
            <Modal.Content>
              <Grid stackable>
                <Grid>
                  <Grid.Column width={4}>
                    <ProfileCache src={getValue(this.state.detail_data, 'photo_url')} style={{ width: '200px' }}/>
                  </Grid.Column>

                  <Grid.Column width={12}>
                    <Grid.Row>
                      <Header>
                        {(!hasValue(this.state.detail_data, 'person.name')) && this.state.detail_data.name_temp}
                        {(hasValue(this.state.detail_data, 'person.name')) && this.state.detail_data.person.name}
                      </Header>
                      <span>{getValue(this.state.detail_data, 'person.description')}</span>
                      <br/>
                      <br/>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid columns={'equal'}>
                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>Destino:</Header>
                            <span>
                              {this.state.detail_data.destiny}
                            </span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Entrou como:</Header>
                            <span>{this.state.detail_data.access_reason_name}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Direção:</Header>
                            <span>{ACCESSLOG_TYPES[this.state.detail_data.type]}</span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>Data:</Header>
                            <span>{moment(this.state.detail_data.date).format('DD/MM/YY HH:mm')}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Status:</Header>
                            <span>{ACCESSLOG_STATUS[this.state.detail_data.status]}</span>
                          </Grid.Column>
                          <Grid.Column>
                            <Header sub>Veículo:</Header>
                            <span>
                              {this.state.detail_data.vehicle_plate && <>{this.state.detail_data.vehicle_plate}</>} &nbsp;
                              {this.state.detail_data.vehicle_model && <>{this.state.detail_data.vehicle_model}</>} &nbsp;
                              {this.state.detail_data.vehicle_color && <>{this.state.detail_data.vehicle_color}</>}
                            </span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Header sub>Observações da Visita:</Header>
                            <span>{this.state.detail_data.description}</span>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <button className="ui button large primary" onClick={() => this.setState({ detail_modal: false, detail_data: null })}>Fechar
              </button>
            </Modal.Actions>
          </Modal>}

        {this.state.edit_modal &&
          <Modal open={this.state.edit_modal} size="small">
            <Modal.Header>Editar Destino</Modal.Header>
            <Modal.Content>
              <Formik
                ref={node => (this.edit_form = node)}
                initialValues={{
                  destination_unit_id: '',
                  go_condo: false
                }}
                validate={values => {
                  let errors = {}
                  if (values.go_condo === false && !values.destination_unit_id) errors.destination_unit_id = 'Selecione a unidade'
                  return errors
                }}
                onSubmit={async (values) => {
                  this.setState({ loading: true })

                  let data = {}

                  if (values.go_condo) {
                    data = {
                      destination_unit: null,
                      destination_space: null,
                      go_condo: true
                    }
                  } else {
                    data = {
                      destination_unit: values.destination_unit_id,
                      go_condo: false
                    }
                  }

                  qs.access_logs.patch(this.state.edit_data.id, data)
                    .then((res) => {
                      this.setState({
                        edit_data: null,
                        edit_modal: false
                      })

                      getGuestLogs()
                        .then(res => {
                          this.GridLogApi.setRowData(res)
                          this.setState({ logoData: res, loading: false })
                          this.GridLogApi.sizeColumnsToFit()
                        })
                        .catch(err => this.setState({ loading: false }))

                      show_modal('Operação realizada com sucesso!', 'success')
                    })
                    .catch(() => {
                      this.setState({ loading: false })
                      show_modal('Erro ao processar a solicitação!', 'error')
                    })
                }}
                render={({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
                  <Form className="ui form error">
                    <Checkbox toggle label={<label>Visitando o Condomínio</label>} onClick={(a, b) => {
                      setFieldValue('go_condo', b.checked)
                      setFieldValue('destination_unit_id', '')
                    }}/>

                    <UnitsSelect name="destination_unit_id"/>
                  </Form>
                )}
              />

            </Modal.Content>
            <Modal.Actions>
              <button className="ui button large" onClick={() => this.setState({ edit_modal: false, edit_data: null })}>Fechar</button>
              <button className="ui button large primary" type="submit" onClick={() => this.edit_form.submitForm()}>Enviar</button>
            </Modal.Actions>
          </Modal>}

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}