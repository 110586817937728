import moment from 'moment'
import React, {useEffect} from 'react'
import {useQuery} from 'react-query'
import {Button, Item, Label, Tab} from 'semantic-ui-react'
import {ProfileCache} from '../../../components/Utils'
import {ACCESSAUTH_STATUS} from '../../../fields'
import {qs} from '../../../queries'

const styles = {
  guest_item: {
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    padding: '0.5em 0.5em',
    borderRadius: '.28571429rem',
    border: '1px solid rgba(34,36,38,.15)'
  }
}

export const AccessLogFormAuthTab = ({ unit_id, handleAuthGuest }: { unit_id: any, handleAuthGuest: (params) => void }) => {
  const { isLoading, data, refetch } = useQuery(['getUnitAccessAuth', unit_id], () => qs.access_authorizations.all_by_unit(unit_id), {
    enabled: false,
    retry: false
  })

  useEffect(() => {
    if (unit_id) {
      refetch()
    }
  }, [unit_id])

  return (
    <Tab
      renderActiveOnly={true}
      menu={{ secondary: true, pointing: true }}
      defaultActiveIndex={0}
      panes={[
        {
          menuItem: 'Visitantes Pré-autorizados', render: () =>
            <React.Fragment>
              <Item.Group>
                {data?.filter(i => i.status !== 3).map(item => (
                  <Item key={item.id} style={styles.guest_item}>
                    <div className="ui tiny image">
                      <ProfileCache src={item.person?.file?.file}/>
                    </div>

                    <Item.Content verticalAlign="middle" style={{ paddingLeft: '4px' }}>
                      <Item.Header>
                        {item.person_id === null && item.name_temp}
                        {item.person_id !== null && item.person.name}
                      </Item.Header>
                      <Item.Description>
                        {item.type === 1 &&
                          <React.Fragment>
                            Data prevista: <b>{moment(item.date).format('DD/MM/YY HH:mm')}</b>
                          </React.Fragment>}
                        {item.type === 2 &&
                          <React.Fragment>
                            Data Permitida:&nbsp;
                            <b>{moment(item.start_date).format('DD/MM/YY')} a {moment(item.end_date).format('DD/MM/YY')}<br/></b>
                            Horário Permitido:&nbsp;
                            <b>{moment(item.start_time, 'HH:mm:ss').format('HH:mm')} às {moment(item.end_time, 'HH:mm:ss').format('HH:mm')}</b>
                          </React.Fragment>}
                        {item.type === 3 &&
                          <React.Fragment>
                            <b>Sempre liberado</b>
                          </React.Fragment>}
                      </Item.Description>
                      <Item.Description>
                        Tipo: <b>{item.access_reason_name} </b><br/>
                        Status: <b>{ACCESSAUTH_STATUS[item.status]}</b><br/>
                        Motivo da autorização: <b>{item.description}</b>

                        {(item.vehicle_plate || item.vehicle_model || item.vehicle_color) && <>
                          <br/>
                          Veículo &nbsp;-&nbsp;&nbsp; Placa: <b>{item.vehicle_plate}</b> &nbsp;&nbsp; Modelo: <b>{item.vehicle_model}</b> &nbsp;&nbsp; Cor: <b>{item.vehicle_color}</b>
                        </>}
                      </Item.Description>
                      <Item.Extra>
                        {item.intercom && <Label color="red">Precisa Interfonar</Label>}
                        <Button
                          primary basic size="mini" floated="right" content="Entrar"
                          onClick={() => handleAuthGuest(item)}
                        />
                      </Item.Extra>
                    </Item.Content>
                  </Item>
                ))}
              </Item.Group>
            </React.Fragment>
        }
      ]}
    />
  )
}