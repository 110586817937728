import { AgGridReact } from 'ag-grid-react'
import * as React from 'react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { Button, Modal } from 'semantic-ui-react'
import { ACCESSLOG_STATUS, ACCESSLOG_TYPES } from '../../../fields'
import { qs } from '../../../queries'
import { dateTimeFormat } from '../../../utils/Formatters'

const GuestHistoryModal: React.FC<{ guest_id: number, setClose: () => void }> = (props) => {
  // const [open, setOpen] = useState(true)
  const columnDefs = [
    {
      headerName: 'Destino',
      field: 'destiny',
      width: 60,
      filter: 'text'
    },
    {
      headerName: 'Entrou como',
      field: 'access_reason_name',
      width: 45,
      filter: 'text'
    },
    {
      headerName: 'Direção',
      field: 'type',
      width: 25,
      filter: 'text',
      suppressMenu: true,
      valueGetter: (params) => ACCESSLOG_TYPES[params.data.type]
    },
    {
      headerName: 'Data',
      width: 45,
      valueGetter: dateTimeFormat
    },
    {
      headerName: 'Status',
      field: 'status',
      width: 40,
      filter: 'text',
      suppressMenu: true,
      valueGetter: (params) => ACCESSLOG_STATUS[params.data.status]
    }
  ]

  const { isLoading, data, refetch } = useQuery<any[]>(['getGuestHistory', props.guest_id], () => qs.guests.get_history(props.guest_id), {
    enabled: false,
    retry: false
  })

  useEffect(() => {
    if (props.guest_id) {
      refetch()
    }
  }, [props.guest_id])

  return (
    <React.Fragment>
      <Modal
        open={true}
        size="fullscreen"
        closeOnDimmerClick={true}
        centered={false}
        closeOnDocumentClick={true}
        closeOnRootNodeClick={true}
        onClose={props.setClose}
      >
        <Modal.Content>
          <div style={{ width: '100%', height: '70vh' }} className="ag-theme-material">
            {!isLoading &&
            <AgGridReact
              columnDefs={columnDefs}
              rowData={data}
              onRowDataUpdated={(params) => params.api.sizeColumnsToFit()}
              onRowDataChanged={(params) => params.api.sizeColumnsToFit()}
            />}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" color="red" onClick={props.setClose} content="Fechar"/>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  )
}

export default GuestHistoryModal