import create from 'zustand'
import moment from 'moment'

const initialState = {
  server_url: '',
  contact_name: '',
  show_caller: false,
  call_token: '',
  call_notifications_token: '',
  call_status: '',
  // ringing: false,
  // in_call: false,
  // muted: false,
  // speaker: false,
  // participants: [],
  // local_participants: [],
  // remote_participants: [],
  call_start_at: moment(),
  call_time: moment(),
}

interface AppStoreStateType {
  call: typeof initialState
  updateCall: (call_state: Partial<typeof initialState>) => void
}

export const useCallStore = create<AppStoreStateType>((set) => ({
  call: initialState,
  updateCall: (call_state) => set((state) => ({ call: { ...state.call, ...call_state } })),
}))

export const CALL_STATUS_NAME = {
  connecting: 'Conectando...',
  calling: 'Chamando...',
  reconnecting: 'Reconectando...',
  on_call: 'Em ligação',
}
