import {CellClickedEvent, ColDef, GridReadyEvent, RowClickedEvent} from 'ag-grid'
import {AgGridReact} from 'ag-grid-react'
import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {Button, Input, Modal} from 'semantic-ui-react'
import {ProfileCache} from '../../../components/Utils'
import {qs} from '../../../queries'
import {GuestModel} from '../../../typings'
import {push_toast} from '../../../utils/common'
import {dateTimeFormat, getValue, removeAccents} from '../../../utils/Formatters'
import {gridDefaults} from '../../../utils/gridDefaults'
import {GuestCreateModal} from './GuestCreateModal'
import {GuestEditModal} from './GuestEditModal'
import GuestHistoryModal from './GuestHistoryModal'

type Props = {
  closeSearchModal: () => void
  updateSelectedGuest: (data) => void
  selected_guests: GuestModel[]
}

const grid_options = {
  ...gridDefaults.adminGrid,
  domLayout: 'normal',
  context: {
    componentParent: this
  },
  rowHeight: 90,
  enableFilter: true,
  pagination: true,
  paginationPageSize: 15,
  rowSelection: 'single'
}

export const GuestSearchModal = (props: Props) => {
  const { isLoading, data, refetch } = useQuery<any[]>('getGuestsCached', () => qs.guests.allCached())

  const closeSearchModal = () => {
    setQuickFilterText('')
    setTimeout(() => {
      props.closeSearchModal()
    }, 300)
  }

  const pushToSelectedGuest = (guest: GuestModel) => {
    let selectedGuest = props.selected_guests
    const selected = props.selected_guests.some(item => item.id === guest.id)

    if (!selected) {
      selectedGuest.push(guest)
      props.updateSelectedGuest(selectedGuest)

      closeSearchModal()

    } else {
      push_toast('Visitante já selecionando', 'error')
      closeSearchModal()
    }
  }

  // GRID PARTS
  const [quick_filter_text, setQuickFilterText] = useState<string | undefined>(undefined)
  const onQuickFilterText = (event) => {
    let text = removeAccents(event.target)
    setQuickFilterText(text)
  }

  const onGridLogReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit()
  }

  const onRowSelected = (event: RowClickedEvent | CellClickedEvent) => {
    // @ts-ignore
    if (event.node.selected === true) {
      if (getValue(event.node, 'data.blocked')) {
        return
      }

      pushToSelectedGuest(event.node.data)
    }
  }

  const column_log_defs: ColDef[] = [
    {
      headerName: 'Foto',
      width: 50,
      suppressMenu: true,
      onCellClicked: onRowSelected,
      cellRendererFramework: (params) => {
        return (
          <ProfileCache src={params.data.photo_url} style={{ height: '85px' }}/>
        )
      }
    },
    {
      headerName: 'Nome',
      field: 'name',
      width: 90,
      filter: 'text',
      suppressMenu: true,
      getQuickFilterText: removeAccents,
      onCellClicked: onRowSelected,
      cellStyle: {
        'white-space': 'normal'
      },
      cellRendererFramework: (params) => {
        let blocked = getValue(params, 'data.blocked')
        return (
          <React.Fragment>
            {blocked && <div className="ui red horizontal label">Bloqueado</div>}
            {params.getValue()}
          </React.Fragment>
        )
      }
    },
    {
      headerName: 'CPF',
      field: 'cpf',
      width: 45,
      filter: 'text',
      onCellClicked: onRowSelected,
      suppressMenu: true
    },
    {
      headerName: 'RG',
      field: 'rg',
      width: 40,
      filter: 'text',
      onCellClicked: onRowSelected,
      suppressMenu: true
    },
    {
      headerName: 'Observações',
      field: 'description',
      width: 70,
      filter: 'text',
      suppressMenu: true,
      onCellClicked: onRowSelected,
      cellStyle: {
        'white-space': 'normal'
      }
    },
    {
      headerName: 'Data de Cadastro',
      field: 'created_at',
      width: 40,
      filter: 'text',
      valueGetter: (params) => dateTimeFormat(params, 'created_at'),
      onCellClicked: onRowSelected,
      suppressMenu: true
    },
    {
      headerName: '',
      field: '',
      width: 40,
      filter: 'text',
      suppressMenu: true,
      cellRendererFramework: (params) => {
        return (
          <React.Fragment>
            <Button content="Editar" compact positive basic s onClick={() => showGuestEditModal(params.node.data.id)}/>
            <br/>
            <br/>
            <Button content="Histórico" compact positive basic s onClick={() => showGuestHistoryModal(params.node.data.id)}/>
          </React.Fragment>
        )
      }
    }
  ]

  // GUEST HISTORY MODAL PARTS
  const [guest_history_modal, setGuestHistoryModal] = useState<boolean>(false)
  const [guest_edit_modal, setGuestEditModal] = useState<boolean>(false)
  const [guest_history_id, setGuestHistoryId] = useState<number | null>()

  const showGuestHistoryModal = (guest_id) => {
    setGuestHistoryModal(true)
    setGuestHistoryId(guest_id)
  }

  const hideGuestHistoryModal = () => {
    setGuestHistoryModal(false)
    setGuestHistoryId(null)
  }

  const showGuestEditModal = (guest_id) => {
    setGuestEditModal(true)
    setGuestHistoryId(guest_id)
  }

  const hideGuestEditModal = () => {
    setGuestEditModal(false)
    setGuestHistoryId(null)
  }

  // GUEST CREATE MODAL PARTS
  const [guest_create_modal, setGuestCreateModal] = useState<boolean>(false)

  const showGuestCreateModal = () => {
    setGuestCreateModal(true)
  }

  const hideGuestCreateModal = () => {
    setGuestCreateModal(false)
  }

  return (
    <React.Fragment>
      <Modal
        open={true} basic dimmer={'blurring'} size="fullscreen"
        closeOnDimmerClick={true} centered={false} closeOnDocumentClick={true} closeOnRootNodeClick={true}
        onClose={closeSearchModal}
      >

        <Modal.Content>
          <div className="ui form huge">
            <div className={'field'} style={{ marginBottom: 15 }}>
              <label>Digite o Nome, RG ou CPF (somente números)</label>
              <Input onChange={onQuickFilterText} icon="search" fluid placeholder="Filtrar..."/>
            </div>
          </div>

          <div style={{ width: '100%', height: 370 }} className="ag-theme-material">
            <AgGridReact
              rowData={data}
              gridOptions={grid_options}
              columnDefs={column_log_defs}
              quickFilterText={quick_filter_text}
              onGridReady={onGridLogReady}
            />
          </div>

          {guest_history_modal && guest_history_id &&
            <GuestHistoryModal
              guest_id={guest_history_id}
              setClose={hideGuestHistoryModal}
            />}

          {guest_edit_modal && guest_history_id &&
            <GuestEditModal
              setClose={hideGuestEditModal}
              guest_id={guest_history_id}
            />}

          {guest_create_modal &&
            <GuestCreateModal
              setClose={hideGuestCreateModal}
              closeSearchModal={closeSearchModal}
              pushToSelectedGuest={pushToSelectedGuest}
            />}

        </Modal.Content>
        <Modal.Actions style={{ paddingTop: 0 }}>
          <Button type="button" color="red" onClick={closeSearchModal}>
            Fechar
          </Button>
          <Button type="button" color="green" onClick={showGuestCreateModal}>
            Cadastrar novo visitante
          </Button>
        </Modal.Actions>
      </Modal>

    </React.Fragment>
  )
}