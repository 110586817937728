import axios from 'axios'
import { Formik } from 'formik'
import React from 'react'
import swal from 'sweetalert2'
import ActivityLoading from '../components/Loading'
import ServerServiceController from '../components/ServerServiceController'
import enviroment from '../enviroment'
import { qs } from '../queries'
import queryClient from '../queryClient'
import authService from '../services/authService'
import LicenseService from '../services/LicenseService'
import { fetchApi } from '../utils/api'
import { localPostApi_ValidateLocalServer } from '../utils/LocalApi'

const styles = {
  loginContainer: {
    minWidth: 320,
    maxWidth: 400,
    height: 'auto',
    position: 'absolute',
    top: '8%',
    left: 0,
    right: 0,
    margin: 'auto'
  },
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ccc'
  },
  paper: {
    padding: 20,
    overflow: 'auto'
  },
  loginBtn: {
    float: 'right'
  },
  logo: {
    height: 245,
    textAlign: 'center',
    marginBottom: 25
  }
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      open: false,
      loading: false
    }

    this.onChange = this.onChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    for (let i of Object.keys(localStorage)) {
      if (i !== 'localIp') {
        localStorage.removeItem(i)
      }
    }

    document.body.style.backgroundColor = '#002c59'

    queryClient.cancelQueries()
    queryClient.removeQueries()
    queryClient.clear()

    localStorage.clear()

    setTimeout(() => {
      queryClient.cancelQueries()
      queryClient.removeQueries()
      queryClient.clear()

      localStorage.clear()
    }, 1000)

  }

  componentWillUnmount() {
    document.body.style.backgroundColor = ''
  }

  onChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  };

  handleClose() {
    this.setState({ open: false })
  };

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.loginContainer}>

          <div style={styles.logo}>
            <img src={require('../assets/images/logo-login.png')}/>
          </div>

          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validate={values => {
              let errors = {}

              if (!values.username) {
                errors.username = 'Digite o usuário'
              }

              if (!values.password) {
                errors.password = 'Digite a senha'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              this.setState({
                loading: true
              })

              const data = {
                username: values.username,
                password: values.password,
                grant_type: 'password',
                client_id: enviroment().client_id,
                client_secret: enviroment().client_secret
              }

              axios({ method: 'post', url: enviroment().rootApi + 'auth/token/login', data: data }).then((res_login) => {
                if (res_login.data.user_role !== 2) {
                  this.setState({ loading: false })
                  swal({ type: 'error', title: 'Esse tipo de usuário não pode logar no Condo Id - Portaria!' })
                  return
                }

                authService.setTeamVersion(res_login.data.use_condoid_v2)

                try {
                  LicenseService.verification(res_login.data.auth_token)
                } catch (e) {
                  console.log(e)
                }

                try {
                  LicenseService.verification(res_login.data.auth_token)
                } catch (e) {
                  console.log(e)
                }

                // LicenseService.verification(res_login.data.auth_token)
                //   .then(ver_res => {

                // if (ver_res) {
                localStorage.setItem('token', res_login.data.auth_token)

                fetchApi('admin/me').then(() => {

                  qs.user.me().then(res => {
                    setTimeout(() => {
                      this.setState({ loading: false })
                      this.props.history.push('/admin/live')

                      // Sync before run "AccessManagerRequirements" in LivePage
                      localPostApi_ValidateLocalServer('access-manager/sync_access_manager')
                    }, 1300)

                  }).catch(err => {
                    console.log(err)
                    this.setState({ loading: false })
                    swal({ type: 'error', title: 'Erro ao recuperar usuário!' })
                  })

                }).catch((err) => {
                  console.log(err)
                  this.setState({ loading: false })
                  swal({ type: 'error', title: 'Sem permissão para acessar!' })
                })

                // } else {
                //   this.setState({ loading: false })
                // }

                // })
                // .catch(err => {
                //   this.setState({ loading: false })
                //   ToasterService.sendErrors(err)
                // })

              }).catch((error) => {
                console.error(error)
                this.setState({ loading: false })
                swal({ type: 'error', title: 'Verifique os dados de acesso!' })
              })

            }}
            render={({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit} className="ui form large error">
                <div className="ui stacked segment">

                  <div className={errors.username ? 'field error' : 'field'}>
                    <label>Usuário</label>
                    <div className="ui left icon input">
                      <i className="user icon"/>
                      <input type="text" name="username" onChange={handleChange} onBlur={handleBlur} value={values.username}/>
                    </div>

                    {touched.username && errors.username &&
                      <h5 className="ui header red">{errors.username}</h5>}
                  </div>

                  <div className={errors.password ? 'field error' : 'field'}>
                    <label>Senha</label>
                    <div className="ui left icon input">
                      <i className="lock icon"/>
                      <input type="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}/>
                    </div>

                    {touched.password && errors.password &&
                      <h5 className="ui header red">{errors.password}</h5>}
                  </div>

                  <button className="ui primary fluid large button" type="submit">Entrar</button>
                </div>

                <br/>
                <br/>

                <ServerServiceController show_install={false}/>

                <br/>
                <br/>
                <em style={{ color: '#5e88a9', textAlign: 'center', fontSize: '12px' }}>v{enviroment().APP_VERSION_READABLE}</em>
                <br/>


                <br/>
              </form>
            )}
          />
        </div>


        <ActivityLoading visible={this.state.loading}/>
      </div>
    )
  }
}

export default LoginPage
