import { Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { hasValue } from '../utils/Formatters'
import { localPostApi } from '../utils/LocalApi'
import { FF, FormikInput } from './form/FormInputs'

export default class AssignPwd extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Modal open={true} size="mini">
          <Modal.Header>Cadastrar Senha: {hasValue(this.props, 'person.name')}</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                pwd: '',
                confirm_pwd: ''
              }}
              validate={values => {
                let errors = {}
                if (values.pwd !== values.confirm_pwd) errors.confirm_pwd = 'Senhas não conferem'
                return errors
              }}
              onSubmit={(values) => {

                localPostApi('access-manager/validate_pwd', { pwd: values.pwd }).then(res => {
                  console.log(res)

                  if (hasValue(res, 'message')) {
                    swal({ type: 'error', title: res.message })
                  } else {
                    this.props.handleAssign(values)


                  }
                }).catch(err => {
                  console.error(err)
                  swal({ type: 'error', title: 'Erro ao validar' })
                })
              }}
              render={({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <div className="ui form large error">
                  <FF label={'Senha'} name="pwd" component={FormikInput} type="password"/>
                  <FF label={'Confirme a Senha'} name="confirm_pwd" component={FormikInput} type="password"/>
                </div>
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" size="large" onClick={this.props.setClose}>Cancelar</Button>
            <Button primary size="large" onClick={(e) => this.form.handleSubmit(e)}>Salvar</Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

