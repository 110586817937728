import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import { Button, Form, Icon } from 'semantic-ui-react'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { SemanticSelectFormik } from '../../components/SemanticSelect'
import queryClient from '../../queryClient'
import { dateTimeFormat } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'
import { getVisits } from '../../utils/LocalApi'

class VisitListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        isExternalFilterPresent: this.isExternalFilterPresent,
        doesExternalFilterPass: this.doesExternalFilterPass
      },
      columnDefs: [
        {
          headerName: 'Nome',
          field: 'name',
          width: 120,
          filter: 'text'
        },
        {
          headerName: 'Data',
          field: 'start_date',
          width: 50,
          valueGetter: (params) => dateTimeFormat(params, 'start_date')
        },
        {
          headerName: 'Unidade',
          field: 'destination',
          width: 60,
          filter: 'text'
        }
      ],
      filter_unit: null,
      all_units: []
    }
  }

  componentDidMount() {
    let units = queryClient.getQueryData('getUnitsSelect')
    console.log(units)

    this.setState({ all_units: units })
  }

  onQuickFilterText = (event) => {
    this.setState({ quickFilterText: event })
  }

  isExternalFilterPresent = () => {
    return this.state.filter_unit !== null
  }

  doesExternalFilterPass = (node) => {
    console.log('\n\n')
    console.log(node.data.destinationable_id)
    console.log(this.state.filter_unit)
    console.log('\n\n')

    if (!this.state.filter_unit) return true
    return node.data.destinationable_id === this.state.filter_unit
  }

  externalFilterChanged = (newValue) => {
    this.setState({ filter_unit: newValue }, () => {
      this.gridApi.onFilterChanged()
    })
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.setState({ loading: true })

    getVisits().then((res) => {
      params.api.setRowData(res)
      params.api.sizeColumnsToFit()
      this.setState({ loading: false })
    }).catch((err) => {
      this.setState({ loading: false })
    })
  }

  render() {
    return (
      <PageBase title="Visitas Ativas" onFilterChange={this.onQuickFilterText}
                create={
                  <React.Fragment>
                    <Form>
                      <Form.Field width={10}>
                        <label>Filtrar por unidade</label>
                        <SemanticSelectFormik options={this.state.all_units} search={true} id_option="id" label_option="with_block"
                                              onChange={(name, data) => this.externalFilterChanged(data)}
                                              onBlur={(name, data) => {}}
                                              fluid={false}
                        />
                      </Form.Field>
                    </Form>
                    <Button color={'red'} size={'big'} icon onClick={() => this.externalFilterChanged(null)}>
                      <Icon name="trash"/>
                    </Button>
                  </React.Fragment>
                }>


        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={this.state.gridOptions}
            columnDefs={this.state.columnDefs}
            rowData={this.props.data}
            quickFilterText={this.state.quickFilterText}
            onGridReady={this.onGridReady.bind(this)}
            getRowNodeId={(data) => data.id}/>
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}

export default VisitListPage