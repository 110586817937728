import { Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { Form, Message, Modal } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { AclRender } from '../../../components/acl/components'
import { DatePickr } from '../../../components/form/DatePickr'
import { FF, FormikInput, FormikSelect } from '../../../components/form/FormInputs'
import PeopleByUnitSelect from '../../../components/form/PeopleByUnitSelect'
import UnitsSelect from '../../../components/form/UnitsSelect'
import ActivityLoading from '../../../components/Loading'
import SemanticSelect from '../../../components/SemanticSelect'
import { BOOK_STATUS } from '../../../fields'
import ToasterService from '../../../services/ToasterService'
import { fetchApi, openBlobApi, postApi, putApi } from '../../../utils/api'
import { giv } from '../../../utils/common'
import { getSpaces } from '../../../utils/DataService'
import { isRequired } from '../../../utils/form'
import { show_modal } from '../../../utils/Formatters'
import BookGrid from './BookGrid'

class BookListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      spaces: [],
      raw_spaces: [],
      modal: false,
      modal_edit: false,
      current_book: {},
      loading: false
    }
  }

  print = (id) => {
    this.setState({ loading: true })

    openBlobApi(`admin/books/${id}/print`).then(() => this.setState({ loading: false })).catch(() => {
      show_modal('A reserva precisa estar aprovada!', 'error')
      this.setState({ loading: false })
    })
  }

  markUsed = (id) => {
    swal.fire({
      title: 'Gostaria de marcar como usada essa reserva?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não!'
    }).then((result) => {
      if (result.value === true) {

        this.setState({ loading: true })

        fetchApi(`admin/books/${id}/mark-used`).then(() => {
          this.setState({ loading: false })
          this.props.gridApi.purgeServerSideCache()

          show_modal('Reserva marcada como usada!', 'success')
        }).catch(() => {
          show_modal('Erro ao marcar reserva!', 'error')
          this.setState({ loading: false })
        })

      }
    })
  }

  handleSubmitForm = () => {
    this.form.submitForm()
  }

  handleNew = () => {
    getSpaces().then(res => {
      const options = []

      res.map((item) => {
        const i = {
          'key': item.id,
          'text': item.name,
          'value': item.id
        }
        options.push(i)
      })

      this.setState({ spaces: options, raw_spaces: res, loading: false })
    }).catch(err => {
      this.setState({ loading: false })
    })

    this.setState({ modal: true, current_book: {} })
  }

  handleEdit = (data) => {
    getSpaces().then(res => {
      const options = []

      res.map((item) => {
        const i = {
          'key': item.id,
          'text': item.name,
          'value': item.id
        }
        options.push(i)
      })

      this.setState({ spaces: options, raw_spaces: res, loading: false })
    }).catch(err => {
      this.setState({ loading: false })
    })

    this.setState({ modal_edit: true, current_book: data })
  }

  render() {
    return (
      <div>
        <h1 className="ui huge header">
          Reservas

          &nbsp;
          &nbsp;

          <AclRender required={['book.add_book']}>
            <button className="ui button primary large" onClick={this.handleNew}>Nova reserva</button>
          </AclRender>
        </h1>

        <div className="ui divider hidden"/>

        <BookGrid componentParent={this}/>

        <Modal open={this.state.modal}>
          <Modal.Header>Nova Reserva</Modal.Header>

          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                space: '',
                unit: '',
                book_date: '',
                description: '',
                status: '',
                tax: '',
                person: '',
                guests_qtd: '',
                availability: null
              }}
              validate={values => {
                let errors = {}

                if (!values.space) errors.space = 'Campo obrigatório'
                if (!values.unit) errors.unit = 'Campo obrigatório'
                if (!values.book_date) errors.book_date = 'Campo obrigatório'
                if (!values.tax) errors.tax = 'Campo obrigatório'

                if (isRequired('book__person')) {
                  if (!values.person) errors.person = 'Campo obrigatório'
                }

                return errors
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.setState({ loading: true })

                postApi('admin/books', values).then((res) => {
                  this.props.gridApi.purgeServerSideCache()

                  this.setState({ loading: false, modal: false })
                  show_modal('Reserva salva!', 'success')
                }).catch((err) => {
                  this.setState({ loading: false })
                  ToasterService.sendErrors(err)
                })

              }}
              render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <Form error className="ui form">

                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Espaço</label>
                      <SemanticSelect name="space" value={values.space} options={this.state.spaces} search={true}
                                      id_option="id" label_option="name"
                                      onBlur={setFieldTouched}
                                      onChange={(field, value) => {
                                        setFieldValue('availability', null)
                                        getSpaces()

                                        if (value) {
                                          this.state.raw_spaces.map(item => {
                                            console.log(item)
                                            if (item.id === value) {
                                              if (typeof item.full_tax == 'number') {
                                                setFieldValue('tax', item.full_tax)
                                              } else {
                                                setFieldValue('tax', 0)
                                              }

                                              let avalibity = []

                                              for (let i of item.availability) {
                                                avalibity.push(moment(i))
                                              }

                                              setFieldValue('availability', avalibity)
                                            }
                                          })

                                          setFieldValue('book_date', '')
                                          setFieldValue('space', value)
                                        }
                                      }}/>
                    </Form.Field>

                    <UnitsSelect name="unit" label="Selecione a Unidade"/>

                    <Form.Field>
                      <label>Data</label>
                      <DatePickr
                        name="book_date" initial={values.book_date}
                        includeDates={values.availability}
                        disabled={values.availability == null}
                        has_time={true}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </Form.Field>
                  </Form.Group>

                  <PeopleByUnitSelect unit_id={values.unit} label="Morador solicitante"/>

                  <Form.Group widths="equal">
                    <FF label={'Status'} name="status" component={FormikSelect} local_options={BOOK_STATUS}/>
                    <FF label={'Taxa (Separe os centavos por um ponto)'} name="tax" component={FormikInput}/>
                    <FF label={'Quantidade de pessoas'} name="guests_qtd" component={FormikInput} type="number"/>
                  </Form.Group>

                  <br/>
                  <Message info icon="info" size={'mini'}
                           content="Ao aprovar, será enviada um notificação para o inquilino se não houver será para o proprietário."/>

                </Form>
              )}
            />
          </Modal.Content>

          <Modal.Actions>
            <button className="ui red button" onClick={() => this.setState({ modal: false, current_book: {} })}>Cancelar</button>
            <button className="ui button primary" onClick={this.handleSubmitForm}>Enviar</button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.modal_edit}>
          <Modal.Header>Editar Reserva</Modal.Header>

          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                space: giv(this.state, 'current_book.space_id'),
                unit: giv(this.state, 'current_book.unit_id'),
                book_date: giv(this.state, 'current_book.book_date'),
                description: giv(this.state, 'current_book.description'),
                status: giv(this.state, 'current_book.status'),
                tax: giv(this.state, 'current_book.tax'),
                person: giv(this.state, 'current_book.person'),
                guests_qtd: giv(this.state, 'current_book.guests_qtd'),
                availability: null
              }}
              validate={values => {
                let errors = {}

                if (!values.space) errors.space = 'Campo obrigatório'
                if (!values.unit) errors.unit = 'Campo obrigatório'
                if (!values.book_date) errors.book_date = 'Campo obrigatório'
                if (!values.tax) errors.tax = 'Campo obrigatório'
                return errors
              }}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                this.setState({ loading: true })

                putApi('admin/books', values, this.state.current_book.id).then((res) => {
                  this.props.gridApi.purgeServerSideCache()

                  this.setState({ loading: false, modal_edit: false })
                  show_modal('Reserva salva!', 'success')
                }).catch((err) => {
                  this.setState({ loading: false })
                  ToasterService.sendErrors(err)
                })

              }}
              render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <Form error className="ui form">
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Espaço</label>
                      <SemanticSelect name="space" value={values.space} options={this.state.spaces} search={true}
                                      id_option="id" label_option="name"
                                      onBlur={setFieldTouched}
                                      onChange={(field, value) => {
                                        setFieldValue('availability', null)
                                        getSpaces()

                                        if (value) {
                                          this.state.raw_spaces.map(item => {
                                            console.log(item)
                                            if (item.id === value) {
                                              if (typeof item.full_tax == 'number') {
                                                setFieldValue('tax', item.full_tax)
                                              } else {
                                                setFieldValue('tax', 0)
                                              }

                                              let avalibity = []

                                              for (let i of item.availability) {
                                                avalibity.push(moment(i))
                                              }

                                              setFieldValue('availability', avalibity)
                                            }
                                          })

                                          setFieldValue('book_date', '')
                                          setFieldValue('space', value)
                                        }
                                      }}/>
                    </Form.Field>

                    <UnitsSelect name="unit" label="Selecione a Unidade"/>

                    <Form.Field>
                      <label>Data</label>
                      <DatePickr
                        name="book_date" initial={values.book_date}
                        includeDates={values.availability}
                        disabled={values.availability == null}
                        has_time={true}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </Form.Field>
                  </Form.Group>

                  <PeopleByUnitSelect unit_id={values.unit} label="Morador solicitante"/>

                  <Form.Group widths="equal">
                    <FF label={'Status'} name="status" component={FormikSelect} local_options={BOOK_STATUS}/>
                    <FF label={'Taxa (Separe os centavos por um ponto)'} name="tax" component={FormikInput}/>
                    <FF label={'Quantidade de pessoas'} name="guests_qtd" component={FormikInput} type="number"/>
                  </Form.Group>

                  <br/>
                  <Message info icon="info" size={'mini'}
                           content="Ao aprovar, será enviada um notificação para o inquilino se não houver será para o proprietário."/>

                </Form>
              )}
            />
          </Modal.Content>

          <Modal.Actions>
            <button className="ui red button" onClick={() => this.setState({ modal_edit: false, current_book: {} })}>Cancelar</button>
            <button className="ui button primary" onClick={this.handleSubmitForm}>Enviar</button>
          </Modal.Actions>
        </Modal>

        <ActivityLoading visible={this.state.loading}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    data: state.people.data,
    gridApi: state.grid_refs.book
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookListPage)

