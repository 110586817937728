import {Field, Formik} from 'formik'
import React from 'react'
import {connect} from 'react-redux'
import {Button, Checkbox, Grid, Modal} from 'semantic-ui-react'
import swal from 'sweetalert2'
import {qs} from '../../queries'
import {actionMap as devices_actions} from '../../redux/modules/devices'
import {decimal2wiegand} from '../../utils/device_utils'
import {hasValue, show_modal} from '../../utils/Formatters'
import {localPostApi} from '../../utils/LocalApi'
import {F, FF, FormikInput, FormikSelect} from '../form/FormInputs'
import ActivityLoading from '../Loading'

const code_type = [
  { value: 1, text: 'Wiegand padrão' },
  { value: 2, text: 'Chaveiro Linear/Nice' },
  { value: 3, text: 'Decimal' },
  { value: 4, text: 'Wiegand Mifare (13,56) Intelbras' }
]

class CardEnroll extends React.Component {
  static defaultProps = {
    person: '',
    resident: true,
    access_group: {},
    custom_access_group: false
  }

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      access_groups: []
    }
  }

  componentDidMount() {
    this.props.cardRead(null)

    if (this.props.custom_access_group) {
      qs.access_groups.guest()
        .then((data) => {
          this.setState({ access_groups: data })
        })
        .catch((err) => {
          swal({ type: 'error', title: 'Não foi possível recuperar os perfis de acesso.' })
        })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.card_read !== '' && this.props.card_read !== null) {
      let code_type = this.form?.state?.values?.code_type

      if (this.form && code_type === 4) {
        this.form.setFieldValue('alias', this.props.card_read)
      }
    }
  }

  convert = () => {
    this.form.setFieldValue('wiegand', '')
    let alias = this.form.state.values.alias
    let code_type = this.form.state.values.code_type
    let usb_reader = this.form.state.values.usb_reader
    if (alias) {
      // has_company_code = If is Linear OR is reading from USB so use company code
      // FIXME: Possible bug in defining company code for other types instead of linear

      let _res = decimal2wiegand(alias, code_type === 2 || code_type === 4 || usb_reader)

      this.form.setFieldValue('code', _res.wiegand)

      // Set automatic company_code(facility_code) only for Intelbras MF
      if (_res.company_code && code_type === 4) {
        this.form.setFieldValue('facility_code', _res.company_code)
      }
    }
  }

  getEquipmentUserId = () => {
    if (this.props.person.id_in_equipment) {
      return this.props.person.id_in_equipment
    } else {
      return this.props.person.obj_number
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal open={true} size="small">
          <Modal.Header>Cadastrar Cartão/Chaveiro</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                type: 3,
                status: 1,
                // To remote api
                person: this.props.person.id,
                code: '', // Wiegand
                alias: '', // Decimal
                facility_code: '',

                // To local server
                user_id: this.getEquipmentUserId(),
                user_name: this.props.person.name,
                code_type: 1,
                access_group: this.props.access_group,
                access_group_id: '',
                is_guest: false,
                usb_reader: false
              }}
              validate={values => {
                let errors = {}

                if (values.code.length !== 8) errors.code = 'Deve ter 8 dígitos'
                if (!values.code) errors.code = 'Digite o número do cartão'
                if ((values.code_type === 3 || values.code_type === 4) && !values.alias) errors.alias = 'Digite o código Decimal'
                if ((values.code_type === 3 || values.code_type === 4) &&
                  !values.code) errors.code = 'Digite no campo DECIMAL e clique na seta para converter'
                return errors
              }}
              onSubmit={async (values) => {
                if (this.props.resident === true) {
                  if (hasValue(this.props, 'person.id') && hasValue(this.props, 'person.name')) {

                    let check = await qs.access_drivers.filterByCode(values.code)
                    console.log(check)

                    if (check && check.length > 0) {
                      if (check[0].person_id && check[0].person_id !== this.props.person.id) {

                        show_modal(`Acionador já cadastrado, exclua ele primeiro. Id do usuário: ${check[0].person_id} `, 'error')
                        return

                      }
                    }

                    localPostApi('devices-manager/card_enroll_client', values, 60 * 1000)
                      .then(res => {
                        this.setState({ loading: true })

                        // Check for existing driver
                        qs.access_drivers.filterByCode(values.code)
                          .then((res) => {
                            if (res.length < 1) {

                              qs.access_drivers.post(values)
                                .then((resp) => this.setState({ loading: false }))
                                .catch((err) => {
                                  this.setState({ loading: false })
                                })

                            } else {

                              qs.access_drivers.patch(values, res[0]['id'])
                                .then((resp) => this.setState({ loading: false }))
                                .catch((err) => this.setState({ loading: false }))

                            }

                          })
                          .catch((err) => {
                            this.setState({ loading: false })
                            throw err
                          })

                      })
                      .catch(err => {
                        show_modal('Verifique os erros para que seja possível cadastrar o acionador do servidor!', 'error')
                      })

                  } else {
                    show_modal('Dados inválidos', 'error')
                  }
                } else {
                  this.props.handleAssign(values)
                }
              }}
              render={({ values, setFieldValue }) => (
                <div className="ui form large error">
                  {this.props.custom_access_group &&
                    <Field
                      label={'Rota de Acesso'}
                      name={'access_group_id'}
                      component={FormikSelect}
                      options={this.state.access_groups}
                      search={true}
                      id_option="id"
                      label_option="name"
                      onChangePos={(evt, b) => {
                        console.log(b)

                        for (let i of this.state.access_groups) {
                          if (i.id === b.value) {
                            setFieldValue('access_group', i)
                          }
                        }
                      }}
                    />}

                  <FF label={'Tipo Acionador'} name="code_type" component={FormikSelect} options={code_type}
                      onChangePos={(event, data) => {
                        console.log(event, data)

                        if (data.value === 2) {
                          setFieldValue('facility_code', 72)
                        } else {
                          setFieldValue('facility_code', null)
                        }

                        setFieldValue('code', '')
                        setFieldValue('alias', '')
                        setFieldValue('usb_reader', false)
                      }}/>

                  <React.Fragment>
                    <Checkbox toggle label={<label>Usar leitor USB</label>} checked={values.usb_reader} onClick={(a, b) => {
                      setFieldValue('usb_reader', b.checked)
                    }}/>
                  </React.Fragment>

                  <div className="ui divider hidden"/>

                  <Grid>
                    {(values.code_type === 3 || values.code_type === 4 ||
                        ((values.code_type === 1 || values.code_type === 2) && values.usb_reader === true)) &&
                      <React.Fragment>
                        <Grid.Column width={7}>
                          <F label={`Código Decimal ${values.facility_code ? `(${values.facility_code})` : ''}`} name="alias" component={FormikInput}/>
                        </Grid.Column>

                        <Grid.Column width={2}>
                          <div className="field">
                            <label>&nbsp;</label>
                            <Button floated={'right'} circular icon="angle right" onClick={this.convert}/>
                          </div>
                        </Grid.Column>
                      </React.Fragment>}

                    <Grid.Column width={7}>
                      <F label={'Código Wiegand'} name="code" component={FormikInput}/>
                    </Grid.Column>
                  </Grid>
                </div>
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" size="large" onClick={() => {
              try {
                this.props.getPersonAccessDrivers()
              } catch (e) {
                console.log('getPersonAccessDrivers not available')
              }
              this.props.setClose()
            }}>
              Fechar
            </Button>
            <Button primary size="large" onClick={(e) => this.form.handleSubmit(e)}>Cadastrar Cartão</Button>
          </Modal.Actions>
        </Modal>
        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    card_read: state.devices.card_read
  }
}

function mapDispatchToProps(dispatch) {
  return {
    cardRead: (data) => dispatch(devices_actions.card_read(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardEnroll)
