import {FastField, Field, getIn} from 'formik'
import React from 'react'
import {Checkbox, Form, Header, Icon, Popup} from 'semantic-ui-react'
import {SemanticSelectFormik} from '../SemanticSelect'
import {DatePickr} from './DatePickr'

export const FormErrorMsg = ({ ...props }) => {

  if (props.error) {
    return (
      <Header as="h6" color="red">{props.error}</Header>
    )
  } else {
    return null
  }
}

export const FF = ({ ...props }) => {
  return (<FastField {...props}/>)
}

export const F = ({ ...props }) => {
  return (<Field {...props}/>)
}

export const FormikInput = ({ field, form: { touched, errors }, ...props }) => {
  let has_error = getIn(errors, field.name)

  return (
    <Form.Field error={!!has_error} width={props.width ? props.width : null}>
      <label>
        {props.label} {' '} {' '} {' '}
        {props.help && <Popup trigger={<Icon name="help" size="small"/>} content={props.help} inverted/>}
      </label>
      <input {...field} {...props}/>
      <FormErrorMsg error={has_error}/>
    </Form.Field>
  )
}

export const FormikTextArea = ({ field, form: { touched, errors }, ...props }) => {
  let has_error = getIn(errors, field.name)

  return (
    <Form.Field error={!!has_error} width={props.width ? props.width : null}>
      <label>
        {props.label} {' '} {' '} {' '}
        {props.help && <Popup trigger={<Icon name="help" size="small"/>} content={props.help} inverted/>}
      </label>
      <textarea {...field} {...props}/>
      <FormErrorMsg error={has_error}/>
    </Form.Field>
  )
}

export const FormikCheck = ({ field, form: { values, errors, setFieldValue }, ...props }) => {
  let has_error = getIn(errors, field.name)
  let value = getIn(values, field.name)

  return (
    <Form.Field error={!!has_error} width={props.width ? props.width : null}>
      <Checkbox
        {...props}
        label={
          <label>
            {props.label} {' '} {' '} {' '}
            {props.help && <Popup trigger={<Icon name="help" size="small"/>} content={props.help} inverted/>}
          </label>
        }
        checked={value === true}
        onChange={(event, params) => setFieldValue(field.name, params.checked)}
      />
      <FormErrorMsg error={has_error}/>
    </Form.Field>
  )
}

export const FormikCheckLabel = ({ field, form: { touched, errors, values, handleChange, handleBlur, setFieldValue }, ...props }) => {
  let has_error = getIn(errors, field.name)
  let value = getIn(values, field.name)

  return (
    <Form.Field error={!!has_error}>
      <label>
        {props.label} {' '} {' '} {' '}
        {props.help && <Popup trigger={<Icon name="help" size="small"/>} content={props.help} inverted/>}
      </label>
      <Checkbox
        {...props}
        label={''}
        checked={value === true}
        onChange={(event, params) => setFieldValue(field.name, params.checked)}/>
      <FormErrorMsg error={has_error}/>
    </Form.Field>
  )
}

export const FormikDate = ({ field, form: { values, errors, setFieldValue, setFieldTouched }, ...props }) => {
  delete field.value
  let has_error = getIn(errors, field.name)
  let value = getIn(values, field.name)

  return (
    <Form.Field error={!!has_error} width={props.width ? props.width : null}>
      <label>{props.label}</label>
      <DatePickr
        {...props}
        {...field}
        initial={value}
        onChange={setFieldValue}
        onBlur={setFieldTouched}
      />
      <FormErrorMsg error={has_error}/>
    </Form.Field>
  )
}

export const FormikSelect = ({ field, form: { values, errors, setFieldValue, setFieldTouched }, ...props }) => {
  if (!props.onChange) props.onChange = setFieldValue
  if (!props.onBlur) props.onBlur = setFieldTouched
  if (!props.id_option) props.id_option = 'value'
  if (!props.label_option) props.label_option = 'text'

  let has_error = getIn(errors, field.name)

  if (!props.multiple && Array.isArray(field.value)) {
    field.value = field.value[0]
  }

  return (
    <Form.Field error={!!has_error} width={props.width ? props.width : null}>
      <label>
        {props.label} {' '} {' '} {' '}
        {props.help && <Popup trigger={<Icon name="help" size="small"/>} content={props.help} inverted/>}
      </label>
      <SemanticSelectFormik {...field} {...props} />
      {typeof has_error === 'string' && has_error && <FormErrorMsg error={has_error}/>}
    </Form.Field>
  )
}