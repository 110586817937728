import React, { useEffect, useState } from 'react'
import '@livekit/components-styles'
import {
  AudioTrack,
  AudioVisualizer,
  ConnectionQualityIndicator,
  ControlBar,
  LKFeatureContext,
  ParticipantContextIfNeeded,
  ParticipantName,
  RoomContext,
  TrackMutedIndicator,
  useIsSpeaking,
  useLiveKitRoom,
  useParticipantInfo,
  useParticipants,
  useRemoteParticipants,
  useRoomContext,
  useTracks
} from '@livekit/components-react'
import { DataPacket_Kind, Participant, RoomEvent, Track } from 'livekit-client'
import moment from 'moment'
import { CALL_STATUS_NAME, useCallStore } from '../../redux/store/useCallStore'
import { Button, Form, Header, Icon, Label, Modal, Popup } from 'semantic-ui-react'
import { Formik } from 'formik'
import UnitsSelect from '../../components/form/UnitsSelect'
import PeopleByUnitSelect from '../../components/form/PeopleByUnitSelect'
import { ChatMessage } from '@livekit/components-core'
import { gv } from '../../utils/common'
import { useMutation } from 'react-query'
import { qs } from '../../queries'
import * as Yup from 'yup'
import { useChannel, useEvent } from '@harelpls/use-pusher'

const intercomRingtone = require('../../assets/audios/intercom_ringtone.mp3')
// const serverUrl = 'wss://condo-id-siqoaxw2.livekit.cloud'
const decoder = new TextDecoder()
const playRingtone = new Audio(intercomRingtone)

export function IntercomNotifications(props: { channel_name: string }) {
  const call_state = useCallStore((state) => state.call)
  const updateCall = useCallStore((state) => state.updateCall)

  const [incoming_token, setIncomingToken] = useState('')

  const channel = useChannel(props.channel_name)

  useEvent(channel, 'message', (arr) => {
    console.log('LIVE NOTIFICATIONS SK ON_MESSAGE', arr)

    handleIncomingNotification(arr)
  })

  const { room, htmlProps } = useLiveKitRoom({
    video: false,
    audio: false,
    screen: false,
    token: call_state.call_notifications_token,
    connect: true,
    serverUrl: call_state.server_url,
  })

  useEffect(() => {
    const onDataReceived = (payload: Uint8Array, participant: Participant, kind: DataPacket_Kind, topic?: string) => {
      console.log('onDataReceived payload', payload)

      const payload_decoded = JSON.parse(decoder.decode(payload)) as ChatMessage

      console.log('onDataReceived payload_decoded', payload_decoded)
      console.log('onDataReceived participant', participant)
      console.log('onDataReceived king', kind)
      console.log('onDataReceived topic', topic)

      handleIncomingNotification(payload_decoded)
    }

    if (room) {
      room.on(RoomEvent.DataReceived, onDataReceived)
    }

  }, [room])

  useEffect(() => {
    if (incoming_token) {
      console.log('playing',)
      playRingtone.play()
    } else {
      playRingtone.pause()
    }

  }, [incoming_token])

  const answerCall = () => {
    updateCall({
      contact_name: '',
      call_status: '',
      call_start_at: moment(),
      show_caller: false,
      call_token: ''
    })

    setTimeout(() => {
      updateCall({
        call_status: CALL_STATUS_NAME.on_call,
        call_start_at: moment(),
        show_caller: true,
        call_token: incoming_token
      })

      setIncomingToken('')
    }, 500)
  }

  const handleIncomingNotification = (payload_decoded: any) => {
    if (gv(payload_decoded, 'action') === 'new_call') {
      updateCall({
        contact_name: gv(payload_decoded, 'contact_name'),
        server_url: gv(payload_decoded, 'server_url'),
      })

      setIncomingToken(gv(payload_decoded, 'gater_call_token'))
    }

  }

  console.log('LIVE NOTIFICATIONS ROOM: ', room)

  // if (room) {
  //   return (
  //     <RoomContext.Provider value={room}>
  //       <LKFeatureContext.Provider value={undefined}>
  //         <IntercomNotificationsHandler/>
  //       </LKFeatureContext.Provider>
  //     </RoomContext.Provider>
  //   )
  // }

  return <>
    <Modal
      basic
      onClose={() => setIncomingToken('')}
      open={!!incoming_token}
      size="fullscreen"
      closeOnDimmerClick={false}
      closeOnEscape={false}
      closeOnDocumentClick={false}
      closeOnPortalMouseLeave={false}
      closeOnTriggerBlur={false}
      closeOnTriggerMouseLeave={false}
      closeOnTriggerClick={false}
    >
      <Header icon>
        <Icon name="phone volume"/>
        NOVA LIGAÇÃO
      </Header>
      <Modal.Content>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '25px' }}>
          <h2>{call_state.contact_name}</h2>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button color="red" size="huge" onClick={() => setIncomingToken('')}>
            <Icon name="remove"/> REJEITAR
          </Button>
          <Button color="green" size="huge" onClick={answerCall}>
            <Icon name="phone"/> ATENDER
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  </>
}

export function IntercomHeader() {
  const call_state = useCallStore((state) => state.call)
  const updateCall = useCallStore((state) => state.updateCall)

  const makeCallToUnit = useMutation((variables: any) => qs.user.callToUnit(variables), {
    onSuccess: (_res) => {
      console.log('_res', _res)

      updateCall({
        server_url: _res.server_url,
        contact_name: _res.contact_name,
        call_token: _res.call_token,
        call_status: CALL_STATUS_NAME.calling,
        call_start_at: moment(),

        show_caller: true,
      })
    }
  })

  if (call_state.call_token && call_state.server_url && call_state.call_status) {
    return (
      <Caller/>
    )
  }

  return (
    <Popup
      flowing hoverable on="click" position="bottom center" verticalOffset={18} inverted
      trigger={
        <Label size="large" color="violet">
          INTERFONE DIGITAL
        </Label>
      }
    >
      <Formik
        initialValues={{
          unit_id: '',
          person_id: '',
        }}
        validationSchema={Yup.object().shape({
          unit_id: Yup.string().required(),
        })}
        onSubmit={(values) => {

          makeCallToUnit.mutate(values)

          // updateCall({
          //   call_status: CALL_STATUS_NAME.on_call,
          //   TODO Corrigir para iniciar somente quando atender
          // call_start_at: moment(),
          // show_caller: true,
          // call_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2OTQ5NTAyMzMsImlzcyI6IkFQSVhRdUo1QWZWcWdtNyIsIm5iZiI6MTY5NDkyODYzMywic3ViIjoicG9ydGVpcm8iLCJ2aWRlbyI6eyJjYW5QdWJsaXNoIjp0cnVlLCJjYW5QdWJsaXNoRGF0YSI6dHJ1ZSwiY2FuU3Vic2NyaWJlIjp0cnVlLCJyb29tIjoicG9ydGFyaWEiLCJyb29tSm9pbiI6dHJ1ZX19.7zMu4FMjoXLubPgFoj_9SBy0VSQjdqcWVpd8KcO7cuU'
          // })
        }}
        render={({ values, submitForm }) => (
          <Form style={{ width: '350px' }} inverted>
            <br/>
            <UnitsSelect name="unit_id" label={'Selecione a Unidade'}/>
            <br/>
            <PeopleByUnitSelect name="person_id" unit_id={values.unit_id} label="Morador (DEIXE EM BRANCO PARA CHAMAR TODOS DA UNIDADE)"/>
            <br/>
            <br/>
            <Button fluid icon="phone" size="huge" color={'violet'} onClick={submitForm} content="Ligar"
                    loading={makeCallToUnit.isLoading} disabled={makeCallToUnit.isLoading}/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
          </Form>
        )}
      />


      {/*<Label*/}
      {/*  size="large" color="violet"*/}
      {/*  onClick={() => {*/}

      {/*  }}*/}
      {/*>*/}
      {/*  Ligar para morador*/}
      {/*</Label>*/}

    </Popup>
  )
}

function Caller() {
  const call_state = useCallStore((state) => state.call)
  const updateCall = useCallStore((state) => state.updateCall)

  const { room, htmlProps } = useLiveKitRoom({
    video: false,
    audio: true,
    screen: false,
    token: call_state.call_token,
    connect: true,
    serverUrl: call_state.server_url,
    // @ts-ignore
    'data-lk-theme': 'default',
    style: { width: '40vw' }
  })

  if (room) {
    return (
      <RoomContext.Provider value={room}>
        <LKFeatureContext.Provider value={undefined}>
          <Popup
            flowing hoverable on="click" position="bottom center" verticalOffset={18} inverted style={{ backgroundColor: '#101010' }}

            open={call_state.show_caller}

            onOpen={() => updateCall({ show_caller: true })}
            onClose={() => updateCall({ show_caller: false })}

            trigger={
              <Label
                size="large"
                style={{
                  animationName: 'callerBtnBackground',
                  animationDuration: '2s',
                  animationIterationCount: 'infinite',
                  animationTimingFunction: 'linear',
                  color: '#FFFFFF'
                }}
              >
                {call_state.call_status.toUpperCase()} {call_state.call_status === CALL_STATUS_NAME.on_call &&
                <CallTimer counter_start={call_state.call_start_at}/>}

                <div style={{ display: 'none' }}>
                  {(call_state.call_status === CALL_STATUS_NAME.on_call || call_state.call_status === CALL_STATUS_NAME.calling) &&
                    call_state.show_caller === false && <OnCallBlocks/>}
                </div>
              </Label>
            }
          >

            <div {...htmlProps}>
              {(call_state.call_status === CALL_STATUS_NAME.on_call || call_state.call_status === CALL_STATUS_NAME.calling) &&
                <OnCallBlocks/>}
            </div>

          </Popup>
        </LKFeatureContext.Provider>
      </RoomContext.Provider>
    )
  }

  return <div></div>
}

function OnCallBlocks() {
  const call_state = useCallStore((state) => state.call)
  const updateCall = useCallStore((state) => state.updateCall)

  const remote_participants = useRemoteParticipants()
  const all_participants = useParticipants()
  const tracks = useTracks()
  const room = useRoomContext()

  const hangupCallAction = () => {
    updateCall({
      call_token: '',
      call_status: ''
    })
  }

  useEffect(() => {
    console.log('NEW_ROOM')

    const onDisconnected = () => {
      console.log('Disconnected from room')

      hangupCallAction()
    }

    room.on(RoomEvent.Disconnected, onDisconnected)
  }, [room])

  useEffect(() => {
    console.log('onParticipantsChanged ', call_state.call_status, remote_participants.length, all_participants.length)

    if (call_state.call_status === CALL_STATUS_NAME.calling && remote_participants.length > 0 && room) {
      updateCall({
        call_status: CALL_STATUS_NAME.on_call,
        call_start_at: moment(),
        show_caller: true,
      })
    }

    if (call_state.call_status === CALL_STATUS_NAME.on_call && remote_participants.length < 1 && room) {
      // hangupCallAction()
    }

  }, [remote_participants])

  console.log('remote_participants', remote_participants)
  console.log('tracks', tracks)
  console.log('room', room)

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: '25px' }}>
        {call_state.call_status === CALL_STATUS_NAME.on_call && <div>
          <CallTimer counter_start={call_state.call_start_at}/>
        </div>}
        <div>
          <h3>{call_state.call_status.toUpperCase()}</h3>
          <br/>
          <br/>
        </div>
      </div>

      <div>
        {call_state.call_status === CALL_STATUS_NAME.on_call &&
          <aside className="lk-carousel" data-lk-orientation="horizontal">
            {all_participants.map((participant) => {
              return (
                <ParticipantView
                  key={participant.sid}
                  participant={participant}
                />
              )
            })}
          </aside>}

        <ControlBar
          variation={'minimal'}
          controls={{ microphone: true, screenShare: false, camera: false, chat: false }}
        />
      </div>
    </>
  )

  // return (
  //   <CarouselLayout tracks={tracks} orientation={'horizontal'}>
  //     <ParticipantAudioTile/>
  //   </CarouselLayout>
  // )
}

const ParticipantView = (props: { participant: Participant }) => {
  const participant = useParticipantInfo({ participant: props.participant })
  const is_speaking = useIsSpeaking(props.participant)

  console.log('participant context', participant)
  console.log('participant prop', props.participant)
  console.log('participant is_speaking', is_speaking)

  return (
    <div style={{ position: 'relative' }} className="lk-participant-tile">
      <ParticipantContextIfNeeded participant={props.participant}>
        <AudioTrack source={Track.Source.Microphone}></AudioTrack>
        <AudioVisualizer/>
        <div className="lk-participant-metadata">
          <div className="lk-participant-metadata-item">
            <TrackMutedIndicator source={Track.Source.Microphone}></TrackMutedIndicator>
            <ParticipantName/>
          </div>
          <ConnectionQualityIndicator className="lk-participant-metadata-item"/>
        </div>
      </ParticipantContextIfNeeded>
    </div>
  )
}

const CallTimer = ({ counter_start }) => {
  const [counter, setCounter] = React.useState(60 * 30)

  useEffect(() => {
    let timer = null
    if (counter > 0) timer = setInterval(() => setCounter(counter - 1), 1000)

    return () => {
      if (timer !== null) clearInterval(timer)
    }
  }, [counter])

  return (
    <>
      {String(moment().diff(counter_start, 'minutes')).padStart(2, '0')}
      :
      {String(moment().diff(counter_start, 'seconds') % 60).padStart(2, '0')}
    </>

  )
}
