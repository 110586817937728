import { Action, createAction, getType } from 'typesafe-actions'

export type ActionTypesBase<CustomActions = {}> = {
  all: () => Action,
  all_success: (data: any) => Action,
  get: (id: number) => Action,
  get_success: (data: {}) => Action,
  post: (data: {}) => Action,
  post_success: (data: {}) => Action,
  put: (id: number, data: {}) => Action,
  put_success: (data: {}) => Action,
  delete: () => Action,
  delete_success: (data: {}) => Action,
  modal: (data: boolean) => Action,
  current: (data: {}) => Action,
  clear_all: () => Action,
} & {
  [Property in keyof CustomActions]: CustomActions[Property];
}

export function reducersMap(actionMap: any) {
  return {
    [getType(actionMap.all_success)]: (state: any, action: any) => ({...state, data: action.payload}),
    [getType(actionMap.get_success)]: (state: any, action: any) => ({...state, current: action.payload}),
    [getType(actionMap.post_success)]: (state: any, action: any) => ({...state, data: [action.payload, ...state.data]}),
    [getType(actionMap.put_success)]: (state: any, action: any) => {
      const object = action.payload
      return {
        ...state,
        data: state.data.map((item: any) => item.id === object.id ? object : item)
      }
    },
    [getType(actionMap.modal)]: (state: any, action: any) => ({...state, showModal: action.payload}),
    [getType(actionMap.current)]: (state: any, action: any) => ({...state, current: action.payload})
  }
}

export function actionMapBase(domain: string): ActionTypesBase {
  return {
    all: createAction(`${domain}/ALL`),
    all_success: createAction(`${domain}/ALL_SUCCESS`, action => (data: any) => action(data)),
    get: createAction(`${domain}/GET`, action => (id: number) => action({id})),
    get_success: createAction(`${domain}/GET_SUCCESS`, action => (data: any) => action(data)),
    post: createAction(`${domain}/POST`, action => (data: any) => action(data)),
    post_success: createAction(`${domain}/POST_SUCCESS`, action => (data: any) => action(data)),
    put: createAction(`${domain}/PUT`, action => (id: number, data: {}) => action({id, data})),
    put_success: createAction(`${domain}/PUT_SUCCESS`, action => (data: any) => action(data)),
    delete: createAction(`${domain}/DELETE`),
    delete_success: createAction(`${domain}/DELETE_SUCCESS`),
    modal: createAction(`${domain}/MODAL`, action => (show: boolean) => action(show)),
    current: createAction(`${domain}/CURRENT`, action => (data: any) => action(data)),
    clear_all: createAction(`${domain}/CLEAR_ALL`)
  }
}
