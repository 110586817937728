import axios from 'axios'
import { hasPerm } from '../components/acl'
import enviroment from '../enviroment'
import { emitter_control_id } from '../hardwares/ControlIdService'
import queryClient from '../queryClient'
import { actionMap as devices_actions } from '../redux/modules/devices'
import { actionMap as system_actions } from '../redux/modules/system'
import { actionMap as visits_actions } from '../redux/modules/visits'
import { push_toast } from './common'
import { getValue, show_modal } from './Formatters'
import ReconnectingWebSocket from './reconnecting-websocket'

const getUrl = async () => {
  try {
    console.log('getting websocket url')
    let is_new = await axios.get(`http://${enviroment().localIp()}:8089/ping`, { timeout: 4 * 1000 })
    console.log('is_new_websocket', is_new)
    return enviroment().localWSFastApi()
  } catch (e) {
    // remover pois está gerando erros
    console.log('getting old websocket url')
    return enviroment().localWS()
  }
}

let chatSocketConn = () => {
  if (enviroment().localIp()) {
    return new ReconnectingWebSocket(getUrl)
  }

  return null
}

export const connect_local_ws = (store) => {
  const chatSocket = chatSocketConn()

  if (!chatSocket) {
    return
  }

  chatSocket.onopen = function (e) {
    store.dispatch(system_actions.status_server(true))
    console.warn('Server connected', e)
  }

  chatSocket.onmessage = function (e) {
    store.dispatch(system_actions.status_server(true))
    HandleWsResponse(e, store)
  }

  chatSocket.onclose = function (e) {
    store.dispatch(system_actions.status_server(false))
    console.warn('Server closed unexpectedly', e)
  }

  return chatSocket
}

const HandleWsResponse = (message, store) => {
  console.log('HandleWsResponse', message)
  let json: any = null

  try {
    json = JSON.parse(message.data)
  } catch (err) {
    console.error(err)
  }

  console.log('HandleWsResponse JSON', json)

  let data = getValue(json, 'data')
  let type = getValue(json, 'type')

  if (json) {
    switch (json.channel) {

      case 'push_toast':
        if (hasPerm(['access_control.desktop_push_toast'])) {
          push_toast(json.message, type)
        }
        break

      case 'push_alert':
        show_modal(json.message, type)
        break

      case 'emit_controlid':
        emitter_control_id.emit(type, data)
        break

      case 'push_clients':
        //TODO: Check this
        // if (data.type === 'global_action') {
        //   if (data.action === 'sync_data') {
        //     getPeoples()
        //     getAccessDrivers()
        //   }
        // }

        switch (type) {
          case 'events':
            queryClient.refetchQueries('getLastEvents')
            break

          case 'visits':
            store.dispatch(visits_actions.all_success(data))
            break

          // CONTROL ID

          case 'device_status':
            store.dispatch(devices_actions.device_status(data))
            break

          //LINEAR

          case 'card_read':
            store.dispatch(devices_actions.card_read(null))
            store.dispatch(devices_actions.card_read(data))
            break

          case 'guarita_read':
            store.dispatch(devices_actions.guarita_read(data))
            break

          case 'guarita_status':
            store.dispatch(devices_actions.guarita_status(data))
            break

          //ANVIZ

          case 'bio64_1':
            store.dispatch(devices_actions.bio64_1(data))
            break
          case 'bio64_2':
            store.dispatch(devices_actions.bio64_2(data))
            break
          case 'bio64_1_bin':
            store.dispatch(devices_actions.bio64_1_bin(data))
            break
          case 'bio64_2_bin':
            store.dispatch(devices_actions.bio64_2_bin(data))
            break
        }

        break
    }
  }
}
