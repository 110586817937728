import axios from 'axios'
import enviroment from '../enviroment'
import { getValue } from '../utils/Formatters'
import { LocalDB } from '../utils/LocalDB'
import authService from './authService'
import ToasterService from './ToasterService'

export const apiAxios = axios.create()

export const apiQsMetadata = () => {
  const current_user = LocalDB.getItem('current_user')
  const user_id = current_user?.id
  const schema_name = current_user?.team?.schema_name

  return `platform=desktop_ui&app_version=${enviroment().APP_VERSION_READABLE}&tin=${schema_name}&uad=${user_id}`
}

// Add a response interceptor
apiAxios.interceptors.response.use(
  function (response) {
    // console.log(response);
    // Do something with response data
    return response
  },
  function (error) {
    ToasterService.sendErrors(error)

    let status = getValue(error, 'response.status')

    // Sentry.captureException(error)

    if (status === 401 || status === 403) {
      authService.logout()
    }
    return Promise.reject(error)
  })

apiAxios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token')
    config.headers['Authorization'] = `Token ${token}`

    const metadata_qs = apiQsMetadata()

    config.url = config.url && config.url.indexOf('?') > -1
      ? `${config.url}&${metadata_qs}`
      : `${config.url}?${metadata_qs}`

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

apiAxios.defaults.headers.common['Platform'] = 'desktop_ui'
apiAxios.defaults.headers.common['AppVersion'] = enviroment().APP_VERSION_READABLE

export const fetchApi = async (endpoint, method = 'get') => {
  const token = await localStorage.getItem('token')

  // @ts-ignore
  return await apiAxios({
    method: method,
    url: enviroment().apiUrl + endpoint,
    headers: { 'Authorization': `Token ${token}` }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    // throw(error)
    return Promise.reject(error)
  })
}

export const openBlobApi = async (endpoint, method = 'get', data = null) => {
  const token = await localStorage.getItem('token')

  // @ts-ignore
  return await apiAxios({
    method: method,
    url: enviroment().apiUrl + endpoint,
    headers: { 'Authorization': `Token ${token}` },
    data: data,
    responseType: 'blob'
  }).then((res) => {
    const file = new Blob([res.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }).catch(async (error) => {
    if (error.response) {
      try {
        const { data } = error.response

        const fileReader = new FileReader()
        const file = await new Promise((resolve, reject) => {
          fileReader.onerror = () => {
            fileReader.abort()
            reject(new Error('Problem parsing file'))
          }

          fileReader.onload = () => {
            resolve(fileReader.result)
          }

          fileReader.readAsText(data)
        })

        // @ts-ignore
        error = { response: { data: JSON.parse(file) } }
      } catch (readError) {
        error = 'Arquivo inválido'
      }
    } else {
      error = 'Arquivo inválido'
    }

    return Promise.reject(error)
  })
}

export const postApi = async (endpoint, data) => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'post',
    url: enviroment().apiUrl + endpoint,
    data: data,
    headers: { 'Authorization': `Token ${token}` }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    // throw(error)
    return Promise.reject(error)

    // Alert.alert('Post', error);
    // return Promise.reject();
  })
}

export const postFormApi = async (endpoint, data, options = {}) => {
  const token = await localStorage.getItem('token')
  // @ts-ignore
  let { headers = {} } = options

  return await apiAxios({
    method: 'post',
    url: enviroment().apiUrl + endpoint,
    data: data,
    headers: {
      'Authorization': `Token ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
      ...headers
    }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    throw (error)
    // return Promise.reject();
  })
}

export const putApi = async (endpoint, data, id, url_params = '') => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'patch',
    url: enviroment().apiUrl + endpoint + '/' + id + url_params,
    data: data,
    headers: { 'Authorization': `Token ${token}` }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    throw (error)
    // return Promise.reject();
  })
}

export const toDataURL = url => fetch(`https://api.allorigins.win/raw?url=${url}`)
  // export const toDataURL = url => fetch(`${url}`)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  )

export const uploadS3 = async (file_upload, type, filename) => {
  try {
    let file_base_data = {
      type: type,
      filename: filename,
      key: ''
    }

    let sign_res = await postApi('admin/files/sign_url', file_base_data)
    file_base_data['key'] = sign_res.key

    console.log(sign_res)

    let form_data = new FormData()
    for (let i of Object.keys(sign_res.fields)) {
      form_data.append(i, sign_res.fields[i])
    }

    form_data.append('file', file_upload)

    let file_res = await axios.post(sign_res.url, form_data, { headers: { 'Content-Type': 'multipart/form-data' } })

    console.log('File URL: ', file_res?.headers?.location)

    return await postApi('admin/files/create_from_sign_url', file_base_data)
  } catch (e) {
    return Promise.reject(e)
  }
}


