import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import ActivityLoading from '../../components/Loading'
import { dateTimeFormat, hasValue } from '../../utils/Formatters'
import { getEvents, localFetchApi, localPostApi } from '../../utils/LocalApi'
import { gridDefaults } from '../../utils/gridDefaults'
import UnitsSelect from '../../components/form/UnitsSelect'
import { Formik } from 'formik'
import { Button, Form } from 'semantic-ui-react'
import PeopleByUnitSelect from '../../components/form/PeopleByUnitSelect'
import * as queryString from 'query-string'

export default class EventListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: 'Nome',
          field: 'name',
          width: 120,
          filter: 'text',
        },
        {
          headerName: 'Data',
          field: 'date',
          width: 80,
          filter: 'text',
          valueGetter: dateTimeFormat
        },
        {
          headerName: 'Tipo',
          field: 'person_type',
          width: 45,
          filter: 'text',
        },
        {
          headerName: 'Evento',
          field: 'event',
          width: 120,
          filter: 'text',
        },
        {
          headerName: 'Destino',
          field: 'unit',
          width: 100,
          filter: 'text',
          valueGetter: (params) => {
            if (hasValue(params, 'data.unit') === 'Condominio') {
              return hasValue(params, 'data.unit')
            } else {
              return hasValue(params, 'data.unit.with_block')
            }
          }
        },
        {
          headerName: 'Leitor',
          field: 'access_relay.name',
          width: 80,
          filter: 'text',
        },
        {
          headerName: 'Equipamento',
          field: 'access_device.name',
          width: 80,
          filter: 'text',
        },
        {
          headerName: 'Dispositivo',
          field: 'driver_type',
          width: 80,
          filter: 'text',
          valueGetter: (params) => params.data.driver_type + ' ' + params.data.driver_code,
          cellRenderer: (params) => params.data.driver_type + '<br/>' + params.data.driver_code
        },
      ],
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        },
        defaultColDef: {
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
        },
        rowHeight: 50
      },
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  onGridReady = (params) => {
    this.GridApi = params.api

    this.setState({loading: true})

    getEvents()
      .then((res) => {
        params.api.setRowData(res)
        params.api.sizeColumnsToFit()
        this.setState({loading: false})
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  syncNewEvents = () => {
    localPostApi('devices-manager/read_events_progressive', {}).then(r => console.log())
  }

  render() {
    return (
      <PageBase
        title="Eventos"
        onFilterChange={this.onQuickFilterText}
        create={
          <React.Fragment>
            <Formik
              initialValues={{
                personable_id: '',
                unit_id: '',
              }}
              onSubmit={(values) => {
                this.setState({loading: true})

                let qs = queryString.stringify(values)

                localFetchApi('events?' + qs)
                  .then((res) => {
                    this.GridApi.setRowData(res)
                    this.GridApi.sizeColumnsToFit()
                    this.setState({loading: false})
                  })
                  .catch((err) => {
                    this.setState({loading: false})
                  })

              }}
              render={({values, submitForm}) => (
                <React.Fragment>
                  <Form style={{flex: 1}}>
                    <Form.Group style={{margin: 0}}>
                      <UnitsSelect name='unit_id' label={'Selecione a Unidade'} width={8}/>
                      <PeopleByUnitSelect name='personable_id' unit_id={values.unit_id} label='Morador' width={8}/>
                    </Form.Group>
                  </Form>
                  <Button icon='filter' size='large' primary onClick={submitForm} content='Filtrar'/>
                  <Button size='large' basic onClick={this.syncNewEvents} content='Sincronizar Equipamentos'/>
                </React.Fragment>
              )}
            />
          </React.Fragment>
        }
      >
        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={50}
                       domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
