import MaskData from 'maskdata'

const maskCPFOptions = {
  maskWith: '*',
  unmaskedStartDigits: 4,
  unmaskedEndDigits: 2
}

const maskPhoneOptions = {
  maskWith: '*',
  unmaskedStartDigits: 4,
  unmaskedEndDigits: 2
}

export default {
  maskCpf: (text) => MaskData.maskPhone(text ? String(text) : '', maskCPFOptions),
  maskPhone: (text) => MaskData.maskPhone(text ? String(text) : '', maskPhoneOptions),
}