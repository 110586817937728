import { createAction, handleActions } from 'redux-actions'
import { call, select, takeEvery } from 'redux-saga/effects'
import { b64toBlob } from '../../utils/device_utils'
import { PersonDataModel } from '../../data-model/PersonDataModel'

const domain = 'devices'

// ACTIONS CREATORS
export const actionMap = {
  device_status: createAction(`${domain}/DEVICE_STATUS`),
  guarita_read: createAction(`${domain}/GUARITA_READ`),
  guarita_status: createAction(`${domain}/GUARITA_STATUS`),
  bio64_1: createAction(`${domain}/BIO64_1`),
  bio64_2: createAction(`${domain}/BIO64_2`),
  bio64_1_bin: createAction(`${domain}/BIO64_1_BIN`),
  bio64_2_bin: createAction(`${domain}/BIO64_2_BIN`),
  bio_template: createAction(`${domain}/BIO_TEMPLATE`),
  bio_enroll_client: createAction(`${domain}/BIO_ENROLL_CLIENT`),
  bio_index: createAction(`${domain}/BIO_INDEX`),
  access_group: createAction(`${domain}/ACCESS_GROUP`),
  bio_template_zk9: createAction(`${domain}/BIO_TEMPLATE_ZK9`),
  card_read: createAction(`${domain}/CARD_READ`),
}

// REDUCER
const initialState = {
  // ControlId
  device_status: {
    last_enroll_error: null,
    biometry_sate: null,
    enroller_state: null,
  },

  //  Linear
  guarita_read: {
    serial: null,
    contador: null,
    bateria: null,
  },
  guarita_status: false,

  bio64_1: '',
  bio64_2: '',
  bio64_1_bin: '',
  bio64_2_bin: '',
  bio_template: '',
  bio_index: '',
  access_group: '',
  bio_template_zk9: '',
  card_read: '',
}

let reducers = {
  [actionMap.device_status]: (state, action) => ({
    ...state,
    device_status: action.payload,
  }),
  [actionMap.guarita_read]: (state, action) => ({
    ...state,
    guarita_read: action.payload,
  }),
  [actionMap.card_read]: (state, action) => ({
    ...state,
    card_read: action.payload,
  }),
  [actionMap.guarita_status]: (state, action) => ({
    ...state,
    guarita_status: action.payload,
  }),
  [actionMap.bio64_1]: (state, action) => ({
    ...state,
    bio64_1: action.payload,
  }),
  [actionMap.bio64_2]: (state, action) => ({
    ...state,
    bio64_2: action.payload,
  }),
  [actionMap.bio64_1_bin]: (state, action) => ({
    ...state,
    bio64_1_bin: action.payload,
  }),
  [actionMap.bio64_2_bin]: (state, action) => ({
    ...state,
    bio64_2_bin: action.payload,
  }),
  [actionMap.bio_template]: (state, action) => ({
    ...state,
    bio_template: action.payload,
  }),
  [actionMap.bio_index]: (state, action) => ({
    ...state,
    bio_index: action.payload,
  }),
  [actionMap.access_group]: (state, action) => ({
    ...state,
    access_group: action.payload,
  }),
  [actionMap.bio_template_zk9]: (state, action) => ({
    ...state,
    bio_template_zk9: action.payload,
  }),
}

export default handleActions(reducers, initialState)

function * postSagas(action) {
  try {
    const bio_template = yield select(state => state.devices.bio_template)
    const bio_template_zk9 = yield select(state => state.devices.bio_template_zk9)
    const bio64_2 = yield select(state => state.devices.bio64_2)

    const current_user = yield select(state => state.people.current)
    const bio_index = yield select(state => state.devices.bio_index)
    const access_group = yield select(state => state.devices.access_group)

    console.log('>> bio_enroll_client')
    console.log('current_user')
    console.log(current_user)
    console.log('bio_index')
    console.log(bio_index)
    console.log('access_group')
    console.log(access_group)
    console.log('bio_template')
    console.log(bio_template)
    console.log('bio_template_zk9')
    console.log(bio_template_zk9)
    console.log('<< bio_enroll_client')

    const blob = b64toBlob(bio64_2, 'image/png')

    const response = yield call(async () => {
      return await new PersonDataModel(current_user).local_bioEnrollClient(
        blob,
        bio_template,
        bio_template_zk9,
        bio_index,
        access_group
      )
    })

    // yield put(actionMap.all_success(response))

    return response
  } catch (error) {
    return false
  } finally {
  }
}

export const devices_sagas = [
  takeEvery(actionMap.bio_enroll_client().type, postSagas),
]

