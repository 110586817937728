import React, { useState } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { Formik } from 'formik'
import { F, FormikSelect } from './form/FormInputs'
import { fetchApi } from '../services/api'
import { openBlobApi } from '../utils/api'
import { useQuery } from 'react-query'
import ToasterService from '../services/ToasterService'

const SmartdocPrinter = (props) => {
  const [showModal, setShowModal] = useState(false)

  const handlePrint = () => {
    setShowModal(true)
  }

  const print = (values) => {
    openBlobApi(`admin/smartdocs/${values.smartdoc}/print`, 'post', values)
      .then(() => {})
      .catch((err) => {
        ToasterService.sendErrors(err)
      })
  }

  const {isLoading, data, refetch} = useQuery('smartdocs', async () => {
    let res = await fetchApi(`admin/smartdocs?filter{module}=${props.module}`)
    return res.data
  })

  return (
    <React.Fragment>
      <Button icon='print' basic onClick={handlePrint} content='Imprimir'/>

      <Modal open={showModal} size='mini'>
        <Modal.Header>Selecione o modelo do documento para imprimir</Modal.Header>
        <Formik
          enableReinitialize={true}
          initialValues={{
            module: props.module,
            object_id: props.object_id,
            smartdoc: '',
          }}
          onSubmit={(values) => {
            print(values)
          }}
        >
          {({submitForm}) => (
            <React.Fragment>
              <Modal.Content>
                <Form error size='small'>
                  <F label={'Modelo'} name="smartdoc" component={FormikSelect} options={data}
                     search={true} id_option='id' label_option='name'/>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button icon='cancel' color='red' size='small' onClick={() => setShowModal(false)} content='Fechar'/>
                <Button icon='save' primary size='small' onClick={submitForm} content='Imprimir'/>
              </Modal.Actions>
            </React.Fragment>
          )}
        </Formik>

      </Modal>
    </React.Fragment>
  )
}

export default SmartdocPrinter
