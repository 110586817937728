import { GridApi, GridReadyEvent } from 'ag-grid'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, Label } from 'semantic-ui-react'
import { FF, FormikDate, FormikSelect } from '../../../components/form/FormInputs'
import UnitsSelect from '../../../components/form/UnitsSelect'
import { TICKET_PRIORITIES, TICKET_STATUS } from '../../../fields'
import { useLocalStorageTS } from '../../../hooks'
import { grid_refs_actions } from '../../../redux/modules/grid-refs'
import { dateTimeFormat } from '../../../utils/Formatters'
import { gridDefaults } from '../../../utils/gridDefaults'

const STATUSES = [
  { value: 1, text: 'Realizado' },
  { value: 2, text: 'Pendente' },
  { value: 3, text: 'Em andamento' }
]

const TicketGrid = (props: { componentParent: any }) => {
  const pagination_size = 15
  const cache_key = 'filter_grid_ticket'
  const initial_values = {
    unit: '',
    status: '',
    date_from: '',
    date_to: ''
  }

  const dispatch = useDispatch()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [filter_values, setFilterValues] = useLocalStorageTS<any>(cache_key, initial_values)

  useEffect(() => {
    return () => setFilterValues(initial_values)
  }, [])

  const prepareFilterQs = () => {
    let filter_qs_state = JSON.parse(localStorage.getItem(cache_key) ?? '{}') ?? {}

    if (filter_qs_state.unit) filter_qs_state['filter{unit}'] = filter_qs_state.unit
    if (filter_qs_state.status) filter_qs_state['filter{status}'] = filter_qs_state.status
    if (filter_qs_state.date_from) {
      filter_qs_state['filter{date.gte}'] = moment(filter_qs_state.date_from).startOf('day').toISOString()
    }
    if (filter_qs_state.date_to) {
      filter_qs_state['filter{date.lte}'] = moment(filter_qs_state.date_to).endOf('day').toISOString()
    }

    filter_qs_state['include[]'] = ['unit.with_block', 'files.file', 'category.name']

    return filter_qs_state
  }

  const serverSideDatasource = gridDefaults.makeServerSideDataSource('admin/tickets', pagination_size, prepareFilterQs)

  const GRID = {
    gridOptions: {
      ...gridDefaults.adminGrid,
      context: {
        componentParent: props.componentParent
      },
      rowHeight: 70,
      cacheBlockSize: pagination_size,
      paginationPageSize: pagination_size,
      serverSideDatasource: serverSideDatasource,
      rowModelType: 'serverSide'
    },
    columnDefs: [
      {
        headerName: '#',
        field: 'id',
        width: 10,
        filter: 'number'
      },
      {
        headerName: 'Categoria',
        field: 'category.name',
        width: 60,
        filter: 'text',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <>
              {params.value} <br/>
              <Label size="small">{TICKET_STATUS[params.data.status]}</Label>
              <Label size="small">{TICKET_PRIORITIES[params.data.priority]}</Label>
            </>
          )
        }
      },
      {
        headerName: 'Unidade',
        field: 'unit.with_block',
        width: 40,
        filter: 'text'
      },
      {
        headerName: 'Data',
        field: 'date',
        width: 25,
        filter: 'text',
        valueGetter: (params) => dateTimeFormat(params, 'date')
      },
      {
        headerName: 'Ações',
        width: 20,
        suppressResize: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true,
        suppressFilter: true,
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          if (params.data) {
            return (
              <React.Fragment>
                <button
                  className="ui button basic compact primary"
                  onClick={() => params.context.componentParent.handleEdit(params.data)}
                >
                  Detalhes
                </button>
              </React.Fragment>
            )
          } else {
            return null
          }
        }
      }
    ],
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api)
      dispatch(grid_refs_actions.update_ref({ key: 'ticket', ref: params.api }))
    }
  }

  return (
    <React.Fragment>
      <div className="ui segment page-filter-container">
        <Formik
          initialValues={{
            unit: filter_values.unit,
            status: filter_values.status,
            date_from: filter_values.date_from,
            date_to: filter_values.date_to
          }}
          onSubmit={(values) => {
            setFilterValues(values)
            gridApi?.onFilterChanged()
          }}
          render={({ submitForm }) => (
            <Form>
              <Form.Group widths="equal" style={{ margin: 0 }}>
                <UnitsSelect name="unit" label="Unidade"/>
                <FF label={'Status'} name="status" component={FormikSelect} options={STATUSES}/>

                <FF label="Data (Início)" name="date_from" component={FormikDate}/>
                <FF label="Data (Fim)" name="date_to" component={FormikDate}/>

                <Button primary size="small" basic content="Filtrar" onClick={submitForm}/>
              </Form.Group>
            </Form>
          )}
        />
      </div>

      <div className="ui raised segment page-grid-container">
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={GRID.gridOptions}
            columnDefs={GRID.columnDefs}
            onGridReady={GRID.onGridReady}
          />
        </div>
        <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
      </div>
      <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
    </React.Fragment>
  )
}

export default TicketGrid