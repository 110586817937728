import React from 'react'
import {imageCacheUrl} from '../utils/common'
import ReactImageFallback from 'react-image-fallback'

export const ProfileCache = (props: any) => {
  let url = imageCacheUrl(props.src)

  return (
    <ReactImageFallback
      fallbackImage={require('../assets/images/default-profile.png')}
      {...props}
      src={url}
    />
  )
}
