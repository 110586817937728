import { getValue } from '../../utils/Formatters'

export const hasPerm = (requiredPermissions, hasAll = false) => {
  const user = JSON.parse(localStorage.getItem('current_user'))
  let userPermissions = getValue(user, 'permissions')

  if (!userPermissions) return false

  let filteredList = userPermissions.filter(permission => {
    return requiredPermissions.filter(required => required === permission).length > 0
  })

  return hasAll ? filteredList.length === requiredPermissions.length : filteredList.length > 0
}


