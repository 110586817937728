import React, { Component } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'

class ActivityLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.visible,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({visible: nextProps.visible})
  }

  componentWillUnmount() {
    this.setState = {
      visible: false
    }
  }

  render() {
    if (this.state.visible === true) {
      return (
        <Dimmer active={this.state.visible} page>
          <Loader size='massive'>Processando</Loader>
        </Dimmer>
      )
    }
    else {
      return null
    }
  }
}

class ActivityLoadingReduxComponent extends Component {
  render() {
    if (this.props.currentlySending === true) {
      return (
        <Dimmer active={this.props.currentlySending} page>
          <Loader size='massive'>Processando</Loader>
        </Dimmer>
      )
    }
    else {
      return null
    }
  }
}

function mapStateToProps(state) {
  const {app} = state
  const {currentlySending} = app || {
    currentlySending: true,
  }

  return {
    currentlySending,
  }
}

export const ActivityLoadingRedux = connect(mapStateToProps)(ActivityLoadingReduxComponent)

export default ActivityLoading