import {localPostApi_ValidateLocalServer} from './LocalApi'

// const EventEmitter = require('eventemitter3')
// export const emitter_hardware = new EventEmitter()

export const LinearRequirements = () => {
  localPostApi_ValidateLocalServer('linear/listen_guarita_status')
}

export const AccessManagerRequirements = () => {
  localPostApi_ValidateLocalServer('access-manager/listen_visits')
}
