import { Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { FF, FormikInput } from './form/FormInputs'

export default class AssignBadgeNumber extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Modal open={true} size="mini">
          <Modal.Header>Associar Crachá</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                badge_number: ''
              }}
              onSubmit={(values) => {
                this.props.handleAssign(values)
              }}
              render={({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                <div className="ui form large error">
                  <FF label={'Número crachá'} name="badge_number" component={FormikInput}/>
                </div>
              )}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" size="large" onClick={this.props.setClose}>Cancelar</Button>
            <Button primary size="large" onClick={(e) => this.form.handleSubmit(e)}>Salvar</Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    )
  }
}

