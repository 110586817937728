import { AgGridReact } from 'ag-grid-react'
import React from 'react'
import { withRouter } from 'react-router-dom'
import swal from 'sweetalert2'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import { ACCESSAUTH_STATUS, ACCESSAUTH_TYPES } from '../../fields'
import { getGuestAuths } from '../../utils/DataService'
import { dateFormat, removeAccents } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'
import { Button, Dropdown } from 'semantic-ui-react'
import { PersonDataModel } from '../../data-model'
import { localFetchApi, localPostApi } from '../../utils/LocalApi'
import { putApi } from '../../utils/api'
import ToasterService from '../../services/ToasterService'
import { actionMap as system_actions } from '../../redux/modules/system'
import { store } from '../../redux/store/configureStore'

class AccessAuthListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },

      AuthQuickFilterText: null,
      loading: false,
      logData: '',

      columnAuthDefs: [
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 40,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Nome',
          width: 60,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params) => {
            if (params.data.person_id === null) {
              return params.data?.name_temp
            } else {
              return params.data?.person?.name
            }
          },
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Motivo visita',
          field: 'access_reason_name',
          width: 40,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Tipo',
          field: 'type',
          width: 20,
          filter: 'text',
          valueGetter: (params) => ACCESSAUTH_TYPES[params.data.type],
          suppressMenu: true
        },
        {
          headerName: 'Data',
          width: 30,
          filter: 'text',
          suppressMenu: true,
          valueGetter: dateFormat
        },
        {
          headerName: 'Iterfonar',
          width: 25,
          filter: 'text',
          suppressMenu: true,
          valueGetter: (params => params.data.intercom ? 'Sim' : 'Não')
        },
        {
          headerName: 'Autorizado por',
          field: 'created_by',
          width: 50,
          filter: 'text',
          suppressMenu: true
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 30,
          filter: 'text',
          valueGetter: (params) => ACCESSAUTH_STATUS[params.data.status],
          suppressMenu: true
        },
        {
          headerName: 'Ações',
          width: 100,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellClass: 'ag-button-cell',
          cellRendererFramework: (params) => {
            if (!params.node.data) return null

            return (
              <Button.Group basic primary compact>
                <Dropdown text="Ações  " icon="pencil" floating button className="icon">
                  <Dropdown.Menu>
                    <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
                    <Dropdown.Divider/>

                    {params.data && params.data.status !== 3 &&
                      <Dropdown.Item onClick={() => { params.context.componentParent.handleAuthAction(params.data) }}>
                        Registrar entrada
                      </Dropdown.Item>}

                    {params.data && params.data?.person?.id &&
                      <Dropdown.Item onClick={() => { params.context.componentParent.cancelAuthorization(params.data) }}>
                        Cancelar autorização
                      </Dropdown.Item>}

                    {params.data && params.data?.person?.id &&
                      <Dropdown.Item onClick={() => { params.context.componentParent.deleteAllDataDevices(params.data.person) }}>
                        Excluir acionador
                      </Dropdown.Item>}

                    {params.data && params.data?.person?.id &&
                      <Dropdown.Item onClick={() => { params.context.componentParent.reEnrollAccessAuth(params.data) }}>
                        Reenviar acionador
                      </Dropdown.Item>}
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            )
          }
        }
      ],

      currentAuth: {},
      currentBook: {}
    }
  }

  AuthOnQuickFilterText = (event) => {
    this.setState({ AuthQuickFilterText: event })
  }

  onGridAuthReady = (params) => {
    this.GridAuthApi = params.api

    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })

    getGuestAuths()
      .then(res => {
        this.GridAuthApi.setRowData(res)
        this.GridAuthApi.sizeColumnsToFit()
        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ loading: false })
      })
  }

  handleAuthAction = (params) => {
    this.props.history.push('/admin/access-logs?source=accessauthorization', params)
    store.dispatch(system_actions.showAccessLogForm(true))
  }

  deleteAllDataDevices = (person_data) => {
    swal({
      title: 'Essa ação irá excluir TODOS OS DADOS desse visitante nos equipamentos!',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        this.setState({ loading: true })
        new PersonDataModel(person_data).local_deleteAllData()
          .finally(res => {
            this.setState({ loading: false })
          })
      }
    })
  }

  cancelAuthorization = (auth_data) => {
    swal({
      title: 'ATENÇÃO: Antes de cancelar verifique se já excluiu os ACIONADORES do visitante!',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim, já exclui os acionadores. Cancelar!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        this.setState({ loading: true })

        putApi('admin/access-authorizations', { status: 0 }, auth_data.id)
          .then((res) => {
            swal({ type: 'success', title: 'Cancelado com sucesso!' })

            this.setState({ loading: false })

            if (auth_data?.person?.id) {
              new PersonDataModel(auth_data.person).local_deleteAllData()
                .finally(() => { this.getData() })
            } else {
              this.getData()
            }

          })
          .catch((err) => {
            ToasterService.sendErrors(err)
          })

      }
    })
  }

  reEnrollAccessAuth = (access_auth_data) => {
    swal({
      title: 'Essa ação irá ENVIAR o acionador para os equipamentos!',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        localPostApi('access-manager_v2/enroll_access_auths', [
          {
            access_auth_id: access_auth_data.id
          }
        ])

      }
    })
  }

  render() {
    //
    return (
      <PageBase
        title="Visitantes Pré-autorizados"
        onFilterChange={this.AuthOnQuickFilterText}
        create={
          <>
            <Button
              primary size={'large'}
              onClick={() => {
                this.setState({ loading: true })
                localFetchApi('access-manager_v2/delete_expired_access_auths_by_date?days_to_back=3', 1000 * 60)
                  .finally(() => {
                    this.setState({ loading: false })
                  })
              }}
            >
              Excluir acionadores expirados (ult. 3 dias)
            </Button>
          </>
        }
      >
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            enableFilter={true}
            gridOptions={this.state.gridOptions}
            columnDefs={this.state.columnAuthDefs}
            quickFilterText={this.state.AuthQuickFilterText}
            onGridReady={this.onGridAuthReady}
            pagination={true}
            domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}

export default withRouter(AccessAuthListPage)