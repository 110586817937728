import React from 'react'
import { Link } from 'react-router-dom'
import PageBase from '../../components/PageBase'
import { Formik } from 'formik'
import { putApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import { getInitialValue, show_modal } from '../../utils/Formatters'
import { Button, Form } from 'semantic-ui-react'
import { getRemoteConfig } from '../../utils/form'
import ToasterService from '../../services/ToasterService'

export default class WorkShiftFormPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      units: []
    }
  }

  render() {
    let work_shift_template = getRemoteConfig('general.gater__work_shifts__description__template')

    return (
      <PageBase title="Editar Turno" showFilter={false}>
        <div>
          <Formik
            initialValues={{
              _id: getInitialValue(this.props, 'location.state.id'),
              name: getInitialValue(this.props, 'location.state.name'),
              next_worker: getInitialValue(this.props, 'location.state.next_worker'),
              description: getInitialValue(this.props, 'location.state.description'),
            }}
            validate={values => {
              let errors = {}

              if (!values.name) errors.name = 'Digite o nome do colaborador'
              if (!values.description) errors.description = 'Digite o relatório'
              return errors
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              putApi('admin/work-shifts', values, values._id)
                .then((res) => {
                  this.setState({ loading: false })
                  this.props.history.push('/admin/work-shifts')
                  show_modal('Turno salvo!', 'success')
                })
                .catch((err) => {
                  this.setState({ loading: false })
                  ToasterService.sendErrors(err)
                })
            }}
            render={({ values, errors, touched, handleChange, handleBlur, setFieldValue, submitForm }) => (
              <Form className="ui form large error">
                {!!work_shift_template &&
                  <Button
                    type="button"
                    secondary
                    onClick={() => {
                      setFieldValue('description', work_shift_template)
                    }}
                  >
                    Usar modelo
                  </Button>}

                <div className="field">
                  <label>Relatório</label>
                  <textarea
                    rows="15"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.description}
                    spellCheck={true}
                  />
                  {touched.description && errors.description &&
                    <h5 className="ui header red">{errors.description}</h5>}
                </div>

                <br/>
                <br/>

                <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                <Link to="/admin/work-shifts">
                  <button className="ui red button right floated large">Cancelar</button>
                </Link>
              </Form>
            )}
          />
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}