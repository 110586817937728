import moment from 'moment'
import React from 'react'
import {useQuery} from 'react-query'
import {Header, Table} from 'semantic-ui-react'
import {ProfileCache} from '../../components/Utils'
import {qs} from '../../queries'
import {qs_local_server} from '../../queries-local-server'
import {hasValue} from '../../utils/Formatters'

const LiveTable = () => {
  const { data } = useQuery('getLastEvents', () => qs_local_server.events.lastEvents())
  const { data: profiles_person } = useQuery<any>('getProfilePhotos', () => qs.profile_photos.allCached())

  const getImgProfile = (log: any) => {
    try {
      if (profiles_person !== null) {
        return profiles_person[log.original_personable_id || log.personable_id]
      }
    } catch (e) {
      return null
    }
  }

  return (
    <Table celled padded>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <Table.HeaderCell>Tipo</Table.HeaderCell>
          <Table.HeaderCell>Evento</Table.HeaderCell>
          <Table.HeaderCell>Destino</Table.HeaderCell>
          <Table.HeaderCell>Leitor</Table.HeaderCell>
          <Table.HeaderCell>Dispositivo</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data?.map(item => (
          <Table.Row key={item.id} error={item.panic}>
            <Table.Cell>
              <Header as="h4" image>

                <ProfileCache src={getImgProfile(item)} className="ui massive rounded image"/>

                <Header.Content>
                  {item.name}
                  <p style={{ fontSize: '11px' }}>
                    #{item.id} -
                    Pessoa
                    # {item.personable_id} {item.original_personable_id !== item.personable_id && ` - ${item.original_personable_id}`}
                  </p>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              {moment(item.date).format('DD/MM/YYYY HH:mm:ss')}
            </Table.Cell>
            <Table.Cell>
              {item.person_type}
            </Table.Cell>
            <Table.Cell>
              {item.event}
            </Table.Cell>
            <Table.Cell>
              {item?.unit?.with_block ?? ''}
            </Table.Cell>
            <Table.Cell>
              {hasValue(item, 'access_device.name')}
              {hasValue(item, 'access_relay.name') &&
                <React.Fragment>
                  ({hasValue(item, 'access_relay.name')})
                </React.Fragment>}
            </Table.Cell>
            <Table.Cell>
              {item.driver_type !== 'Senha' && item.driver_code}<br/>
              {item.driver_type}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default LiveTable

