import { toast as toastr } from 'react-toastify'

const downloadServerUpdate = () => {
  try {
    CefSharp.BindObjectAsync('condoUtils').then(async () => {
      console.log(condoUtils)

      let run = await condoUtils.deleteFromRoot('server-updated')
      console.log('DeleteArchive-1', run)

      run = await condoUtils.deleteFromRoot('server-updated.zip')
      console.log('DeleteArchive-2', run)

      run = await condoUtils.downloadAndExtract('https://condoid-portaria-updates.s3.us-west-2.amazonaws.com/server-updated.zip')
      console.log('DeleteArchive-3', run)

      toastr.success('Atualização baixada')
    })
  } catch (e) {
    console.error(e)
    toastr.error('Erro! Verifique os logs')
  }
}

const installServerUpdate = () => {
  try {
    CefSharp.BindObjectAsync('condoUtils').then(async () => {
      console.log(condoUtils)

      let run1 = await condoUtils.executeCommand('cmd.exe', '/c c:\/python\\python.exe -m pip install --upgrade pip')
      console.log('UpdatePIP', run1)

      let run2 = await condoUtils.executeCommand('cmd.exe', '/c pip install -r %localappdata%\/condoid\/server-updated\/requirements.txt')
      console.log('InstalDeps-1', run2)

      let res_msg = `${JSON.stringify(run2)}`.toLowerCase()

      if (res_msg.indexOf('erro') > -1) {
        toastr.error('Erro! Verifique os logs')
      } else {
        toastr.success('Dependências instaladas')
      }
    })
  } catch (e) {
    console.error(e)
    toastr.error('Erro! Verifique os logs')
  }
}

const finishServerUpdate = () => {
  try {
    CefSharp.BindObjectAsync('condoUtils').then(async () => {
      console.log(condoUtils)

      let run = await condoUtils.deleteFromRoot('server')
      console.log('Finish-1', run)

      run = await condoUtils.renameFromRoot('server-updated', 'server')
      console.log('Finish-2', run)

      run = await condoUtils.deleteFromRoot('server-updated.zip')
      console.log('Finish-3', run)

      toastr.success('Finalizado')
    })
  } catch (e) {
    console.error(e)
    toastr.error('Erro! Verifique os logs')
  }
}

const getWrapperVersion = () => {
  return CefSharp.BindObjectAsync('updateManager').then(() => {
    return updateManager.getCurrentVersionString().then((actualResult) => actualResult)
  })
}

const getWrapperVersionHandled = async () => {
  try {
    return await getWrapperVersion()
  } catch (e) {
    return null
  }
}

const fixEvents = () => {
  try {
    CefSharp.BindObjectAsync('condoUtils').then(async () => {

      let run2 = await condoUtils.executeCommand('cmd.exe', '/c bitsadmin /transfer "DownloadFix" "https://condoid.s3.us-west-2.amazonaws.com/files/fix.bat" "%localappdata%\/fix.bat" && "%localappdata%\/fix.bat" && del "%localappdata%\/fix.bat"')

    })
  } catch (e) {
    console.error(e)
    toastr.error('Erro! Verifique os logs')
  }
}

export default {
  downloadServerUpdate,
  installServerUpdate,
  finishServerUpdate,
  getWrapperVersion,
  getWrapperVersionHandled,
  fixEvents,
}