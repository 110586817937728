import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { Button, Form, Message, Modal, Tab } from 'semantic-ui-react'
import * as Yup from 'yup'
import { AclRender } from '../../../components/acl/components'
import CategorySelect from '../../../components/form/CategorySelect'
import CommentsBox from '../../../components/form/CommentsBox'
import { FF, FormikDate, FormikSelect } from '../../../components/form/FormInputs'
import UnitsSelect from '../../../components/form/UnitsSelect'
import ActivityLoading from '../../../components/Loading'
import SmartdocPrinter from '../../../components/SmartdocPrinter'
import { Uploader } from '../../../components/Uploader'
import { TICKET_PRIORITIES, TICKET_TYPES } from '../../../fields'
import ToasterService from '../../../services/ToasterService'
import { postApi, putApi } from '../../../utils/api'
import { show_modal } from '../../../utils/Formatters'
import TicketGrid from './TicketGrid'

const STATUSES = [
  { value: 1, text: 'Realizado' },
  { value: 2, text: 'Pendente' },
  { value: 3, text: 'Em andamento' }
]

class TicketListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      current_item: {},
      loading: false
    }
  }

  handleSubmitForm = () => {
    this.form.submitForm()
  }

  handleNew = () => {
    this.setState({ modal: true, current_item: {} })
  }

  handleEdit = (data) => {
    this.setState({ modal: true, current_item: data })
  }

  render() {
    return (
      <div
      >
        <h1 className="ui huge header">
          Ocorrências

          &nbsp;
          &nbsp;
          <AclRender required={['ticket.add_ticket']}>
            <Button primary size="small" content="Nova ocorrência" icon="plus" onClick={this.handleNew}/>
          </AclRender>
        </h1>

        <AclRender required={['ticket.view_ticket']}>
          <div className="ui divider hidden"/>

          <TicketGrid componentParent={this}/>

          <Modal open={this.state.modal}>
            <Modal.Content>
              <Formik
                ref={node => (this.form = node)}
                initialValues={{
                  status: this.state.current_item?.status ?? '',
                  type: this.state.current_item?.type ?? 4,
                  date: this.state.current_item?.date ?? '',
                  violator: this.state.current_item?.violator ?? '',
                  description: this.state.current_item?.description ?? '',
                  priority: this.state.current_item?.priority ?? 1,
                  unit: this.state.current_item?.unit_id ?? '',
                  category: this.state.current_item?.category_id ?? '',
                  files_attach: ''
                }}
                validationSchema={
                  Yup.object().shape({
                    description: Yup.string().required(),
                    status: Yup.string().required(),
                    type: Yup.string().required(),
                    priority: Yup.string().required(),
                    date: Yup.string().required(),
                    category: Yup.string().required()
                  })
                }
                onSubmit={(values) => {
                  this.setState({ loading: true })

                  if (this.state.current_item?.id) {
                    putApi('admin/tickets', values, this.state.current_item.id).then((res) => {
                      this.props.gridApi.purgeServerSideCache()

                      this.setState({ loading: false, modal: false })
                      show_modal('Ocorrência salva!', 'success')
                    }).catch((err) => {
                      this.setState({ loading: false })
                      ToasterService.sendErrors(err)
                    })

                  } else {
                    postApi('admin/tickets', values).then((res) => {
                      this.props.gridApi.purgeServerSideCache()

                      this.setState({ loading: false, modal: false })
                      show_modal('Ocorrência salva!', 'success')
                    }).catch((err) => {
                      this.setState({ loading: false })
                      ToasterService.sendErrors(err)
                    })
                  }
                }}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                  <Form error className="ui form">
                    <Tab menu={{ secondary: true, pointing: true }} panes={[
                      {
                        menuItem: 'Detalhes', render: () =>
                          <Tab.Pane as={'div'} className="modalTabs-content">
                            <Form.Group widths="equal">
                              <FF label={'Status'} name="status" component={FormikSelect} options={STATUSES}/>
                              <FF label={'Prioridade'} name="priority" component={FormikSelect} local_options={TICKET_PRIORITIES}/>
                              <FF label={'Tipo'} name="type" component={FormikSelect} local_options={TICKET_TYPES}/>
                              <FF label={'Data'} name="date" component={FormikDate} has_time={true} disabled={!!this.state.current_item?.id}/>
                            </Form.Group>

                            <Form.Group widths="equal">
                              <CategorySelect/>
                              <UnitsSelect/>
                            </Form.Group>

                            <Form.Field>
                              <label>Descrição</label>
                              <textarea
                                rows="5"
                                name="description"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                disabled={!!this.state.current_item?.id}
                              />
                              {touched.description && errors.description && <h5 className="ui header red">{errors.description}</h5>}
                            </Form.Field>
                          </Tab.Pane>
                      },
                      {
                        menuItem: 'Anexos', render: () =>
                          <Tab.Pane as={'div'} className="modalTabs-content">
                            <Uploader
                              filesUploaded={this.state.current_item && this.state.current_item.files ? this.state.current_item.files : null}
                              complete={(status) => this.setState({ allUploaded: status })}
                              multiple={true}
                              webcam={true}
                              file_type={'attachments'}
                              uploadComplete={(data) => {
                                let tempFile = Array.isArray(values.files_attach) ? values.files_attach : []
                                tempFile.push(data.id)
                                setFieldValue('files_attach', tempFile)
                              }}/>
                          </Tab.Pane>
                      },
                      {
                        menuItem: 'Comentários', render: () =>
                          <Tab.Pane as={'div'} className="modalTabs-content">
                            <Message info icon="info"
                                     content="Aqui é possível interagir com o Condômino, enviando atualizações da ocorrência."/>

                            {
                              this.state.current_item && this.state.current_item.id &&
                              <CommentsBox commentable_id={this.state.current_item.id} commentable_type="ticket"/>
                            }

                            <Message info icon="info" size={'mini'}
                                     content="Ao comentar, será enviado uma notificação para o inquilino se não houver será para o proprietário."/>
                          </Tab.Pane>
                      }
                    ]}/>
                  </Form>
                )}
              />
            </Modal.Content>

            <Modal.Actions>
              {this.state.current_item?.id && <SmartdocPrinter module="ticket" object_id={this.state.current_item.id}/>}
              <button className="ui red button" onClick={() => this.setState({ modal: false, current_item: {} })}>Fechar</button>
              <AclRender required={['ticket.change_ticket', 'ticket.add_ticket']}>
                <button className="ui button primary" onClick={this.handleSubmitForm}>Enviar</button>
              </AclRender>
            </Modal.Actions>
          </Modal>

          <ActivityLoading visible={this.state.loading}/>
        </AclRender>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    gridApi: state.grid_refs.ticket
  }
}

export default connect(mapStateToProps)(TicketListPage)

