import {connect as formikConnect, Field, FormikProps} from 'formik'
import React from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../queries'
import {FormikSelect} from './FormInputs'

type Props = {
  label?: string;
  name?: string;
  formik?: FormikProps<{ access_group: number | string }>
}

const UnitsSelect = (props: Props) => {
  const { isLoading, data } = useQuery('getUnitsSelect', () => qs.units.allCached(), {
    // twelve hours
    staleTime: 1000 * 60 * 60 * 12
  })

  return (
    <Field
      label={props.label ? props.label : 'Unidade'}
      name={props.name ? props.name : 'unit'}
      component={FormikSelect}
      options={data}
      search={true}
      id_option="id"
      label_option="with_block"
      loading={isLoading}
      {...props}
    />
  )
}

export default formikConnect(UnitsSelect)