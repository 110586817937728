import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";

export class DatePickr extends React.Component {
  constructor(props) {
    super(props)

    let _format = this.props.only_time ? 'HH:mm' : ''
    this.state = {
      selected: this.props.initial ? moment(this.props.initial, _format) : undefined
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Fix on clearing input, check if has existing value. Else will show an "Invalid Date" because {selected: moment(null)}
    if (this.props.initial && this.props.initial !== prevProps.initial) {
      this.setState({selected: moment(this.props.initial)})
    }
  }

  handleChange = (date, event) => {
    this.setState({selected: date})
    if (date) {
      if (this.props.only_time) {
        this.props.onChange(this.props.name, date.format('HH:mm'))
        return
      }

      if (this.props.has_time) {
        this.props.onChange(this.props.name, date.toISOString())
        return
      }

      this.props.onChange(this.props.name, date.format('YYYY-MM-DD'))
    } else {
      this.props.onChange(this.props.name, '')
    }
  }

  handleBlur = () => {
    this.props.onBlur(this.props.name, true)
  }

  render() {
    if (this.props.only_time) {
      return (
        <DatePicker isClearable={true}
                    {...this.props}
                    selected={this.state.selected}
                    autoComplete={'off'}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}

                    showTimeSelect
                    timeIntervals={15}
                    dateFormat="LT"
                    rawFormat="HH:mm"
                    timeCaption="Hora"
                    timeFormat="HH:mm"
                    showTimeSelectOnly/>
      )
    }

    if (this.props.has_time) {
      return (
        <DatePicker isClearable={true}
                    {...this.props}
                    selected={this.state.selected}
                    autoComplete={'off'}

                    showYearDropdown
                    showMonthDropdown
                    dropdownMode='select'
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}

                    dateFormat="DD/MM/YYYY HH:mm"
                    rawFormat="YYYY-MM-DD HH:mmZ"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}/>
      )
    }

    return (
      <DatePicker isClearable={true}
                  {...this.props}
                  selected={this.state.selected}
                  autoComplete={'off'}

                  showYearDropdown
                  showMonthDropdown
                  dropdownMode='select'
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  dateFormat={this.props.dateFormat ? this.props.dateFormat : 'DD/MM/YYYY'}
                  rawFormat="YYYY-MM-DD"/>
    )
  }
}

export class DateTimePickr extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: this.props.initial ? moment(this.props.initial) : undefined
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange = (date, event) => {
    this.setState({selected: date})
    if (date) {
      this.props.onChange(this.props.name, date.toISOString())
    } else {
      this.props.onChange(this.props.name, '')
    }
  }

  handleBlur = () => {
    this.props.onBlur(this.props.name, true)
  }

  render() {
    return (
      <DatePicker {...this.props}
                  selected={this.state.selected}
                  isClearable={true}
                  autoComplete={'off'}
                  showYearDropdown
                  showMonthDropdown
                  dropdownMode='select'
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}/>
    )
  }
}
