import { GridApi, GridReadyEvent } from 'ag-grid'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form } from 'semantic-ui-react'
import { FF, FormikInput } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import { useLocalStorageTS } from '../../hooks'
import { grid_refs_actions } from '../../redux/modules/grid-refs'
import { gridDefaults } from '../../utils/gridDefaults'

const VehicleGrid = (props: { componentParent: any }) => {
  const pagination_size = 15
  const cache_key = 'filter_grid_vehicle'
  const initial_values = {
    unit: '',
    parking: '',
    plate: '',
    model: ''
  }

  const dispatch = useDispatch()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [filter_values, setFilterValues] = useLocalStorageTS<any>(cache_key, initial_values)

  useEffect(() => {
    return () => setFilterValues(initial_values)
  }, [])

  const prepareFilterQs = () => {
    let filter_qs_state = JSON.parse(localStorage.getItem(cache_key) ?? '{}') ?? {}

    if (filter_qs_state.unit) filter_qs_state['filter{unit}'] = filter_qs_state.unit
    if (filter_qs_state.parking) filter_qs_state['filter{parking.code.icontains}'] = filter_qs_state.parking
    if (filter_qs_state.plate) filter_qs_state['filter{plate.icontains}'] = filter_qs_state.plate
    if (filter_qs_state.model) filter_qs_state['filter{model.icontains}'] = filter_qs_state.model

    filter_qs_state['include[]'] = ['parking.with_pavement', 'unit.with_block', 'person.name']

    return filter_qs_state
  }

  const serverSideDatasource = gridDefaults.makeServerSideDataSource('admin/vehicles', pagination_size, prepareFilterQs)

  const GRID = {
    gridOptions: {
      ...gridDefaults.adminGrid,
      context: {
        componentParent: props.componentParent
      },
      rowHeight: 60,
      cacheBlockSize: pagination_size,
      paginationPageSize: pagination_size,
      serverSideDatasource: serverSideDatasource,
      rowModelType: 'serverSide'
    },
    columnDefs: [
      {
        headerName: 'Marca',
        field: 'brand',
        width: 30,
        filter: 'text'
      },
      {
        headerName: 'Modelo',
        field: 'model',
        width: 35,
        filter: 'text'
      },
      {
        headerName: 'Cor',
        field: 'color',
        width: 35,
        filter: 'text'
      },
      {
        headerName: 'Placa',
        field: 'plate',
        width: 25,
        filter: 'text'
      },
      {
        headerName: 'Vaga',
        field: 'parking.with_pavement',
        width: 30,
        filter: 'text'
      },
      {
        headerName: 'Unidade',
        field: 'unit.with_block',
        width: 35,
        filter: 'text'
      },
      {
        headerName: 'Morador',
        field: 'person.name',
        width: 50,
        filter: 'text'
      }
    ],
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api)
      dispatch(grid_refs_actions.update_ref({ key: 'vehicle', ref: params.api }))
    }
  }

  return (
    <React.Fragment>
      <div className="ui segment page-filter-container">
        <Formik
          initialValues={{
            unit: filter_values.unit,
            parking: filter_values.parking,
            plate: filter_values.plate,
            model: filter_values.model,
          }}
          onSubmit={(values) => {
            setFilterValues(values)
            gridApi?.onFilterChanged()
          }}
          render={({ submitForm }) => (
            <Form>
              <Form.Group widths="equal" style={{ margin: 0 }}>
                <UnitsSelect name="unit" label="Unidade"/>

                <FF name="parking" label="Vaga" component={FormikInput}/>
                <FF name="plate" label="Placa" component={FormikInput}/>
                <FF name="model" label="Modelo" component={FormikInput}/>

                <Button primary size="small" basic content="Filtrar" onClick={submitForm}/>
              </Form.Group>
            </Form>
          )}
        />
      </div>

      <div className="ui raised segment page-grid-container">
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={GRID.gridOptions}
            columnDefs={GRID.columnDefs}
            onGridReady={GRID.onGridReady}
          />
        </div>
        <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
      </div>
      <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
    </React.Fragment>
  )
}

export default VehicleGrid