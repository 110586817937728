/*eslint-disable no-undef*/

import React, { Component } from 'react'
import { hot } from 'react-hot-loader/root'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Route, Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DownloadProgress from './components/DownloadProgress'
import { ActivityLoadingRedux } from './components/Loading'
import AppContainer from './containers/App'
import LoginPage from './containers/LoginPage'
import queryClient from './queryClient'
import { history, store } from './redux/store/configureStore'
import './styles.scss'

function hotkeys_listener(e) {
  // F7
  if (e.keyCode === 118) {
    history.push('/admin/panel-hdw')
  }
  if (e.keyCode === 116) {
    window.location.reload()
  }
}

document.addEventListener('keyup', hotkeys_listener, false)

class App extends Component {
  componentDidMount() {
    if (localStorage.getItem('token') !== null) {
      // history.push('/admin/live')
    } else {
      history.push('/login')
    }
  }

  render() {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ToastContainer autoClose={8000}/>

          <Router history={history}>
            <React.Fragment>
              <Route path="/login" component={LoginPage}/>
              <Route path="/admin" component={AppContainer}/>
              {/*<Route component={NotFoundPage}/>*/}

              <ActivityLoadingRedux/>
            </React.Fragment>
          </Router>

          <DownloadProgress/>
        </QueryClientProvider>
      </Provider>
    )
  }
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App
