import * as AWS from 'aws-sdk'
import * as AWSIoTData from 'aws-iot-device-sdk'

// https://github.com/serverless-guru/real-time-weather/blob/master/README.md
// https://gitlab.com/jbesw/askjames-iot-demo/blob/master/src/components/IoT.vue
// https://aws.amazon.com/pt/blogs/iot/configuring-cognito-user-pools-to-communicate-with-aws-iot-core/

const REGION = 'sa-east-1'
const COGNITO_REGION = 'us-west-2'
const IDENTITY_POOL_ID = 'us-west-2:5510753a-d1d6-4be2-be1d-21181981ad2a'
const USER_POOL_WEB_CLIENT_ID = 'condoid-client-' + (Math.floor((Math.random() * 100000000) + 1))
const MQTT_ID = 'a29qkxo91l5dc0-ats'

let awsConfig = {
  region: REGION,
  cognito_region: COGNITO_REGION,
  identityPoolId: IDENTITY_POOL_ID,
  clientId: USER_POOL_WEB_CLIENT_ID,
  mqttEndpoint: `${MQTT_ID}.iot.${REGION}.amazonaws.com`,
}

AWS.config.region = awsConfig.cognito_region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: awsConfig.identityPoolId
})

export const iotAuthenticate = async (device = null) => {
  try {
    await AWS.config.credentials.getPromise()
  } catch (e) {
    console.log('Removing localstorage data')

    for (let i of Object.keys(localStorage)) {
      if (i.includes('cognito')) {
        localStorage.removeItem(i)
      }
    }

    AWS.config.region = awsConfig.cognito_region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsConfig.identityPoolId
    })

    await AWS.config.credentials.getPromise()
  }

  if (device) {
    device.updateWebSocketCredentials(
      AWS.config.credentials.AccessKeyId,
      AWS.config.credentials.SecretKey,
      AWS.config.credentials.SessionToken
    )
  }

  return true
}

export const iotClient = async () => {
  await iotAuthenticate()

  const device = AWSIoTData.device({
    region: awsConfig.region,
    host: awsConfig.mqttEndpoint,
    clientId: awsConfig.clientId,
    protocol: 'wss',
    maximumReconnectTimeMs: 8000,
    debug: false,
    accessKeyId: AWS.config.credentials.accessKeyId,
    secretKey: AWS.config.credentials.secretAccessKey,
    sessionToken: AWS.config.credentials.sessionToken
  })

  // device.on('error', (err) => {
  //   console.error(err)
  //   iotAuthenticate(device)
  // })

  return device
}
