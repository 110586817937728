import enviroment from '../enviroment'
import {apiAxios} from '../services/api'
import ToasterService from '../services/ToasterService'

export const spreadsheets = {
  all: async () => {
    interface HTTPResponse {
      data: {
        id: number
        items_count: number
        created_at: string
        updated_at: string
        name: string
        description: null
        show_app_resident: boolean
        show_app_top: boolean
        updated_by: number
        updated_by_id: number
      }[]
    }

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + `admin/spreadsheets?filter{show_app_gater}=true`)
    return data.data
  },
  get: async (spreadsheet_id) => {
    interface HTTPResponse {
      id: number
      fields: Field[]
      items_count: number
      created_at: string
      updated_at: string
      name: string
      description: null
      show_app_resident: boolean
      show_app_top: boolean
      allow_gater_create_edit: boolean
      updated_by: number
      updated_by_id: number
    }

    interface Field {
      id: number
      created_at: string
      updated_at: string
      name: string
      type: string
      headline: boolean
      required: boolean
      show_grid: boolean
      calendar_field: boolean
      dropdown_options: null
      created_by: number
      updated_by: number
      spreadsheet: number
      created_by_id: number
      updated_by_id: number
      spreadsheet_id: number
    }

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + `admin/spreadsheets/${spreadsheet_id}?include[]=fields.*`)
    return data
  }
}
export const spreadsheet_rows = {
  by_spreadsheet: async (spreadsheet_id: number) => {
    interface HTTPResponse {
      data: SpreadsheetRow[]
    }

    interface SpreadsheetRow {
      id: number
      spreadsheet_datas: SpreadsheetData[]
      created_by: string
      created_at: string
      updated_at: string
      updated_by: null
      spreadsheet_id: number
      updated_by_id: null
      calendar_value: string
      headline_value: string
      spreadsheet_datas_dict: { [key: number]: SpreadsheetData }
    }

    interface SpreadsheetData {
      id: number
      field_type: string
      field_id: number
      field_headline: boolean
      field_show_grid: boolean
      field_calendar_field: boolean
      created_at: string
      updated_at: string
      line_text: null | string
      long_text: null
      checkbox: null
      dt: null
      dt_time: null | string
      time: null
      number_int: null
      number_float: null
      dropdown: null
      object_id: null
      created_by: number
      updated_by: null
      user: null
      object_type: null
      spreadsheet_row: number
      spreadsheet_field: number
      created_by_id: number
      updated_by_id: null
      user_id: null
      object_type_id: null
      spreadsheet_row_id: number
      spreadsheet_field_id: number
    }

    const { data } = await apiAxios.get<HTTPResponse>(
      enviroment().apiUrl + `admin/spreadsheet-rows?include[]=created_by&include[]=spreadsheet_datas.*&filter{spreadsheet}=${spreadsheet_id}`)
    return data.data
  },
  post: async (values: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/spreadsheet-rows`, values)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  patch: async ({ id, ...values }: any) => {
    try {
      const { data } = await apiAxios.patch(enviroment().apiUrl + `admin/spreadsheet-rows/${id}`, values)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}