import {AgGridReact} from 'ag-grid-react'
import React from 'react'
import {withRouter} from 'react-router-dom'
import swal from 'sweetalert2'
import ActivityLoading from '../../components/Loading'
import PageBase from '../../components/PageBase'
import {qs} from '../../queries'
import {push_toast} from '../../utils/common'
import {getCondoLabors} from '../../utils/DataService'
import {removeAccents} from '../../utils/Formatters'
import {gridDefaults} from '../../utils/gridDefaults'

class CondoLaborListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },

      QuickFilterText: null,
      loading: false,

      columnDefs: [
        {
          headerName: 'Nome',
          field: 'name',
          width: 100,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Ações',
          width: 150,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellRendererFramework: (params) => {
            return (
              <button className="ui button basic compact green"
                      onClick={() => params.context.componentParent.handleAction(params.data)}>Entrar</button>
            )
          }
        }
      ]
    }
  }

  OnQuickFilterText = (event) => {
    this.setState({ QuickFilterText: event })
  }

  onGridReady = (params) => {
    this.GridApi = params.api

    this.setState({ loading: true })

    getCondoLabors()
      .then(res => {
        params.api.setRowData(res)
        params.api.sizeColumnsToFit()
        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ loading: false })
      })

  }

  handleAction = (params) => {
    this.setState({ loading: true })

    const data = {
      person: params.id,
      access_reason: 11,
      type: 1,
      status: 3,
      go_condo: true
    }

    qs.access_logs.createEntrance(data)
      .then((res) => {
        getCondoLabors()
          .then(res => {
            this.GridApi.setRowData(res)
            this.GridLogApi.sizeColumnsToFit()
            this.setState({ loading: false })
          })
          .catch(err => {
            this.setState({ loading: false })
          })

        push_toast('Salvo com sucesso!', 'success')
      })
      .catch(() => {
        this.setState({ loading: false })
        swal({ type: 'error', title: 'Erro na operação!' })
      })
  }

  render() {
    return (
      <PageBase title="Colaboradores Condomínio" create={false} onFilterChange={this.OnQuickFilterText}>
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.QuickFilterText}
                       onGridReady={this.onGridReady}
                       pagination={true}
                       domLayout={'autoHeight'}
          />
        </div>
        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}

export default withRouter(CondoLaborListPage)