import {AgGridEvent, GridOptions} from 'ag-grid'
import {AgGridReact} from 'ag-grid-react'
import {Formik, FormikProps} from 'formik'
import {Toc} from 'material-ui-icons'
import MenuItem from 'material-ui/MenuItem'
import React, {useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Button, Form, Input, Modal} from 'semantic-ui-react'
import * as Yup from 'yup'
import {hasPerm} from '../../../components/acl'
import {FF, FormikCheckLabel, FormikDate, FormikInput, FormikSelect, FormikTextArea} from '../../../components/form/FormInputs'
import {qs} from '../../../queries'
import {SpreadsheetDataModel, SpreadsheetFieldModel, SpreadsheetModel, SpreadsheetRowModel} from '../../../typings'
import {gridDefaults} from '../../../utils/gridDefaults'

export const SpreadsheetMenu = () => {
  const { data } = useQuery('getSpreadsheets', () => qs.spreadsheets.all(), {
    staleTime: 1000 * 60 * 240
  })
  const [current, setCurrent] = useState<number | null>(null)

  const closeAction = () => {
    setCurrent(null)
  }

  return (
    <React.Fragment>
      <div style={{ background: '#051c2e' }}>
        {data?.map(i => (
          <MenuItem
            key={i.id}
            className={'menu-items'}
            style={{ color: '#ffffff', fontSize: 13, padding: '0px' }}
            primaryText={i.name}
            leftIcon={<Toc/>}
            onClick={() => setCurrent(i.id)}
          />
        ))}
      </div>

      {current && <SpreadsheetModal spreadsheet_id={current} closeAction={closeAction}/>}
    </React.Fragment>
  )
}

const SpreadsheetModal = ({ spreadsheet_id, closeAction }: { spreadsheet_id: number, closeAction: () => void }) => {
  const spreadsheet = useQuery(['getSpreadsheet', spreadsheet_id], () => qs.spreadsheets.get(spreadsheet_id))
  const spreadsheet_rows = useQuery(['getSpreadsheetRows', spreadsheet_id], () => qs.spreadsheet_rows.by_spreadsheet(spreadsheet_id))

  const [quick_filter, setQuickFilter] = useState<string>('')
  const [show_create_edit_modal, setCreateEditShowModal] = useState<boolean>(false)
  const [current_row, setCurrentRow] = useState<SpreadsheetRowModel | null>(null)

  const qc = useQueryClient()

  const allow_create_edit = spreadsheet?.data?.allow_gater_create_edit &&
    hasPerm(['spreadsheet.add_spreadsheet', 'spreadsheet.change_spreadsheet'])

  useEffect(() => {
    // if (spreadsheet_id) {
    //   spreadsheet.refetch()
    //   spreadsheet_rows.refetch()
    // }
  }, [spreadsheet_id])

  const is_loading = spreadsheet.isLoading || spreadsheet_rows.isLoading
  const grid_options: GridOptions = {
    ...gridDefaults.adminGrid,
    rowHeight: 60
  }

  const columnDefs = () => {
    let columnDefs: any = []

    if (spreadsheet.data?.fields) {
      for (let i of spreadsheet.data?.fields) {
        if (i.show_grid) {
          columnDefs.push(
            {
              headerName: i.name,
              field: i.type === 'dropdown_multiple' ? `spreadsheet_datas_dict.${i.id}.dropdown` : `spreadsheet_datas_dict.${i.id}.${i.type}`,
              width: 100,
              type: i.type === 'dt_time' ? 'dateTimeColumn' : i.type === 'dt' ? 'dateColumn' : 'textColumn'
            }
          )
        }
      }

      columnDefs.push(
        {
          headerName: 'Ações',
          width: 80,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellClass: 'ag-button-cell',
          cellRendererFramework: (_props) => {
            return (
              <button
                className="ui icon button basic compact primary"
                onClick={() => {
                  setCurrentRow(_props.node.data)
                  setCreateEditShowModal(true)
                }}
              >
                <i className="pencil icon"/>
              </button>
            )
          }
        }
      )
    }

    return columnDefs
  }

  const onGridReady = (params: AgGridEvent) => {
    params.api.sizeColumnsToFit()
  }

  const close = async () => {
    await qc.removeQueries(['getSpreadsheet', spreadsheet_id])
    await qc.removeQueries(['getSpreadsheetRows', spreadsheet_id])

    closeAction()
  }

  const handleCloseCreateEditModal = () => {
    setCurrentRow(null)
    setCreateEditShowModal(false)
    spreadsheet_rows.refetch()
  }

  return (
    <React.Fragment>
      <Modal size={'fullscreen'} open={true}>
        <Modal.Header>
          <div className="page-modal-header">
            <div>{spreadsheet.data?.name}</div>

            <div className="page-modal-buttons">
              <Input
                icon="search" size="mini" placeholder="Filtrar..." className="page-modal-search"
                onChange={event => setQuickFilter(event.target.value)}
              />
              <Button negative onClick={close}>Fechar</Button>
              {allow_create_edit &&
                <Button
                  primary
                  onClick={() => {
                    setCurrentRow(null)
                    setCreateEditShowModal(true)
                  }}
                >
                  Novo
                </Button>}
            </div>
          </div>
        </Modal.Header>

        <Modal.Content scrolling>
          {!is_loading &&
            <React.Fragment>
              <div style={{ width: '100%' }} className="ag-theme-material">
                <AgGridReact
                  gridOptions={grid_options}
                  columnDefs={columnDefs()}
                  rowData={spreadsheet_rows.data ?? []}
                  quickFilterText={quick_filter}
                  onGridReady={onGridReady}
                  getRowNodeId={(data) => data.id}/>
              </div>

              {show_create_edit_modal && spreadsheet.data &&
                <CreateEditModal
                  current={current_row}
                  current_spreadsheet={spreadsheet.data}
                  handleClose={handleCloseCreateEditModal}
                />}

            </React.Fragment>}

          {is_loading && <p>Carregando...</p>}
        </Modal.Content>
      </Modal>
    </React.Fragment>
  )
}

type CreateEditModalPropsType = {
  current: SpreadsheetRowModel | null,
  current_spreadsheet: SpreadsheetModel | any
  handleClose: () => void
}

const CreateEditModal = (props: CreateEditModalPropsType) => {
  const create = useMutation((values: any) => qs.spreadsheet_rows.post(values), {
    onSuccess: () => {props.handleClose()}
  })
  const update = useMutation((values: any) => qs.spreadsheet_rows.patch(values), {
    onSuccess: () => {props.handleClose()}
  })

  const is_loading = create.isLoading || update.isLoading

  const allow_create_edit = props.current_spreadsheet?.allow_gater_create_edit &&
    hasPerm(['spreadsheet.add_spreadsheet', 'spreadsheet.change_spreadsheet'])

  const getFieldValue = (field: SpreadsheetDataModel) => {
    switch (field.field_type) {
      case 'line_text':
      case 'select':
      case 'phone':
      case 'email':
      case 'url':
        return field.line_text
      case('long_text'):
        return field.long_text
      case('checkbox'):
        return field.checkbox
      case('dt'):
        return field.dt
      case('dt_time'):
        return field.dt_time
      case('time'):
        return field.time
      case('dropdown'):
      case('dropdown_multiple'):
        return field.dropdown
    }
  }

  const initialValues = () => {
    let schema: { [index: string]: any } = {}

    // First set all field as blank
    if (props.current_spreadsheet?.fields) {
      for (let i of props.current_spreadsheet.fields) {
        schema[String(i.id)] = ''
      }
    }

    if (props.current) {
      for (let i of props.current.spreadsheet_datas) {
        let field_value = getFieldValue(i)

        if (field_value !== undefined && field_value !== null) {
          schema[String(i.field_id)] = field_value
        }
      }
    }

    return schema
  }

  const validationSchema = () => {
    let schema: { [index: string]: any } = {}

    if (props.current_spreadsheet?.fields) {
      for (let i of props.current_spreadsheet.fields) {
        if (i.required) {
          schema[String(i.id)] = Yup.string().required()
        }
      }
    }

    return Yup.lazy(values => (
      Yup.object().shape(schema)
    ))
  }

  const onSubmit = (values: { [K in keyof SpreadsheetRowModel]?: SpreadsheetRowModel[K] }) => {
    try {
      if (allow_create_edit) {
        let items: any = []

        for (let i of props.current_spreadsheet!.fields) {
          let field_index = i.type
          // @ts-ignore
          let field_value = values[i.id]

          if (i.type === 'dropdown') field_value = [field_value]
          if (i.type === 'dropdown_multiple') field_index = 'dropdown'

          if (i.type === 'dropdown') {
            if (Array.isArray(field_value)) {
              field_value = field_value.flat(Infinity).filter(i => !!i)
            } else {
              field_value = null
            }
          }

          let field_data = {
            [field_index]: field_value,
            spreadsheet_field: i.id
          }

          console.log(field_data)

          if (props.current?.spreadsheet_datas) {
            let existing_id = props.current.spreadsheet_datas.find(d => d.field_id === i.id)

            if (existing_id !== undefined) {
              field_data['id'] = existing_id.id
            }
          }

          items.push(field_data)
        }

        let data = {
          items: items,
          spreadsheet: props.current_spreadsheet!.id
        }

        if (props.current) {
          update.mutate({ ...data, id: props.current.id })
        } else {
          create.mutate(data)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const renderField = (field: SpreadsheetFieldModel) => {
    switch (field.type) {
      case 'line_text':
      case 'select':
      case 'phone':
      case 'email':
      case 'url':
        return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikInput}/>
      case('long_text'):
        return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikTextArea}/>
      case('checkbox'):
        return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikCheckLabel}/>
      case('dt'):
        return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikDate}/>
      case('dt_time'):
        return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikDate} has_time={true}/>
      // case('time'):
      // return <FF key={field.id} label={`${field.name}`} name={`${field.id}`} component={DatePickr} mode={'time'} dateFormat='HH:mm'/>
      case('dropdown_multiple'):
        return <FF
          key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikSelect}
          multiple
          options={field.dropdown_options.map(i => ({ value: i, text: i }))}
        />
      case('dropdown'):
        return <FF
          key={field.id} label={`${field.name}`} name={`${field.id}`} component={FormikSelect}
          options={field.dropdown_options.map(i => ({ value: i, text: i }))}
        />
    }
  }

  return (
    <>
      <Modal open={true} size="mini">
        <Modal.Header>{props.current ? `Editar` : 'Novo'}</Modal.Header>
        <Formik
          key={'row_form'}
          initialValues={initialValues()}
          validationSchema={validationSchema()}
          onSubmit={onSubmit}
        >
          {(formikBag: FormikProps<{ [K in keyof SpreadsheetRowModel]?: SpreadsheetRowModel[K] }>) => (
            <React.Fragment>
              <Modal.Content>
                <Form error>
                  <React.Fragment>
                    {props.current_spreadsheet && props.current_spreadsheet.fields.map(field => renderField(field))}
                  </React.Fragment>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button icon="cancel" color="red" onClick={props.handleClose} content="Fechar"/>
                {allow_create_edit &&
                  <Button icon="save" primary onClick={formikBag.submitForm} content="Salvar" loading={is_loading} disabled={is_loading}/>
                }
              </Modal.Actions>
            </React.Fragment>
          )}
        </Formik>
      </Modal>
    </>
  )
}