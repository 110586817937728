import * as React from "react";
import {useEffect} from "react";
import {Modal, Progress} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {show_modal} from "../utils/Formatters";

const DownloadProgress = () => {
    // @ts-ignore
    const download_status = useSelector(state => state.system.download_status)

    let show = download_status?.percent_complete > 0 && download_status?.is_complete === false

    useEffect(() => {
        if (download_status?.percent_complete === 100 && download_status?.is_complete === true) {
            show_modal(`Arquivo baixado com sucesso! Local do arquivo: ${download_status.full_path}`, 'success')
        }

    }, [download_status?.is_complete])

    return (
        <React.Fragment>
            <Modal basic open={show} size='small' dimmer='inverted'>
                <Modal.Content>
                    <Progress percent={download_status?.percent_complete} indicating progress color='green' label='Baixando... '/>
                </Modal.Content>
            </Modal>
        </React.Fragment>
    )
}

export default DownloadProgress