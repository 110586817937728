import 'ag-grid-enterprise'
import { Formik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import {Button, Form, Message, Modal} from 'semantic-ui-react'
import swal from 'sweetalert2'
import { FF, FormikInput } from '../../components/form/FormInputs'
import ActivityLoading from '../../components/Loading'
import { history } from '../../redux/store/configureStore'
import ToasterService from '../../services/ToasterService'
import { postApi } from '../../utils/api'
import { getValue } from '../../utils/Formatters'
import WorkShiftGrid from './WorkShiftGrid'

class WorkShiftListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentItem: null,
      currentViewItem: null,
      loading: false,
      openModal: false,
      openModalOpen: false,
      openModalView: false
    }
  }

  handleClose = () => {
    this.setState({ openModal: false })
  }

  handleOpen = (data) => {
    this.setState({ openModal: true, currentItem: data })
  }

  handleView = (data) => {
    this.setState({ openModalView: true, currentViewItem: data })
  }

  render() {
    return (
      <div>
        <h1 className="ui huge header">
          Passagem de Turno

          &nbsp;
          &nbsp;
          <Button primary size="small" content="Abrir Turno" icon="plus" onClick={() => this.setState({ openModalOpen: true })}/>
        </h1>

        <div className="ui divider hidden"/>

        <WorkShiftGrid componentParent={this}/>

        <Modal open={this.state.openModal} size={'small'}>
          <Modal.Header>Encerrar Turno</Modal.Header>
          <Modal.Content>
            <Formik
              initialValues={{
                next_worker: ''
              }}
              validate={values => {
                let errors = {}
                if (!values.next_worker) errors.next_worker = 'Digite o nome'
                return errors
              }}
              onSubmit={(values) => {
                this.setState({ loading: true })

                postApi(`admin/work-shifts/${this.state.currentItem.id}/close`, values).then((res) => {
                  swal({ type: 'success', title: 'Turno encerrado!' })

                  this.setState({ loading: false, openModal: false })
                  history.push('/login')

                }).catch((err) => {

                  this.setState({ loading: false })
                  ToasterService.sendErrors(err)
                })

              }}
              render={({ submitForm }) => (
                <Form className="ui form large error">
                  <Message info size="small" icon="info" header="Ao encerrar o turno, você será redirecionado para tela de login."/>

                  <br/>
                  <br/>

                  <FF label={'Colaborador do próximo turno'} name="next_worker" component={FormikInput}/>

                  <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                  <button className="ui red button right floated large" onClick={this.handleClose}>Cancelar</button>

                  <div className="ui hidden divider"/>
                  <div className="ui hidden divider"/>
                </Form>
              )}
            />
          </Modal.Content>
        </Modal>

        <Modal open={this.state.openModalOpen} size={'small'}>
          <Modal.Header>Abrir Turno</Modal.Header>
          <Modal.Content>
            <Formik
              initialValues={{
                name: ''
              }}
              validate={values => {
                let errors = {}
                if (!values.name) errors.name = 'Digite o nome'
                return errors
              }}
              onSubmit={(values) => {
                this.setState({ loading: true })

                postApi(`admin/work-shifts`, values).then((res) => {
                  swal({ type: 'success', title: 'Turno aberto!' })

                  this.props.gridApi.purgeServerSideCache()
                  this.setState({ loading: false, openModalOpen: false })
                }).catch((err) => {

                  this.setState({ loading: false })
                  ToasterService.sendErrors(err)
                })

              }}
              render={({ values, errors, touched, handleChange, handleBlur, submitForm }) => (
                <Form className="ui form large error">

                  <div className={errors.name ? 'field error' : 'field'}>
                    <label>Seu Nome</label>
                    <input type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
                    {touched.name && errors.name &&
                    <h5 className="ui header red">{errors.name}</h5>}
                  </div>

                  <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                  <button className="ui red button right floated large" onClick={() => this.setState({ openModalOpen: false })}>
                    Cancelar
                  </button>

                  <div className="ui hidden divider"/>
                  <div className="ui hidden divider"/>
                </Form>
              )}
            />
          </Modal.Content>
        </Modal>

        <Modal open={this.state.openModalView} size={'small'}>
          <Modal.Header>Detalhes Turno</Modal.Header>
          <Modal.Content>
            <p><strong>Relatório</strong></p>

            <p style={{ whiteSpace: 'pre-line' }}>
              {getValue(this.state, 'currentViewItem.description')}
            </p>

            <div className="ui hidden divider"/>

            <button className="ui red button right floated small"
                    onClick={(data) => this.setState({ openModalView: false, currentViewItem: null })}>
              Fechar
            </button>

            <div className="ui hidden divider"/>
            <div className="ui hidden divider"/>
          </Modal.Content>
        </Modal>

        <ActivityLoading visible={this.state.loading}/>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    gridApi: state.grid_refs.work_shift
  }
}

export default connect(mapStateToProps)(WorkShiftListPage)
