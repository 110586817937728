import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import ActivityLoading from '../../components/Loading'
import { Modal } from 'semantic-ui-react'
import { getAccessDocs } from '../../utils/DataService'
import { dateFormat, getValue, removeAccents } from '../../utils/Formatters'
import { ACCESS_DOC_STATUS, ACCESS_DOC_TYPES } from '../../fields'
import { gridDefaults } from '../../utils/gridDefaults'

export default class AccessDocsListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },
      quickFilterText: null,
      currentViewItem: null,
      loading: false,
      openModalView: false,
      columnDefs: [
        {
          headerName: 'Tipo',
          field: 'type',
          width: 20,
          valueGetter: (params) => ACCESS_DOC_TYPES[params.data.type],
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 15,
          valueGetter: (params) => ACCESS_DOC_STATUS[params.data.status],
        },
        {
          headerName: 'Autorizado',
          field: 'person.name',
          width: 40,
          filter: 'text',
          getQuickFilterText: removeAccents,
        },
        {
          headerName: 'Responsável',
          field: 'authorizer.name',
          width: 40,
          filter: 'text',
          getQuickFilterText: removeAccents,
        },
        {
          headerName: 'Unidade',
          field: 'unit.with_block',
          width: 30,
          filter: 'agSetColumnFilter',
          getQuickFilterText: removeAccents,
        },
        {
          headerName: 'Expiração',
          field: 'expiration',
          width: 20,
          valueGetter: (params) => dateFormat(params, 'expiration'),
        },
        {
          headerName: 'Ações',
          width: 20,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          cellRendererFramework: this.actionsFormat
        },
      ],
      getRowNodeId: function (data) {
        return data.id
      }
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  actionsFormat = (params) => {
    return (
      <button className="ui button basic compact primary" onClick={() => {params.context.componentParent.handleView(params.data)}}>
        Detalhes
      </button>
    )
  }

  onGridReady = (params) => {
    this.setState({loading: true})

    this.gridApi = params.api

    getAccessDocs()
      .then(data => {
        params.api.setRowData(data)
        this.setState({loading: false})
        params.api.sizeColumnsToFit()
      })
      .catch(err => {
        this.setState({loading: false})
      })
  }

  handleView = (data) => {
    this.setState({openModalView: true, currentViewItem: data})
  }

  render() {
    return (
      <PageBase title="Termos de Autorização" onFilterChange={this.onQuickFilterText} create={''}>
        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady.bind(this)}
                       pagination={true}
                       paginationPageSize={25}
                       domLayout={'autoHeight'}
                       getRowNodeId={this.state.getRowNodeId}
          />
        </div>

        <Modal open={this.state.openModalView} size={'small'}>
          <Modal.Header>Detalhes</Modal.Header>
          <Modal.Content>
            <p><strong>Observações</strong></p>
            <p>
              {getValue(this.state, 'currentViewItem.description')}
            </p>

            <div className="ui hidden divider"/>

            <button className="ui red button right floated small"
                    onClick={(data) => this.setState({openModalView: false, currentViewItem: null})}>
              Fechar
            </button>

            <div className="ui hidden divider"/>
            <div className="ui hidden divider"/>
          </Modal.Content>
        </Modal>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
