/*eslint-disable no-undef*/
import moment from 'moment'
import React from 'react'
    import { connect } from 'react-redux'
import { Dropdown, Message, Table } from 'semantic-ui-react'
import swal from 'sweetalert2'
import { ACCESSDRIVER_TYPES } from '../../fields'
import { qs } from '../../queries'
import { actionMap as devices_actions } from '../../redux/modules/devices'
import { giv } from '../../utils/common'
import { hasValue } from '../../utils/Formatters'
import ActivityLoading from '../Loading'
import CardEnroll from './CardEnroll'
import FingerprintEnroll from './FingerprintEnroll'
import { PersonDataModel } from '../../data-model/PersonDataModel'

class BioCardManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      bio_modal: false,
      pwd_modal: false,
      card_modal: false,
      loading: false,
      data: {},
      access_drivers: []
    }
  }

  componentDidMount() {
    this.getPersonAccessDrivers()
    this.props.accessGroup(this.getAccessGroup())
    // Used for syncBio
    this.props.bioTemplate('')
    this.props.bioTemplateZK9('')
    this.props.bioIndex('')
  }

  getAccessGroup = () => {
    let master_access_group = JSON.parse(JSON.stringify(this.props.access_group))
    let slave_access_devices_ids = giv(this.props.slave_access_group, 'access_devices_ids', [])

    if (master_access_group) {
      let all_access_devices_ids = [...master_access_group.access_devices_ids, ...slave_access_devices_ids]

      master_access_group.access_devices_ids = [...new Set(all_access_devices_ids)]
    }

    console.log('master_access_group', master_access_group)

    return master_access_group
  }

  getPersonAccessDrivers = () => {
    this.setState({ loading: true })

    qs.access_drivers.filterByPersonId(this.props.resident.id)
      .then((res) => {
        this.setState({ loading: false })
        this.setState({ access_drivers: res })
      })
      .catch((err) => {
        this.setState({ loading: false })
      })
  }

  getEquipmentUserId = () => {
    if (this.props.resident.id_in_equipment) {
      return this.props.resident.id_in_equipment
    } else {
      return this.props.resident.obj_number
    }
  }

  deleteBio = (bio_index) => {
    new PersonDataModel(this.props.resident).local_deleteBio(bio_index)
      .then(res => {
        if (bio_index > 2) {
          return
        }

        // Delete in server
        this.setState({ loading: true })

        let driver = {
          person_id: this.props.resident.id,
          type: 5
        }

        qs.access_drivers.delete(driver)
          .finally((res) => {
            this.getPersonAccessDrivers()
            this.setState({ loading: false })
          })

      })
      .catch(err => {
        swal({ type: 'error', title: 'Verifique os erros para que seja possível excluir o acionador do servidor!' })
      })
  }

  delete_all_data = () => {
    new PersonDataModel(this.props.resident).local_deleteAllData()
  }

  deleteCard = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        console.log(driver)

        if (hasValue(driver, 'id') && hasValue(driver, 'code') && hasValue(driver, 'person_id')) {

          new PersonDataModel(this.props.resident).local_deleteCard(driver.code)
            .then(res => {
              // Delete in server
              this.setState({ loading: true })

              driver['person_id'] = this.props.resident.id

              qs.access_drivers.delete(driver)
                .finally((res) => {
                  this.getPersonAccessDrivers()
                  this.setState({ loading: false })
                })

            })
            .catch(err => {
              swal({ type: 'error', title: 'Verifique os erros para que seja possível excluir o acionador do servidor!' })
            })

        } else {
          swal({ type: 'error', title: 'Erro no código do acionador de acesso' })
        }
      }
    })
  }

  handleSync = (item) => {
    if (item.type === 5) {
      let raw = item.raw
      let template = ''
      let template2 = ''

      console.log('Template Initial')
      console.log(raw)
      console.log(typeof raw)

      raw = JSON.parse(raw.replace(/'/g, '"'))

      console.log('Template Parsed')
      console.log(raw)
      console.log(typeof raw)

      if (typeof raw === 'object' && raw.v10) {
        template = raw.v10
        template2 = raw.v9
      } else {
        template = JSON.stringify(raw)
      }

      this.syncBio(template, template2)

      return true
    }

    if (item.type === 3) {
      let values = {
        code: item.code,
        facility_code: item.facility_code,

        access_group: this.getAccessGroup(),
        is_guest: false
      }

      console.log('Card values')
      console.log(values)

      this.syncCard(values)

      return true
    }
  }

  syncBio = (template, template2) => {
    swal({
      title: 'Tem certeza que deseja sincronizar essa digital?',
      text: 'Dependendo do modelo do equipamento a digital anterior poderá ser substituída!',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        this.props.bioTemplate(template)
        this.props.bioTemplateZK9(template2)
        this.props.bioIndex(1)

        setTimeout(() => {
          this.props.bioEnrollClient()
        }, 900)
      }
    })
  }

  syncCard = (values) => {
    swal({
      title: 'Tem certeza que deseja sincronizar esse cartão?',
      text: 'Dependendo do modelo do equipamento o cartão anterior poderá ser substituída!',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value === true) {
        new PersonDataModel(this.props.resident).local_cardEnrollClient(
          values.code,
          values.facility_code,
          values.access_group,
          values.is_guest
        )
      }
    })
  }

  render() {
    let access_group = this.getAccessGroup()

    return (
      <React.Fragment>
        <Message
          info icon="question"
          header="Dica!"
          list={[
            'Somente controles são exibidos na tabela abaixo',
            'Para excluir uma digital, use o botão grande vermelho "Excluir"',
            'Para equipamentos Biométrico da Linear, ao excluir qualquer cartão irá remover o acesso via cartão, pois o equipamento permite somente um cartão por usuário.'
          ]}
        />

        {!this.props.resident.access_group_id &&
          <Message error icon="ban"
                   header="É necessário configurar a ROTA DE ACESSO!"
                   content=""/>
        }

        {this.props.resident.access_group_id &&
          <React.Fragment>
            {this.props.person_status && <Dropdown text="Cadastrar" icon="plus" floating labeled button upward className="icon big primary">
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.setState({ bio_modal: true })}>Cadastrar Digital</Dropdown.Item>
                {/*<Dropdown.Item onClick={() => this.setState({pwd_modal: true})}>Cadastrar Senha</Dropdown.Item>*/}
                <Dropdown.Item onClick={() => this.setState({ card_modal: true })}>Cadastrar Cartão/Chaveiro</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>}
            <Dropdown text="Excluir" icon="delete" floating labeled button upward className="icon big red">
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.deleteBio(1)}>
                  Excluir Digital 1
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.deleteBio(2)}>
                  Excluir Digital 2
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.deleteBio(4)}>
                  Excluir Senha
                </Dropdown.Item>
                <Dropdown.Item onClick={() => this.delete_all_data()}>
                  Excluir todos dados
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        }


        <br/>
        <h2>Acionadores Cadastrados</h2>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data de Cadastro</Table.HeaderCell>
              <Table.HeaderCell>Última atualização</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.access_drivers.length > 0 && this.state.access_drivers.filter(i => i.type === 3 || i.type === 5).map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.type === 3 && item.code}
                    {item.type === 5 && <>Id: {item.id}</>}
                  </Table.Cell>
                  <Table.Cell>
                    {ACCESSDRIVER_TYPES[item.type]}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    {item.type === 3 &&
                      <button className="ui mini basic icon button red" onClick={() => this.deleteCard(item)}>
                        <i className="trash icon"/>
                      </button>}

                    <button className="ui mini basic icon button blue" onClick={() => this.handleSync(item)}>
                      <i className="upload icon"/>
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>


        {this.state.card_modal &&
          <CardEnroll
            parent={this}
            person={this.props.resident}
            resident={true}
            access_group={access_group}
            setClose={() => this.setState({ card_modal: false })}
            getPersonAccessDrivers={this.getPersonAccessDrivers}
          />}

        {this.state.bio_modal &&
          <FingerprintEnroll parent={this} person={this.props.resident} access_group={access_group}/>}

        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return {
    accessGroup: (data) => dispatch(devices_actions.access_group(data)),
    bioTemplate: (data) => dispatch(devices_actions.bio_template(data)),
    bioTemplateZK9: (data) => dispatch(devices_actions.bio_template_zk9(data)),
    bioIndex: (data) => dispatch(devices_actions.bio_index(data)),
    bioEnrollClient: () => dispatch(devices_actions.bio_enroll_client())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BioCardManager)
