export const BOOK_STATUS = {
  0: 'Cancelada',
  1: 'Aprovada',
  2: 'Pendente',
}

export const CONSTRUCTION_STATUS = {
  0: 'Recusado',
  1: 'Aprovada',
  2: 'Pendente',
}

export const CONSTRUCTION_MANAGER = {
  0: 'Não',
  1: 'Arquiteto',
  2: 'Engenheiro',
}

export const CONTRACT_STATUS = {
  1: 'Ativo',
  0: 'Inativo',
}

export const CONTRACT_TYPES = {
  1: 'Mensal',
  2: 'Avulso',
}

export const DOCUMENT_STATUS = {
  1: 'Publicado',
  2: 'Rascunho',
}

export const INFORMATION_STATUS = {
  1: 'Publicado',
  2: 'Rascunho',
}

export const LOCKER_STATUS = {
  0: 'Inativo',
  1: 'Ativo',
  2: 'Alugado',
  3: 'Disponível para aluguel',
}

export const MAILING_STATUS = {
  1: 'Aguardando',
  2: 'Entregue',
}

export const LOST_STATUS = {
  1: 'Pendente',
  2: 'Devolvido',
  3: 'Dono não localizado',
}

export const MOVING_STATUS = {
  1: 'Aprovada',
  2: 'Pendente',
  3: 'Recusada',
}

export const MOVING_TYPES = {
  1: 'Entrada',
  2: 'Saída',
}

export const NOTICE_STATUS = {
  1: 'Publicado',
  2: 'Rascunho',
}

export const PARKING_TYPES = {
  1: 'Simples',
  2: 'Dupla',
}

export const PARKING_STATUS = {
  1: 'Ativo',
  2: 'Alugado',
  3: 'Disponível para Aluguel',
}

export const PATRIMONY_STATUS = {
  1: 'Quitado',
  2: 'Em pagamento',
}

export const PENALTY_STATUS = {
  1: 'Emitida',
  2: 'Em Análise',
  3: 'Aprovada',
  4: 'Recusada',
}

export const PENALTY_TYPES = {
  1: 'Multa',
  2: 'Advertência',
}

export const SPACE_STATUS = {
  1: 'Ativo',
  0: 'Inativo',
  2: 'Em manutenção',
}

export const SPACE_TAX_TYPES = {
  1: 'Percentual Salário Mínino',
  2: 'Valor Fixo',
}

export const TICKET_STATUS = {
  1: 'Realizado',
  2: 'Pendente',
  3: 'Em andamento',
  4: 'Outros',
}

export const TICKET_TYPES = {
  1: 'Problema',
  2: 'Dúvida',
  3: 'Sugestão',
  4: 'Interno (oculto para moradores)',
}

export const TICKET_PRIORITIES = {
  1: 'Normal',
  2: 'Baixa',
  3: 'Alta',
  4: 'Urgente',
}

export const VEHICLE_TYPES = {
  1: 'Carro',
  2: 'Moto',
  3: 'SUV',
  4: 'Picape',
}

export const OBJECT_CONTROL_STATUS = {
  1: 'Retirado',
  2: 'Devolvido',
}

export const ACCESSLOG_STATUS = {
  0: 'Recusado',
  1: 'Saída Concluída',
  2: 'Aguardando',
  3: 'Entrou',
  4: 'Saiu',
}

export const ACCESSLOG_TYPES = {
  1: 'Entrada',
  2: 'Saída',
}

export const ACCESSAUTH_TYPES = {
  1: 'Única',
  2: 'Período',
  3: 'Sempre liberado',
}

export const ACCESSAUTH_STATUS = {
  0: 'Recusado',
  1: 'Ativo',
  2: 'Aguardando',
  3: 'Concluído',
}

export const GUESTLIST_STATUS = {
  1: 'Aguardando',
  2: 'Entrou',
  3: 'Saiu',
}

export const ACCESSDRIVER_TYPES = {
  1: 'Controle',
  2: 'TAG Ativo',
  3: 'Cartão',
  5: 'Biometria',
  6: 'TAG Passivo',
  7: 'Senha',
  8: 'Face',
}

export const WORKSHIFT_STATUS = {
  0: 'Encerrado',
  1: 'Aberto',
}

export const ACCESS_DOC_STATUS = {
  1: 'Ativo',
  0: 'Inativo',
}

export const ACCESS_DOC_TYPES = {
  1: 'Acesso espaço',
  2: 'Atestado médico',
}

export const GUEST_STATUS = {
  1: 'Livre',
  0: 'Bloqueado',
}

export const MAILING_POUCH_STATUS = {
  0: 'Cancelado',
  1: 'Pendente',
  2: 'Entregue',
  3: 'Importado',
}
