import React from 'react'
import { removeAccents } from '../utils/Formatters'
import { Grid } from 'semantic-ui-react'

export default class PageBase extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    this.props.onFilterChange(removeAccents(e.target))
  }

  render() {
    return (
      <div>
        <Grid stackable verticalAlign='middle'>
          <Grid.Column largeScreen={4} widescreen={16}>
            <h1 className="ui huge header" style={{paddingTop: 15}}>{this.props.title}</h1>
          </Grid.Column>
          <Grid.Column largeScreen={12} widescreen={16} textAlign='right'>
            <div className={'page-tools'}>
              {
                this.props.showFilter !== false &&
                <div className="ui icon input big" style={{marginRight: 8, marginTop: 2}}>
                  <input onChange={this.handleChange} placeholder="Filtrar..."/>
                  <i className="inverted circular search link icon"/>
                </div>
              }
              {this.props.create}
            </div>
          </Grid.Column>
        </Grid>

        <div className="ui divider hidden"/>
        <div className="ui raised segment">
          {this.props.children}
          <div className="ui divider hidden" style={{clear: 'both', margin: 0}}/>
        </div>
      </div>
    )
  }
};