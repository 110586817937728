import { Formik } from 'formik'
import React from 'react'
import { Button, Form, Image, Message, Modal } from 'semantic-ui-react'
import { GuestModel } from '../../typings'
import { ProfileCache } from '../Utils'

type Props = {
  setClose: () => void
  handleAssign: (data: any) => void
  person: GuestModel
}

export const GuestFaceEnroll = (props: Props) => {
  return (
    <Modal open={true} size="small">
      <Modal.Header>Vincular Face</Modal.Header>
      <Formik
        initialValues={{
          file_id: props.person.file_id,
          // access_group: '',
          // access_group_id: ''
        }}
        validate={values => {
          let errors: any = {}
          // if (!values.access_group_id) errors.access_group_id = 'Selecione'
          if (!values.file_id) errors.file_id = true
          return errors
        }}
        onSubmit={(values) => {
          props.handleAssign(values)
        }}
        render={({ submitForm, errors }) => (
          <React.Fragment>
            <Modal.Content>
              <Form>
                <Form.Group widths="equal">
                  <Image size="medium">
                    <ProfileCache src={props.person.photo_url}/>
                  </Image>

                  {/*<AccessGroupSelect label="Rota" name={'access_group_id'}/>*/}
                </Form.Group>

                {errors.file_id && <Message icon='ban' negative header='Nenhuma face cadastrada!'/>}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color="red" size="large" onClick={props.setClose} content="Fechar"/>
              <Button primary size="large" onClick={submitForm} content="Vincular"/>
            </Modal.Actions>
          </React.Fragment>
        )}
      />
    </Modal>
  )
}