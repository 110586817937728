import React from 'react'
import { Link } from 'react-router-dom'
import PageBase from '../../components/PageBase'
import { Formik } from 'formik'
import { postApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import { show_modal } from '../../utils/Formatters'
import { FF, FormikInput } from '../../components/form/FormInputs'
import { DatePickr } from '../../components/form/DatePickr'
import { Form } from 'semantic-ui-react'
import { WebcamCaptureUploader } from '../../components/WebcamCapture'
import ToasterService from '../../services/ToasterService'

export default class LostFormPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
    }
  }

  render() {
    return (
      <PageBase title="Novo Achado & Perdido" showFilter={false}>
        <div>
          <Formik
            ref={node => (this.form = node)}
            initialValues={{
              name: '',
              found_date: '',
              found_place: '',
              status: 1,
              files_attach: [],
            }}
            validate={values => {
              let errors = {}

              if (!values.name) errors.name = 'Digite a descrição do objeto'
              if (!values.found_date) errors.found_date = 'Campo obrigatório'
              if (!values.found_place) errors.found_place = 'Campo obrigatório'
              return errors
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              postApi('admin/losts', values).then((res) => {
                this.setState({ loading: false })
                this.props.history.push('/admin/losts')
                show_modal('Item salvo!', 'success')
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })
            }}
            render={({ values, setFieldValue, setFieldTouched, submitForm }) => (
              <Form size="large" error>
                <FF label={'Descrição do Objeto'} name="name" component={FormikInput}/>

                <Form.Group widths="equal">
                  <FF label={'Local que foi encontrado'} name="found_place" component={FormikInput}/>

                  <Form.Field>
                    <label>Data que foi encontrado</label>
                    <DatePickr
                      name="found_date"
                      has_time={true}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </Form.Field>
                </Form.Group>

                <WebcamCaptureUploader values={values} setFieldValue={setFieldValue} size="medium"/>

                <button className="ui button primary right floated large" type="submit" onClick={submitForm}>Enviar</button>
                <Link to="/admin/losts">
                  <button className="ui red button right floated large">Cancelar</button>
                </Link>
              </Form>
            )}
          />

          <small>Ao salvar, será enviado uma notificação para todos os moradores.</small>
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}