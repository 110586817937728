import localForage from 'localforage'
import moment from 'moment'
import enviroment from '../enviroment'
import {apiAxios, fetchApi} from '../services/api'
import ToasterService from '../services/ToasterService'
import {push_toast} from '../utils/common'
import {show_modal_once} from '../utils/Formatters'
import {getCache, putCache} from '../utils/LocalApi'

export type ListRequestResult<T> = {
  data: T
}

export const requestWithCache = async (sync: boolean | 'bypass' = 'bypass', cache_path, url) => {
  let cached_data: any = null

  try {
    if (sync !== 'bypass') {
      cached_data = await getCache(cache_path)
    }
  } catch (e) {
    console.log('%c### CACHED ERROR-> ', e)
    cached_data = null
  }

  if (cached_data && sync === false) {
    return cached_data
  } else {
    return await fetchApi(url).then((res) => {
      if (sync !== 'bypass') {
        putCache(cache_path, res.data)
      }

      return res.data
    }).catch((err) => {
      if (cached_data) {
        show_modal_once(
          'Não foi possível recuperar os dados do servidor! \n Você está visualizando uma versão em cache. CUIDADO!',
          'error'
        )

        return Promise.resolve(cached_data)
      }

      return Promise.reject(err)
    })
  }
}

type FetchBaseParamsType = {
  key: string,
  url: string,
  stale_time: number
}

export const isStale = (last_date: string | null, stale_time: number): boolean => {
  if (last_date && stale_time) {
    const end = moment(last_date).add(stale_time, 'seconds')

    return end.isBefore(moment())
  }

  return true
}

const isForceStale = (key: string): boolean => {
  if (localStorage.getItem(key)) {
    localStorage.removeItem(key)
    return true
  }
  return false
}

const isStaleBySession = (last_date: string | null): boolean => {
  let session_date = localStorage.getItem('SESSION_STARTED_AT')

  if (session_date && last_date) {
    const end = moment(last_date)
    const start = moment(last_date)

    return end.isBefore(start)
  }

  return false
}

export const fetchBaseQuery = <ItemModel>(params: FetchBaseParamsType) => {
  const cache_key = `@${params.key}_FETCH@`
  const last_update_key = `@${params.key}_FETCH_LAST_UPDATE@`
  const force_key = `@${params.key}_FETCH_STALED@`

  console.log('fetchBaseQuery -> init url', params.url)

  const cacheUpdates = async (): Promise<ItemModel[]> => {
    let data_cached = await localForage.getItem<object>(cache_key) ?? {}
    let last_update = await localForage.getItem<string>(last_update_key)
    let url = params.url

    try {
      if (last_update) {
        last_update = moment(last_update).subtract(4, 'hours').toISOString()

        if (url.indexOf('?') > -1) {
          url += '&filter{updated_at.gte}=' + last_update

        } else {
          url += '?filter{updated_at.gte}=' + last_update
        }
      }

      console.log('cacheUpdates -> url', url)

      const { data } = await apiAxios.get(enviroment().apiUrl + url)

      const res_data = data.data

      if (res_data.length) {
        for (let o of res_data) {
          data_cached[o.id] = o
        }

        await localForage.setItem(cache_key, data_cached)
      }

      await localForage.setItem(last_update_key, moment().toISOString())

      return Object.values(data_cached).reverse()
    } catch (err) {
      ToasterService.sendErrors(err)
      console.error(err)

      if (data_cached) {
        push_toast('Não possível conectar no servidor Online do Condo Id. Você visualizando os DADOS EM CACHE. Cuidado!', 'warn')
        return Object.values(data_cached).reverse()
      } else {
        throw err
      }
    }
  }

  const allCached = async (force_update = false): Promise<ItemModel[]> => {
    let last_update = await localForage.getItem<string>(last_update_key)
    let is_stale = isStale(last_update, params.stale_time)
    let is_force_stale = isForceStale(force_key)
    let is_stale_by_session = isStaleBySession(last_update)

    console.log('allCached -> query key', params.key)
    console.log('allCached -> force_update', force_update)
    console.log('allCached -> last_update', last_update)
    console.log('allCached -> is_stale', is_stale)
    console.log('allCached -> is_force_stale', is_force_stale)
    console.log('allCached -> is_stale_by_session', is_stale_by_session)

    if (force_update || is_stale || is_force_stale || is_stale_by_session) {
      await cacheUpdates()
    }

    let data = await localForage.getItem<{ [index: number]: ItemModel }>(cache_key)

    if (!data) {
      return await cacheUpdates()
    }

    return Object.values(data).reverse()
  }

  const setAllStaled = () => {
    localStorage.setItem(force_key, 'true')
  }

  const getCachedData = async () => {
    return await localForage.getItem<{ [index: number]: ItemModel }>(cache_key)
  }

  allCached.setStaled = setAllStaled

  return {
    allCached,
    getCachedData
  }
}