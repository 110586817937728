import React, { Component } from 'react'
import { Button, Icon, Message } from 'semantic-ui-react'
import ReactDOM from 'react-dom'
import { Dashboard, DashboardModal } from '@uppy/react'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'
import { postApi } from '../services/api'

const Webcam = require('@uppy/webcam')
const AwsS3 = require('@uppy/aws-s3')

let worker
const locales = {
  addMore: 'Adicione mais',
  addMoreFiles: 'Adicionar mais arquivos',
  addingMoreFiles: 'Adicionando mais arquivos',
  allowAccessDescription:
    'Para poder tirar fotos e gravar vídeos com sua câmera, por favor permita o acesso a câmera para esse site.',
  allowAccessTitle: 'Por favor permita o acesso a sua câmera',
  authenticateWith: 'Conectar com %{pluginName}',
  authenticateWithTitle:
    'Por favor conecte com %{pluginName} para selecionar arquivos',
  back: 'Voltar',
  browse: 'navegue',
  browseFiles: 'navegue',
  cancel: 'Cancelar',
  cancelUpload: 'Cancelar envio de arquivos',
  chooseFiles: 'Selecionar arquivos',
  closeModal: 'Fechar Modal',
  companionError: 'Conexão com serviço falhou',
  complete: 'Concluído',
  connectedToInternet: 'Conectado á internet',
  copyLink: 'Copiar link',
  copyLinkToClipboardFallback: 'Copiar URL abaixo',
  copyLinkToClipboardSuccess: 'Link copiado para a área de transferência',
  creatingAssembly: 'Preparando envio de arquivos...',
  creatingAssemblyFailed: 'Transloadit: Não foi possível criar o Assembly',
  dashboardTitle: 'Envio de arquivos',
  dashboardWindowTitle:
    'Janela para envio de arquivos (Pressione esc para fechar)',
  dataUploadedOfTotal: '%{complete} de %{total}',
  done: 'Concluir',
  dropHereOr: 'Arraste arquivos aqui ou %{browse}',
  dropHint: 'Solte seus arquivos aqui',
  dropPasteBoth: 'Solte arquivos aqui, cole ou %{browse}',
  dropPasteFiles: 'Solte arquivos aqui, cole ou %{browse}',
  dropPasteFolders: 'Solte arquivos aqui, cole ou %{browse}',
  dropPasteImportBoth: 'Solte arquivos aqui, cole, %{browse} ou importe de',
  dropPasteImportFiles: 'Solte arquivos aqui, cole, %{browse} ou importe de',
  dropPasteImportFolders: 'Solte arquivos aqui, cole, %{browse} ou importe de',
  editFile: 'Editar arquivo',
  editing: 'Editando %{file}',
  emptyFolderAdded: 'Nenhum arquivo foi adicionado da pasta vazia',
  encoding: 'Codificando...',
  enterCorrectUrl:
    'URL incorreta: Por favor tenha certeza que inseriu um link direto para um arquivo',
  enterUrlToImport: 'Coloque a URL para importar um arquivo',
  exceedsSize: 'Esse arquivo excedeu o tamanho máximo permitido',
  failedToFetch:
    'Serviço falhou para buscar essa URL, por favor tenha certeza que a URL está correta',
  failedToUpload: 'Falha para enviar %{file}',
  fileSource: 'Origem do arquivo: %{name}',
  filesUploadedOfTotal: {
    0: '%{complete} de %{smart_count} arquivo enviado',
    1: '%{complete} de %{smart_count} arquivos enviados'
  },
  filter: 'Filtrar',
  finishEditingFile: 'Finalizar edição de arquivo',
  folderAdded: {
    0: 'Adicionado %{smart_count} arquivo de %{folder}',
    1: 'Adicionado %{smart_count} arquivos de %{folder}'
  },
  import: 'Importar',
  importFrom: 'Importar de %{name}',
  loading: 'Carregando...',
  logOut: 'Deslogar',
  myDevice: 'Meu dispositivo',
  noFilesFound: 'Você não possui arquivos ou pastas aqui',
  noInternetConnection: 'Sem conexão com a internet',
  pause: 'Pausar',
  pauseUpload: 'Pausar envio de arquivos',
  paused: 'Pausado',
  poweredBy: 'Desenvolvido por',
  processingXFiles: {
    0: 'Processando %{smart_count} arquivo',
    1: 'Processando %{smart_count} arquivos'
  },
  removeFile: 'Remover arquivo',
  resetFilter: 'Resetar filtro',
  resume: 'Retomar',
  resumeUpload: 'Retomar envio de arquivos',
  retry: 'Tentar novamente',
  retryUpload: 'Tentar enviar novamente',
  saveChanges: 'Salvar alterações',
  selectX: {
    0: 'Selecionar %{smart_count}',
    1: 'Selecionar %{smart_count}'
  },
  smile: 'Sorria!',
  startRecording: 'Começar gravação de vídeo',
  stopRecording: 'Parar gravação de vídeo',
  takePicture: 'Tirar uma foto',
  timedOut: 'Envio de arquivos parado por %{seconds} segundos, abortando.',
  upload: 'Enviar arquivos',
  uploadComplete: 'Envio de arquivos finalizado',
  uploadFailed: 'Envio de arquivos falhou',
  uploadPaused: 'Envio de arquivos pausado',
  uploadXFiles: {
    0: 'Enviar %{smart_count} arquivo',
    1: 'Enviar %{smart_count} arquivos'
  },
  uploadXNewFiles: {
    0: 'Enviar +%{smart_count} arquivo',
    1: 'Enviar +%{smart_count} arquivos'
  },
  uploading: 'Enviando',
  uploadingXFiles: {
    0: 'Enviando %{smart_count} arquivo',
    1: 'Enviando %{smart_count} arquivos'
  },
  xFilesSelected: {
    0: '%{smart_count} arquivo selecionado',
    1: '%{smart_count} arquivos selecionados'
  },
  xMoreFilesAdded: {
    0: '%{smart_count} arquivo adicionados',
    1: '%{smart_count} arquivos adicionados'
  },
  xTimeLeft: '%{time} restantes',
  youCanOnlyUploadFileTypes: 'Você pode enviar apenas arquivos: %{types}',
  youCanOnlyUploadX: {
    0: 'Você pode enviar apenas %{smart_count} arquivo',
    1: 'Você pode enviar apenas %{smart_count} arquivos'
  },
  youHaveToAtLeastSelectX: {
    0: 'Você precisa selecionar pelo menos %{smart_count} arquivo',
    1: 'Você precisa selecionar pelo menos %{smart_count} arquivos'
  },
  selectAllFilesFromFolderNamed: 'Selecione todos os arquivos da pasta %{name}',
  unselectAllFilesFromFolderNamed:
    'Desmarque todos os arquivos da pasta %{name}',
  selectFileNamed: 'Selecione o arquivo %{name}',
  unselectFileNamed: 'Deselecionar arquivo %{name}',
  openFolderNamed: 'Pasta aberta %{name}'
}

export class Uploader extends Component {
  static defaultProps = {
    modal: false,
    button_text: 'Enviar arquivo',
    webcam: false
  }

  state = {
    evalute: true
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.visible,
      modalOpen: false
    }

    this.uppy = Uppy({
      restrictions: {
        maxNumberOfFiles: this.props.multiple === false ? 1 : 10
      },
      autoProceed: false,
      locale: {
        strings: locales
      }
    })
  }

  componentWillMount() {
    const token = localStorage.getItem('token')

    if (this.props.webcam === true) {
      this.uppy.use(Webcam, {
        countdown: false,
        modes: ['picture'],
        mirror: true,
        locale: {
          strings: {
            smile: 'Sorria!',
            takePicture: 'Capturar foto',
            startRecording: 'Iniciar gravação',
            stopRecording: 'Parar gravação',
            allowAccessTitle: 'Por favor autorize o acesso a Webcam',
            allowAccessDescription: 'Permissão necessária'
          }
        }
      })
    }

    // this.uppy.use(XHRUpload, {
    //   endpoint: environment.rootApi + 'api/v1/admin/files',
    //   formData: true,
    //   fieldName: 'file',
    //   bundle: false,
    //   headers: {
    //     'Authorization': `Token ${token}`,
    //     'File-Type': this.props.file_type
    //   },
    //   getResponseData: (xhr) => {
    //     const resp = JSON.parse(xhr)
    //     return resp
    //   },
    //   responseUrlFieldName: 'file',
    //   limit: 1,
    //   timeout: 1000000
    // })

    this.uppy.use(AwsS3, {
      getUploadParameters: async (file) => {
        let file_base_data = {
          type: this.props.file_type,
          filename: file.name,
          key: ''
        }

        let sign_res = await postApi('admin/files/sign_url', file_base_data)

        return {
          method: 'POST',
          url: sign_res.url,
          fields: sign_res.fields,
        }
      }
    })

    this.uppy.on('upload-success', (result, data) => {
      console.log(result)

      let file_base_data = {
        type: this.props.file_type,
        filename: result.name,
        key: result.meta['x-amz-meta-file-key']
      }

      postApi('admin/files/create_from_sign_url', file_base_data)
        .then(res => {
          this.props.uploadComplete(res)

          if (this.props.modal) {
            this.handleClose()
          }
        })
        .catch(err => {
          let f_state = this.uppy.getFile(result.id)

          this.uppy.setFileState(result.id, {
            error: 'Falha nos parâmetros!',
            progress: {
              ...f_state.progress,
              uploadComplete: false,
              percentage: 100
            },
            response: null
          })

          this.uppy.setState({
            error: 'Falha nos parâmetros!',
            info: {
              details: 'Falha nos parâmetros!.',
              isHidden: false,
              message: `Falha para enviar ${result.name}`,
              type: 'error'
            }
          })
        })

    })
  }

  componentDidMount() {
    if (!this.props.file_type || typeof this.props.file_type === 'undefined' ||
      this.props.file_type == null || this.props.file_type === '') {
      throw new Error('Uploader - Field type not defined')
    }

    worker = setInterval(() => {
      const data = this.uppy.getState()

      const evalute = Object.values(data.files).every((el, index, arr) => {
        // console.log(el.progress)
        return el.progress.uploadComplete === true
      })

      if (evalute === true || evalute === false) {
        if (evalute !== this.state.evalute) {
          this.setState({evalute}, () => {
            this.props.complete(evalute)
          })
        }
      }

    }, 600)

    if ((this.props.filesUploaded && this.props.filesUploaded.length > 0) ||
      (this.props.filesUploaded && this.props.filesUploaded.id)) {

      if (this.props.multiple === false) {
        const filesUploaded = this.props.filesUploaded

        const arrayToObject = (array) =>
          array.reduce((obj, item) => {
            obj[item.id] = {
              meta: {type: this.handleExt(item.ext), name: item.name},
              type: this.handleExt(item.ext),
              data: {},
              progress: {
                percentage: 100,
                uploadComplete: true,
                uploadStarted: 1516133828822
              },
              // preview: item.ext !== 'application/pdf' ? item.file : '',
              preview: item.ext.includes('image') ? item.file : '',
              uploadURL: item.file
            }
            return obj
          }, {})

        const files = arrayToObject(filesUploaded)

        let uploaderState = {
          files,
          totalProgress: 100,
          error: null
        }

        this.uppy.setState(uploaderState)
      }

      if (this.props.multiple === true) {
        const filesUploaded = this.props.filesUploaded

        const arrayToObject = (array) =>
          array.reduce((obj, item) => {
            obj[item.id] = {
              meta: {type: this.handleExt(item.ext), name: item.name},
              type: this.handleExt(item.ext),
              data: {},
              progress: {
                percentage: 100,
                uploadComplete: true,
                uploadStarted: 1516133828822
              },
              // preview: item.ext !== 'application/pdf' ? item.file : '',
              preview: item.ext.includes('image') ? item.file : '',
              uploadURL: item.file
            }
            return obj
          }, {})

        const files = arrayToObject(filesUploaded)

        let uploaderState = {
          files,
          totalProgress: 100,
          error: null
        }

        this.uppy.setState(uploaderState)
      }
    }
  }

  componentWillUnmount() {
    try {
      clearInterval(worker)
      this.uppy.close()
      this.uppy = null
      worker = null
    } catch (e) {
      console.error(e)
    }
  }

  handleExt = (ext) => {
    switch (ext) {
      case 'image/jpeg':
        return 'image/jpeg'
      case 'jpg':
        return 'image/jpeg'
      case 'jpeg':
        return 'image/jpeg'
      case 'image/png':
        return 'image/png'
      case 'png':
        return 'image/png'
      case 'gif':
        return 'image/gif'
      case 'application/pdf':
        return 'application/pdf'
      case 'text/xml':
        return 'text/xml'
      default:
        return 'application/txt'

    }
  }

  handleOpen = () => {
    this.setState({modalOpen: true})
  }

  handleClose = () => {
    this.setState({modalOpen: false})
  }

  render() {
    if (this.props.modal && this.state.modalOpen === true) {
      return ReactDOM.createPortal(
        <React.Fragment>
          <DashboardModal
            uppy={this.uppy}
            height={320}
            showProgressDetails={true}
            inline={false}
            proudlyDisplayPoweredByUppy={false}
            disablePageScrollWhenModalOpen={false}
            plugins={this.props.webcam ? ['Webcam'] : []}
            closeModalOnClickOutside
            onRequestClose={this.handleClose}
            open={this.state.modalOpen}
            locale={{
              strings: locales
            }}
          />
        </React.Fragment>
        , document.body
      )

    } else if (this.props.modal && this.state.modalOpen === false) {
      return (
        <React.Fragment>
          <Button primary basic type="button" size="small"
                  onClick={this.handleOpen}><Icon
            name="file"/>{this.props.button_text}</Button>
        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>
          <Dashboard
            uppy={this.uppy}
            height={320}
            showProgressDetails={true}
            inline={true}
            proudlyDisplayPoweredByUppy={false}
            plugins={this.props.webcam ? ['Webcam'] : []}
            locale={{
              strings: locales
            }}
          />

          <Message
            info icon="info"
            content="Clique em enviar arquivos e após concluir o envio dos arquivos, cliquer abaixo em enviar para salvar."
          />

        </React.Fragment>
      )
    }
  }
}

