import React from 'react'
import { Tab } from 'semantic-ui-react'
import { qs } from '../../queries'
import { qs_local_server } from '../../queries-local-server'
import AccessAuthListPage from './AccessAuthListPage'
import AccessLogListPage from './AccessLogListPage'
import CondoLaborListPage from './CondoLaborListPage'
import GuestBookListPage from './GuestBookListPage'
import UpdaterService from '../../services/UpdaterService'

export default class AccessControlPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      currentAuth: {},
      currentBook: {}
    }
  }

  componentDidMount() {
    UpdaterService.fixEvents()

    qs.guests.cacheUpdates()
    qs.user.me()
    qs.utils.ping()
    qs_local_server.utils.pingServer()

  }

  render() {
    return (
      <Tab
        renderActiveOnly={true}
        menu={{ secondary: true, pointing: true }}
        defaultActiveIndex={0}
        panes={[
          { menuItem: 'Registro de Entrada / Saída', render: () => <React.Fragment><AccessLogListPage/></React.Fragment> },
          { menuItem: 'Visitantes Pré-autorizados', render: () => <React.Fragment><AccessAuthListPage/></React.Fragment> },
          { menuItem: 'Lista de Convidados do Dia', render: () => <React.Fragment><GuestBookListPage/></React.Fragment> },
          { menuItem: 'Colaboradores Condomínio', render: () => <React.Fragment><CondoLaborListPage/></React.Fragment> }
        ]}
      />
    )
  }
}