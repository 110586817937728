import axios from 'axios'
import {toast as toastr} from 'react-toastify'
import swal from 'sweetalert2'
import enviroment from '../enviroment'
import {history} from '../redux/store/configureStore'

const authService = {

  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  login(username, password) {
    const data = {
      username: username,
      password: password,
      grant_type: 'password',
      client_id: enviroment().client_id,
      client_secret: enviroment().client_secret
    }

    if (authService.loggedIn()) return Promise.resolve(true)

    return axios({
      method: 'post',
      url: enviroment().rootApi + 'auth/token/login',
      data: data
    })
      .then(async (res) => {
        localStorage.setItem('token', res.data.auth_token)
        return res
      })
      .catch((error) => {
        swal({
          title: 'Verifique os seus dados de acesso!',
          type: 'error',
          confirmButtonText: 'Ok!'
        })
        return Promise.reject(error)
      })
  },

  async changePassword(data) {
    const token = await localStorage.getItem('token')
    return axios({
      method: 'post',
      url: enviroment().rootApi + 'auth/password',
      data: data,
      headers: { 'Authorization': `Token ${token}` }
    })
      .then(async (res) => {
        return res
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  },

  // getMe() {
  //    return fetchApi('admin/me')
  //       .then((res) => {
  //          localStorage.setItem('current_user', JSON.stringify(res.data));
  //          return res.data
  //       })
  //       .catch(err => Promise.reject(err));
  // },

  getTeam() {
    const user = JSON.parse(localStorage.getItem('current_user'))
    if (user && user.team) {
      return user.team
    }
    return null
  },

  /**
   * Logs the current user out
   */
  logout() {
    localStorage.clear()
    toastr.error('Sessão expirou. Entre novamente!')
    history.push('/login')
  },

  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    return !!localStorage.getItem('token') && !!localStorage.getItem('current_user')
  },

  getToken() {
    return localStorage.getItem('token')
  },

  /**
   * Registers a user and then logs them in
   * @param  {string} username The username of the user
   * @param  {string} password The password of the user
   */
  register(username, password) {
    // Post a fake request
    // return request.post('/register', {username, password})
    // // Log user in after registering
    //     .then(() => authService.login(username, password))
  },

  onChange() {
  },

  getTeamVersionLocal() {
    try {
      let version = localStorage.getItem('condoidv2')

      if (['true', 'false'].includes(version)) {
        return JSON.parse(version)
      }

      return null
    } catch (e) {
      console.error(e)
      throw e
    }
  },

  setTeamVersion(use_v2) {
    localStorage.setItem('condoidv2', use_v2)
    localStorage.setItem('condoidv2', use_v2)
  },

  async handleTeamVersion(use_v2) {
    let init_status = authService.getTeamVersionLocal()

    localStorage.setItem('condoidv2', use_v2)

    return new Promise((resolve) => {

      setTimeout(() => {
        if (use_v2 !== init_status) {
          window.location.reload()
        }

        resolve(true)

      }, 1000 * 2)
    })

  }
}

export default authService
