import moment from 'moment/moment'
import * as queryString from 'query-string'
import { fetchApi } from '../services/api'
import ToasterService from '../services/ToasterService'
import { dateFormatNew, dateTimeFormatNew, getValue } from './Formatters'

export class gridDefaults {
  static dateFilter = (filterLocalDateAtMidnight, cellValue) => {
    const cellDate = moment(cellValue, 'DD/MM/YYYY').unix()
    const pickerDate = moment(filterLocalDateAtMidnight).unix()

    if (pickerDate === cellDate) {
      return 0
    }

    if (cellDate < pickerDate) {
      return -1
    }

    if (cellDate > pickerDate) {
      return 1
    }
  }

  static dateComparator = (date1, date2) => {
    date1 = moment(date1, 'DD/MM/YYYY').unix()
    date2 = moment(date2, 'DD/MM/YYYY').unix()

    if (date1 === null && date2 === null) {
      return 0
    }
    if (date1 === null) {
      return -1
    }
    if (date2 === null) {
      return 1
    }

    return date1 - date2
  }

  static dateTimeFilter = (filterLocalDateAtMidnight, cellValue) => {
    const cellDate = moment(cellValue, 'DD/MM/YYYY HH:mm').unix()
    const pickerDate = moment(filterLocalDateAtMidnight).unix()

    if (pickerDate === cellDate) {
      return 0
    }

    if (cellDate < pickerDate) {
      return -1
    }

    if (cellDate > pickerDate) {
      return 1
    }
  }

  static dateTimeComparator = (date1, date2) => {
    date1 = moment(date1, 'DD/MM/YYYY HH:mm').unix()
    date2 = moment(date2, 'DD/MM/YYYY HH:mm').unix()

    if (date1 === null && date2 === null) {
      return 0
    }
    if (date1 === null) {
      return -1
    }
    if (date2 === null) {
      return 1
    }

    return date1 - date2
  }

  static dateFormatter = (params) => {
    if (params.data === null || params.data === undefined) {
      return null
    }

    let value = getValue(params.data, params.column.colId)

    if (value) {
      return dateFormatNew(value)
    }

    return null
  }

  static dateTimeFormatter = (params) => {
    if (params.data === null || params.data === undefined) {
      return null
    }

    let value = getValue(params.data, params.column.colId)

    if (value) {
      return dateTimeFormatNew(value)
    }

    return null
  }

  static makeServerSideDataSource = (path: string, pagination_size: number, prepareFilterQs: () => {}) => {
    return {
      getRows: (params) => {
        let filter_qs_state = prepareFilterQs()

        console.log('[Datasource - FILTER] - rows requested by grid: ', filter_qs_state)

        params.parentNode.gridApi.showLoadingOverlay()

        let filter_qs: any = {
          ...filter_qs_state,
          limit: pagination_size,
          offset: params.request.startRow
        }

        let qs = queryString.stringify(filter_qs, { encode: false })

        let full_route = `${path}?${qs}`

        fetchApi(full_route).then(res => {
          params.successCallback(res.data, res.count)

          params.parentNode.gridApi.sizeColumnsToFit()
          params.parentNode.gridApi.hideOverlay()

          if (res.data.length === 0) {
            params.parentNode.gridApi.showNoRowsOverlay()
          }

        }).catch(err => {
          ToasterService.sendErrors(err)
          // Promise.reject(err)
          params.parentNode.gridApi.sizeColumnsToFit()
          params.parentNode.gridApi.hideOverlay()

          params.failCallback()
        })
      }
    }
  }

  static adminGrid = {
    valueCache: true,

    pagination: true,
    paginationPageSize: 50,

    domLayout: 'autoHeight',

    showToolPanel: false,
    enableRowGroup: false,
    enablePivot: false,
    toolPanelSuppressSideButtons: true,
    toolPanelSuppressRowGroups: true,
    toolPanelSuppressValues: true,
    toolPanelSuppressPivots: true,
    toolPanelSuppressPivotMode: true,

    defaultColDef: {
      sortable: true,
      suppressResize: true,
      suppressMovable: true,
      suppressSorting: false,
      suppressMenu: true,
      suppressFilter: true
    },

    columnTypes: {
      'dateColumn': {
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => gridDefaults.dateFilter(filterLocalDateAtMidnight, cellValue)
        },
        comparator: gridDefaults.dateComparator,
        valueGetter: gridDefaults.dateFormatter
      },
      'dateTimeColumn': {
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => gridDefaults.dateTimeFilter(filterLocalDateAtMidnight, cellValue)
        },
        comparator: gridDefaults.dateTimeComparator,
        valueGetter: gridDefaults.dateTimeFormatter
      },
      'textColumn': {
        filter: 'text'
      }
    },

    localeText: {
      // for filter panel
      page: 'Página',
      more: 'Mais',
      to: 'de',
      of: 'de',
      next: 'Próximo',
      last: 'Último',
      first: 'Primeiro',
      previous: 'Anterior',
      loadingOoo: 'Carregando...',

      // for set filter
      selectAll: 'Selecionar Tudo',
      searchOoo: 'Pesquisar...',
      blanks: 'em branco',

      // for number filter and text filter
      filterOoo: 'Filtrar',
      applyFilter: 'Aplicar Filtro',

      // for number filter
      equals: 'Igual',
      notEquals: 'Igual',
      lessThan: 'Menor que',
      greaterThan: 'Maior que',

      // for text filter
      contains: 'Contêm',
      startsWith: 'Começa com',
      endsWith: 'Termina com',

      notEqual: 'Diferente',
      inRange: 'Entre',
      lessThanOrEqual: 'Menos ou igual',
      greaterThanOrEqual: 'Maior ou igual',
      notContains: 'Não contém',
      clearFilter: 'Limpar filtro',

      // the header of the default group column
      group: 'Grupo',

      // tool panel
      columns: 'Colunas',
      rowGroupColumns: 'Agrupar Colunas',
      rowGroupColumnsEmptyMessage: 'Arraste a coluna aqui para agrupar',
      valueColumns: 'Valor da Coluna',
      pivotMode: 'Modo Pivor',
      groups: 'Grupos',
      values: 'Valores',
      pivots: 'Pivôs',
      valueColumnsEmptyMessage: 'Arraste a coluna aqui para agregar',
      pivotColumnsEmptyMessage: 'la drag here to pivot',

      // other
      noRowsToShow: 'Nada encontrado',

      // enterprise menu
      pinColumn: 'Fixar coluna',
      valueAggregation: 'Agregar valores',
      autosizeThiscolumn: 'Auto Ajustar esta coluna',
      autosizeAllColumns: 'Auto Ajustar todas colunas',
      groupBy: 'Agrupado por',
      ungroupBy: 'Desagrupado por',
      resetColumns: 'Resetar colunas',
      expandAll: 'Expandir tudo',
      collapseAll: 'Recolher tudo',
      toolPanel: 'Painel de ferramentas',
      export: 'Exportar',
      csvExport: 'Exportar para CSV',
      excelExport: 'Exportar para Excel',

      // enterprise menu pinning
      pinLeft: 'Fixar a esquerda <<',
      pinRight: 'Fixar a direita >>',
      noPin: 'Soltar <>',

      // enterprise menu aggregation and status panel
      sum: 'Soma',
      min: 'Min',
      max: 'Max',
      none: 'Nenhum',
      count: 'Quantidade',
      average: 'Média',
      avg: 'Média',

      // standard menu
      copy: 'Copiar',
      copyWithHeaders: 'Copiar com cabeçalhos',
      ctrlC: 'Ctrl + C',
      paste: 'Colar',
      ctrlV: 'Ctrl + V'
    },

    getContextMenuItems: (params) => {
      return []
    },

    getRowNodeId: (data) => {
      return data.id
    }
  }
}
