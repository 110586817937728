import React from 'react'
import PageBase from '../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import { postApi, putApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import { FieldArray, Formik } from 'formik'
import { Button, Dropdown, Form, Grid, Header, Label, List, Modal, Table } from 'semantic-ui-react'
import { getMailingPouchs } from '../../utils/DataService'
import { dateTimeFormat, removeAccents } from '../../utils/Formatters'
import swal from 'sweetalert2'
import { MAILING_POUCH_STATUS } from '../../fields'
import { gridDefaults } from '../../utils/gridDefaults'
import moment from 'moment'
import { FF, FormikDate, FormikInput } from '../../components/form/FormInputs'
import UnitsSelect from '../../components/form/UnitsSelect'
import ToasterService from '../../services/ToasterService'
import TeamClusterSelect from '../../components/form/TeamClusterSelect'
import * as Yup from 'yup'

export default class MailingPouchPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this,
        },
        rowHeight: 70,
      },
      quickFilterText: null,
      current_mailing: null,
      loading: false,
      open_modal: false,
      details_modal: false,
      pick_modal: false,
      import_modal: false,
      columnDefs: [
        {
          headerName: 'Status',
          field: 'status',
          width: 55,
          filter: 'text',
          cellRendererFramework: (params) => {
            let value = params.getValue()
            let status_value = MAILING_POUCH_STATUS[value]

            return (
              <div>
                {[3, 2].includes(value) ?
                  <Label color="green" className="grid-label">{status_value}</Label> :
                  <Label color="yellow" className="grid-label">{status_value}</Label>}
              </div>
            )
          },
        },
        {
          headerName: 'Remetente/Destinatário',
          width: 140,
          cellRendererFramework: (params) => {
            return (
              <div>
                Remetente: {params.data.from_team_name} <br/>
                Destinatário: {params.data.destination_team_name}
              </div>
            )
          },
        },
        {
          headerName: 'Remetente',
          field: 'from_team_name',
          width: 120,
          filter: 'text',
          hide: true,
          getQuickFilterText: removeAccents,
        },
        {
          headerName: 'destination_team_name',
          field: 'to',
          width: 120,
          filter: 'text',
          hide: true,
          getQuickFilterText: removeAccents,
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 30,
          filter: 'text',
          hide: true,
          valueGetter: (params) => MAILING_POUCH_STATUS[params.data.status],
        },
        {
          headerName: 'Data',
          width: 70,
          cellRendererFramework: (params) => {
            return (
              <div>
                Criado: {dateTimeFormat(params, 'created_at')} <br/>
                Retirado: {dateTimeFormat(params, 'picked_at')}
              </div>
            )
          },
        },
        {
          headerName: 'Data de Criação',
          field: 'created_at',
          width: 60,
          filter: 'text',
          hide: true,
          valueGetter: (params) => dateTimeFormat(params, 'created_at'),
        },
        {
          headerName: 'Retirado em',
          field: 'picked_at',
          width: 60,
          filter: 'text',
          hide: true,
          valueGetter: (params) => dateTimeFormat(params, 'delivered_at'),
        },
        {
          headerName: 'Ações',
          width: 115,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          suppressSizeToFit: true,
          cellClass: 'ag-button-cell',
          cellRendererFramework: this.actionsFormat,
        },
      ],
      getRowNodeId: function (data) {
        return data.id
      },
    }
  }

  onQuickFilterText = (event) => {
    this.setState({ quickFilterText: event })
  }

  actionsFormat = (params) => {
    return (
      <Button.Group basic primary compact>
        <Dropdown
          text="Ações  "
          icon="pencil"
          floating
          button
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
            <Dropdown.Divider/>

            <Dropdown.Item onClick={() => {
              params.context.componentParent.handleOpenDetails(params.data)
            }}>
              Detalhes
            </Dropdown.Item>

            {params.data && params.data.status === 1 &&
            <Dropdown.Item onClick={() => {
              params.context.componentParent.handleOpenPick(params.data)
            }}>
              Retirar
            </Dropdown.Item>}

            {params.data && params.data.status === 1 &&
            <Dropdown.Item onClick={() => {
              params.context.componentParent.handleOpenImport(params.data)
            }}>
              Importar
            </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      </Button.Group>
    )
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.getData()
  }

  getData = () => {
    this.setState({ loading: true })

    getMailingPouchs().then(data => {
      this.gridApi.setRowData(data)
      this.setState({ loading: false })
      this.gridApi.sizeColumnsToFit()
    }).catch(err => {
      this.setState({ loading: false })
    })
  }

  handleClose = () => {
    this.setState({ open_modal: false, details_modal: false, import_modal: false, pick_modal: false, current_mailing: null })
  }

  handleOpenNew = () => {
    this.setState({ open_modal: true, current_mailing: null })
  }

  handleOpenPick = (data) => {
    this.setState({ pick_modal: true, current_mailing: data })
  }

  handleOpenDetails = (data) => {
    this.setState({ details_modal: true, current_mailing: data })
  }

  handleOpenImport = (data) => {
    this.setState({ import_modal: true, current_mailing: data })
  }

  render() {
    return (
      <PageBase title="Malotes" onFilterChange={this.onQuickFilterText} create={
        <button className="ui button primary large" onClick={this.handleOpenNew}>Novo</button>
      }>

        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            enableFilter={true}
            gridOptions={this.state.gridOptions}
            columnDefs={this.state.columnDefs}
            quickFilterText={this.state.quickFilterText}
            onGridReady={this.onGridReady.bind(this)}
            pagination={true}
            paginationPageSize={25}
            domLayout={'autoHeight'}
            getRowNodeId={this.state.getRowNodeId}
          />
        </div>

        {this.state.details_modal &&
        <Modal open={this.state.details_modal}>
          <Modal.Header>Detalhes do Malote</Modal.Header>
          <Modal.Content>
            <Grid columns={'equal'}>
              <Grid.Row>
                <Grid.Column>
                  <Header sub>Status:</Header>
                  <span>{MAILING_POUCH_STATUS[this.state.current_mailing?.status]}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Remetente:</Header>
                  <span>{this.state.current_mailing?.from_team_name}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Destinatário:</Header>
                  <span>{this.state.current_mailing?.destination_team_name}</span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header sub>Data de Criação:</Header>
                  <span>{moment(this.state.current_mailing?.created_at).format('DD/MM/YY HH:mm')}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Retirado por:</Header>
                  <span>{this.state.current_mailing?.picked_by}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Documento:</Header>
                  <span>{this.state.current_mailing?.picked_doc}</span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Retirado em:</Header>
                  <span>
                    {this.state.current_mailing?.picked_at && moment(this.state.current_mailing?.picked_at).format('DD/MM/YY HH:mm')}
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <Header sub>Local de Entrega:</Header>
                  <span>
                    {this.state.current_mailing?.picked_local}
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header sub>Itens:</Header>
                  Código
                  <List divided relaxed>
                    {
                      this.state.current_mailing?.items.map((i) => (
                        <List.Item>
                          <List.Content>
                            <List.Header>{i.tracking_code}</List.Header>
                          </List.Content>
                        </List.Item>
                      ))
                    }
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
          </Modal.Actions>
        </Modal>}

        {this.state.open_modal &&
        <Modal open={this.state.open_modal} size="large">
          <Formik
            initialValues={{
              destination_team: '',
              items: [],
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              values['status'] = 1

              postApi(`admin/mailing-pouchs`, values).then((res) => {
                swal({ type: 'success', title: 'Malote criado!' })
                this.setState({ open_modal: false, loading: false })
                this.getData()
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })

            }}
          >
            {(formikBag) => (
              <React.Fragment>
                <Modal.Header>Novo malote</Modal.Header>
                <Modal.Content>
                  <Form error>
                    <TeamClusterSelect label="Condomínio destinatário" name="destination_team"/>

                    <FieldArray
                      name="items"
                      render={arrayHelpers => (
                        <React.Fragment>
                          <Header as="h4" dividing>Items</Header>

                          <Button
                            primary size="mini" type="button"
                            onClick={() => arrayHelpers.push({ 'tracking_code': '', 'description': '' })}
                          >
                            Adicionar item
                          </Button>

                          {typeof formikBag.errors.items === 'string' &&
                          <Label size="small" basic color="red">{formikBag.errors.items}</Label>}
                          <br/>
                          <br/>
                          <span>Código de rastreio</span>

                          <Form.Group widths="equal">
                            <Table basic="very" size="small" style={{ margin: '0 0 1em' }}>
                              <Table.Body>
                                {formikBag.values.items?.map((item, index) => (
                                  <Table.Row key={index}>
                                    <Table.Cell>
                                      <FF
                                        id={`item_list_${index}`} name={`items.${index}.tracking_code`} component={FormikInput}
                                        onKeyPress={(a) => {
                                          if (a.key.toLowerCase() === 'enter') {
                                            a.preventDefault()
                                            arrayHelpers.push({ 'tracking_code': '', 'description': '' })
                                            setTimeout(() => {
                                              let el = document.getElementById(`item_list_${index + 1}`)
                                              if (el) {
                                                el.focus()
                                              }
                                            }, 100)
                                          }
                                        }}
                                      />
                                    </Table.Cell>

                                    <Table.Cell width="1" textAlign="right">
                                      <Button
                                        icon="trash" basic color="red" size="mini"
                                        onClick={() => arrayHelpers.remove(index)}
                                      />
                                    </Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>
                          </Form.Group>
                        </React.Fragment>
                      )}
                    />
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
                  <button className="ui blue button large" onClick={formikBag.handleSubmit}>Salvar</button>
                </Modal.Actions>
              </React.Fragment>
            )}
          </Formik>
        </Modal>}

        {this.state.pick_modal &&
        <Modal open={this.state.pick_modal}>
          <Formik
            initialValues={{
              status: 2,
              picked_by: '',
              picked_doc: '',
              picked_local: '',
              picked_at: '',
            }}
            validationSchema={Yup.object().shape({
              picked_by: Yup.string().required(),
              picked_doc: Yup.string().required(),
              picked_local: Yup.string().required(),
              picked_at: Yup.string().required(),
            })}
            onSubmit={(values) => {
              this.setState({ loading: true })

              putApi(`admin/mailing-pouchs`, values, this.state.current_mailing.id).then((res) => {
                swal({ type: 'success', title: 'Malote retirado!' })
                this.setState({ pick_modal: false, loading: false })
                this.getData()
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })
            }}
          >
            {(formikBag) => (
              <React.Fragment>
                <Modal.Header>Retirar malote</Modal.Header>
                <Modal.Content>
                  <Form error>
                    <Form.Group widths="equal">
                      <FF label="Entregue para" name="picked_by" component={FormikInput}/>
                      <FF label="Documento" name="picked_doc" component={FormikInput}/>
                    </Form.Group>

                    <Form.Group widths="equal">
                      <FF label="Local" name="picked_local" component={FormikInput}/>
                      <FF label="Data" name="picked_at" component={FormikDate} has_time={true}/>
                    </Form.Group>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
                  <button className="ui blue button large" onClick={formikBag.handleSubmit}>Salvar</button>
                </Modal.Actions>
              </React.Fragment>
            )}
          </Formik>
        </Modal>}

        {this.state.import_modal &&
        <Modal open={this.state.import_modal} size="large">
          <Formik
            initialValues={{
              mailing_pouch_id: this.state.current_mailing?.id,
              mailings: this.state.current_mailing.items,
            }}
            onSubmit={(values) => {
              this.setState({ loading: true })

              postApi(`admin/mailing-pouchs/${this.state.current_mailing?.id}/import_mailings`, values).then((res) => {
                swal({ type: 'success', title: 'Corrêspondencias importadas!' })
                this.setState({ import_modal: false, loading: false })
                this.getData()
              }).catch((err) => {
                this.setState({ loading: false })
                ToasterService.sendErrors(err)
              })

            }}
          >
            {(formikBag) => (
              <React.Fragment>
                <Modal.Header>Importar</Modal.Header>
                <Modal.Content>
                  <Form>
                    <Table celled>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Código</Table.HeaderCell>
                          <Table.HeaderCell>Unidade</Table.HeaderCell>
                          <Table.HeaderCell>Remetente</Table.HeaderCell>
                          <Table.HeaderCell>Destinatário</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <FieldArray
                          name="mailings"
                          render={() => (
                            formikBag.values.mailings.map((item, index) => (
                              <Table.Row key={index}>
                                <Table.Cell>{item.tracking_code}</Table.Cell>
                                <Table.Cell>
                                  <UnitsSelect name={`mailings.${index}.unit`} label={''}/>
                                </Table.Cell>
                                <Table.Cell>
                                  <FF label={''} name={`mailings.${index}.from_field`} component={FormikInput}/>
                                </Table.Cell>
                                <Table.Cell>
                                  <FF label={''} name={`mailings.${index}.to`} component={FormikInput}/>
                                </Table.Cell>
                              </Table.Row>
                            ))
                          )}
                        />
                      </Table.Body>
                    </Table>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <button className="ui red button large" onClick={this.handleClose}>Fechar</button>
                  <button className="ui blue button large" onClick={formikBag.handleSubmit}>Importar</button>
                </Modal.Actions>
              </React.Fragment>
            )}
          </Formik>
        </Modal>}

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}
