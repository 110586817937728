import React from 'react'
import PageBase from '../../../components/PageBase'
import { AgGridReact } from 'ag-grid-react'
import ActivityLoading from '../../../components/Loading'
import { dateFormat, removeAccents, show_modal } from '../../../utils/Formatters'
import { getNotices } from '../../../utils/DataService'
import { Form, Modal } from 'semantic-ui-react'
import { Formik } from 'formik'
import { DatePickr } from '../../../components/form/DatePickr'
import { FF, FormikCheck, FormikInput, FormikSelect } from '../../../components/form/FormInputs'
import { postApi, putApi } from '../../../utils/api'
import { AclRender } from '../../../components/acl/components'
import { gridDefaults } from '../../../utils/gridDefaults'
import UnitsSelect from '../../../components/form/UnitsSelect'
import { NOTICE_STATUS } from '../../../fields'
import UnitGroupsSelect from '../../../components/form/UnitGroupsSelect'

const statues = [
  {value: 1, text: 'Publicado'},
  {value: 2, text: 'Rascunho'},
]

class NoticeListPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      modal: false,
      current_item: {},
      quickFilterText: null,
      loading: false,
      columnDefs: [
        {
          headerName: 'Título',
          field: 'name',
          width: 50,
          filter: 'text',
          getQuickFilterText: removeAccents
        },
        {
          headerName: 'Status',
          field: 'status',
          width: 25,
          filter: 'text',
          valueGetter: (params) => NOTICE_STATUS[params.data.status],
        },
        {
          headerName: 'Data de Expiração',
          field: 'published_until',
          width: 25,
          filter: 'text',
          valueGetter: (params) => dateFormat(params, 'published_until'),
        },
        {
          headerName: 'Ações',
          width: 30,
          suppressResize: true,
          suppressMovable: true,
          suppressSorting: true,
          suppressMenu: true,
          suppressFilter: true,
          cellRendererFramework: this.actionsFormat
        },
      ],
      gridOptions: {
        ...gridDefaults.adminGrid,
        context: {
          componentParent: this
        }
      },
      getRowNodeId: function (data) {
        return data.id
      }
    }
  }

  onQuickFilterText = (event) => {
    this.setState({quickFilterText: event})
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.handleGetData()
  }

  actionsFormat = (params) => {
    if (params.data) {
      return (
        <React.Fragment>
          <button
            className="ui button basic compact primary"
            onClick={() => params.context.componentParent.handleEdit(params.data)}
          >
            Detalhes
          </button>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  handleGetData = () => {
    this.setState({loading: true})

    getNotices()
      .then((data) => {
        this.setState({loading: false, data: data})

        this.gridApi.setRowData(data)
        this.gridApi.sizeColumnsToFit()
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  handleSubmitForm = () => {
    this.form.submitForm()
  }

  handleNew = () => {
    this.setState({modal: true, current_item: {}})
  }

  handleEdit = (data) => {
    this.setState({modal: true, current_item: data})
  }

  render() {
    return (
      <PageBase
        title="Comunicados"
        onFilterChange={this.onQuickFilterText}
        create={<AclRender required={['notice.add_notice']}>
          <button className="ui button primary large" onClick={this.handleNew}>Novo comunicado</button>
        </AclRender>}
      >
        <div style={{width: '100%'}} className="ag-theme-material">
          <AgGridReact enableFilter={true}
                       gridOptions={this.state.gridOptions}
                       rowData={this.state.data}
                       columnDefs={this.state.columnDefs}
                       quickFilterText={this.state.quickFilterText}
                       onGridReady={this.onGridReady}
                       pagination={true}
                       paginationPageSize={50}
                       domLayout={'autoHeight'}
                       getRowNodeId={this.state.getRowNodeId}
          />
        </div>

        <Modal open={this.state.modal}>
          <Modal.Content>
            <Formik
              ref={node => (this.form = node)}
              initialValues={{
                name: this.state.current_item?.name,
                status: this.state.current_item?.status,
                body: this.state.current_item?.body,
                published_until: this.state.current_item?.published_until,
                sent_push: this.state.current_item?.sent_push ?? false,
                sent_email: false,
                flag_notify: false
              }}
              validate={values => {
                let errors = {}

                if (!values.name) errors.name = 'Campo obrigatório'
                if (!values.status) errors.status = 'Campo obrigatório'
                if (!values.body) errors.body = 'Campo obrigatório'
                if (!values.published_until) errors.published_until = 'Campo obrigatório'
                return errors
              }}
              onSubmit={(values) => {
                this.setState({loading: true})

                if (this.state.current_item?.id) {
                  putApi('admin/notices', values, this.state.current_item.id)
                    .then((res) => {
                      this.handleGetData()
                      this.setState({loading: false, modal: false})
                      show_modal('Comunicado salvo!', 'success')
                    })
                    .catch(() => {
                      this.setState({loading: false})
                      show_modal('Verifique os dados!', 'error')
                    })

                } else {
                  postApi('admin/notices', values)
                    .then((res) => {
                      this.handleGetData()
                      this.setState({loading: false, modal: false})
                      show_modal('Comunicado salvo!', 'success')
                    })
                    .catch(() => {
                      this.setState({loading: false})
                      show_modal('Verifique os dados!', 'error')
                    })
                }
              }}
              render={({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched,}) => (
                <Form error className="ui form">
                  <FF label={'Título'} name="name" component={FormikInput}/>
                  <Form.Group widths='equal'>
                    <FF label={'Status'} name="status" component={FormikSelect} options={statues}/>

                    <Form.Field>
                      <label>Data de Expiração</label>
                      <DatePickr
                        name='published_until'
                        initial={values.published_until}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </Form.Field>
                  </Form.Group>

                  <Form.Field>
                    <label>Corpo</label>
                    <textarea
                      rows="5"
                      name="body"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.body}
                    />

                    {touched.body && errors.body && <h5 className="ui header red">{errors.body}</h5>}
                  </Form.Field>

                  <AclRender required={['notice.change_notice', 'notice.add_notice']}>
                    <br/>
                    <br/>

                    <div className='notify-box'>
                      <h5>Configurações da Notificação:</h5>

                      <FF label={'Enviar notificação para moradores'} name="flag_notify" component={FormikCheck}/>

                      <Form.Group widths='equal'>
                        <UnitsSelect name='flag_notify_units' label='Unidades (deixe em branco para todos)' multiple/>
                        <UnitGroupsSelect name='flag_notify_unit_groups' label='Grupos de Unidades (deixe em branco para todos)' multiple/>
                      </Form.Group>
                    </div>
                  </AclRender>
                </Form>
              )}
            />
          </Modal.Content>

          <Modal.Actions>
            <button className="ui red button" onClick={() => this.setState({modal: false, current_item: {}})}>Fechar</button>
            <AclRender required={['notice.change_notice', 'notice.add_notice']}>
              <button className="ui button primary" onClick={this.handleSubmitForm}>Enviar</button>
            </AclRender>
          </Modal.Actions>
        </Modal>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  }
}

export default NoticeListPage

