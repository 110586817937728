import * as queryString from 'query-string'
import {toast} from 'react-toastify'
import {getInitialValue, getValue, hasValue} from './Formatters'

export function debounce(func: () => void, wait: number, immediate: boolean) {
  let timeout: any
  return function () {
    // @ts-ignore
    let context: any = this, args: any = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function swipeobj(obj: { [key: string]: any }) {
  const ret: any = {}

  Object.keys(obj).forEach((key) => {
    ret[obj[key]] = key
  })

  return ret
}

export function map2array(obj: { [key: string]: any }) {
  const ret: any = []

  Object.keys(obj).forEach((key) => {
    ret.push({ id: key, name: obj[key] })
  })

  return ret
}

export const buildQs = (fields: string[] = [], filters: { [key: string]: any } = {}): string => {
  let fields_parsed: string = queryString.stringify({ include: fields }, { arrayFormat: 'bracket' })

  let filter_builds: any = {}
  Object.keys(filters).forEach(key => filter_builds[`filter{${key}}`] = filters[key])
  let filters_parsed: string = queryString.stringify(filter_builds, { encode: false })

  let qs = fields_parsed

  if (fields_parsed && filters_parsed) {
    qs = qs + '&'
  }

  qs = qs + filters_parsed

  if (fields_parsed || filters_parsed) {
    return `?${qs}`
  }

  return ''
}

export const hv = (obj: object, nestedKeys: string) => hasValue(obj, nestedKeys)
export const gv = (obj: object | any, nestedKeys: string) => getValue(obj, nestedKeys)
export const giv = (obj: object, nestedKeys: string, default_value = '') => getInitialValue(obj, nestedKeys, default_value)

export const imageCacheUrl = (image_url: string) => {
  return `localcache://condoid.local/${image_url}`
}

export function push_toast(msg: string, type: 'success' | 'error' | 'info' | 'warn' | 'warning') {
  switch (type) {
    case 'success':
      toast.success(msg, { position: toast.POSITION.BOTTOM_RIGHT, toastId: msg })
      break

    case 'error':
      toast.error(msg, { position: toast.POSITION.BOTTOM_RIGHT, toastId: msg })
      break

    case 'info':
      toast.info(msg, { position: toast.POSITION.BOTTOM_RIGHT, toastId: msg })
      break

    case 'warn':
      toast.warn(msg, { position: toast.POSITION.BOTTOM_RIGHT, toastId: msg })
      break

    case 'warning':
      toast.warn(msg, { position: toast.POSITION.BOTTOM_LEFT, toastId: msg })
      break

    default:
      toast(msg, { toastId: msg })
  }
}

export function chunkArray(arr: any[], chunkSize: number) {
  if (chunkSize <= 0) throw 'Invalid chunk size'
  let R: any[] = []

  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize))
  }

  return R
}