import * as Yup from 'yup'
import { setLocale } from 'yup'
import React from 'react'
import { LocalDB } from './LocalDB'
import { gv } from './common'

const phoneMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
const mobileMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

export const formUtils = {
  handleError(field) {
    return !!field
  },

  genders: [
    {value: 'm', text: 'Masculino'},
    {value: 'f', text: 'Feminino'},
    {value: 'o', text: 'Outro'},
  ],

  states: [
    {value: 'AC', text: 'Acre'},
    {value: 'AL', text: 'Alagoas'},
    {value: 'AP', text: 'Amapá'},
    {value: 'AM', text: 'Amazonas'},
    {value: 'BA', text: 'Bahia'},
    {value: 'CE', text: 'Ceará'},
    {value: 'DF', text: 'Distrito Federal'},
    {value: 'ES', text: 'Espírito Santo'},
    {value: 'GO', text: 'Goiás'},
    {value: 'MA', text: 'Maranhão'},
    {value: 'MT', text: 'Mato Grosso'},
    {value: 'MS', text: 'Mato Grosso do Sul'},
    {value: 'MG', text: 'Minas Gerais'},
    {value: 'PA', text: 'Pará'},
    {value: 'PB', text: 'Paraíba'},
    {value: 'PR', text: 'Paraná'},
    {value: 'PE', text: 'Pernambuco'},
    {value: 'PI', text: 'Piauí'},
    {value: 'RJ', text: 'Rio de Janeiro'},
    {value: 'RN', text: 'Rio Grande do Norte'},
    {value: 'RS', text: 'Rio Grande do Sul'},
    {value: 'RO', text: 'Rondônia'},
    {value: 'RR', text: 'Roraima'},
    {value: 'SC', text: 'Santa Catarina'},
    {value: 'SP', text: 'São Paulo'},
    {value: 'SE', text: 'Sergipe'},
    {value: 'TO', text: 'Tocantins'},
  ],

  phoneMask: phoneMask,
  mobileMask: mobileMask,
  cepMask: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  plateMask: [/[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /\d/, /\d/, /\d/, /\d/],

  unMaskNumbers: (masked, mask = /\D/g) => {
    return masked.replace(new RegExp(mask), '')
  },

  debug(values) {
    return (
      <pre>
            {JSON.stringify(values, null, 2)}
         </pre>
    )
  },
}

// Blacklist common values.
const BLACKLIST = [
  '00000000000000',
  '11111111111111',
  '22222222222222',
  '33333333333333',
  '44444444444444',
  '55555555555555',
  '66666666666666',
  '77777777777777',
  '88888888888888',
  '99999999999999'
]

/**
 * Compute the Verifier Digit (or "Dígito Verificador (DV)" in portuguese) for CNPJ.
 *
 * You can learn more about this on [wikipedia (pt-br)](https://pt.wikipedia.org/wiki/D%C3%ADgito_verificador)
 *
 * @export
 * @param {string} numbers the CNPJ string with only numbers.
 * @returns {number} the verifier digit.
 */
export function verifierDigit(numbers) {
  let index = 2
  const reverse = numbers.split('').reduce(function (buffer, number) {
    return [parseInt(number, 10)].concat(buffer)
  }, [])

  const sum = reverse.reduce(function (buffer, number) {
    buffer += number * index
    index = (index === 9 ? 2 : index + 1)
    return buffer
  }, 0)

  const mod = sum % 11
  return (mod < 2 ? 0 : 11 - mod)
}

function isValid(cnpj) {
  const stripped = (cnpj || '').toString().replace(/[-\/.]/g, '')

  // CNPJ must be defined
  if (!stripped) { return false }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) { return false }

  // CNPJ can't be blacklisted
  if (BLACKLIST.includes(stripped)) { return false }

  let numbers = stripped.substr(0, 12)
  numbers += verifierDigit(numbers)
  numbers += verifierDigit(numbers)

  return numbers.substr(-2) === stripped.substr(-2)
}

const cpfCalcChecker1 = firstNineDigits => {
  let sum = null

  for (let j = 0; j < 9; ++j) {
    sum += firstNineDigits.toString().charAt(j) * (10 - j)
  }

  const lastSumChecker1 = sum % 11
  const checker1 = (lastSumChecker1 < 2) ? 0 : 11 - lastSumChecker1

  return checker1
}

const cpfCalcChecker2 = cpfWithChecker1 => {
  let sum = null

  for (let k = 0; k < 10; ++k) {
    sum += cpfWithChecker1.toString().charAt(k) * (11 - k)
  }

  const lastSumChecker2 = sum % 11
  const checker2 = (lastSumChecker2 < 2) ? 0 : 11 - lastSumChecker2

  return checker2
}

const cpf_validate = value => {
  if (!value || value === true) {
    return
  }

  if (typeof value === 'number') {
    value = String(value)
  }

  const cleanCPF = value.replace(/\.|-|\s/g, '')
  const firstNineDigits = cleanCPF.substring(0, 9)
  const checker = cleanCPF.substring(9, 11)

  // Check if has dots
  if (value.length !== 11) {
    return false
  }

  if (cleanCPF.length !== 11) {
    return false
  }

  // Checking if all digits are equal
  for (let i = 0; i < 10; i++) {
    if (`${firstNineDigits}${checker}` === Array(12).join(i)) {
      return false
    }
  }

  const checker1 = cpfCalcChecker1(firstNineDigits)
  const checker2 = cpfCalcChecker2(`${firstNineDigits}${checker1}`)

  if (checker.toString() === checker1.toString() + checker2.toString()) {
    return true
  } else {
    return false
  }
}

function validate_cpf_cpnj(value) {
  if (!value || value === true) {
    return
  }

  const cleaned = value.replace(/\.|-|\s/g, '')
  if (cleaned.length < 12) {
    return cpf_validate(value)
  }
  return isValid(value)
}

export function yupRules() {
  setLocale({
    mixed: {
      default: 'Não é válido',
      required: 'Campo obrigatório',
    },
    string: {
      email: 'Email não é válido',
    },
    number: {
      min: 'Deve ser maior que ${min}',
    },
  })

  Yup.addMethod(Yup.string, 'validCPF', () => {
    return Yup.string().test('validCPF', 'CPF incorreto, digite somente números', (value) => validate_cpf_cpnj(value))
  })

  Yup.addMethod(Yup.string, 'validPhone', () => {
    return Yup.string().test('validPhone', 'Digite o DDD e o telefone', (value) => value ? value.length === 10 : true)
  })

  Yup.addMethod(Yup.string, 'validMobile', () => {
    return Yup.string().test('validMobile', 'Digite o DDD e o celular', (value) => value ? value.length === 11 : true)
  })

  Yup.addMethod(Yup.string, 'validCEP', () => {
    return Yup.string().test('validCEP', 'Digite o CEP', (value) => value ? (value.replace(/_/g, '')).length === 9 : true)
  })

  Yup.addMethod(Yup.string, 'validPlate', () => {
    return Yup.string().test('validPlate', 'Digite a placa', (value) => value ? (value.replace(/_/g, '')).length === 7 : true)
  })
}

export const field_opts = {
  person_status: [
    {value: 0, text: 'Inativo'},
    {value: 1, text: 'Ativo'},
    // {value: 2, text: 'Sem Acesso'},
  ],

  person_unit_status: [
    {value: 0, text: 'Inativo'},
    {value: 1, text: 'Ativo'},
  ],

  person_types: [
    {value: 1, text: 'Proprietário'},
    {value: 2, text: 'Inquilino'},
  ],

  unit_status: [
    {value: 'Habitada', text: 'Habitada'},
    {value: 'Desabitada', text: 'Desabitada'},
    {value: 'Disponível para aluguel', text: 'Disponível para aluguel'},
    {value: 'Em reforma', text: 'Em reforma'},
  ],

  sign_request_status: [
    {value: 1, text: 'Pendente'},
    {value: 2, text: 'Processada'},
    {value: 0, text: 'Rejeitada'},
  ],
}

export const getRemoteConfig = (path) => {
  let me = LocalDB.getItem('current_user')

  return gv(me, `team.configs.${path}`)
}

export const isRequired = (field) => {
  let result = getRemoteConfig(`general.gater__required_fields__${field}`)

  if (result === false) return false
  if (result === true) return true

  return true
}

export const isPrivate = (field) => {
  let result = getRemoteConfig(`general.gater__privacy_fields__${field}`)

  if (result === false) return false
  if (result === true) return true

  return true
}

export const getDynamicEnumValue = (value, path) => {
  let _enum = getRemoteConfig(path) ?? {}

  if (!value || typeof value === 'undefined' || value === '') {
    return null
  }

  if (!_enum || typeof _enum === 'undefined' || _enum === '') {
    return null
  }

  return _enum[value] ?? null
}
