export const FETCHING_DATA = 'FETCHING_DATA'
export const FETCHING_DATA_SUCCESS = 'FETCHING_DATA_SUCCESS'
export const FETCHING_DATA_FAILURE = 'FETCHING_DATA_FAILURE'
export const TOGGLE_MENU = 'TOGGLE_MENU'

export const SET_AUTH = 'SET_AUTH'
export const SENDING_REQUEST = 'SENDING_REQUEST'
export const REQUEST_ERROR = 'REQUEST_ERROR'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const LOGOUT = 'LOGOUT'

