import React, { useState } from 'react'
import { Button, Grid, Header, Icon, Item, Label, Message, Segment, Transition } from 'semantic-ui-react'
import AssignPwd from '../../../components/AssignPwd'
import CardEnroll from '../../../components/devices/CardEnroll'
import { GuestFaceEnroll } from '../../../components/devices/GuestFaceEnroll'
import ActivityLoading from '../../../components/Loading'
import { ProfileCache } from '../../../components/Utils'
import { AccessDriverTypesEnum } from '../../../constants/app-enums'
import { useLocalServerSettingsQuery } from '../../../hooks'
import { GuestModel } from '../../../typings'
import { AccessLogFormAuthTab } from './AccessLogFormAuthTab'
import { GuestEditModal } from './GuestEditModal'
import AssignBadgeNumber from '../../../components/AssignBadgeNumber'

type Props = {
  showGuestSearchModal: () => void
  enableToPool: () => void
  updateSelectedGuest: (data: any) => void
  handleAccessSource: (type: string, params: any) => void
  selected_guests: GuestModel[]
  error_guests: any
  destination_unit_id: string
}

const styles = {
  guest_item: {
    background: '#fff',
    boxShadow: '0 1px 2px 0 rgba(34,36,38,.15)',
    padding: '0.5em 0.5em',
    borderRadius: '.28571429rem',
    border: '1px solid rgba(34,36,38,.15)'
  }
}

export const GuestHandlerGrid = (props: Props) => {
  const { data: settings } = useLocalServerSettingsQuery()
  const [activity_loading, setActivityLoading] = useState<boolean>(false)
  const [edit_modal, setEditModal] = useState<boolean>(false)

  const removeGuest = (id: number | string) => {
    let selectedGuest = props.selected_guests
    const i = selectedGuest.findIndex(item => item.id == id)

    if (i !== -1) {
      selectedGuest.splice(i, 1)
      props.updateSelectedGuest(selectedGuest)
    }
  }

  // ACCESS DRIVER PARTS
  const [current_guest, setCurrentGuest] = useState<GuestModel | null>()
  const [show_card_modal, setShowCardModal] = useState<boolean>(false)
  const [show_pwd_modal, setShowPwdModal] = useState<boolean>(false)
  const [show_face_modal, setShowFaceModal] = useState<boolean>(false)
  const [show_badge_modal, setShowBadgeModal] = useState<boolean>(false)

  const _anviz_guest_control = settings?.anviz__status === '1' && settings?.anviz__guest_control === '1'
  const _controlid_guest_control = settings?.controlid__status === '1' && settings?.controlid__guest_control === '1'
  const _placa_ethernet_status = settings?.placa_ethernet__status === '1' && settings?.placa_ethernet__guest_control === '1'
  const _nk_guest_control = settings?.nk__status === '1' && settings?.nk__guest_control === '1'
  const _dahua_guest_fp_card = settings?.dahua__status === '1' && settings?.dahua__guest_fp_card === '1'
  const _zk_face_guest_control = settings?.zk__face === '1'

  const guest_control__card = settings?.guest_control__card === '1'
  const guest_control__fingerprint = settings?.guest_control__fingerprint === '1'
  const guest_control__pwd = settings?.guest_control__pwd === '1'
  const guest_control__face = settings?.guest_control__face === '1'
  const guest_control__qrcode = settings?.guest_control__qr_code === '1'

  const is_enabled_guest_control = _anviz_guest_control || _controlid_guest_control || _placa_ethernet_status || _nk_guest_control ||
    _dahua_guest_fp_card || _zk_face_guest_control || guest_control__qrcode

  const is_enabled_guest_card = _anviz_guest_control || _controlid_guest_control || _placa_ethernet_status || _dahua_guest_fp_card ||
    guest_control__card
  const is_enabled_guest_pwd = _nk_guest_control || guest_control__pwd
  const is_enabled_guest_face = guest_control__face

  const showCardModal = (item: GuestModel) => {
    setCurrentGuest(item)
    setShowCardModal(true)
  }

  const showBadgeModal = (item: GuestModel) => {
    setCurrentGuest(item)
    setShowBadgeModal(true)
  }

  const showPwdModal = (item: GuestModel) => {
    setCurrentGuest(item)
    setShowPwdModal(true)
  }

  const showFaceModal = (item: GuestModel) => {
    setCurrentGuest(item)
    setShowFaceModal(true)
  }

  const hideAccessDriverAssignerModal = () => {
    setShowCardModal(false)
    setShowBadgeModal(false)
    setShowPwdModal(false)
    setShowFaceModal(false)
    setCurrentGuest(null)
  }

  const removeAllAccessDriverType = (data: GuestModel) => {
    let selectedGuest = props.selected_guests
    const i = selectedGuest.findIndex(item => item.id === data.id)

    if (i !== -1) {
      data['access_driver_type'] = null
      data['access_driver_serial_hex'] = null
      data['access_driver_serial_dec'] = null
      data['access_driver_wiegand'] = null
      data['access_driver_card_company_code'] = null
      data['access_driver_face'] = null
      data['create_automatic_access_auth'] = false

      // deprecated
      data['access_driver_code'] = null

      selectedGuest[i] = data

      props.updateSelectedGuest(selectedGuest)
    }
  }

  const addQrCode = (data: GuestModel) => {
    let selectedGuest = props.selected_guests
    const i = selectedGuest.findIndex(item => item.id === data.id)

    if (i !== -1) {
      data['create_automatic_access_auth'] = true
      data['access_driver_type'] = AccessDriverTypesEnum.qrcode

      selectedGuest[i] = data

      props.updateSelectedGuest(selectedGuest)
    }
  }

  const addPwd = (values: any) => {
    if (current_guest) {
      let selectedGuest = props.selected_guests
      const i = selectedGuest.findIndex(item => item.id === current_guest.id)

      if (i !== -1) {
        current_guest['access_driver_code'] = '' + values.pwd

        selectedGuest[i] = current_guest

        props.updateSelectedGuest(selectedGuest)
        props.enableToPool()
        hideAccessDriverAssignerModal()
      }
    }
  }

  const addBadgeNumber = (values: any) => {
    if (current_guest) {
      let selectedGuest = props.selected_guests
      const i = selectedGuest.findIndex(item => item.id === current_guest.id)

      if (i !== -1) {
        current_guest['badge_number'] = '' + values.badge_number

        selectedGuest[i] = current_guest

        props.updateSelectedGuest(selectedGuest)
        hideAccessDriverAssignerModal()
      }
    }
  }

  const addCard = (values: any) => {
    if (current_guest) {
      let selectedGuest = props.selected_guests
      const i = selectedGuest.findIndex(item => item.id === current_guest.id)

      if (i !== -1) {
        current_guest['access_driver_type'] = AccessDriverTypesEnum.card
        current_guest['access_driver_wiegand'] = '' + values.code
        current_guest['access_driver_serial_dec'] = '' + values.alias
        current_guest['access_driver_card_company_code'] = '' + values.facility_code

        current_guest['create_automatic_access_auth'] = true

        current_guest['access_group'] = values.access_group

        selectedGuest[i] = current_guest

        props.updateSelectedGuest(selectedGuest)
        hideAccessDriverAssignerModal()
      }
    }
  }

  const addFace = (values: any) => {
    if (current_guest) {
      current_guest['access_driver_type'] = AccessDriverTypesEnum.face

      current_guest['access_driver_code'] = '' + current_guest.photo_url
      // current_guest['access_driver_face'] = '' + current_guest.photo_url

      current_guest['create_automatic_access_auth'] = true

      current_guest['access_group'] = values.access_group

      let selectedGuest = props.selected_guests
      const i = selectedGuest.findIndex(item => item.id === current_guest.id)

      if (i !== -1) {
        selectedGuest[i] = current_guest

        props.updateSelectedGuest(selectedGuest)
        hideAccessDriverAssignerModal()
      }
    }
  }

  // GUEST EDIT PARTS
  const showEditModal = (item: GuestModel) => {
    setCurrentGuest(item)
    setEditModal(true)
  }

  const hideEditModal = () => {
    setEditModal(false)
    setCurrentGuest(null)
  }

  const handleEditGuestAssign = (values: GuestModel) => {
    console.log('values', values)
    let selectedGuest = props.selected_guests
    const i = selectedGuest.findIndex(item => item.id === values.id)

    if (i !== -1) {
      selectedGuest[i] = values
    } else {
      selectedGuest.push(values)
    }

    props.updateSelectedGuest(selectedGuest)
    hideEditModal()
  }

  // ACCESS AUTH PARTS
  const handleAuthGuest = (params: any) => {
    props.handleAccessSource('accessauthorization', params)
  }

  return (
    <React.Fragment>
      <Grid>
        <Grid.Column width={10}>
          {props.error_guests &&
            <Message negative size="mini">
              <Message.Header>Adicione pelo menos um visitante!</Message.Header>
            </Message>}

          <Segment basic style={{ padding: 0, margin: 0 }}>
            <Header as="h4" floated="right" style={{ marginRight: 0 }}>
              <Button type="button" color={'green'} style={{ marginRight: 0 }} onClick={props.showGuestSearchModal}>
                Adicionar visitante
              </Button>
            </Header>

            <Header as="h4" floated="left">
              VISITANTES SELECIONADOS
            </Header>
          </Segment>

          <Transition.Group duration={600} animation="pulse" as={Item.Group}>
            {props.selected_guests.map(item => (
              <Item key={item.id} style={styles.guest_item}>
                <div className="ui tiny image">
                  <ProfileCache src={item.photo_url}/>
                </div>

                <Item.Content verticalAlign="middle">
                  <Item.Header>{item.name}</Item.Header>

                  <div>
                    {item.badge_number &&
                      <Label size="medium" color="violet">
                        <Icon name="id badge"/> {item.badge_number}
                      </Label>}

                    <Button color={'red'} icon basic size="mini" floated="right" type="button" onClick={() => removeGuest(item.id)}>
                      <Icon name="trash"/>
                    </Button>

                    <Button color="green" basic size="mini" floated="right" type="button" onClick={() => showEditModal(item)}>
                      Editar
                    </Button>

                    <Button primary basic size="mini" floated="right" type="button" onClick={() => showBadgeModal(item)}>
                      Associar Crachá
                    </Button>
                  </div>

                  {is_enabled_guest_control &&
                    <React.Fragment>
                      {item && (item.access_driver_code || item.access_driver_serial_dec || item.access_driver_wiegand) &&
                        <Button color="red" basic size="mini" type="button" onClick={() => removeAllAccessDriverType(item)}>
                          Remover Acionador
                        </Button>}

                      {item &&
                        !(item.access_driver_code || item.access_driver_serial_dec || item.access_driver_wiegand) &&
                        is_enabled_guest_card &&
                        <Button primary basic size="mini" type="button" onClick={() => showCardModal(item)}>
                          Associar Cartão
                        </Button>}

                      {item &&
                        !(item.access_driver_code || item.access_driver_serial_dec) &&
                        is_enabled_guest_pwd &&
                        <Button primary basic size="mini" type="button" onClick={() => showPwdModal(item)}>
                          Associar Senha
                        </Button>}

                      {item &&
                        !(item.access_driver_code || item.access_driver_face) &&
                        is_enabled_guest_face &&
                        <Button primary basic size="mini" type="button" onClick={() => showFaceModal(item)}>
                          Associar Face
                        </Button>}

                    </React.Fragment>}

                  {guest_control__qrcode &&
                    <React.Fragment>
                      {item && item.create_automatic_access_auth &&
                        <Button color="red" basic size="mini" type="button" onClick={() => removeAllAccessDriverType(item)}>
                          Remover QRCode
                        </Button>}

                      {item && !item.create_automatic_access_auth &&
                        <Button primary basic size="mini" type="button" onClick={() => addQrCode(item)}>
                          Liberar QRCode
                        </Button>}
                    </React.Fragment>}

                </Item.Content>
              </Item>
            ))}
          </Transition.Group>
        </Grid.Column>

        <Grid.Column width={6}>
          <AccessLogFormAuthTab unit_id={props.destination_unit_id} handleAuthGuest={handleAuthGuest}/>
        </Grid.Column>
      </Grid>

      {current_guest && show_card_modal &&
        <CardEnroll
          setClose={hideAccessDriverAssignerModal}
          handleAssign={addCard}

          person={current_guest}
          resident={false}
          custom_access_group={false}
        />}

      {current_guest && show_pwd_modal &&
        <AssignPwd
          setClose={hideAccessDriverAssignerModal}
          handleAssign={addPwd}

          person={current_guest}
        />}

      {current_guest && show_badge_modal &&
        <AssignBadgeNumber
          setClose={hideAccessDriverAssignerModal}
          handleAssign={addBadgeNumber}

          person={current_guest}
        />}

      {current_guest && show_face_modal &&
        <GuestFaceEnroll
          setClose={hideAccessDriverAssignerModal}
          handleAssign={addFace}
          person={current_guest}
        />}

      {current_guest && edit_modal &&
        <GuestEditModal
          setClose={hideEditModal}
          guest_id={current_guest.id}
          pushToSelectedGuest={handleEditGuestAssign}
        />}

      <ActivityLoading visible={activity_loading}/>
    </React.Fragment>
  )
}