import ToasterService from './services/ToasterService'
import { localFetchApi, localFetchApi_ValidateLocalServer, localPostApi } from './utils/LocalApi'
import { LocalDB } from './utils/LocalDB'

const settings = {
  all: async () => {
    interface HTTPResponse {
      _server_version: number;
      _auto_configure_devices: string;
      _disable_toast: string;
      _down_photos: string;
      _fingerprint_capture_mode: string;
      _inter_port: string;
      _job_bulk_sync_event_remote_qtd: string;
      _server: string;
      _sync_event_remote: string;
      _team: string;
      _team_name: string;
      _websocket: string;
      anviz__guest_control: string;
      anviz__id: string;
      anviz__keepalive: string;
      anviz__read_events_progressive: string;
      anviz__read_events_progressive_on_realtime: string;
      anviz__server_port: string;
      anviz__status: string;
      bravas__status: string;
      controlid__card: string;
      controlid__default: string;
      controlid__face: string;
      controlid__guest_control: string;
      controlid__guest_group: string;
      controlid__idbox: string;
      controlid__invalid_exit: string;
      controlid__pwd: string;
      controlid__reader_controlid: string;
      controlid__status: string;
      controlid__tag: string;
      controlid__user_group: string;
      dahua__face: string;
      dahua__fp_card: string;
      dahua__polling_interval: string;
      dahua__server_listener: string;
      dahua__status: string;
      dahua__guest_fp_card: string;
      guarita__authcode: string;
      guarita__id: string;
      guarita__read_events_progressive: string;
      guarita__server_port: string;
      guarita__serverauthcode: string;
      guarita__slave_port: string;
      guarita__status: string;
      hikvision__default: string;
      hikvision__face: string;
      hikvision__fp_card: string;
      hikvision__polling_interval: string;
      hikvision__server_listener: string;
      hikvision__status: string;
      nk__can_access: string;
      nk__guest_control: string;
      nk__pwd: string;
      nk__status: string;
      placa_ethernet__guest_control: string;
      placa_ethernet__server_port: string;
      placa_ethernet__status: string;
      guest_control__qr_code: string;
      guest_control__card: string;
      guest_control__fingerprint: string;
      guest_control__pwd: string;
      guest_control__face: string;
      zk__face: string;
      zk__id: string;
      zk__pull_polling_interval: string;
      zk__status: string;
    }

    let response: HTTPResponse = await localFetchApi_ValidateLocalServer('settings', 1000 * 15)
    LocalDB.setItem('settings', response)

    return response
  },
  list_all: async () => {
    return await localFetchApi('settings/list_all')
  }
}

const utils = {
  pingServer: async () => {
    return await localFetchApi_ValidateLocalServer('utils/ping_server')
  }
}

const devices_manager = {
  takeRelay: async (device_id, relay_id, relay, can, receptor_type) => {
    const data = {
      device_id: device_id,
      relay_id: relay_id,
      relay: relay,
      can: can,
      receptor_type: receptor_type
    }

    return await localPostApi('devices-manager/take_relay', data)
  },
  remoteReadCode: async () => {
    return await localFetchApi('devices-manager/remote_read_code', 15 * 1000)
  },
  printQRCodes: async (printer_ip, printer_port, title, qr_codes) => {
    try {
      const data = {
        printer_ip: printer_ip,
        printer_port: printer_port,
        title: title,
        qr_codes: qr_codes
      }

      return await localPostApi('devices-manager/print_qr_codes', data)

    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  enrollFaceClient: async (user_id, name, face_b64, access_group) => {
    try {
      const data = {
        face: face_b64,
        user_id: user_id,
        name: name,
        access_group: access_group
      }

      return await localPostApi('devices-manager/enroll_face_client', data, 60 * 1000)

    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  getAllUserIds: async () => {
    try {
      return await localFetchApi('devices-manager/get_all_user_ids', 10 * 60 * 1000)

    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
}

const events = {
  lastEvents: async () => {
    interface HTTPResponse {
      id: number;
      access_device: AccessDevice | null;
      access_relay: AccessRelay | null;
      access_device_id: number | null;
      access_device_name: null | string;
      access_relay_id?: number;
      access_relay_name?: string;
      created_at: string;
      updated_at: string;
      event: string;
      date: string;
      name: string;
      personable_id: number | null;
      original_personable_id: number | null;
      person_type: string;
      unit: Unit | null;
      unit_name: null | string;
      driver_type: null | string;
      driver_code: null | string;
      panic: boolean;
      access_log_id: null;
      receptor: null | string;
      can: number | null;
      key: number | null;
      battery: null | string;
      raw_event: string;
      is_sync: boolean;
      event_uuid: string;
    }

    interface AccessDevice {
      id: number;
      brand: number;
      model: number;
      name: string;
      description: string;
      status: number;
      type: number;
      ip: string;
      port: string;
      mac: null;
      serial: null;
    }

    interface AccessRelay {
      id: number;
      name: string;
      status: number;
      type: number;
      relay: number;
      receptor_type: number;
      can: number;
      way: number;
      access_type: number;
      take_relay: boolean;
      take_access: boolean;
      dispenser: boolean;
      control_parking: boolean;
      access_device: number;
    }

    interface Unit {
      id: number;
      area: null;
      code: string;
      type: number;
      status: number;
      street: null;
      unit_id: number;
      block_id: number;
      residents: null;
      pivot_type: number;
      with_block: string;
      pivot_status: number;
      static_debtor: boolean;
    }

    let res: HTTPResponse[] = await localFetchApi_ValidateLocalServer('events/last_events')
    return res || []
  }
}

const cache = {
  drop_cache_data: async (key: string) => {
    const data = {
      key
    }

    return await localPostApi('cache/drop_cache_data', data)
  },
  dropAllCacheData: async () => {
    return await localPostApi('cache/drop_all_cache_data', {})
  },
  cache_updates: async (key: string) => {
    try {
      const data = {
        key
      }
      await localPostApi('cache/cache_updates', data)
    } catch (e) {
      console.error(e)
    }
  }
}

export const qs_local_server = {
  settings,
  utils,
  devices_manager,
  events,
  cache
}
