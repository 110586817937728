import localForage from 'localforage'
import moment from 'moment'
import enviroment from '../enviroment'
import {apiAxios} from '../services/api'
import ToasterService from '../services/ToasterService'
import {AccessDriverModel} from '../typings'
import {show_modal} from '../utils/Formatters'
import {putCache} from '../utils/LocalApi'
import {qs} from './index'

export const access_drivers = {
  cache_key: '@ACCESS_DRIVERS_CACHE@',
  last_update: '@ACCESS_DRIVERS_CACHE_LAST_UPDATE@',

  allCached: async () => {
    let data = await localForage.getItem<object>(access_drivers.cache_key)

    if (!data) {
      return await access_drivers.cacheUpdates()
    }

    return Object.values(data)
  },
  cacheUpdates: async () => {
    interface HTTPResponse {
      data: AccessDriverModel[]
    }

    let drivers_cached = await localForage.getItem<object>(access_drivers.cache_key) ?? {}
    let last_update = await localForage.getItem<string>(access_drivers.last_update)
    let url = ''

    if (last_update) {
      // Menos quanto horas para evitar possíveis bugs com fuso
      last_update = moment(last_update).subtract(4, 'hours').toISOString()

      url = 'admin/access-drivers' +
        '?include[]=person.name' +
        '&include[]=vehicle.plate' +
        '&include[]=parking.code' +
        '&include[]=unit.with_block' +
        '&filter{updated_at.gte}=' + last_update + '&sort[]=id&limit=20000'

    } else {
      url = 'admin/access-drivers' +
        '?include[]=person.name' +
        '&include[]=vehicle.plate' +
        '&include[]=parking.code' +
        '&include[]=unit.with_block'
    }

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + url)
    const res_data = data.data

    if (res_data.length) {
      for (let o of res_data) {
        drivers_cached[o.id] = o
      }

      await localForage.setItem(access_drivers.cache_key, drivers_cached)
    }

    await localForage.setItem(access_drivers.last_update, moment().toISOString())

    try {
      // Add backwards integration with old server version
      putCache('access_drivers', Object.values(drivers_cached), { timeout: 40 * 1000 })
    } catch (e) { }

    return Object.values(drivers_cached)
  },
  post: async (values: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/access-drivers`, values)

      // Not wait
      qs.access_drivers.cacheUpdates()

      return data
    } catch (e) {
      show_modal('Erro ao cadastrar o acionador no servidor!', 'error')
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  patch: async (values: any, id: string | number) => {
    try {
      const { data } = await apiAxios.patch(enviroment().apiUrl + `admin/access-drivers/${id}`, values)

      // Not wait
      qs.access_drivers.cacheUpdates()

      return data
    } catch (e) {
      show_modal('Erro ao atualizar o acionador no servidor!', 'error')
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  delete: async (item) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/access-drivers/delete_all`, item)

      // Not wait
      qs.access_drivers.cacheUpdates()

      return data
    } catch (e) {
      show_modal('Erro ao excluir o acionador no servidor!', 'error')
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  filterByCode: async (code: string) => {
    try {
      interface HTTPResponse {
        count: number;
        next: null;
        previous: null;
        data: AccessDriverModel[];
      }

      const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + `admin/access-drivers?filter{code.icontains}=${code.toUpperCase()}`)
      return data.data
    } catch (e) {
      show_modal('Erro ao buscar o acionador!', 'error')
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },
  filterByPersonId: async (person_id: string | number) => {
    try {
      interface HTTPResponse {
        count: number;
        next: null;
        previous: null;
        data: AccessDriverModel[];
      }

      const { data } = await apiAxios.get<HTTPResponse>(
        enviroment().apiUrl + `admin/access-drivers?filter{person_id}=${person_id}&filter{deleted.isnull}=true&include[]=unit&include[]=vehicle`)
      return data.data
    } catch (e) {
      show_modal('Não foi possível recuperar os acionadores!', 'error')
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}