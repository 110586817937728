import {fetchBaseQuery} from './query_utils'

interface Datum {
  id: number;
  access_relay: AccessRelay;
  created_at: string;
  updated_at: string;
  status: number;
  description: string;
  request_pwd: boolean;
  show_app_gater: boolean;
  show_app_resident: boolean;
  allow_owner: boolean;
  allow_renter: boolean;
  allow_dependent: boolean;
  check_gps: boolean;
  max_gps_ratio: number;
  created_by: number;
  updated_by: number;
  access_relay_id: number;
  created_by_id: number;
  updated_by_id: number;
}

interface AccessRelay {
  id: number;
  with_access_device: string;
  deleted: null;
  created_at: string;
  updated_at: string;
  name: string;
  status: number;
  type: number;
  relay: number;
  receptor_type: number;
  can: number;
  way: number;
  access_type: number;
  take_relay: boolean;
  take_access: boolean;
  dispenser: boolean;
  control_parking: boolean;
  created_by: number;
  updated_by: null;
  deleted_by: null;
  access_device_id: number;
  created_by_id: number;
  updated_by_id: null;
  deleted_by_id: null;
}

const cache_key = 'ACCESS_BUTTONS'

const fetchQuery = fetchBaseQuery<Datum>({
  key: cache_key,
  url: 'admin/access-buttons?filter{show_app_gater}=true&include[]=access_relay.id',
  // six hours
  stale_time: 60 * 60 * 6
})

export const access_buttons = {
  allCached: fetchQuery.allCached
}