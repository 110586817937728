import React, { Component } from 'react'

//
//
// https://github.com/mozmorris/react-webcam
//
//

function hasGetUserMedia() {
  return !!(
    (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
  )
}

export default class Webcam extends Component {
  // static propTypes = {
  //   audio: PropTypes.bool,
  //   onUserMedia: PropTypes.func,
  //   onUserMediaError: PropTypes.func,
  //   height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   screenshotFormat: PropTypes.oneOf([
  //     'image/webp',
  //     'image/png',
  //     'image/jpeg',
  //   ]),
  //   style: PropTypes.object,
  //   className: PropTypes.string,
  //   screenshotQuality: PropTypes.number,
  //   minScreenshotWidth: PropTypes.number,
  //   minScreenshotHeight: PropTypes.number,
  //   audioConstraints: audioConstraintType,
  //   videoConstraints: videoConstraintType,
  //   imageSmoothing: PropTypes.bool,
  // }

  static defaultProps = {
    audio: true,
    className: '',
    height: 480,
    imageSmoothing: true,
    onUserMedia: () => {},
    onUserMediaError: () => {},
    screenshotFormat: 'image/webp',
    width: 640,
    minScreenshotWidth: 500,
    minScreenshotHeight: 300,
    screenshotQuality: 0.8,
  }

  static mountedInstances = []

  static userMediaRequested = false

  constructor() {
    super()
    this.state = {
      hasUserMedia: false,
    }
  }

  componentDidMount() {
    if (!hasGetUserMedia()) return

    Webcam.mountedInstances.push(this)

    if (!this.state.hasUserMedia && !Webcam.userMediaRequested) {
      this.requestUserMedia()
    }
  }

  componentWillUpdate(nextProps) {
    if (
      JSON.stringify(nextProps.audioConstraints) !==
      JSON.stringify(this.props.audioConstraints) ||
      JSON.stringify(nextProps.videoConstraints) !==
      JSON.stringify(this.props.videoConstraints)
    ) {
      this.requestUserMedia()
    }
  }

  componentWillUnmount() {
    const index = Webcam.mountedInstances.indexOf(this)
    Webcam.mountedInstances.splice(index, 1)

    Webcam.userMediaRequested = false
    if (Webcam.mountedInstances.length === 0 && this.state.hasUserMedia) {
      if (this.stream.getVideoTracks && this.stream.getAudioTracks) {
        this.stream.getVideoTracks().map(track => track.stop())
        this.stream.getAudioTracks().map(track => track.stop())
      } else {
        this.stream.stop()
      }
      window.URL.revokeObjectURL(this.state.src)
    }
  }

  getScreenshot() {
    if (!this.state.hasUserMedia) return null

    const canvas = this.getCanvas()
    return (
      canvas &&
      canvas.toDataURL(
        this.props.screenshotFormat,
        this.props.screenshotQuality,
      )
    )
  }

  getCanvas() {
    if (!this.state.hasUserMedia || !this.video.videoHeight) return null

    if (!this.ctx) {
      const canvas = document.createElement('canvas')
      const aspectRatio = this.video.videoWidth / this.video.videoHeight

      var canvasWidth = this.props.minScreenshotWidth || this.video.clientWidth
      var canvasHeight = canvasWidth / aspectRatio

      if (this.props.minScreenshotHeight && (canvasHeight < this.props.minScreenshotHeight)) {
        canvasHeight = this.props.minScreenshotHeight
        canvasWidth = canvasHeight * aspectRatio
      }

      canvas.width = canvasWidth
      canvas.height = canvasHeight

      this.canvas = canvas
      this.ctx = canvas.getContext('2d')
    }

    const {ctx, canvas} = this
    ctx.imageSmoothingEnabled = this.props.imageSmoothing
    ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height)

    return canvas
  }

  requestUserMedia() {
    navigator.getUserMedia =
      navigator.mediaDevices.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia

    const sourceSelected = (audioConstraints, videoConstraints) => {
      const constraints = {
        video: videoConstraints || true,
      }

      if (this.props.audio) {
        constraints.audio = audioConstraints || true
      }

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          Webcam.mountedInstances.forEach(instance =>
            instance.handleUserMedia(null, stream),
          )
        })
        .catch((e) => {
          Webcam.mountedInstances.forEach(instance =>
            instance.handleUserMedia(e),
          )
        })
    }

    if ('mediaDevices' in navigator) {
      sourceSelected(this.props.audioConstraints, this.props.videoConstraints)
    } else {
      const optionalSource = id => ({optional: [{sourceId: id}]})

      const constraintToSourceId = (constraint) => {
        const deviceId = (constraint || {}).deviceId

        if (typeof deviceId === 'string') {
          return deviceId
        } else if (Array.isArray(deviceId) && deviceId.length > 0) {
          return deviceId[0]
        } else if (typeof deviceId === 'object' && deviceId.ideal) {
          return deviceId.ideal
        }

        return null
      }

      MediaStreamTrack.getSources((sources) => {
        let audioSource = null
        let videoSource = null

        sources.forEach((source) => {
          if (source.kind === 'audio') {
            audioSource = source.id
          } else if (source.kind === 'video') {
            videoSource = source.id
          }
        })

        const audioSourceId = constraintToSourceId(this.props.audioConstraints)
        if (audioSourceId) {
          audioSource = audioSourceId
        }

        const videoSourceId = constraintToSourceId(this.props.videoConstraints)
        if (videoSourceId) {
          videoSource = videoSourceId
        }

        sourceSelected(
          optionalSource(audioSource),
          optionalSource(videoSource),
        )
      })
    }

    Webcam.userMediaRequested = true
  }

  handleUserMedia(err, stream) {
    if (err) {
      this.setState({hasUserMedia: false})
      this.props.onUserMediaError(err)

      return
    }

    this.stream = stream

    try {
      this.video.srcObject = stream
      this.setState({hasUserMedia: true})
    } catch (error) {
      this.setState({
        hasUserMedia: true,
        src: window.URL.createObjectURL(stream),
      })
    }

    this.props.onUserMedia()
  }

  render() {
    return (
      <video
        autoPlay
        width={this.props.width}
        height={this.props.height}
        src={this.state.src}
        muted={this.props.audio}
        className={this.props.className}
        playsInline
        style={this.props.style}
        ref={(ref) => {
          this.video = ref
        }}
      />
    )
  }
}