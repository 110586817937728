import React from 'react'
import ParkingGrid from './ParkingGrid'

export default class ParkingsListPage extends React.Component {
  render() {
    return (
      <div>
        <h1 className="ui huge header">
          Vagas
        </h1>

        <div className="ui divider hidden"/>

        <ParkingGrid componentParent={this}/>
      </div>
    )
  }
}
