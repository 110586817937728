/*eslint-disable no-undef*/

import { Field, FieldArray, Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { toast as toastr } from 'react-toastify'
import { Button, Form, Message, Segment, Table } from 'semantic-ui-react'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'
import swal from 'sweetalert2'
import PageBase from '../components/PageBase'
import ServerServiceController from '../components/ServerServiceController'
import WebcamOld from '../components/WebcamOld'
import enviroment from '../enviroment'
import { qs } from '../queries'
import { qs_local_server } from '../queries-local-server'
import ToasterService from '../services/ToasterService'
import UpdaterService from '../services/UpdaterService'
import { push_toast } from '../utils/common'
import { formUtils } from '../utils/form'
import { show_modal_once } from '../utils/Formatters'
import { localFetchApi, localPostApi, localPostApi_ValidateLocalServer, localPutApi } from '../utils/LocalApi'
import { LocalDB } from '../utils/LocalDB'
import { people } from '../queries/people'
import localForage from 'localforage'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export default class PanelPage extends React.Component {
  worker = null

  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      settings: [],
      settings_map: [],
      pwd: ''
    }
  }

  componentDidMount() {
    this.worker = setInterval(() => {
      this.setState({ pwd: '' })
    }, 1000 * 60 * 6)

    this.getSettingsList()
    qs_local_server.settings.all().then(res => this.setState({ settings_map: res }))
  }

  componentWillUnmount() {
    clearInterval(this.worker)
  }

  getSettingsList = () => {
    qs_local_server.settings.list_all().then(res => this.setState({ settings: res }))
  }

  render() {
    let cur_user = LocalDB.getItem('current_user')

    if (this.state.pwd === moment().format('DDYYMM')) {
      return (
        <PageBase title="Painel" create={false} showFilter={false}>
          <h2>Versão Servidor: {this.state.settings_map?._server_version}</h2>
          <h2>Código Condomínio: {cur_user?.team?.schema_name}</h2>

          <br/>

          <Segment>
            <Button
              icon="refresh" content="Recarregar"
              onClick={() => {
                window.location.reload(true)
                window.location.reload(true)
              }}
            />

            <Button
              icon="cog" content="Debug"
              onClick={() => {
                CefSharp.BindObjectAsync('condoUtils').then(async () => {
                  await condoUtils.showDevTools()
                })
              }}
            />

            <Button
              icon="lock" content="Autenticar Servidor"
              onClick={() => {
                qs.user.me().then(() => {
                  show_modal_once('Processado! Verique o arquivo "settings.ini" se foi aplicado o token.')
                })
              }}
            />

            <Button
              content="Configurar Equipamentos"
              onClick={() => {
                localPostApi_ValidateLocalServer('devices-manager/setup_devices', {})
              }}
            />

            <Button
              content="Limpar Cache - Portaria"
              onClick={async () => {
                await localForage.clear()
                toastr.success('Cache limpo!')
              }}
            />

            <Button
              content="Resetar Cache"
              onClick={async () => {
                const question = await swal({
                  title: 'Tem certeza?',
                  text: 'Reiniciar o servidor após a operação!',
                  type: 'question'
                })

                console.log('question', question)

                if (question.value) {
                  ToasterService.warning('Aguarde!')

                  qs_local_server.cache.drop_cache_data('CacheState').then(() => {
                    show_modal_once('Sucesso!')
                  })
                }
              }}
            />

            <Button
              content="Excluir acionadores expirados (ult. 30 dias)"
              onClick={async () => {
                localFetchApi('access-manager_v2/delete_expired_access_auths_by_date?days_to_back=30', 1000 * 90)
              }}
            />

            <Button
              content="VERIFICAR usuários inválidos nos equipamentos"
              onClick={async () => {
                const res_dev_user_ids = await qs_local_server.devices_manager.getAllUserIds()

                let dev_user_ids = [...new Set(Object.values(res_dev_user_ids).flat()).values()]
                dev_user_ids = dev_user_ids.map(x => String(x))

                let people_active_ids = await people.idsWithUnitsActive()
                people_active_ids = people_active_ids.map(x => String(x))

                console.log('dev_user_ids', JSON.stringify(dev_user_ids))
                console.log('people_active_ids', JSON.stringify(people_active_ids))

                console.log('dev_user_ids .length', dev_user_ids.length)
                console.log('people_active_ids .length', people_active_ids.length)

                const ids_to_delete = []

                for (let i of dev_user_ids) {
                  if (people_active_ids.indexOf(i) === -1) {
                    ids_to_delete.push(i)
                  }
                }

                console.log('ids_to_delete', JSON.stringify(ids_to_delete))

              }}
            />

            <Button
              content="EXCLUIR usuários inválidos nos equipamentos"
              onClick={async () => {
                const res_dev_user_ids = await qs_local_server.devices_manager.getAllUserIds()

                let dev_user_ids = [...new Set(Object.values(res_dev_user_ids).flat()).values()]
                dev_user_ids = dev_user_ids.map(x => String(x))

                let people_active_ids = await people.idsWithUnitsActive()
                people_active_ids = people_active_ids.map(x => String(x))

                console.log('dev_user_ids', JSON.stringify(dev_user_ids))
                console.log('people_active_ids', JSON.stringify(people_active_ids))

                console.log('dev_user_ids .length', dev_user_ids.length)
                console.log('people_active_ids .length', people_active_ids.length)

                const ids_to_delete = []

                for (let i of dev_user_ids) {
                  if (people_active_ids.indexOf(i) === -1) {
                    ids_to_delete.push(i)
                  }
                }

                console.log('ids_to_delete', JSON.stringify(ids_to_delete))

                for (let s of ids_to_delete) {
                  localPostApi('devices-manager/delete_all_data_user', { user_id: s }, 60 * 1000)

                  await sleep(800)
                }

              }}
            />
          </Segment>

          <Segment>
            <h2>Comandos Servidor</h2>
            <ServerServiceController show_install={true}/>
          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Atualizar Servidor</h2>

            <Message error icon="ban" header="Pare o servidor antes de iniciar esse processo!" size="small"/>

            <Button
              content="1 - Baixar Nova Atualização"
              onClick={UpdaterService.downloadServerUpdate}
            />

            <Button
              content="2 - Instalar Novas Dependências"
              onClick={UpdaterService.installServerUpdate}
            />

            <Button
              content="3 - Finalizar"
              onClick={UpdaterService.finishServerUpdate}
            />

          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Configuração Servidor</h2>

            <Message error icon="ban" header="Reinicie o servidor para aplicar as configurações!" size="small"/>

            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Chave</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    settings: this.state.settings
                  }}
                  onSubmit={values => {}}
                  render={({ values }) => (
                    <FieldArray
                      name="settings"
                      render={() => (
                        values.settings.map((item, index) => (
                          <Table.Row key={index}>
                            <Table.Cell>{item.key}</Table.Cell>
                            <Table.Cell>
                              <div className="ui form mini">
                                <Field name={`settings.${index}.value`}/>
                              </div>
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <Button
                                icon="save"
                                onClick={() => {
                                  toastr.info('Atualizando...')

                                  localPutApi(`settings/${item.key}`, { value: values.settings[index].value }).then(res => {
                                    toastr.success('Atualizado!')
                                    this.getSettingsList()
                                  }).catch(err => toastr.error('Erro, verifique os logs!'))
                                }}
                              />
                            </Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    />
                  )}
                />
              </Table.Body>
            </Table>
          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Anviz</h2>

            <Formik
              initialValues={{
                config2_params: ''
              }}
              render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <form onSubmit={handleSubmit} className="ui form large error">
                  <div className={errors.card ? 'field error' : 'field'}>
                    <Button onClick={() => localPostApi('anviz/update_datetime', {})}>Atualizar Data</Button>
                    <Button onClick={() => localFetchApi('anviz/get_config_2')}>Get Config 2</Button>
                    <Button onClick={() => localPostApi('anviz/set_config_2', JSON.parse(values.config2_params))}>
                      Alterar Config 2
                    </Button>


                    <br/>
                    <br/>
                    <label>Config 2 Params:</label>
                    <div className="ui input">
                      <textarea name="config2_params" onChange={handleChange} onBlur={handleBlur} value={values.config2_params}/>
                    </div>
                  </div>
                </form>
              )}/>
          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Control Id</h2>
            <Button onClick={() => localPostApi('controlid/setup_devices', {})}>Setup devices</Button>
          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Módulo Guarita</h2>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell/>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    Sincronizar Dispositivos. Necessário sincronizar Acionadores e Moradores primeiro.
                  </Table.Cell>

                  <Table.Cell textAlign="right">
                    <Button
                      icon="save"
                      onClick={() => {
                        swal.fire({
                          title: 'Gostaria de sincronizar todos os acionadores? Essa comando irá excluir os existente.',
                          icon: 'question',
                          showCancelButton: true,
                          confirmButtonText: 'Sim!',
                          cancelButtonText: 'Não!'
                        }).then((result) => {
                          if (result.value === true) {
                            push_toast('Comando enviado! Por favor aguarde.', 'info')
                            localFetchApi('linear/sync_drivers')
                          }
                        })
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>

          </Segment>

          <br/>
          <br/>

          <Segment>
            <h2>Ações</h2>

            <SwaggerUI url={`http://${enviroment().localIp()}:8000/schema`}/>
          </Segment>

          <Segment>
            <h2>Requisições</h2>

            <Table celled>
              <Table.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    url: '',
                    post_data: [],
                    data: '',
                    res: ''
                  }}
                  onSubmit={values => {}}
                  render={({ values, setFieldValue, resetForm }) => (
                    <React.Fragment>
                      <Table.Row verticalAlign="top">
                        <Table.Cell width={4}>
                          <div className="ui form mini">
                            <label>URL</label>
                            <Field name={'url'}/>
                          </div>
                          {formUtils.debug(values)}

                          <Button
                            icon="save" content="Resetar"
                            onClick={() => resetForm({ url: '', post_data: [], data: '', res: '' })}
                          />
                        </Table.Cell>
                        <Table.Cell width={10}>
                          <FieldArray
                            name="post_data"
                            render={(arrayHelpers) => (
                              <Form size="mini">
                                <div className="ui form mini">
                                  <label>DATA </label>
                                  <Button icon="plus" compact size="mini" onClick={() => { arrayHelpers.push({ param: '', value: '' })}}/>
                                </div>
                                <br/>

                                {values?.post_data?.map((item, index) => (
                                  <div key={index}>
                                    <Form.Group widths="equal">
                                      <div className="ui form mini">
                                        <Field name={`post_data.${index}.param`}/>
                                      </div>
                                      <div className="ui form mini">
                                        <Field name={`post_data.${index}.value`}/>
                                      </div>
                                      <Button icon="trash" compact size="mini" onClick={() => { arrayHelpers.remove(index)}}/>
                                    </Form.Group>
                                  </div>
                                ))}
                              </Form>
                            )}
                          />
                        </Table.Cell>
                        <Table.Cell textAlign="right" width={4}>
                          <Button
                            icon="save" content="GET"
                            onClick={() => {
                              toastr.info('Comando enviado')

                              localFetchApi(values.url).then(res => {
                                setFieldValue('res', res)
                              }).catch(err => {
                                console.log(err)
                                toastr.error('Erro, verifique os logs!')
                                setFieldValue('res', err)
                              })
                            }}
                          />
                          <Button
                            icon="save" content="POST"
                            onClick={() => {
                              toastr.info('Comando enviado')

                              let _data = {}

                              for (let i of values.post_data) {
                                _data[i.param] = i.value
                              }

                              setFieldValue('data', _data)

                              localPostApi(values.url, _data).then(res => {
                                setFieldValue('res', res)
                              }).catch(err => {
                                console.log(err)
                                toastr.error('Erro, verifique os logs!')
                                setFieldValue('res', err)
                              })
                            }}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  )}
                />
              </Table.Body>
            </Table>
          </Segment>

          <Segment>
            <h2>Teste Webcam</h2>

            <WebcamOld
              audio={false}
              width={368}
              height={280}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 630,
                height: 475,
                facingMode: 'user'
              }}
              style={{ width: 300, height: 226 }}
            />

          </Segment>
        </PageBase>

      )
    }

    return (
      <React.Fragment>
        <Formik
          initialValues={{
            pwd: ''
          }}
          onSubmit={values => {}}
          render={({ values }) => (
            <Form>
              <Form.Group widths="equal">
                <Field name="pwd" type="password"/>
                <Button
                  icon="save"
                  primary
                  onClick={() => this.setState({ 'pwd': values.pwd })}
                />
              </Form.Group>
            </Form>
          )}
        />
      </React.Fragment>
    )
  }
}
