import { toast as toastr } from 'react-toastify'

export default class ToasterService {

  static validateError(errors) {
    if (Object.keys(errors).length > 0) {
      toastr.warning('Verifique o formulário')
    }
  }

  static sendErrors(error, def_msg = 'Ocorreu algum erro') {
    if (error && error.response && error.response.data && error.response.data.errors) {
      const messages = error.response.data.errors

      messages.forEach((item) => {
        toastr.error(item.message)
      })
    } else if (error && error.response && error.response.data && error.response.data.message) {
      toastr.error(error.response.data.message)
    } else if (error && error.response && error.response.data && error.response.data.detail) {
      toastr.error(error.response.data.detail)
    } else if (error && error.response && error.response.data && Array.isArray(error.response.data)) {
      error.response.data.forEach((item) => {
        Object.values(item).forEach((item) => {
          toastr.error(item[0])
        })
      })
    } else if (error && error.response && error.response.data && typeof (error.response.data) === 'object') {
      Object.values(error.response.data).forEach((item) => {
        toastr.error(item[0])
      })
    } else {
      toastr.error(def_msg)
    }
  }

  static success(msg) {
    toastr.success(msg)
  }

  static warning(msg) {
    toastr.warn(msg)
  }
}