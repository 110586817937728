import React from 'react'
import {Button, Dropdown, Form, Icon, Message, Modal, Table} from 'semantic-ui-react'
import swal from 'sweetalert2'
import { qs } from '../queries'
import { Formik } from 'formik'
import ActivityLoading from './Loading'
import moment from 'moment'
import SemanticSelect from './SemanticSelect'
import { getValue, hasValue } from '../utils/Formatters'
import { ACCESSDRIVER_TYPES } from '../fields'
import { localFetchApi, localPostApi } from '../utils/LocalApi'
import { LinearRequirements } from '../utils/HardwareService'
import { actionMap as devices_actions } from '../redux/modules/devices'
import { connect } from 'react-redux'

let local_contador = ''

const LOCAL_ACCESSDRIVER_TYPES = [
  {value: 1, text: 'Controle'},
  // {value: 2, text: 'TAG Ativo'},
  // {value: 3, text: 'Cartão'},
  // {value: 5, text: 'Biometria'},
  {value: 6, text: 'TAG Passivo'},
  // {value: 7, text: 'Senha'},
]

class GuaritaManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      guarita_modal: false,
      loading: false,
      controllers: [],
      units: [],
      vehicles: [],
      default_guarita: {},
    }
  }

  componentDidMount() {
    this.props.guaritaRead({serial: null, contador: null, bateria: null})
    this.getControllers()
    this.handleSelects()

    LinearRequirements()

    localFetchApi('linear/get_guarita')
      .then((data) => {
        console.log(data)
        this.setState({default_guarita: data})
      })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.guarita_read.serial !== '' && this.props.guarita_read.serial !== null) {
      local_contador = this.props.guarita_read.contador
      if (this.guaritaForm) {
        this.guaritaForm.setFieldValue('code', this.props.guarita_read.serial)
      }
    }
  }

  handleSelects = () => {
    const resident = this.props.resident
    let parkings = []
    let units = []

    if (resident && resident.units && resident.units.length > 0) {
      for (let i of resident.units) {
        units.push({'key': i.id, 'text': i.with_block, 'value': i.id})

        if (i.parkings && i.parkings.length > 0) {
          for (let p of i.parkings) {
            parkings.push({'key': p.id, 'text': p.with_pavement, 'value': p.id})
          }
        }
      }

      this.setState({units: units, parkings: parkings})
    }
  }

  getControllers = () => {
    this.setState({loading: true})

    qs.access_drivers.filterByPersonId(this.props.resident.id)
      .then((res) => {
        this.setState({loading: false})
        this.setState({controllers: res})
      })
      .catch((err) => {
        this.setState({loading: false})
      })
  }

  handleDeleteDriver = (driver) => {
    swal({
      title: 'Tem certeza que deseja remover esse acionador desse cadastro?',
      text: 'Essa ação não poderá ser desfeita',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sim!',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.value === true) {
        if (driver && (driver.code !== '' && driver.code !== null && driver.code !== undefined)) {
          this.setState({loading: true})

          qs.access_drivers.delete(driver)
            .then((res) => {
              localPostApi('linear/delete_driver', driver)

              this.getControllers()
              this.setState({loading: false})
            })
            .catch(() => {
              this.setState({loading: false})
            })
        } else {
          swal({type: 'error', title: 'Erro no código do acionador de acesso',})
        }
      }
    })
  }

  handleChangeSemantic = (event, data) => {
    const unit = this.props.resident.units.find(item => item.id === data.value)

    if (unit && unit.vehicles && unit.vehicles.length > 0) {
      const vehicles = unit.vehicles.map((item) => {
        return {'key': item.id, 'text': item.model + ' - ' + item.plate, 'value': item.id}
      })
      vehicles.unshift({'key': '', 'text': '-- vazio --', 'value': ''})
      this.setState({vehicles: vehicles})
    }

    // this is going to call setFieldValue and manually update values.topcis
    this.guaritaForm.setFieldValue('unit', data.value)
  }

  openGuaritaModal = () => {
    const group = this.props.resident.access_group_id

    if (group && group != null) {
      this.setState({guarita_modal: true})
    } else {
      swal({type: 'error', title: 'Essa PESSOA não possui nenhuma ROTA DE ACESSO configurada.'})
    }
  }

  reSync = (item) => {
    let data = {
      code: item.code,
      name: this.props.resident.name,
      access_group: this.props.resident.access_group_id,
      type: item.type
    }

    localPostApi('linear/create_driver', data)
  }

  render() {
    return (
      <React.Fragment>
        <Message warning icon='warning'
                 header='Como fazer a leitura do código do controle:'
                 content='- Clique em "Cadastrar Acionador", aperte no controle o BOTÃO 1 e BOTÃO 2 juntos próximo ao Guarita IP'/>

        {this.props.person_status && <Button primary size={'large'} onClick={this.openGuaritaModal}>
          <Icon name='add'/> Cadastrar Acionador
        </Button>}

        <br/>
        <h2>Acionadores Cadastrados</h2>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Unidade</Table.HeaderCell>
              <Table.HeaderCell>Veículo</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data de Cadastro</Table.HeaderCell>
              <Table.HeaderCell>Última atualização</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.controllers.length > 0 && this.state.controllers.filter(i => i.type === 1 || i.type === 6).map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.code}
                  </Table.Cell>
                  <Table.Cell>
                    {hasValue(item, 'unit.with_block')}
                  </Table.Cell>
                  <Table.Cell>
                    {item.vehicle && item.vehicle.model + ' - ' + item.vehicle.plate}
                  </Table.Cell>
                  <Table.Cell>
                    {ACCESSDRIVER_TYPES[item.type]}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    <button className="ui mini basic icon button red" onClick={() => this.handleDeleteDriver(item)}>
                      <i className="trash icon"/>
                    </button>
                    <button className="ui mini basic icon button blue" onClick={() => this.reSync(item)}>
                      <i className="upload icon"/>
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>

        <Modal open={this.state.guarita_modal} size='small'>
          <Modal.Header>Cadastrar Acionador</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.guaritaForm = node)}
              initialValues={{
                type: '', // Controles
                status: 1,
                code: '',
                name: this.props.resident.name,
                person: this.props.resident.id,
                vehicle: '',
                parking: '',
                unit: '',
                access_group: this.props.resident.access_group_id
              }}
              validate={values => {
                let errors = {}

                if (!values.code) errors.code = 'Faça a leitura ou digite o código do acionador'
                if (!values.unit) errors.unit = 'Selecione a unidade'
                if (!values.parking) errors.parking = 'Selecione a vaga'
                if (!values.type) errors.type = 'Selecione o tipo'
                if (!values.person) errors.person = ''
                if (!values.name) errors.name = ''
                if (!values.access_group) errors.access_group = 'Rota de acesso não configurado'

                return errors
              }}
              onSubmit={(values) => {
                values['code'] = values.code.toUpperCase()
                this.setState({loading: true, form_values: values})

                // Check for existing driver
                qs.access_drivers.filterByCode(values.code)
                  .then((res) => {
                    if (res.length < 1) {
                      qs.access_drivers.post(values)
                        .then((resp) => {
                          let data = {
                            code: values.code,
                            name: values.name,
                            access_group: values.access_group,
                            type: values.type
                          }

                          localPostApi('linear/create_driver', data)

                          this.getControllers()
                          this.setState({loading: false})
                        })
                        .catch((err) => {
                          this.setState({loading: false})
                        })
                    } else {
                      this.setState({loading: false})
                      swal({
                        title: 'Acionador já existente, gostaria de vincular a esse cadastro?',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'SIM',
                        cancelButtonText: 'NÃO',
                      }).then((result) => {
                        if (result.value) {
                          this.setState({loading: true})

                          qs.access_drivers.patch(values, res[0]['id'])
                            .then((resp) => {
                              let data = {
                                code: values.code,
                                name: values.name,
                                access_group: values.access_group,
                                type: values.type
                              }

                              localPostApi('linear/create_driver', data)

                              this.getControllers()
                              this.setState({loading: false})
                            })
                            .catch((err) => {
                              this.setState({loading: false})
                            })
                        }
                      })
                    }
                  })
                  .catch((err) => {
                    this.setState({loading: false})
                    throw err
                  })
              }}
              render={({values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched}) => (
                <Form className="ui form large error">
                  <SemanticSelect
                    labelField={'Tipo'}
                    value={values.type}
                    options={LOCAL_ACCESSDRIVER_TYPES}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.type}
                    touched={touched.type}
                    fieldKey="type"
                  />

                  <div className={errors.code ? 'field error' : 'field'}>
                    <label>Código do Acionador</label>
                    <div className="ui input">
                      <input type="text" name="code" onChange={handleChange} onBlur={handleBlur} value={values.code}/>
                    </div>
                    {touched.code && errors.code && <h5 className='ui header red'>{errors.code}</h5>}
                  </div>

                  <div className={errors.unit ? 'field error' : 'field'}>
                    <label>Unidade</label>
                    <Dropdown placeholder='Selecione' fluid selection
                              options={this.state.units}
                              onChange={this.handleChangeSemantic}
                              onBlur={setFieldTouched}
                              value={values.unit}
                              search={true}
                              deburr={true}
                    />
                    {errors.unit && touched.unit && (
                      <h5 className="ui header red">{errors.unit}</h5>
                    )}
                  </div>

                  <SemanticSelect
                    labelField={'Vaga'}
                    value={values.parking}
                    options={this.state.parkings}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.parking}
                    touched={touched.parking}
                    fieldKey="parking"
                    search={true}
                  />

                  <SemanticSelect
                    labelField={'Veículo'}
                    value={values.vehicle}
                    options={this.state.vehicles}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.vehicle}
                    touched={touched.vehicle}
                    fieldKey="vehicle"
                    search={true}
                  />

                  {getValue(this.props, 'guarita_read.bateria') === 0 && <p>Bateria: Ok</p>}
                  {getValue(this.props, 'guarita_read.bateria') === 1 && <p>Bateria: Fraca</p>}
                </Form>
              )}/>
          </Modal.Content>
          <Modal.Actions>
            <button className="ui basic button large" onClick={() => this.setState({guarita_modal: false})}>Fechar</button>
            <button className="ui button grey large" onClick={() => localPostApi('linear/update_receptors', {})}>
              Atualizar Receptores
            </button>
            <button className="ui button primary large" onClick={() => this.guaritaForm.submitForm()}>Salvar Acionador</button>
          </Modal.Actions>
        </Modal>
        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    guarita_read: state.devices.guarita_read,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    guaritaRead: (data) => dispatch(devices_actions.guarita_read(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuaritaManager)
