import axios from 'axios'
import React from 'react'
import enviroment from '../enviroment'
import { history } from '../redux/store/configureStore'
import { apiQsMetadata } from '../services/api'
import { getValue } from './Formatters'

const apiAxios = axios.create()
// Add a response interceptor
apiAxios.interceptors.response.use(function (response) {
  // console.log(response);
  // Do something with response data
  return response
}, function (error) {
  let status = getValue(error, 'response.status')

  if (status === 401 || status === 403) {
    history.push('/login')
  }
  return Promise.reject(error)
})

apiAxios.interceptors.request.use(function (config) {
  config.headers.Platform = 'desktop_ui'
  config.headers.AppVersion = enviroment().APP_VERSION_READABLE

  const metadata_qs = apiQsMetadata()

  config.url = config.url && config.url.indexOf('?') > -1
    ? `${config.url}&${metadata_qs}`
    : `${config.url}?${metadata_qs}`

  return config
}, function (err) {
  return Promise.reject(err)
})

export const fetchApi = async (endpoint, method = 'get') => {
  const token = await localStorage.getItem('token')

  return await apiAxios({
    method: method,
    url: enviroment().apiUrl + endpoint,
    headers: {'Authorization': `Token ${token}`}
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const postApi = async (endpoint, data) => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'post',
    url: enviroment().apiUrl + endpoint,
    data: data,
    headers: {'Authorization': `Token ${token}`}
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const postFormApi = async (endpoint, data) => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'post',
    url: enviroment().apiUrl + endpoint,
    data: data,
    headers: {
      'Authorization': `Token ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const putApi = async (endpoint, data, id) => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'patch',
    url: enviroment().apiUrl + endpoint + '/' + id,
    data: data,
    headers: {'Authorization': `Token ${token}`}
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const putApiCustom = async (endpoint, data) => {
  const token = await localStorage.getItem('token')
  return await apiAxios({
    method: 'patch',
    url: enviroment().apiUrl + endpoint,
    data: data,
    headers: {'Authorization': `Token ${token}`}
  })
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

export const openBlobApi = async (endpoint, method = 'get', data = null) => {
  const token = await localStorage.getItem('token')

  return await apiAxios({
    method: method,
    url: enviroment().apiUrl + endpoint,
    headers: {'Authorization': `Token ${token}`},
    data: data,
    responseType: 'blob',
  })
    .then((res) => {
      const file = new Blob([res.data], {type: 'application/pdf'})
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    })
    .catch(async (error) => {
      if (error.response) {
        try {
          const {data} = error.response

          const fileReader = new FileReader()
          const file = await new Promise((resolve, reject) => {
            fileReader.onerror = () => {
              fileReader.abort()
              reject(new Error('Problem parsing file'))
            }

            fileReader.onload = () => {
              resolve(fileReader.result)
            }

            fileReader.readAsText(data)
          })

          error = {response: {data: JSON.parse(file)}}
        } catch (readError) {
          error = 'Arquivo inválido'
        }
      } else {
        error = 'Arquivo inválido'
      }

      return Promise.reject(error)
    })
}


