import {connect as formikConnect, Field, FormikProps} from 'formik'
import moment from 'moment'
import React, {useEffect} from 'react'
import {useQuery} from 'react-query'
import {qs} from '../../queries'
import {show_modal} from '../../utils/Formatters'
import {FormikSelect} from './FormInputs'

type Props = {
  label?: string;
  name?: string;
  width: number | undefined;
  formik?: FormikProps<{ access_reason: number | string }>
}

const AccessReasonSelect = (props: Props) => {
  const { isLoading, data } = useQuery<any[]>('getAccessReason', () => qs.access_reasons.allCached())

  useEffect(() => {
    let access_reason = props?.formik?.values?.access_reason

    console.log('access_reason', access_reason)

    if (props.formik && access_reason && data && data?.length > 0) {
      for (let i of data) {
        if (i.id === access_reason && i.access_group) {
          let day_week = moment().locale('en').format('ddd').toLowerCase()
          // @ts-ignore
          let is_allowed = i.access_group[`${day_week}_allowed`]

          if (!is_allowed) {
            show_modal(`${i.name} não é permitido acessar hoje!`, 'error')
            props.formik.setFieldValue('access_reason', '')
            props.formik.setFieldValue('access_group', '')
            return
          }

          // @ts-ignore
          let start = moment(i.access_group[`${day_week}_start`], 'HH:mm')
          // @ts-ignore
          let end = moment(i.access_group[`${day_week}_end`], 'HH:mm')

          if (!moment().isBetween(start, end)) {
            show_modal(
              `${i.name} não é permitido acessar agora!
              Horários permitidos:
              ${start.format('HH:mm')}h até ${end.format('HH:mm')}h`,
              'error'
            )

            props.formik.setFieldValue('access_reason', '')
            props.formik.setFieldValue('access_group', '')
            return
          }
        }
      }
    }

  }, [props?.formik?.values])

  return (
    <Field
      label={props.label ? props.label : 'Motivo da Visita'}
      name={props.name ? props.name : 'access_reason'}
      width={props.width ? props.width : undefined}
      component={FormikSelect}
      options={data}
      search={true}
      id_option="id"
      label_option="name"
      onChangePos={(evt, b) => {
        if (data) {
          for (let i of data) {
            if (i.id === b.value) {
              // @ts-ignore
              props.formik.setFieldValue('access_group', i.access_group)
            }
          }
        }
      }}

    />
  )
}

export default formikConnect(AccessReasonSelect)

