import { all } from 'redux-saga/effects'
import { people_sagas } from '../../containers/People'
import { devices_sagas } from '../modules/devices'

function * rootSaga() {
  yield all([
    ...people_sagas,
    ...devices_sagas
  ])
}

export default rootSaga