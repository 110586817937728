import {buildQs} from '../utils/common'
import {fetchBaseQuery} from './query_utils'

let qs = buildQs(['access_group.access_relays.id'], { show_app_gater: true })

const fetchQuery = fetchBaseQuery<any>({
  key: 'ACCESS_REASONS',
  url: `admin/access-reasons${qs}`,
  stale_time: 60 * 60 * 2
})

export const access_reasons = {
  allCached: fetchQuery.allCached
}