import moment from 'moment'
import ToasterService from '../services/ToasterService'
import {fetchApi} from './api'
import {show_modal_once} from './Formatters'
import {getCache, putCache} from './LocalApi'
import {LocalDB} from './LocalDB'

let sync_configs = {}

const request = async (sync = true, sync_toggle, cache_path, memory_path, url) => {
  let cached
  try {
    if (sync !== 'bypass') {
      cached = await getCache(cache_path)
    }
  } catch (e) {
    console.log('%c### CACHED ERROR-> ', e)
    cached = false
  }

  // console.log('%c### CACHED -> ' + cache_path + ' >>>>>>>>>>>>', 'color: green', cached)

  if (cached && sync === false) {
    return cached
  } else {
    LocalDB.setItem(memory_path, moment.now())
    sync_configs[sync_toggle] = false

    return await fetchApi(url).then((res) => {
      if (sync !== 'bypass') {
        putCache(cache_path, res.data)
      }

      // LocalDB.setItem(cache_path, res.data)
      LocalDB.setItem(memory_path, moment.now())
      return res.data
    }).catch((err) => {
      ToasterService.sendErrors(err)

      if (cached) {
        show_modal_once(
          'Não foi possível recuperar os dados do servidor! \n Você está visualizando uma versão em cache. CUIDADO!',
          'error'
        )

        return Promise.resolve(cached)
      }

      return Promise.reject(err)
    })
  }
}

export const getGuestLogs = async (days = 1) => {
  let date = moment().subtract(days, 'days').startOf('day').toISOString()

  return await request('bypass', 'sync_guest_logs', 'guest_logs', 'memory_guest_logs',
    `admin/access-logs?filter{date.gte}=${date}`)
}

export const getGuestAuths = async (sync = true) => {
  return await request(sync, 'sync_guest_auths', 'guest_auths', 'memory_guest_auths',
    'admin/access-authorizations/gater_list')
}

export const getGuestBooks = async () => {
  let today = moment().endOf('day').toISOString()
  let yesterday = moment().subtract(1, 'days').endOf('day').toISOString()

  return await request('bypass', 'sync_guest_books', 'guest_books', 'memory_guest_books',
    `admin/guest-lists?filter{book.book_date.gte}=${yesterday}&filter{book.book_date.lte}=${today}&sort[]=-book.book_date` +
    '&include[]=book.space&include[]=book.unit&include[]=person.name&include[]=created_by')
}

export const getLockers = async () => {
  return await request('bypass', 'sync_lockers', 'lockers', 'memory_lockers',
    'admin/lockers?include[]=unit')
}

export const getMailingPouchs = async (sync = true) => {
  return await request('bypass', 'sync_mailings', 'mailings', 'memory_mailings',
    'admin/mailing-pouchs?include[]=created_by_name&include[]=from_team_name&include[]=destination_team_name')
}

export const getLosts = async () => {
  let start = moment().subtract(30, 'days').startOf('day').toISOString()

  return await request('bypass', 'sync_losts', 'losts', 'memory_mailings',
    'admin/losts?include[]=unit.with_block&include[]=files.file&filter{created_at.gte}=' + start)
}

export const getMovings = async () => {
  let start = moment().subtract(10, 'days').format('YYYY-MM-DD')

  return await request('bypass', 'sync_movings', 'movings', 'memory_movings',
    'admin/movings?include[]=unit&include[]=moving_type.name&filter{date.gte}=' + start)
}

export const getObjects = async () => {
  let start = moment().subtract(10, 'days').startOf('day').toISOString()

  return await request('bypass', 'sync_objects', 'objects', 'memory_objects',
    'admin/object-controls?include[]=unit.id&filter{created_at.gte}=' + start)
}

export const getConstructions = async () => {
  let start = moment().subtract(10, 'months').startOf('day').toISOString()

  return await request('bypass', 'sync_constructions', 'constructions', 'memory_constructions',
    'admin/constructions?include[]=unit&filter{created_at.gte}=' + start)
}

export const getNotices = async () => {
  let start = moment().subtract(10, 'days').format('YYYY-MM-DD')

  return await request('bypass', 'sync_notices', 'notices', 'memory_notices',
    'admin/notices?filter{published_until.gte}=' + start)
}

export const getSpaces = async () => {
  return await request('bypass', 'sync_spaces', 'spaces', 'memory_spaces',
    'admin/spaces?include[]=availability')
}

export const getAccessGroups = async (sync = true) => {
  return await request(sync, 'sync_access_groups', 'access_groups', 'memory_access_groups',
    'admin/access-groups?filter{type}=1&include[]=access_relays')
}

export const getAccessDocs = async () => {
  return await request('bypass', 'sync_access_docs', 'access_docs', 'memory_access_docs',
    'admin/access-docs?include[]=unit.with_block&include[]=person.name&include[]=authorizer.name')
}

export const getCondoLabors = async (sync = true) => {
  return await request(sync, 'sync_condo_labors', 'condo_labors', 'memory_condo_labors',
    'admin/condo-labors')
}
