import moment from 'moment'
import swal from 'sweetalert2'

/*eslint-disable no-undef*/
export function dateFormat(params, field = null) {
  if (field) {
    return hasValue(params.data, field) !== null ? moment(hasValue(params.data, field)).format('DD/MM/YYYY') : ''
  }
  return hasValue(params.data, 'date') !== null ? moment(params.data.date).format('DD/MM/YYYY') : ''
}

export function dateTimeFormat(params, field = null) {
  if (field) {
    return hasValue(params.data, field) !== null ? moment(hasValue(params.data, field)).format('DD/MM/YY HH:mm') : ''
  }
  return hasValue(params.data, 'date') !== null ? moment(params.data.date).format('DD/MM/YY HH:mm') : ''
}

export function removeAccents(params) {
  const accentMap = {
    'á': 'a',
    'ã': 'a',
    'â': 'a',
    'é': 'e',
    'í': 'i',
    'ó': 'o',
    'ô': 'o',
    'ú': 'u',
  }

  const accent_fold = (s) => {
    if (!s) {
      return ''
    }
    let ret = ''
    for (let i = 0; i < s.length; i++) {
      ret += accentMap[s.charAt(i)] || s.charAt(i)
    }
    return ret
  }

  return accent_fold(params.value)
}

function getNestedObject(obj, dotSeparatedKeys) {
  if (arguments.length > 1 && typeof dotSeparatedKeys !== 'string') return undefined
  if (typeof obj !== 'undefined' && typeof dotSeparatedKeys === 'string') {
    const pathArr = dotSeparatedKeys.split('.')
    pathArr.forEach((key, idx, arr) => {
      if (typeof key === 'string' && key.includes('[')) {
        try {
          // extract the array index as string
          const pos = /\[([^)]+)\]/.exec(key)[1]
          // get the index string length (i.e. '21'.length === 2)
          const posLen = pos.length
          arr.splice(idx + 1, 0, Number(pos))

          // keep the key (array name) without the index comprehension:
          // (i.e. key without [] (string of length 2)
          // and the length of the index (posLen))
          arr[idx] = key.slice(0, (-2 - posLen)) // eslint-disable-line no-param-reassign
        } catch (e) {
          // do nothing
        }
      }
    })
    // eslint-disable-next-line no-param-reassign, no-confusing-arrow
    obj = pathArr.reduce((o, key) => (o && o[key] !== 'undefined' ? o[key] : undefined), obj)
  }
  return obj
}

export const hasValue = (obj, nestedKeys) => {
  let input = obj

  if (nestedKeys) {
    input = getNestedObject(obj, nestedKeys)
  }
  if (typeof input !== 'undefined' && input !== null && input !== '') {
    return input
  }
  return null
}

export const getValue = (obj, nestedKeys) => {
  let input = obj

  if (nestedKeys) {
    input = getNestedObject(obj, nestedKeys)
  }
  if (typeof input !== 'undefined' && input !== null && input !== '') {
    return input
  }
  return null
}

export const getInitialValue = (obj, nestedKeys, default_value = '') => {
  let value = getValue(obj, nestedKeys)
  if (typeof value !== 'undefined' && value !== null && value !== '') {
    if (Array.isArray(value)) {
      if (value.length === 0) {
        return default_value
      }
    }

    return value
  } else {
    return default_value
  }
}

export function show_modal(msg, type) {
  if (swal.isVisible()) {
    swal.insertQueueStep({type: type, title: msg})
  } else {
    swal.queue([{type: type, title: msg}])
  }
}

export function show_modal_once(msg, type) {
  swal({type: type, title: msg})
}

export function dateFormatNew(params, path = null) {
  let formated = params

  if (path !== null) {
    formated = getValue(params, path)
  }

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YY')
  }
  return formated
}

export function dateTimeFormatNew(params, path = null) {
  let formated = params

  if (path !== null) {
    formated = getValue(params, path)
  }

  if (typeof formated !== 'undefined' && formated !== null && formated !== '') {
    formated = moment(formated).format('DD/MM/YY HH:mm')
  }
  return formated
}
