import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { shallowEqual } from 'react-redux'
import { getValue, hasValue } from '../utils/Formatters'

export default class SemanticSelect extends React.Component {
  constructor(props) {
    super(props)

    this.handleChangeSemantic = this.handleChangeSemantic.bind(this)
    this.handleBlurSemantic = this.handleBlurSemantic.bind(this)
  }

  handleChangeSemantic(event, data) {
    // this is going to call setFieldValue and manually update values.topcis
    this.props.onChange(this.props.fieldKey, data.value)
  };

  handleBlurSemantic() {
    // this is going to call setFieldTouched and manually update touched.topcis
    this.props.onBlur(this.props.fieldKey, true)
  };

  render() {
    return (
      <div className={this.props.error ? 'field error' : 'field'} style={this.props.style}>
        <label>
          {this.props.labelField}
        </label>

        <Dropdown placeholder='Selecione' fluid selection closeOnChange
                  search={this.props.search}
                  options={this.props.options}
                  onChange={this.handleChangeSemantic}
                  onBlur={this.handleBlurSemantic}
                  value={this.props.value}
                  deburr={true}
                  upward={this.props.upward}
                  disabled={this.props.disabled}
        />

        {this.props.error && this.props.touched && (
          <h5 className="ui header red">{this.props.error}</h5>
        )}
      </div>
    )
  }
}

export class SemanticSelectFormik extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: []
    }

    this.handleChangeSemantic = this.handleChangeSemantic.bind(this)
    this.handleBlurSemantic = this.handleBlurSemantic.bind(this)
    this.generateOption = this.generateOption.bind(this)
  }

  generateOption(props) {
    const options = []
    const empty = {'key': '', 'text': '-- Vazio --', 'value': ''}
    options.push(empty)

    if (hasValue(props, 'local_options')) {
      let formated = Object.keys(props.local_options)

      for (let item of formated) {
        let item_formated = parseInt(item)
        const i = {value: isNaN(item_formated) ? item : item_formated, text: props.local_options[item]}
        options.push(i)
      }
    } else if (Array.isArray(props.options)) {
      for (let item of props.options) {
        const i = {'key': item[props.id_option], 'text': item[props.label_option], 'value': item[props.id_option]}
        options.push(i)
      }
    }

    return options
  }

  componentDidMount() {
    const options = this.generateOption(this.props)
    this.setState({options: options})
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const options = this.generateOption(this.props)
    const prev_options = getValue(prevState, 'options')
    let result_state = !shallowEqual(options, prev_options)

    if (result_state) {
      if (prev_options.length === 1 && options.length === 1) {
        if (options[0].text === '-- Vazio --') {
          result_state = false
        }
      }

      if (prev_options.length === options.length) {
        result_state = false
      }
    }

    if (result_state) {
      this.setState({options: options})
      // counter_updates += 1
      // console.log(this.props.name, counter_updates)
    }
  }

  handleChangeSemantic(event, data) {
    this.props.onChange(this.props.name, data.value)

    if (this.props.onChangePos) {
      this.props.onChangePos(event, data)
    }
  };

  handleBlurSemantic() {
    this.props.onBlur(this.props.name, true)
  };

  render() {
    return (
      <Dropdown placeholder='Selecione' fluid selection closeOnChange
                {...this.props}
                options={this.state.options}
                onChange={this.handleChangeSemantic}
                onBlur={this.handleBlurSemantic}
                value={this.props.value}
                deburr={true}
      />
    )
  }
}
