import enviroment from '../enviroment'
import {apiAxios} from '../services/api'
import {AccessAuthorizationModel} from '../typings'
import {ListRequestResult} from './query_utils'

export const access_authorizations = {
  all_by_unit: async (unit_id: number) => {
    const { data } = await apiAxios.get<ListRequestResult<AccessAuthorizationModel[]>>(
      enviroment().apiUrl + `admin/access-authorizations/gater_list?unit_id=${unit_id}`)

    return data.data
  }
}