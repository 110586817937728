import { Formik } from 'formik'
import moment from 'moment'
import React from 'react'
import { Button, Dropdown, Form, Grid, Icon, Modal, Table } from 'semantic-ui-react'
import { ACCESSDRIVER_TYPES } from '../../fields'
import { qs } from '../../queries'
import { hasValue, show_modal } from '../../utils/Formatters'
import { F, FF, FormikInput, FormikSelect } from '../form/FormInputs'
import ActivityLoading from './../Loading'
import SemanticSelect from './../SemanticSelect'
import { qs_local_server } from '../../queries-local-server'
import { connect } from 'react-redux'
import { PersonDataModel } from '../../data-model'
import { PersonModel } from '../../redux'

const LOCAL_ACCESSDRIVER_TYPES = [
  { value: 1, text: 'Controle' },
  // {value: 2, text: 'TAG Ativo'},
  // {value: 3, text: 'Cartão'},
  // {value: 5, text: 'Biometria'},
  { value: 6, text: 'TAG Passivo' },
  // {value: 7, text: 'Senha'},
]

type Props = {
  person: PersonModel & {
    units: any
  }
  person_status: boolean
  modules_activated: any
  access_group: any
  guarita_read: { serial: string | null } | null
}

class VehicleAccessManager extends React.Component<Props, any> {
  modalForm: any

  constructor(props) {
    super(props)

    this.state = {
      open_modal: false,
      loading: false,
      controllers: [],
      units: [],
      vehicles: []
    }
  }

  componentDidMount() {
    this.getControllers()
    this.handleSelects()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.guarita_read?.serial !== '' && this.props.guarita_read?.serial !== null) {
      if (this.modalForm && this.props.guarita_read?.serial) {
        this.modalForm.setFieldValue('code', this.props.guarita_read.serial)
      }
    }
  }

  handleSelects = () => {
    const resident = this.props.person
    let parkings: any[] = []
    let units: any[] = []

    if (resident && resident.units && resident.units.length > 0) {
      for (let i of resident.units) {
        units.push({ 'key': i.id, 'text': i.with_block, 'value': i.id })

        if (i.parkings && i.parkings.length > 0) {
          for (let p of i.parkings) {
            parkings.push({ 'key': p.id, 'text': p.with_pavement, 'value': p.id })
          }
        }
      }

      this.setState({ units: units, parkings: parkings })
    }
  }

  getControllers = () => {
    this.setState({ loading: true })

    qs.access_drivers.filterByPersonId(this.props.person.id)
      .then((res) => {
        this.setState({ loading: false })
        this.setState({ controllers: res })
      })
      .catch((err) => {
        this.setState({ loading: false })
      })
  }

  handleChangeSemantic = (event, data) => {
    const unit = this.props.person.units.find(item => item.id === data.value)

    if (unit && unit.vehicles && unit.vehicles.length > 0) {
      const vehicles = unit.vehicles.map((item) => {
        return { 'key': item.id, 'text': item.model + ' - ' + item.plate, 'value': item.id }
      })
      vehicles.unshift({ 'key': '', 'text': '-- vazio --', 'value': '' })
      this.setState({ vehicles: vehicles })
    }

    // this is going to call setFieldValue and manually update values.topcis
    this.modalForm.setFieldValue('unit', data.value)
  }

  openModal = () => {
    const group = this.props.access_group?.id

    if (group) {
      this.setState({ open_modal: true })
    } else {
      show_modal('Morador não possui nenhuma ROTA DE ACESSO configurada.', 'error')
    }
  }

  handleDeleteDriver = async (driver) => {
    try {
      this.setState({ loading: true })
      const person_data_model = new PersonDataModel(this.props.person)

      await person_data_model.local_deleteDriverVehicle(
        driver.code,
        driver.type,
        driver.id,
        this.props.access_group?.access_devices_ids,
        // Compatibilidade
        this.props.access_group?.id,
        this.props.access_group,
      )

      this.getControllers()

    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
      this.getControllers()
    }
  }

  createUpdateDriver = async (values) => {
    try {
      this.setState({ loading: true })
      const person_data_model = new PersonDataModel(this.props.person)

      await person_data_model.local_createDriverVehicle(
        values.code,
        values.type,
        null,
        this.props.access_group?.access_devices_ids,
        // Compatibilidade
        this.props.access_group?.id,
        this.props.access_group,
        // Remote only
        values.alias,
        values.vehicle,
        values.parking,
        values.unit,
      )

      this.getControllers()

    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ loading: false })
      this.getControllers()
    }
  }

  reSync = (item) => {
    let data = {
      status: 1,
      code: item.code,
      type: item.type,
    }

    this.createUpdateDriver(data)
  }

  render() {
    return (
      <React.Fragment>
        {this.props.person_status && <Button primary size={'large'} onClick={this.openModal}>
          <Icon name="add"/> Cadastrar Acionador
        </Button>}

        <br/>
        <h2>Acionadores Cadastrados</h2>

        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Unidade</Table.HeaderCell>
              <Table.HeaderCell>Veículo</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Data de Cadastro</Table.HeaderCell>
              <Table.HeaderCell>Última atualização</Table.HeaderCell>
              <Table.HeaderCell>Ações</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {
              this.state.controllers.length > 0 && this.state.controllers.filter(i => [1, 6].includes(i.type)).map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    {item.code}
                  </Table.Cell>
                  <Table.Cell>
                    {hasValue(item, 'unit.with_block')}
                  </Table.Cell>
                  <Table.Cell>
                    {item.vehicle && item.vehicle.model + ' - ' + item.vehicle.plate}
                  </Table.Cell>
                  <Table.Cell>
                    {ACCESSDRIVER_TYPES[item.type]}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.created_at).format('DD/MM/YYYY')}
                  </Table.Cell>
                  <Table.Cell>
                    {moment(item.updated_at).format('DD/MM/YYYY HH:mm')}
                  </Table.Cell>
                  <Table.Cell>
                    <button className="ui mini basic icon button red" onClick={() => this.handleDeleteDriver(item)}>
                      <i className="trash icon"/>
                    </button>
                    <button className="ui mini basic icon button blue" onClick={() => this.reSync(item)}>
                      <i className="upload icon"/>
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
        </Table>

        <Modal open={this.state.open_modal} size="small">
          <Modal.Header>Cadastrar Acionador</Modal.Header>
          <Modal.Content>
            <Formik
              ref={node => (this.modalForm = node)}
              initialValues={{
                type: '',
                status: 1,
                code: '', // Decimal
                alias: '', // Hexadecimal
                person: this.props.person.id,
                vehicle: '',
                parking: '',
                unit: '',
                // For ac server only
                // user_id: this.props.person.id,
                // user_name: this.props.person.id,
                // access_group: this.props.access_group,
                // access_group_id: this.props.access_group?.id
              }}
              validate={values => {
                let errors: any = {}

                if (!values.code) errors.code = 'Faça a leitura ou digite o código do acionador'
                if (!values.unit) errors.unit = 'Selecione a unidade'
                if (!values.parking) errors.parking = 'Selecione a vaga'
                if (!values.type) errors.type = 'Selecione o tipo'
                // if (!values.user_id) errors.user_id = ''
                // if (!values.user_name) errors.user_name = ''
                // if (!values.access_group_id) errors.access_group_id = 'Rota de acesso não configurado'

                return errors
              }}
              onSubmit={(values) => {
                this.createUpdateDriver(values)
              }}
              render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
                <Form className="ui form large error">
                  <FF label={'Tipo'} name="type" component={FormikSelect} options={LOCAL_ACCESSDRIVER_TYPES}/>

                  <div className="field">
                    <Grid>
                      <Grid.Column width={7}>
                        <F name="alias" label="Código hexadecimal" component={FormikInput}/>
                      </Grid.Column>

                      <Grid.Column width={2}>
                        <div className="field">
                          <label>&nbsp;</label>
                          <Button
                            floated={'right'} circular icon="angle right"
                            onClick={() => {
                              if (values.alias) {
                                console.log(values.alias)

                                let decimal = `${parseInt(values.alias, 16)}`
                                let decimal_padded = decimal.padStart(10, '0')

                                console.log(decimal)
                                setFieldValue('code', decimal_padded)
                              }
                            }}
                          />
                        </div>
                      </Grid.Column>

                      <Grid.Column width={7}>
                        <F name="code" label="Código decimal" component={FormikInput}/>
                      </Grid.Column>
                    </Grid>
                  </div>

                  <div className={errors.unit ? 'field error' : 'field'}>
                    <label>Unidade</label>
                    <Dropdown
                      placeholder="Selecione" fluid selection
                      options={this.state.units}
                      onChange={this.handleChangeSemantic}
                      value={values.unit}
                      search={true}
                      deburr={true}
                    />
                    {errors.unit && touched.unit && (
                      <h5 className="ui header red">{errors.unit}</h5>
                    )}
                  </div>

                  <SemanticSelect
                    labelField={'Vaga'}
                    value={values.parking}
                    options={this.state.parkings}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.parking}
                    touched={touched.parking}
                    fieldKey="parking"
                    search={true}
                  />

                  <SemanticSelect
                    labelField={'Veículo'}
                    value={values.vehicle}
                    options={this.state.vehicles}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.vehicle}
                    touched={touched.vehicle}
                    fieldKey="vehicle"
                    search={true}
                  />
                </Form>
              )}/>
          </Modal.Content>
          <Modal.Actions>
            {this.props.modules_activated.mip1000_active &&
              <Button
                basic size="large"
                onClick={() => { qs_local_server.devices_manager.remoteReadCode() }}
              >
                Ler Controle/Tag no Mip 1000
              </Button>}

            <Button basic size="large" onClick={() => {
              this.setState({ open_modal: false })
              this.getControllers()
            }}>Fechar</Button>
            <Button primary size="large" onClick={() => this.modalForm.submitForm()}>Salvar Acionador</Button>
          </Modal.Actions>
        </Modal>
        <ActivityLoading visible={this.state.loading}/>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    guarita_read: state.devices.guarita_read,
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(VehicleAccessManager)
