/*eslint-disable no-undef*/
import {Formik} from 'formik'
import React from 'react'
import ReactImageFallback from 'react-image-fallback'
import {connect} from 'react-redux'
import {Button, Form, Grid, Modal} from 'semantic-ui-react'
import * as Yup from 'yup'
import {qs_local_server} from '../../queries-local-server'
import {actionMap as devices_actions} from '../../redux/modules/devices'
import {show_modal} from '../../utils/Formatters'
import {localPostApi} from '../../utils/LocalApi'
import {FF, FormikSelect} from '../form/FormInputs'

const id_bios = [
  { value: 1, text: 'Digital 1' },
  { value: 2, text: 'Digital 2' }
]

const vias = [
  { value: 1, text: 'Leitor de mesa' },
  { value: 0, text: 'Online' }
]

class FingerprintEnroll extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      settings: null,
      temp_template_1: '',
      temp_template_2: ''
    }
  }

  static defaultProps = {
    person: {},
    parent: null
  }

  componentDidMount() {
    this.cleanUp()
    qs_local_server.settings.all().then(res => this.setState({ settings: res }))
  }

  handleClose = () => {
    this.cleanUp()
    this.props.parent.setState({ bio_modal: false })
  }

  cleanUp = () => {
    this.setState({
      temp_template_1: '',
      temp_template_2: ''
    })
    this.props.deviceBio64_1('')
    this.props.deviceBio64_2('')
    this.props.deviceBio64_1_bin('')
    this.props.deviceBio64_2_bin('')
    this.props.bioTemplate('')
    this.props.bioIndex('')
  }

  getEquipmentUserId = () => {
    if (this.props.person.id_in_equipment) {
      return this.props.person.id_in_equipment
    } else {
      return this.props.person.obj_number
    }
  }

  enrollBio = async (values) => {
    let { bio, sensor, pass } = values

    let anviz_active = this.state.settings?.anviz__status === '1'
    let zk_active = this.state.settings?.zk__status === '1'
    let controlid_active = this.state.settings?.controlid__status === '1'
    let hikvision_active = this.state.settings?.hikvision__status === '1'

    if (anviz_active) {
      if (sensor === 1) {
        if (pass === 1) {
          this.cleanUp()
          this.props.bioIndex(bio)
        }

        CefSharp.BindObjectAsync('anvizReader').then(() => {
          anvizReader.enroll(pass).then(function (actualResult) {
            console.log(actualResult)
          })
        })

        if (pass === 1) {
          this.form.setFieldValue('pass', 2)
        } else {
          this.form.setFieldValue('pass', 1)
        }

      } else {
        let data = {
          user_id: this.getEquipmentUserId(),
          user_name: this.props.person.name,
          bio_id: bio
        }

        localPostApi('anviz/enroll_online', data)
      }

      return
    }

    if (zk_active) {
      this.cleanUp()
      this.props.bioIndex(bio)

      if (sensor === 1) {
        CefSharp.BindObjectAsync('zkReader').then(() => {
          zkReader.enroll().then(function (actualResult) {
            console.log(actualResult)
          })
        })
      } else {
        let data = {
          user_id: this.getEquipmentUserId(),
          user_name: this.props.person.name,
          bio_index: bio,
          access_group: this.props.access_group
        }

        localPostApi('devices-manager/bio_enroll_online', data)
      }

      return
    }

    if (controlid_active) {
      if (sensor === 1) {
        if (pass === 1) {
          this.cleanUp()
          this.props.bioIndex(bio)
        }

        this.form.setFieldValue('btn_disabled', true)

        try {
          if (this.state.settings?.controlid__reader_controlid === '1') {
            let controlid_reader_res = await CefSharp.BindObjectAsync('controlIdReader')
              .then(() => controlIdReader.enroll()
                .then((actualResult) => JSON.parse(actualResult))
              )

            console.log('controlid_reader_res', controlid_reader_res)

            if (controlid_reader_res.buffer) {
              if (pass === 1) {
                this.props.deviceBio64_1(controlid_reader_res.base64)
              } else if (pass === 2) {
                this.props.deviceBio64_1_bin(controlid_reader_res.base64)
              } else if (pass === 3) {
                this.props.deviceBio64_2(controlid_reader_res.base64)
              }

              try {
                if (pass === 1) {
                  this.form.setFieldValue('pass', pass + 1)
                  this.setState({ temp_template_1: controlid_reader_res.template })
                } else if (pass === 2) {
                  this.form.setFieldValue('pass', pass + 1)
                  this.setState({ temp_template_2: controlid_reader_res.template })
                } else if (pass === 3) {
                  this.form.setFieldValue('pass', 1)

                  this.props.bioTemplate(JSON.stringify(
                    [this.state.temp_template_1, this.state.temp_template_2, controlid_reader_res.template]
                  ))
                }
              } catch (e) {}
            }

          } else {
            let futronic_reader_res = await CefSharp.BindObjectAsync('futronicReader')
              .then(() => futronicReader.enroll()
                .then((actualResult) => JSON.parse(actualResult))
              )

            console.log('futronic_reader_res', futronic_reader_res)

            if (futronic_reader_res.buffer) {
              if (pass === 1) {
                this.props.deviceBio64_1(futronic_reader_res.base64)
              } else if (pass === 2) {
                this.props.deviceBio64_1_bin(futronic_reader_res.base64)
              } else if (pass === 3) {
                this.props.deviceBio64_2(futronic_reader_res.base64)
              }

              let data = {
                buffer: futronic_reader_res.buffer,
                width: futronic_reader_res.width,
                height: futronic_reader_res.height
              }

              try {
                let extract_res = await localPostApi('controlid/template_extract', data)

                console.log('extract_res', extract_res)

                if (extract_res.template) {

                  if (pass === 1) {
                    this.form.setFieldValue('pass', pass + 1)
                    this.setState({ temp_template_1: extract_res.template })
                  } else if (pass === 2) {
                    this.form.setFieldValue('pass', pass + 1)
                    this.setState({ temp_template_2: extract_res.template })
                  } else if (pass === 3) {
                    this.form.setFieldValue('pass', 1)

                    this.props.bioTemplate(JSON.stringify(
                      [this.state.temp_template_1, this.state.temp_template_2, extract_res.template]
                    ))
                  }

                }
              } catch (e) {}
            }
          }

        } catch (e) {
          console.log(e)
        }

        if (this.form) {
          this.form.setFieldValue('btn_disabled', false)
        }

      } else {

        let data = {
          user_id: this.getEquipmentUserId(),
          user_name: this.props.person.name,
          bio_index: bio,
          access_group: this.props.access_group
        }

        localPostApi('devices-manager/bio_enroll_online', data)
      }

      return
    }

    if (hikvision_active) {
      this.cleanUp()
      this.props.bioIndex(bio)

      if (sensor === 1) {
        CefSharp.BindObjectAsync('hikvisionReader').then(() => {
          hikvisionReader.enroll().then(function (actualResult) {
            console.log('actualResult', actualResult)
          })
        })
      } else {
        let data = {
          user_id: this.getEquipmentUserId(),
          user_name: this.props.person.name,
          bio_index: bio,
          access_group: this.props.access_group
        }

        localPostApi('devices-manager/bio_enroll_online', data)
      }

      return
    }

  }

  syncBios = () => {
    if (this.props.bio_template) {
      this.form.setFieldValue('btn_disabled', true)
      setTimeout(() => {
        if (this.form) {
          this.form.setFieldValue('btn_disabled', false)
        }
      }, 15000)

      this.props.bioEnrollClient()

    } else {
      show_modal('Capture as digitais primeiro!', 'erro')
    }
  }

  render() {
    let anviz_active = this.state.settings?.anviz__status === '1'
    let zk_active = this.state.settings?.zk__status === '1'
    let controlid_active = this.state.settings?.controlid__status === '1'
    let hikvision_active = this.state.settings?.hikvision__status === '1'

    return (
      <React.Fragment>
        <Modal open={true} size="small">
          <Modal.Header>Clique em "CADASTRAR DIGITAL"</Modal.Header>
          <Formik
            ref={node => (this.form = node)}
            initialValues={{
              bio: 1,
              sensor: 1,
              btn_disabled: false,
              pass: 1
            }}
            validationSchema={() => {
              return Yup.lazy(values => {
                return Yup.object().shape({
                  bio: Yup.string().required(),
                  sensor: Yup.string().required()
                })
              })
            }}
            onSubmit={(values) => {
              this.enrollBio(values)
            }}
            render={({ values, submitForm }) => (
              <React.Fragment>
                <Modal.Content>
                  <Form error className="ui form">
                    <Form.Group widths="equal">
                      <FF label={'Id digital'} name="bio" component={FormikSelect} options={id_bios}/>
                      <FF label={'Cadastrar via'} name="sensor" component={FormikSelect} options={vias}/>
                    </Form.Group>

                    <Grid>
                      <Grid.Row textAlign="center" columns="equal">
                        <Grid.Column>
                          <div className="ui medium image">
                            <ReactImageFallback
                              src={'data:image/jpeg;base64,' + this.props.bio64_1}
                              fallbackImage={require('../../assets/images/fingerprint.jpg')}/>
                          </div>
                        </Grid.Column>

                        <Grid.Column>
                          <div className="ui medium image">
                            <ReactImageFallback
                              src={'data:image/jpeg;base64,' + this.props.bio64_1_bin}
                              fallbackImage={require('../../assets/images/fingerprint.jpg')}/>
                          </div>
                        </Grid.Column>

                        <Grid.Column>
                          <div className="ui medium image">
                            <ReactImageFallback
                              src={'data:image/jpeg;base64,' + this.props.bio64_2}
                              fallbackImage={require('../../assets/images/fingerprint.jpg')}/>
                          </div>
                        </Grid.Column>

                        {!controlid_active && <Grid.Column>
                          <div className="ui medium image">
                            <ReactImageFallback
                              src={'data:image/jpeg;base64,' + this.props.bio64_2_bin}
                              fallbackImage={require('../../assets/images/fingerprint.jpg')}/>
                          </div>
                        </Grid.Column>}
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  {this.state.settings && zk_active &&
                    <React.Fragment>
                      <Button disabled={values.btn_disabled} size="large" onClick={this.props.bioEnrollClient}>Reenviar</Button>
                      <Button disabled={values.btn_disabled} primary size="large" onClick={submitForm}>Cadastrar Digital</Button>
                    </React.Fragment>}

                  {this.state.settings && hikvision_active &&
                    <React.Fragment>
                      <Button disabled={values.btn_disabled} primary size="large" onClick={submitForm}>Capturar Digital</Button>
                      <Button disabled={values.btn_disabled} positive size="large" onClick={this.syncBios}>Sincronizar Digital</Button>
                    </React.Fragment>}

                  {this.state.settings && (anviz_active || controlid_active) &&
                    <React.Fragment>
                      <Button disabled={values.btn_disabled} primary size="large" onClick={submitForm}>
                        Capturar Digital - Passo ({values.pass})
                      </Button>
                      <Button disabled={values.btn_disabled} positive size="large" onClick={this.syncBios}>Sincronizar Digital</Button>
                    </React.Fragment>}

                  <Button size="large" negative onClick={this.handleClose}>Fechar</Button>
                </Modal.Actions>
              </React.Fragment>
            )}
          />
        </Modal>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    bio64_1: state.devices.bio64_1,
    bio64_2: state.devices.bio64_2,
    bio64_1_bin: state.devices.bio64_1_bin,
    bio64_2_bin: state.devices.bio64_2_bin,
    bio_template: state.devices.bio_template
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deviceBio64_1: (data) => dispatch(devices_actions.bio64_1(data)),
    deviceBio64_2: (data) => dispatch(devices_actions.bio64_2(data)),
    deviceBio64_1_bin: (data) => dispatch(devices_actions.bio64_1_bin(data)),
    deviceBio64_2_bin: (data) => dispatch(devices_actions.bio64_2_bin(data)),
    bioTemplate: (data) => dispatch(devices_actions.bio_template(data)),
    bioIndex: (data) => dispatch(devices_actions.bio_index(data)),
    bioEnrollClient: () => dispatch(devices_actions.bio_enroll_client())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FingerprintEnroll)
