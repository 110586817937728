import { GridApi, GridReadyEvent } from 'ag-grid'
import 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Dropdown, Form, Label } from 'semantic-ui-react'
import { FF, FormikInput, FormikSelect } from '../../components/form/FormInputs'
import { WORKSHIFT_STATUS } from '../../fields'
import { useLocalStorageTS } from '../../hooks'
import { grid_refs_actions } from '../../redux/modules/grid-refs'
import { dateTimeFormat } from '../../utils/Formatters'
import { gridDefaults } from '../../utils/gridDefaults'
import { useHistory } from 'react-router-dom'

const WorkShiftGrid = (props: { componentParent: any }) => {
  const pagination_size = 50
  const initial_values = {
    start_date: 2,
    name: '',
    next_worker: ''
  }

  const dispatch = useDispatch()
  const history = useHistory()
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [filter_values, setFilterValues] = useLocalStorageTS<any>('filter_grid_work_shift', initial_values)

  useEffect(() => {
    return () => setFilterValues(initial_values)
  }, [])

  const prepareFilterQs = () => {
    let filter_qs_state = JSON.parse(localStorage.getItem('filter_grid_work_shift') ?? '{}') ?? {}

    if (filter_qs_state.start_date) {
      filter_qs_state['filter{created_at.gte}'] = moment().subtract(filter_qs_state.start_date, 'days').startOf('day').toISOString()
    } else {
      filter_qs_state['filter{created_at.gte}'] = moment().subtract(2, 'days').startOf('day').toISOString()
    }

    if (filter_qs_state.name) filter_qs_state['filter{name.icontains}'] = filter_qs_state.name
    if (filter_qs_state.next_worker) filter_qs_state['filter{next_worker.icontains}'] = filter_qs_state.next_worker

    filter_qs_state['include[]'] = 'created_by_name'

    return filter_qs_state
  }

  const serverSideDatasource = gridDefaults.makeServerSideDataSource('admin/work-shifts', pagination_size, prepareFilterQs)

  const GRID = {
    gridOptions: {
      ...gridDefaults.adminGrid,
      context: {
        componentParent: props.componentParent
      },
      rowHeight: 85,
      cacheBlockSize: pagination_size,
      paginationPageSize: pagination_size,
      serverSideDatasource: serverSideDatasource,
      rowModelType: 'serverSide'
    },
    columnDefs: [
      {
        headerName: 'Colaborador',
        field: 'name',
        width: 70,
        filter: 'text'
      },
      {
        headerName: 'Colaborador próximo turno',
        field: 'next_worker',
        width: 70,
        filter: 'text'
      },
      {
        headerName: 'Status',
        field: 'status',
        width: 30,
        filter: 'text',
        valueGetter: (params) => params.data ? WORKSHIFT_STATUS[params.data.status] : null,
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          let value = params.getValue()

          return (
            <div>
              {value === 'Encerrado' ?
                <Label color="green" className="grid-label">{value}</Label> :
                <Label color="red" className="grid-label">{value}</Label>}
            </div>
          )
        }
      },
      {
        headerName: 'Detalhes',
        width: 70,
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <div>
              <strong>Abertura:</strong> {dateTimeFormat(params, 'created_at')} <br/>
              <strong>Encerramento:</strong> {dateTimeFormat(params, 'closed_at')}<br/>
              <strong>Autor:</strong> {params.data.created_by_name}
            </div>
          )
        }
      },
      {
        headerName: 'Data de Abertura',
        field: 'created_at',
        width: 40,
        filter: 'text',
        hide: true,
        valueGetter: (params) => dateTimeFormat(params, 'created_at')
      },
      {
        headerName: 'Data de Encerramento',
        field: 'closed_at',
        width: 40,
        filter: 'text',
        hide: true,
        valueGetter: (params) => dateTimeFormat(params, 'closed_at')
      },
      {
        headerName: 'Ações',
        width: 115,
        suppressResize: true,
        suppressMovable: true,
        suppressSorting: true,
        suppressMenu: true,
        suppressFilter: true,
        suppressSizeToFit: true,
        cellClass: 'ag-button-cell',
        cellRendererFramework: (params) => {
          if (!params.node.data) return null

          return (
            <Button.Group basic primary compact>
              <Dropdown
                text="Ações  "
                icon="pencil"
                floating
                button
                className="icon"
              >
                <Dropdown.Menu>
                  <Dropdown.Header icon="tags" content="Selecione uma Ação"/>
                  <Dropdown.Divider/>

                  {params.data && params.data.status !== 1 &&
                    <React.Fragment>
                      <Dropdown.Item onClick={() => { params.context.componentParent.handleView(params.data) }}>
                        Visualizar
                      </Dropdown.Item>
                    </React.Fragment>}

                  {params.data && params.data.status === 1 &&
                    <React.Fragment>
                      <Dropdown.Item
                        onClick={() => {
                          history.push(`/admin/work-shift-form?item=${params.data.id}`, params.data)
                        }}
                      >
                        Editar
                      </Dropdown.Item>

                      <Dropdown.Item onClick={() => { params.context.componentParent.handleOpen(params.data) }}>
                        Encerrar
                      </Dropdown.Item>
                    </React.Fragment>}
                </Dropdown.Menu>
              </Dropdown>
            </Button.Group>
          )
        }
      }
    ],
    onGridReady: (params: GridReadyEvent) => {
      setGridApi(params.api)
      dispatch(grid_refs_actions.update_ref({ key: 'work_shift', ref: params.api }))
    }
  }

  return (
    <React.Fragment>
      <div className="ui segment page-filter-container">
        <Formik
          initialValues={{
            start_date: filter_values.start_date,
            name: filter_values.name,
            next_worker: filter_values.next_worker
          }}
          validate={values => {
            let errors: any = {}
            if (!values.start_date) errors.start_date = 'Selecione uma data'
            return errors
          }}
          onSubmit={(values) => {
            setFilterValues(values)
            console.log(gridApi)
            gridApi?.onFilterChanged()
          }}
          render={({ submitForm }) => (
            <Form>
              <Form.Group widths="equal" style={{ margin: 0 }}>
                <FF name="start_date" label="Data" component={FormikSelect}
                    options={
                      [
                        { value: 2, text: '2 dias atrás' },
                        { value: 7, text: '7 dias atrás' },
                        { value: 15, text: '15 dias atrás' },
                        { value: 30, text: '30 dias atrás' },
                        { value: 60, text: '60 dias atrás' }
                      ]
                    }
                />

                <FF name="name" label="Colaborador" component={FormikInput}/>
                <FF name="next_worker" label="Colaborador próximo turno" component={FormikInput}/>

                <Button primary size="small" basic content="Filtrar" onClick={submitForm}/>
              </Form.Group>
            </Form>
          )}
        />
      </div>

      <div className="ui raised segment page-grid-container">
        <div style={{ width: '100%' }} className="ag-theme-material">
          <AgGridReact
            gridOptions={GRID.gridOptions}
            columnDefs={GRID.columnDefs}
            onGridReady={GRID.onGridReady}
          />
        </div>
        <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
      </div>
      <div className="ui divider hidden" style={{ clear: 'both', margin: 0 }}/>
    </React.Fragment>
  )
}

export default WorkShiftGrid