export const MODELTYPE_ID_TYPE = {
  1: 'permission',
  2: 'group',
  3: 'contenttype',
  4: 'session',
  // 5:'logentry',
  6: 'token',
  7: 'logentry',
  8: 'taskresult',
  9: 'profile',
  10: 'request',
  11: 'response',
  12: 'sqlquery',
  13: 'user',
  14: 'account',
  15: 'device',
  16: 'team',
  17: 'notification',
  18: 'pushnotification',
  19: 'sentnotification',
  20: 'sentnotificationurlclick',
  21: 'usernotification',
  22: 'file',
  23: 'animal',
  24: 'block',
  25: 'locker',
  26: 'lost',
  27: 'parking',
  28: 'parkingpavement',
  29: 'person',
  30: 'personunit',
  31: 'unit',
  32: 'vehicle',
  33: 'comment',
  34: 'category',
  35: 'maintenance',
  36: 'provider',
  37: 'patrimony',
  38: 'document',
  39: 'information',
  40: 'notice',
  41: 'guest',
  42: 'blockeddate',
  43: 'book',
  44: 'guestlist',
  45: 'periodtime',
  46: 'space',
  47: 'accessauthorization',
  48: 'accessdevice',
  49: 'accessdriver',
  50: 'accessgroup',
  51: 'accesslog',
  52: 'accessrelay',
  53: 'accessrule',
  54: 'accessstation',
  55: 'accesstime',
  56: 'contract',
  57: 'objectcontrol',
  58: 'mailing',
  59: 'penalty',
  60: 'penaltytype',
  61: 'ticket',
  62: 'signrequest',
  63: 'construction',
  64: 'moving',
  65: 'panic',
}

export const MODELTYPE_TYPE_ID = {
  'permission': 1,
  'group': 2,
  'contenttype': 3,
  'session': 4,
  // 'logentry':5,
  'token': 6,
  'logentry': 7,
  'taskresult': 8,
  'profile': 9,
  'request': 10,
  'response': 11,
  'sqlquery': 12,
  'user': 13,
  'account': 14,
  'device': 15,
  'team': 16,
  'notification': 17,
  'pushnotification': 18,
  'sentnotification': 19,
  'sentnotificationurlclick': 20,
  'usernotification': 21,
  'file': 22,
  'animal': 23,
  'block': 24,
  'locker': 25,
  'lost': 26,
  'parking': 27,
  'parkingpavement': 28,
  'person': 29,
  'personunit': 30,
  'unit': 31,
  'vehicle': 32,
  'comment': 33,
  'category': 34,
  'maintenance': 35,
  'provider': 36,
  'patrimony': 37,
  'document': 38,
  'information': 39,
  'notice': 40,
  'guest': 41,
  'blockeddate': 42,
  'book': 43,
  'guestlist': 44,
  'periodtime': 45,
  'space': 46,
  'accessauthorization': 47,
  'accessdevice': 48,
  'accessdriver': 49,
  'accessgroup': 50,
  'accesslog': 51,
  'accessrelay': 52,
  'accessrule': 53,
  'accessstation': 54,
  'accesstime': 55,
  'contract': 56,
  'objectcontrol': 57,
  'mailing': 58,
  'penalty': 59,
  'penaltytype': 60,
  'ticket': 61,
  'signrequest': 62,
  'construction': 63,
  'moving': 64,
  'panic': 65,
}