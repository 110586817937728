/*eslint-disable no-undef*/

import axios from 'axios'
import { createBrowserHistory as createHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { qs } from '../../queries'
import LicenseService from '../../services/LicenseService'
import UpdaterService from '../../services/UpdaterService'
import rootReducer from '../reducers'
import rootSaga from '../sagas'
import swal from 'sweetalert2'

const sagaMiddleware = createSagaMiddleware()

export const history = createHistory()

/*
CefSharp.BindObjectAsync('condoUtils').then(()=>{condoUtils.eval('File.Exists("E:\/Documents\/add_a_workbook.xlsx")', 'using System.IO;').then(function (actualResult) {console.log(actualResult)})})

CefSharp.BindObjectAsync('condoUtils').then(()=>{condoUtils.downloadFile('https://s3-us-west-2.amazonaws.com/condoid/setups/server-updated.zip').then(function (actualResult) {console.log(actualResult)})})

CefSharp.BindObjectAsync('condoUtils').then(()=>{condoUtils.extractFile("F:\/Dropbox\/WORKS\/APPS\/Condominios\/DEV\/condoid_desktop\/server\/packager\/server_14.04.2021-12.06.55.48.zip").then(function (actualResult) {console.log(actualResult)})})

CefSharp.BindObjectAsync('condoUtils').then(()=>{condoUtils.executeCommand('cmd.exe', '/c ipconfig').then(function (actualResult) {console.log(actualResult)})})
*/

history.listen((location, action) => {
  try {
    try {
      if (location.pathname.indexOf('live') > -1) {
        swal({ type: 'warning', title: 'ATENÇÃO!', html: '<h1>O nosso novo e único canal de atendimento é o <br/> 62 3095-3020. <br/> Esse número também é WhatsApp<h1/>' })
      }
    } catch (e) {
      console.log(e)
    }
    qs.user.set_user_salesiq()

    CefSharp.BindObjectAsync('updateManager')
      .then(() => {
        updateManager.check()
          .then(function (actualResult) {
            console.log(actualResult)
          })
      })

    UpdaterService.getWrapperVersion()
      .then(function (actualResult) {
        console.log(actualResult)

        let token = localStorage.getItem('token')
        let data = {
          wrapperVersion: actualResult,
          token: token
        }

        axios.post('https://desktop-update.condoid.com.br/ping', data)
          .then((res) => { console.log(res) })
          .catch((err) => console.error(err))

      })
      .catch((e) => {
        console.error('Erro ao pegar atual versão')
        console.error(e)
      })
    // .catch(function (e) {
    //   console.error(e)
    // })

  } catch (e) {
    console.log(e)
  }

  try {
    if (location.pathname.indexOf('/admin') > -1) {
      LicenseService.refreshVerification()
    }
  } catch (e) {
    console.error(e)
  }
})

function configureStore(initialState, helpersConfig) {
  const store = createStore(
    rootReducer,
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(
      // customMiddleWare,
      sagaMiddleware
    )
  )
  sagaMiddleware.run(rootSaga)
  return store
}

export const store = configureStore()