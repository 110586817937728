import { push_toast } from '../utils/common'

export default class ServerServiceManager {
  static async getAllServices() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.getAllServices().then((actualResult) => actualResult)
    })
  }

  static async install() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.install().then((actualResult) => actualResult)
    })
  }

  static async uninstall() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.uninstall().then((actualResult) => actualResult)
    })
  }

  static async startService() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.startService().then((actualResult) => actualResult)
    })
  }

  static async stopService() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.stopService().then((actualResult) => actualResult)
    })
  }

  static async rebootService() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.rebootService().then((actualResult) => actualResult)
    })
  }

  static async getServiceStatus() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.getServiceStatus().then((actualResult) => actualResult)
    })
  }

  static async isServerInstalled() {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.isServerInstalled().then((actualResult) => actualResult)
    })
  }

  static async startServiceByName(serviceName: string, showToast: boolean = false) {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.startServiceByName(serviceName, showToast).then((actualResult) => actualResult)
    })
  }

  static async getServiceStatusByName(serviceName: string) {
    return CefSharp.BindObjectAsync('serverServiceManager').then(() => {
      return serverServiceManager.getServiceStatusByName(serviceName).then((actualResult) => actualResult)
    })
  }

  static async getDBServiceNames() {
    let all_services = await ServerServiceManager.getAllServices()

    // console.log('ServerServiceManager getDBServiceNames -> all_services', all_services)

    let postgres_sc_names: string[] = []
    let mongo_sc_name = ''

    for (let o of all_services) {
      if (o.service_name.toLowerCase().indexOf('postgres') > -1) {
        postgres_sc_names.push(o.service_name)
      }
      if (o.service_name.toLowerCase().indexOf('mongo') > -1) {
        mongo_sc_name = o.service_name
      }
    }

    // console.log('ServerServiceManager getDBServiceNames -> postgres_sc_names', postgres_sc_names)
    // console.log('ServerServiceManager getDBServiceNames -> mongo_sc_name', mongo_sc_name)

    return {
      postgres_sc_names,
      mongo_sc_name,
    }
  }

  static async getDBServiceStatus() {
    let { postgres_sc_names, mongo_sc_name } = await ServerServiceManager.getDBServiceNames()

    // Handle Postgres Status
    let postgres_statuses: boolean[] = []
    for (let o of postgres_sc_names) {
      postgres_statuses.push(await ServerServiceManager.getServiceStatusByName(o) == 'Running')
    }

    // @ts-ignore
    let postgres_status = postgres_statuses.reduce((a, b) => a + b, 0) === postgres_sc_names.length

    // Handle Mongo Status
    let mongo_status: boolean = false
    if (mongo_sc_name) {
      mongo_status = await ServerServiceManager.getServiceStatusByName('mongodb') == 'Running'
    }

    // console.log('ServerServiceManager getDBServiceStatus -> postgres_statuses', postgres_statuses)
    // console.log('ServerServiceManager getDBServiceStatus -> postgres_status', postgres_status)
    // console.log('ServerServiceManager getDBServiceStatus -> mongo_status', mongo_status)

    return {
      postgres_status,
      mongo_status,
    }
  }

  static async startDBServices() {
    let { postgres_sc_names, mongo_sc_name } = await ServerServiceManager.getDBServiceNames()

    for (let o of postgres_sc_names) {
      await ServerServiceManager.startServiceByName(o)
    }

    if (mongo_sc_name) {
      await ServerServiceManager.startServiceByName(mongo_sc_name)
    }

    let { postgres_status, mongo_status } = await ServerServiceManager.getDBServiceStatus()

    return {
      postgres_status,
      mongo_status,
    }
  }

  static async fullStartServer(): Promise<boolean> {
    let is_installed = await ServerServiceManager.isServerInstalled()

    if (!is_installed) {
      return false
    }

    push_toast('Iniciando, por favor aguarde!', 'info')

    let { postgres_status, mongo_status } = await ServerServiceManager.startDBServices()

    if (postgres_status && mongo_status) {
      await ServerServiceManager.rebootService()
      return true
    } else {
      push_toast('Erro ao iniciar os serviços do Servidor! Procure o suporte ou tente reiniciar o computador.', 'error')
      return false
    }
  }
}