import { handleActions } from 'redux-actions'
import { Action, createAction, getType } from 'typesafe-actions'

const domain = 'grid-refs'

type ActionTypesBase = {
  update_ref: (data: {}) => Action,
}

// ACTIONS CREATORS Should be snake_case
export const grid_refs_actions: ActionTypesBase = {
  update_ref: createAction(`${domain}/update_ref`, action => (data: any) => action(data))

}

// REDUCER Should be snake_case
const initialState: any = {
  mailing: null,
  work_shift: null,
  vehicle: null,
  tickets: null,
}

let reducers = {
  [getType(grid_refs_actions.update_ref)]: (state: any, action: any) => ({ ...state, [action.payload.key]: action.payload.ref })
}

export const grid_refs_reducer = handleActions(reducers, initialState)

