import React from 'react'
import { Link } from 'react-router-dom'
import {Form} from 'semantic-ui-react'
import PageBase from '../../components/PageBase'
import { Formik } from 'formik'
import { postApi } from '../../utils/api'
import ActivityLoading from '../../components/Loading'
import swal from 'sweetalert2'
import UnitsSelect from '../../components/form/UnitsSelect'
import ToasterService from '../../services/ToasterService'

export default class ObjectFormPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      units: []
    }
  }

  render() {
    return (
      <PageBase title="Registrar Saída de Objetos" showFilter={false}>
        <div>
          <Formik
            initialValues={{
              name: '',
              requester: '',
              unit: '',
              description: '',
            }}
            validate={values => {
              let errors = {}
              if (!values.name) errors.name = 'Digite o nome do objeto'
              if (!values.requester) errors.requester = 'Digite o nome do solicitante'

              return errors
            }}
            onSubmit={(values) => {
              this.setState({loading: true})

              postApi('admin/object-controls', values)
                .then((res) => {
                  this.setState({loading: false})
                  this.props.history.push('/admin/objects')
                  swal({type: 'success', title: 'Salvo com sucesso!'})
                })
                .catch((err) => {
                  this.setState({loading: false})
                  ToasterService.sendErrors(err)
                })
            }}
            render={({values, errors, touched, handleChange, handleBlur, submitForm}) => (
              <Form className="ui form large error">
                <div className="two fields">
                  <div className={errors.name ? 'field error' : 'field'}>
                    <label>Nome do Objeto</label>
                    <input type="text"
                           name="name"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.name}/>

                    {touched.name && errors.name &&
                    <h5 className='ui header red'>{errors.name}</h5>}
                  </div>

                  <div className={errors.requester ? 'field error' : 'field'}>
                    <label>Solicitante</label>
                    <input type="text"
                           name="requester"
                           onChange={handleChange}
                           onBlur={handleBlur}
                           value={values.requester}/>

                    {touched.requester && errors.requester &&
                    <h5 className='ui header red'>{errors.requester}</h5>}
                  </div>
                </div>

                <div className="two fields">
                  <UnitsSelect/>
                </div>

                <br/>
                <br/>

                <div className="field">
                  <label>Observações</label>
                  <textarea rows="2"
                            name="description"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.description}/>
                  {touched.description && errors.description &&
                  <h5 className="ui header red">{errors.description}</h5>}
                </div>

                <br/>
                <br/>
                <br/>

                <button className="ui button primary right floated large" onClick={submitForm}>Enviar</button>
                <Link to="/admin/objects">
                  <button className="ui red button right floated large">Cancelar</button>
                </Link>
              </Form>
            )}
          />
        </div>

        <ActivityLoading visible={this.state.loading}/>
      </PageBase>
    )
  };
};