import { datadogRum } from '@datadog/browser-rum'
import moment from 'moment'
import enviroment from '../enviroment'
import { fetchApi, postApi } from '../services/api'
import UpdaterService from '../services/UpdaterService'
import { localPutApi_ValidateLocalServer } from '../utils/LocalApi'
import { LocalDB } from '../utils/LocalDB'
import { isStale, requestWithCache } from './query_utils'

export const user = {
  me: async (sync = true) => {
    try {
      let current_user_cache = LocalDB.getItem('current_user')
      let last_update = LocalDB.getItem('@CURRENT_USER_LAST_UPDATE@')
      let is_stale = isStale(last_update, 30)

      let response: any = {}

      console.log('current_user_cache', current_user_cache)
      console.log('current_user_cache is_stale', is_stale)

      if (current_user_cache && !is_stale) {
        console.log('USING CURRENT USER CACHE')
        response = current_user_cache
      } else {
        response = await requestWithCache(sync, 'current_user', 'admin/me')
        LocalDB.setItem('@CURRENT_USER_LAST_UPDATE@', moment().toISOString())
      }

      LocalDB.setItem('current_user', response)

      let user_context = {
        id: response.id,
        username: response.username,
        name: response.name,
        email: response.email,
        role: response.role,
        date_joined: response.date_joined,
        last_login: response.last_login,

        current_team_id: response.current_team_id,
        current_team_name: response.team.name,
        current_account_id: response.current_account_id,

        is_superuser: response.is_superuser,
        is_staff: response.is_staff,
        is_active: response.is_active
      }

      datadogRum.addRumGlobalContext('user', user_context)

      const wrapper_version = await UpdaterService.getWrapperVersionHandled()

      // Sentry.setUser({
      //   id: response.id,
      //   username: response.username,
      //   name: response.name,
      //   email: response.email,
      //   wrapper_version: wrapper_version,
      //
      //   current_team_id: response.current_team_id,
      //   current_team_name: response.team.name,
      //   current_account_id: response.current_account_id
      // })

      // Sentry.setContext('ENVIRONMENT', {
      //   app_version: enviroment().APP_VERSION_READABLE,
      //   wrapper_version: wrapper_version
      // })

      const token = localStorage.getItem('token')
      localPutApi_ValidateLocalServer('cache/u_token', { single: token })

      await user.set_user_salesiq()

      return response
    } catch (e) {
      return Promise.reject(e)
    }
  },
  notifications: async () => {
    return await fetchApi(`admin/push-notifications/me`)
  },
  read_all_notifications: async () => {
    return await fetchApi(`admin/push-notifications/read_me_all`)
  },
  liveNotificationsKey: async () => {
    return await fetchApi(`admin/my-account/live-notifications-key`)
  },
  callToUnit: async (variables: any) => {
    console.log('variables', variables)
    return await postApi(`admin/my-account/call-to-unit`, variables)
  },
  set_user_salesiq: async () => {
    // try {
    //   let response = LocalDB.getItem('current_user')
    //   const wrapper_version = await UpdaterService.getWrapperVersionHandled()
    //
    //   console.log('$zoho', $zoho)
    //   console.log('window.$zoho', window.$zoho)
    //
    //   if ($zoho && response) {
    //     $zoho.salesiq.visitor.id(`${response.id}`)
    //     $zoho.salesiq.visitor.name(`${response.name}`)
    //     $zoho.salesiq.visitor.email(`${response.email}`)
    //
    //     $zoho.salesiq.visitor.info({
    //       'Usuário': `${response.username}`,
    //
    //       'Condomínio Atual': `${response.team.name}`,
    //       'Código Condomínio': `${response.team.schema_name}`,
    //
    //       'Versão App (Interface)': `${enviroment().APP_VERSION_READABLE}`,
    //       'Versão App (Instalação)': `${wrapper_version}`
    //     })
    //
    //     console.log('ZOHO SALESIQ UPDATED')
    //   }
    // } catch (e) {
    //   console.error('ZOHO SALESIQ ERROR', e)
    // }
  }
}