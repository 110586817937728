import { Formik } from 'formik'
import React, { useRef, useState } from 'react'
import MaskedInput from 'react-text-mask'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as Yup from 'yup'
import { FF, FormikInput } from '../../../components/form/FormInputs'
import ActivityLoading from '../../../components/Loading'
import { WebcamCaptureUploader } from '../../../components/WebcamCapture'
import { qs } from '../../../queries'
import { push_toast } from '../../../utils/common'
import { show_modal } from '../../../utils/Formatters'

type Props = {
  setClose: () => void
  closeSearchModal: () => void
  pushToSelectedGuest: (data) => void
}

export const GuestCreateModal = (props: Props) => {
  const [activity_loading, setActivityLoading] = useState<boolean>(false)
  const webcam_capture = useRef<any>()

  const addNewGuest = async (data) => {
    await qs.guests.get(data.id)
    props.pushToSelectedGuest(data)
    props.setClose()
    props.closeSearchModal()
  }

  return (
    <React.Fragment>
      <Modal open={true} size="fullscreen">
        <Formik
          initialValues={{
            name: '',
            cpf: '',
            rg: '',
            description: '',
            file: '',
          }}
          validationSchema={() => {
            return Yup.lazy((values: any) => {
              return Yup.object().shape({
                name: Yup.string().required(),
                // @ts-ignore
                cpf: values.cpf ? Yup.string().validCPF() : Yup.string()
              })
            })
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setActivityLoading(true)

            const data = {
              name: values.name,
              cpf: values.cpf,
              rg: values.rg,
              description: values.description
            }

            if (webcam_capture.current?.state.base64) {
              try {
                let file_res = await webcam_capture.current.savePhoto()
                console.log(file_res)
                data['file'] = file_res.file_id
              } catch (e) {
                setActivityLoading(false)
                push_toast('Erro ao salvar a foto', 'error')
                return
              }
            }

            qs.guests.post(data).then((res) => {
              setActivityLoading(false)
              addNewGuest(res)
            }).catch((err) => {
              setActivityLoading(false)
              show_modal('Verifique os dados!', 'error')
            })

          }}
          render={({ values, errors, touched, submitForm, handleBlur, setFieldValue }) => (
            <React.Fragment>
              <Modal.Header>Cadastrar novo visitante</Modal.Header>
              <Modal.Content scrolling>
                <Form>
                  <Form.Group>
                    <FF label={'Nome'} name="name" component={FormikInput} width={8}/>

                    <Form.Field error={!!errors.cpf} width={4}>
                      <label>CPF</label>
                      <MaskedInput
                        name="cpf" value={values.cpf}
                        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                        onChange={(event) => setFieldValue('cpf', event.target.value.replace(new RegExp(/\D/g), ''))}
                        onBlur={handleBlur}
                      />

                      {touched.cpf && errors.cpf && <h5 className="ui header red">{errors.cpf}</h5>}
                    </Form.Field>

                    <FF label={'RG'} name="rg" component={FormikInput} width={4}/>
                  </Form.Group>

                  <FF label={'Observações do visitante'} name="description" component={FormikInput}/>

                  <WebcamCaptureUploader
                    ref={webcam_capture}
                    file_type="guests"
                    values={values} setFieldValue={setFieldValue}
                    size="medium"
                    show_success_modal={false}
                    hide_save_btn
                    isProfile
                  />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color="red" type="button" content="Cancelar" onClick={props.setClose}/>
                <Button primary type="button" content="Salvar Novo Visitante" onClick={submitForm}/>
              </Modal.Actions>
            </React.Fragment>
          )}/>
      </Modal>

      <ActivityLoading visible={activity_loading}/>
    </React.Fragment>
  )
}