import React, { useEffect, useState } from 'react'
import { Button, Grid, List, Modal, Table } from 'semantic-ui-react'
import { qs } from '../../../queries'
import { useQuery } from 'react-query'
import { isPrivate } from '../../../utils/form'
import PrivacyData from '../../../utils/PrivacyData'

export const QuickUnitData = ({ unit_id }: { unit_id: any }) => {
  const { isLoading, data, refetch } = useQuery(['getUnitPeopleData', unit_id], () => qs.units.quick_unit_data(unit_id), {
    enabled: false,
    retry: false
  })

  const [show_modal, setShowModal] = useState(false)

  useEffect(() => {
    if (unit_id) {
      refetch()
    }
  }, [unit_id])

  return (
    <React.Fragment>
      <Grid.Column width={2}>
        <div className="field">
          <label>Moradores</label>
          <Button as={'a'} loading={isLoading} compact onClick={() => {
            setShowModal(true)
          }}>Exibir moradores</Button>

        </div>
      </Grid.Column>

      <Grid.Column width={3}>
        <div className="field">
          <label>Vagas da Unidade</label>
          <List style={{ marginTop: 0 }}>
            {data?.parkings?.map(item => (
              <List.Item key={item.id}>{item.with_pavement}</List.Item>
            ))}
          </List>
        </div>
      </Grid.Column>

      <Modal open={show_modal}>
        <Modal.Content scrolling>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Nome</Table.HeaderCell>
                <Table.HeaderCell>Celular 1</Table.HeaderCell>
                <Table.HeaderCell>Celular 2</Table.HeaderCell>
                <Table.HeaderCell>Telefone</Table.HeaderCell>
                <Table.HeaderCell>Nome do contato</Table.HeaderCell>
                <Table.HeaderCell>Telefone do contato</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data?.people?.map(item => (
                <Table.Row key={item.id}>
                  <Table.Cell>{item.name}</Table.Cell>
                  <Table.Cell>
                    {isPrivate('person__phone') ? PrivacyData.maskPhone(item.mobile1) : item.mobile1}
                  </Table.Cell>
                  <Table.Cell>
                    {isPrivate('person__phone') ? PrivacyData.maskPhone(item.mobile2) : item.mobile2}
                  </Table.Cell>
                  <Table.Cell>
                    {isPrivate('person__phone') ? PrivacyData.maskPhone(item.phone) : item.phone}
                  </Table.Cell>
                  <Table.Cell>{item.contact_name}</Table.Cell>
                  <Table.Cell>{item.contact_phone}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Fechar" color='red' onClick={() => {
            setShowModal(false)
          }}/>
        </Modal.Actions>

      </Modal>
    </React.Fragment>

  )
}