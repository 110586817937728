import localForage from 'localforage'
import moment from 'moment'
import enviroment from '../enviroment'
import queryClient from '../queryClient'
import {apiAxios, fetchApi} from '../services/api'
import ToasterService from '../services/ToasterService'
import {GuestModel} from '../typings'
import {putCache, requestToServerCacheUpdate} from '../utils/LocalApi'
import {LocalDB} from '../utils/LocalDB'

export const guests = {
  cache_key: '@GUESTS_CACHE@',
  last_update: '@GUESTS_CACHE_LAST_UPDATE@',

  get_history: async (guest_id: number) => {
    return await fetchApi(`admin/guests/${guest_id}/history`)
  },

  _cacheItem: async (data: GuestModel) => {
    let guests_cached = await localForage.getItem<object>(guests.cache_key) ?? {}
    guests_cached[data.id] = data
    await localForage.setItem(guests.cache_key, guests_cached)

    await queryClient.invalidateQueries('getGuestsCached')

    requestToServerCacheUpdate('guests')
  },

  allCached: async () => {
    let data = await localForage.getItem<object>(guests.cache_key)

    if (!data) {
      return await guests.cacheUpdates()
    }

    return Object.values(data).reverse()
  },

  cacheUpdates: async () => {
    interface HTTPResponse {
      data: GuestModel[]
    }

    interface TotalCountHTTPResponse {
      total_count: number
    }

    let guests_cached = await localForage.getItem<object>(guests.cache_key) ?? {}
    let last_update = await localForage.getItem<string>(guests.last_update)
    let url = ''

    if (last_update) {
      // Menos quanto horas para evitar possíveis bugs com fuso
      last_update = moment(last_update).subtract(4, 'hours').toISOString()

      url = 'admin/guests?' + 'filter{updated_at.gte}=' + last_update + '&sort[]=id&limit=40000'

    } else {
      url = 'admin/guests?limit=40000'
    }

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + url)
    const res_data = data.data

    if (res_data.length) {
      for (let o of res_data) {
        guests_cached[o.id] = o
      }

      await localForage.setItem(guests.cache_key, guests_cached)
    }

    await localForage.setItem(guests.last_update, moment().toISOString())

    const stats_res = await apiAxios.get<TotalCountHTTPResponse>(enviroment().apiUrl + 'admin/guests/stats')
    const total_count = stats_res.data.total_count

    const local_total_count = Object.keys(guests_cached).length
    const loop_count = parseInt(LocalDB.getItem('guest_cache_update_loop_count') || 0)

    if (total_count > local_total_count && loop_count < 2) {
      console.error(new Error(`Guests cacheUpdates -> wrong total count. Server: ${total_count} URL: ${url} - Local: ${local_total_count} - Loop Count: ${loop_count}`))

      LocalDB.setItem('guest_cache_update_loop_count', loop_count + 1)

      await localForage.removeItem(guests.last_update)
      return await guests.cacheUpdates()
    }

    try {
      requestToServerCacheUpdate('guests')
    } catch (e) { }

    try {
      // Add backwards integration with old server version
      putCache('guests', Object.values(guests_cached), { timeout: 40 * 1000 })
    } catch (e) { }

    return Object.values(guests_cached).reverse()
  },

  get: async (guest_id: string | number) => {
    type HTTPResponse = GuestModel

    const { data } = await apiAxios.get<HTTPResponse>(enviroment().apiUrl + `admin/guests/${guest_id}`)

    await guests._cacheItem(data)

    return data
  },

  post: async (values: any) => {
    try {
      const { data } = await apiAxios.post(enviroment().apiUrl + `admin/guests`, values)

      // Cache using the response because is the same of list/get
      await guests._cacheItem(data)

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  },

  patch: async ({ id, ...values }: any) => {
    try {
      const { data } = await apiAxios.patch(enviroment().apiUrl + `admin/guests/${id}`, values)

      // Cache using the response because is the same of list/get
      await guests._cacheItem(data)

      await queryClient.invalidateQueries(['getGuest', id])

      return data
    } catch (e) {
      ToasterService.sendErrors(e)
      return Promise.reject(e)
    }
  }
}